import { styled, Collapse as MuiCollapse } from "@mui/material";

export const Collapse = styled(MuiCollapse)(
  () => `
    &.MuiCollapse-root {
      & > .MuiCollapse-wrapper {
        & > .MuiCollapse-wrapperInner {
          padding-top: 36px;
        }
      }
    }
  `
);
