export const GET_TEMPLATES = 'customerBackend/mail/design/GET_TEMPLATES';
export const CREATE_NEW_MAIL_TEMPLATE = 'customerBackend/mail/design/CREATE_NEW_MAIL_TEMPLATE';
export const UPDATE_MAIL_TEMPLATE = 'customerBackend/mail/design/UPDATE_MAIL_TEMPLATE';


export const getTemplates = () => ({
  type: GET_TEMPLATES,
  meta: {
    type: 'api',
    url: `mailTemplates/templates`,
    method: 'get',
  },
});


export const saveNewVersion = (content) => ({
  type: CREATE_NEW_MAIL_TEMPLATE,
  meta: {
    type: 'api',
    url: `mailTemplates/templates`,
    method: 'post',
    data:content,
  },
});

export const updateMailTemplate = (content, templateId) => ({
  type: UPDATE_MAIL_TEMPLATE,
  meta: {
    type: 'api',
    url: `mailTemplates/templates/`+templateId,
    method: 'put',
    data:{
      ...content,
      templateId
    },
  },
});

