import { LoadingIndicator } from "shared";
import { QuestionnaireStatus } from "api";
import { questionnaireSteps } from "../../config";
import { useQuestionnaireStepperState } from "../../hooks";
import { LoadingWrapper } from "./StepRenderer.styles";
import { SubmittedScreen } from "../SubmittedScreen";
import { useEffect } from "react";

export const StepRenderer = () => {
  const { selectedStep, questionnaireStatus, loading } = useQuestionnaireStepperState();

  const questionnaireSubmitted = questionnaireStatus === QuestionnaireStatus.SUBMITTED;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedStep]);

  if (loading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (questionnaireSubmitted) return <SubmittedScreen />;

  if (!selectedStep) return null;

  const Component = questionnaireSteps[selectedStep as keyof typeof questionnaireSteps];

  if (!Component) return null;

  return Component;
};
