import { styled, IconButton } from "@mui/material";
import { MaterialDesignContent, CustomContentProps, useSnackbar } from "notistack";
import { ForwardedRef, forwardRef, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const NotificationAlertComponent = (
  props: CustomContentProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { t } = useTranslation();

  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    closeSnackbar(props.id);
  }, [closeSnackbar, props.id]);

  return (
    <MaterialDesignContent
      ref={ref}
      {...props}
      message={t(props.message as string)}
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export const NotificationAlert = styled(
  forwardRef<HTMLDivElement, CustomContentProps>(NotificationAlertComponent)
)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    },
    variant
  }) => {
    let backgroundColor: string = custom.turquoise.main;

    switch (variant) {
      case "success":
        backgroundColor = custom.green.main;
        break;
      case "warning":
        backgroundColor = custom.orange.main;
        break;
      case "error":
        backgroundColor = custom.coral.main;
        break;
    }

    return `
      background-color: ${backgroundColor};
      width: auto;

      ${[breakpoints.up("sm")]} {
        width: 500px;
      }

      ${[breakpoints.up("xl")]} {
        max-width: 900px;

      }
    `;
  }
);
