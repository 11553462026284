import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      border-radius: 10px;
      flex-direction: column;
      width: 950px;
      overflow: hidden;

      ${breakpoints.down("xl")} {
        width: 100%;
      }
    }
  `
);

export const DaysWrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      padding: 16px 36px 25px;
      background-color: ${custom.grey.light};
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      box-shadow: 0px 13px 16px #00000029;

      ${breakpoints.up("md")} {
        padding: 16px 55px 25px;
        width: auto;
      }
    }
  `
);

export const TimeInputWrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      background-color: ${custom.grey.main};
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;

      ${breakpoints.up("xl")} {
        align-items: stretch;
        flex-direction: row;
      }
    }
  `
);

export const TimeInputDelimiter = styled(MuiBox)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      width: 100%;
      height: 1px;
      background-color: ${custom.white.light};

      ${breakpoints.up("lg")} {
        height: auto;
        width: 1px;
      }
    }
  `
);

export const ErrorText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.coral.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      margin-top: 28px;

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${body1.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    }
  `
);
