export const GET_TEMPLATES = 'customerBackend/mail/abtest/GET_TEMPLATES';
export const CREATE_NEW_ROW = 'customerBackend/mail/abtest/CREATE_NEW_ROW';
export const UPDATE_ROW = 'customerBackend/mail/abtest/UPDATE_ROW';
export const START_TEST = 'customerBackend/mail/abtest/START_TEST';



export const getTemplates = () => ({
  type: GET_TEMPLATES,
  meta: {
    type: 'api',
    url: `abtests`,
    method: 'get',
  },
});

export const updateRow = (row) => ({
  type: UPDATE_ROW,
  meta: {
    type: 'api',
    url: `abtests/` + row.name,
    method: 'put',
    data:row,
  },
});


export const startTest = (name) => ({
  type: START_TEST,
  meta: {
    type: 'api',
    url: `abtests/` + name+"/start",
    method: 'put',
  },
});


export const createNewRow = (newName) => ({
  type: CREATE_NEW_ROW,
  newName,
});


export const saveNewVersion = (content) => ({
  // type: CREATE_NEW_MAIL_TEMPLATE,
  // meta: {
  //   type: 'api',
  //   url: `mailTemplates/templates`,
  //   method: 'post',
  //   data:content,
  // },
});

export const updateMailTemplate = (content, templateId) => ({
  // type: UPDATE_MAIL_TEMPLATE,
  // meta: {
  //   type: 'api',
  //   url: `mailTemplates/templates/`+templateId,
  //   method: 'put',
  //   data:{
  //     ...content,
  //     templateId
  //   },
  // },
});

