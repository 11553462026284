import { Box as MuiBox, styled } from "@mui/material";

export const FormWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin: 0 20px;
      padding: 80px 0 65px;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 500px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);
