import React from "react";
import { styled, ButtonGroup as MuiButtonGroup } from "@mui/material";
import { ButtonGroupProps } from "./ButtonGroup.props";

export const ButtonGroup = styled(
  React.forwardRef<HTMLDivElement, ButtonGroupProps>(function ForwardedButtonGroup(
    { variant = "outlined", children, ...props },
    ref
  ) {
    return (
      <MuiButtonGroup {...props} ref={ref} variant={variant}>
        {children}
      </MuiButtonGroup>
    );
  })
)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    },
    variant = "outlined"
  }) => {
    let color;
    let hoverColor;
    let backgroundColor;
    let hoverBackgroundColor;
    let borderColor;
    let hoverBorderColor;

    switch (variant) {
      case "contained":
        color = custom.white.light;
        hoverColor = custom.white.main;
        backgroundColor = custom.grey.dark;
        hoverBackgroundColor = custom.turquoise.main;
        borderColor = custom.white.dark;
        hoverBorderColor = hoverBackgroundColor;
        break;
      case "text":
        color = custom.turquoise.main;
        hoverColor = custom.turquoise.main;
        backgroundColor = "transparent";
        hoverBackgroundColor = `${custom.turquoise.main}29`;
        borderColor = color;
        hoverBorderColor = hoverColor;
        break;
      case "outlined":
        color = custom.turquoise.main;
        hoverColor = custom.turquoise.main;
        backgroundColor = "transparent";
        hoverBackgroundColor = `${custom.turquoise.main}29`;
        borderColor = color;
        hoverBorderColor = hoverColor;
        break;
    }

    return `
      & .MuiButton-root {
        max-height: calc(${body2.desktop.lineHeight} + (10px * 2)); // vertical padding + line height
        color: ${color};
        font-size: ${body2.desktop.fontSize};
        line-height: ${body2.desktop.lineHeight};
        background-color: ${backgroundColor};
        border: 0;
        padding: 10px 16px;

        &.Mui-disabled {
          color: ${color};
          background-color: ${backgroundColor};
        }

        &.MuiButtonGroup-grouped {
          :not(:last-of-type) {
            border-color: ${borderColor};
          }

          &:not(:last-of-type) {
            &:hover {
              border-color: ${hoverBorderColor};
            }

            &.MuiButton-containedSuccess {
              color: ${hoverColor};
              background-color: ${hoverBackgroundColor};
            }
          }

          &:hover {
            color: ${hoverColor};
            background-color: ${hoverBackgroundColor};
          }

          &.MuiButton-containedSuccess {
            color: ${hoverColor};
            background-color: ${hoverBackgroundColor};
          }
        }

        &.MuiButtonGroup-groupedText {
          &.MuiButtonGroup-groupedHorizontal {
            &:not(:last-of-type) {
              border-right: 1px solid;
            }
          }

          &.MuiButtonGroup-groupedVertical {
            &:not(:last-of-type) {
              border-bottom: 1px solid;
            }
          }

          &.MuiButton-textSuccess {
            color: ${hoverColor};
            background-color: ${hoverBackgroundColor};
          }
        }

        &.MuiButtonGroup-groupedOutlined {
          border: 1px solid;

          &.MuiButtonGroup-groupedHorizontal {
            &:not(:last-of-type) {
              border-right: 0;
            }
          }

          &.MuiButtonGroup-groupedVertical {
            &:not(:last-of-type) {
              border-bottom: 0;
            }
          }

          &.MuiButton-outlinedSuccess {
            color: ${hoverColor};
            background-color: ${hoverBackgroundColor};
          }
        }
      }
    `;
  }
);
