import { useCallback, useState } from "react";

const FIRST_PAGE_OFFSET: number = 0;
const DEFAULT_LIMIT: number = 5;

export const usePagination = (initialLimit: number = DEFAULT_LIMIT) => {
  const [{ offset, limit }, setPaginationState] = useState({
    offset: FIRST_PAGE_OFFSET,
    limit: initialLimit
  });

  const getPage = useCallback(
    (page: number) => {
      setPaginationState((state) => ({ ...state, offset: page * limit }));
    },
    [limit]
  );

  const getFirstPage = useCallback(() => {
    setPaginationState((state) => ({ ...state, offset: FIRST_PAGE_OFFSET }));
  }, []);

  return { limit, offset, getPage, getFirstPage, setPaginationState };
};
