import { GetWebsiteImageRequest, GetWebsiteImageResponse } from "api";
import { apiClient } from "../../../utils";

export const getWebsiteImage = async ({
  mongoShopId,
  ...values
}: GetWebsiteImageRequest & { mongoShopId: string }) => {
  const { data } = await apiClient.get<GetWebsiteImageResponse>(`shop/${mongoShopId}/screenshot`, {
    params: values
  });

  return data;
};
