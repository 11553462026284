import Icon from "@mui/icons-material/ExpandMoreOutlined";
import { styled, Button as MuiButton, Theme, MenuItem as MuiMenuItem } from "@mui/material";
import { NavigationButtonStylesProps } from "../types";
import { NavigationBarButtonProps } from "./NavigationBarButton.props";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: "transparent",
  marginTop: "20px",
  minWidth: "292px",

  "& .MuiList-root": {
    padding: "17px 0",
    backgroundColor: custom.background,
    boxShadow: custom.shadows.navigation
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});

export const NavigationBarButtonStyles = styled(
  ({ children, withSubroutes, ...props }: NavigationButtonStylesProps) => (
    <MuiButton
      {...props}
      variant="text"
      disableRipple
      endIcon={withSubroutes && <Icon viewBox="3 3 18 18" />}
    >
      {children}
    </MuiButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "active"
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    },
    active,
    selected
  }) => {
    const buttonColor = active || selected ? custom.turquoise.main : custom.white.main;

    return `
      &.MuiButton-root {
        color: ${buttonColor};
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
        min-width: 0;
        text-transform: uppercase;
        box-sizing: content-box;
        height: ${body1.desktop.lineHeight};

        & .MuiButton-endIcon {
          margin-left: 6px;
          margin-right: 0;
        }

        & .MuiButton-endIcon {
          transform: ${active ? "rotate(180deg)" : "rotate(0deg)"};
        }

        &.Mui-selected {
          background: none;
          color: ${custom.turquoise.main};
        }

        :hover {
          background: none;
          color: ${custom.turquoise.main};
        }

        :active {
          color: ${custom.white.light};
        }
      }
    `;
  }
);

export const MenuItem = styled(({ children, href, ...props }: NavigationBarButtonProps) => {
  return (
    <MuiMenuItem component="a" href={href} {...props}>
      {children}
    </MuiMenuItem>
  );
})(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => {
    return `
      padding: 8px 16px;
      color: ${custom.white.light};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};

      &.Mui-disabled {
        color: ${custom.white.dark};
      }

      &:hover {
        color: ${custom.white.main};
      }

      &.Mui-selected {
        background: none;
        color: ${custom.turquoise.main};

        &:hover {
          background: none;
          color: ${custom.turquoise.main};
        }

        &.Mui-disabled {
          opacity: 1;
        }
      }
    `;
  }
);
