import { useMutation } from "@tanstack/react-query";
import { ResetPasswordResponse } from "api";
import { AxiosError } from "axios";
import { FormProps } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { postResetPassword } from "../../api";
import { ResetPasswordValues } from "../../types";

export const useResetPasswordMutation = () => {
  const { mutateAsync } = useMutation<
    ResetPasswordResponse,
    AxiosError<{ message: string }>,
    ResetPasswordValues
  >(postResetPassword);

  const execute: FormProps<ResetPasswordValues, ResetPasswordValues>["onSubmit"] = async (
    values
  ) => {
    try {
      await mutateAsync({
        email: values.email.toLowerCase()
      });
    } catch (err) {
      return {
        [FORM_ERROR]: "global.error.serverUnavailable"
      };
    }
  };

  return execute;
};
