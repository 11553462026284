import { Menu, useTheme } from "@mui/material";
import React, { useState, useCallback, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  NavigationBarButtonStyles,
  makePaperStyles,
  MenuItem
} from "../NavigationBarButton.styles";
import { useAuthState } from "../../../../hooks";
import { NavigationButtonWithSubroutesProps, PathConfig } from "../../../../types";
import { withFeatureVisibility } from "../../../../HoC";

export const NavigationBarButtonWithSubroutes = ({
  translationKey,
  subroutes,
  onClick,
  shopId,
  requiredPermission
}: NavigationButtonWithSubroutesProps) => {
  const theme = useTheme();

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const { checkPermission } = useAuthState();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (menuAnchor) {
        setMenuAnchor(null);
      } else {
        setMenuAnchor(event.currentTarget);
      }
    },
    [menuAnchor]
  );

  const handleCloseNavMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleSubrouteClick = useCallback(
    (route: PathConfig) => (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onClick(route);
      setMenuAnchor(null);
    },
    [onClick]
  );

  const parsedSubroutes = useMemo(
    () =>
      subroutes.map(({ translationKey, path, requiredPermission, featureFlag }) => {
        if (requiredPermission && !checkPermission(requiredPermission)) return null;

        const active = pathname === path.path;

        const href = path.includeShopId ? `${path.path}?shop=${shopId}` : path.path;

        if (featureFlag) {
          const Component = withFeatureVisibility(MenuItem, featureFlag);
          return (
            <Component
              selected={active}
              disabled={active}
              href={href}
              key={translationKey}
              onClick={handleSubrouteClick(path)}
            >
              {t(translationKey)}
            </Component>
          );
        } else {
          return (
            <MenuItem
              selected={active}
              disabled={active}
              href={href}
              key={translationKey}
              onClick={handleSubrouteClick(path)}
            >
              {t(translationKey)}
            </MenuItem>
          );
        }
      }),
    [subroutes, pathname, shopId, checkPermission]
  );

  const routeActive = subroutes.some(({ path }) => path.path === pathname);

  const active = Boolean(menuAnchor);

  if (requiredPermission && !checkPermission(requiredPermission)) {
    return null;
  }

  return (
    <React.Fragment>
      <NavigationBarButtonStyles
        withSubroutes
        selected={routeActive}
        active={active}
        onClick={handleOpenNavMenu}
      >
        {t(translationKey)}
      </NavigationBarButtonStyles>
      <Menu
        id={`${translationKey}-menu`}
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={active}
        slotProps={{
          paper: {
            sx: makePaperStyles(theme)
          }
        }}
        onClose={handleCloseNavMenu}
      >
        {parsedSubroutes}
      </Menu>
    </React.Fragment>
  );
};
