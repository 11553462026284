import decode from "jwt-decode";
import { LocalStorageTokenData } from "../../utils";
import { Permission, LocalStorageKey } from "../../types";
import { useLocalStorageValue } from "../useLocalStorageValue";

export const useAuthState = () => {
  const token = useLocalStorageValue(LocalStorageKey.TOKEN);

  const checkPermission = (requiredPermission: Permission): boolean => {
    if (!token) return false;
    const { role } = decode<LocalStorageTokenData>(token);
    return requiredPermission >= role;
  };

  const getRole = (): Permission | undefined => {
    if (!token) return;
    const { role } = decode<LocalStorageTokenData>(token);
    return role;
  };

  return { token, checkPermission, getRole };
};
