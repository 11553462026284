import { useSelectedShop, useQueryConfig, useQueryWrapper } from "shared";
import { getComeBackMailingReprocessStatus } from "../../api";

export const useComeBackMailingReprocessStatus = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper(
    [`${queryName}-reprocess-status`, selectedShopId],
    async () => getComeBackMailingReprocessStatus(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { initialState: data, loading: isLoading };
};
