import { useQueryClient, useMutation } from "@tanstack/react-query";
import { BrowseAbandonmentVoucherSettings, LegacyVoucherTypes, TriggerConfig } from "api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import {
  useQueryConfig,
  useSelectedShop,
  useErrorFormatter,
  useNotifications,
  NetworkError
} from "shared";
import { postBrowseAbandonmentVoucher } from "../../api";
import {
  BrowseAbandonmentVoucherSettingsPayload,
  BrowseAbandonmentVoucherSettingsValues,
  BrowseAbandonmentVoucherInitialSettingsValues
} from "../../types";
import { mapFormToCustomConditions } from "../../utils";

export const useCreateBrowseAbandonmentVoucher = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: createBrowseAbandonmentVoucher } = useMutation<
    BrowseAbandonmentVoucherSettings,
    AxiosError<{ message: string }>,
    BrowseAbandonmentVoucherSettingsPayload & { shopId: number }
  >(postBrowseAbandonmentVoucher);

  const executeCreateBrowseAbandonmentVoucher: FormProps<
    BrowseAbandonmentVoucherSettingsValues,
    BrowseAbandonmentVoucherInitialSettingsValues
  >["onSubmit"] = useCallback(
    async ({
      contactPerson,
      title,
      voucherType,
      customParameter,
      isVoucherExclusive,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      voucherCode,
      voucherCurrency,
      voucherFootnote,
      voucherListName,
      voucherValue,
      conditions
    }) => {
      try {
        await createBrowseAbandonmentVoucher({
          customParameter: customParameter?.length ? customParameter : undefined,
          utmCampaign: utmCampaign?.length ? utmCampaign : undefined,
          utmContent: utmContent?.length ? utmContent : undefined,
          utmMedium: utmMedium?.length ? utmMedium : undefined,
          utmSource: utmSource?.length ? utmSource : undefined,
          voucherFootnote: voucherFootnote?.length ? voucherFootnote : undefined,
          voucherCode: voucherType === LegacyVoucherTypes.CODE ? voucherCode : undefined,
          isVoucherExclusive:
            voucherType === LegacyVoucherTypes.CODE ? Boolean(isVoucherExclusive) : undefined,
          voucherListName: voucherType === LegacyVoucherTypes.LIST ? voucherListName : undefined,
          shopId: selectedShopId!,
          contactPersonId: contactPerson.contactPersonId,
          conditions: mapFormToCustomConditions(conditions),
          title,
          voucherType,
          voucherCurrency,
          voucherValue
        });

        queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);
        queryClient.invalidateQueries(["browseAbandonmentCanEnable", selectedShopId]);

        const data = queryClient.getQueryData<TriggerConfig>([
          "browseAbandonmentConfig",
          selectedShopId
        ]);

        if (data?.enabled) {
          showNotification("featuresModule.browseAbandonment.createSuccessMessage", "success");
        } else {
          showNotification("featuresModule.browseAbandonment.notActivatedMessage", "warning");
        }
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [selectedShopId, queryName, limit, offset, showNotification]
  );

  return executeCreateBrowseAbandonmentVoucher;
};
