/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import UpSiteTitle from '../../components/UpSiteTitle';
import Logo from './uptain_Logo_stanze_weiß.png';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  color: #fff;
  text-align: center;
  > img {
    width: 150px;
  }
  > a {
    color: #fff;
  }
`;

const NotFound = () => (
  <Wrapper>
    <UpSiteTitle title="404" />
    <Content>
      <img src={Logo} alt="Uptain Logo" />
      <h1>
        <FormattedMessage
          id="app.components.NotFoundPage.header"
          defaultMessage="This page is not available."
        />
      </h1>
      <Link to="/dashboard">
        <FormattedMessage
          id="app.components.NotFoundPage.Link"
          defaultMessage="Back to the dashboard"
        />
      </Link>
    </Content>
  </Wrapper>
);

export default NotFound;
