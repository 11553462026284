import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Subheading, DescriptionText, Switch, Divider } from "shared";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { addOptionsValidation } from "./AddOptions.validation";
import { ErrorText, StepWrapper, StepsContent } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useAddOptionsStep } from "../../../hooks";
import { FieldLine, FieldTitle, FieldsWrapper } from "./AddOptions.styles";
import { questionnaireAddOptionsFieldsConfig } from "../../../config";

export const AddOptions = () => {
  const { t } = useTranslation();

  const { data, hasPrev, metadata, handlePrevStep, executeSubmitFormOfAddress } =
    useAddOptionsStep();

  const fields = useMemo(
    () => questionnaireAddOptionsFieldsConfig.filter((field) => metadata[field]),
    [metadata]
  );

  return (
    <Form
      initialValues={data}
      validate={addOptionsValidation(fields)}
      onSubmit={executeSubmitFormOfAddress}
      render={({ handleSubmit, submitting, errors, touched }) => {
        const hasError = errors?.[FORM_ERROR] && Object.values(touched ?? {}).some(Boolean);

        return (
          <StepWrapper>
            <StepsContent>
              <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
              <Subheading>{t("questionnaireModule.steps.start.addOptions.title")}</Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.start.addOptions.description")}
              </DescriptionText>
              <FieldsWrapper error={hasError}>
                {fields.map((field, index) => (
                  <Fragment key={field}>
                    <Field
                      name={field}
                      type="checkbox"
                      render={({ input }) => (
                        <FieldLine>
                          <FieldTitle>
                            {t(`questionnaireModule.steps.start.addOptions.form.${field}.label`)}
                          </FieldTitle>
                          <Switch {...input} color={hasError ? "error" : "default"} />
                        </FieldLine>
                      )}
                    />
                    {index < fields.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </FieldsWrapper>
              {hasError && <ErrorText>{t(errors[FORM_ERROR])}</ErrorText>}
              <NavigationButtons
                prevDisabled={!hasPrev}
                nextLoading={submitting}
                onNextClick={handleSubmit}
                onPrevClick={handlePrevStep}
              />
            </StepsContent>
            <MobileStepper
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepWrapper>
        );
      }}
    />
  );
};
