import { styled, Box as MuiBox } from "@mui/material";
import { ReactComponent as LaptopGraphics } from "assets/icons/laptop_wrapper.svg";
import { ReactComponent as MobileGraphics } from "assets/icons/mobile_wrapper.svg";
import React from "react";
import { WrapperProps } from "./PreviewComponent.props";
import { DeviceType } from "../../../../types";

export const Wrapper = styled((props: WrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "device"
})(({ device }) => {
  const width = device === DeviceType.DESKTOP ? 300 : 246;

  const height = device === DeviceType.DESKTOP ? 248 : 490;

  return `
    &.MuiBox-root {
      position: relative;
      width: ${width}px;
      height: ${height}px;
    }
  `;
});

export const LaptopDisplay = styled(
  React.forwardRef<
    HTMLIFrameElement,
    React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & {
      background: string;
    }
  >(function ForwardRefIframe(props, ref) {
    return <iframe ref={ref} {...props} />;
  }),
  {
    shouldForwardProp: (prop) => prop !== "background"
  }
)(
  ({ background }) => `
    z-index: 1;
    position: absolute;
    top: 11px;
    left: 35px;
    width: 1440px;
    height: 900px;
    transform: scale(0.16);
    transform-origin: 0 0;
    background-image: url("data:image/png;base64,${background}");
  `
);

export const MobileDisplay = styled(
  React.forwardRef<
    HTMLIFrameElement,
    React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & {
      background: string;
    }
  >(function ForwardRefIframe(props, ref) {
    return <iframe ref={ref} {...props} />;
  }),
  {
    shouldForwardProp: (prop) => prop !== "background"
  }
)(
  ({ background }) => `
    z-index: 1;
    position: absolute;
    top: 15px;
    left: 16px;
    width: 400px;
    height: 863px;
    transform: scale(0.534);
    transform-origin: 0 0;
    background-image: url("data:image/png;base64,${background}");
    background-size: 100% auto;
    border-radius: 34px;
    overflow: hidden;
  `
);

export const DesktopWrapperGraphics = styled(LaptopGraphics)(
  () => `
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  `
);

export const MobileWrapperGraphics = styled(MobileGraphics)(
  () => `
    z-index: 2;
    height: 490px;
    width: 246px;
    pointer-events: none;
    top: 0;
    left: 0;
    position: absolute;
  `
);

export const LoadingWrapper = styled((props: WrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "device"
})(({ device }) => {
  if (device === DeviceType.MOBILE) {
    return `
      &.MuiBox-root {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `;
  } else {
    return `
      &.MuiBox-root {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 167px;
      }
    `;
  }
});
