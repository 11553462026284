import { useCallback, ChangeEvent } from "react";
import { TextInputWrapperProps } from "./TextInput.props";
import { Box } from "./TextInput.styled";
import { PasswordInput, TooltipInput } from "./variants";

export const TextInput = ({
  password,
  errorMessage,
  error,
  value,
  onChange,
  marginBottom,
  marginTop,
  ...props
}: TextInputWrapperProps) => {
  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  if (password) {
    return (
      <Box error={error} marginTop={marginTop} marginBottom={marginBottom}>
        <PasswordInput
          {...props}
          error={error}
          errorMessage={errorMessage}
          onChange={handleChange}
          value={value}
        />
      </Box>
    );
  }

  return (
    <Box error={error} marginTop={marginTop} marginBottom={marginBottom}>
      <TooltipInput
        {...props}
        error={error}
        errorMessage={errorMessage}
        onChange={handleChange}
        value={value}
      />
    </Box>
  );
};
