import React from "react";
import {
  styled,
  Paper as MuiPaper,
  MenuItem as MuiMenuItem,
  Popper as MuiPopper
} from "@mui/material";

import { PaperProps, MenuItemProps } from "./SplitButton.props";

export const Paper = styled(
  React.forwardRef<HTMLDivElement, PaperProps>(function ForwardedPaper(props, ref) {
    return <MuiPaper ref={ref} {...props} />;
  })
)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
      color: ${custom.white.main};
      background-color: ${custom.background};
      margin-top: 8px;
    `
);

export const MenuItem = styled((props: MenuItemProps) => <MuiMenuItem {...props} />)(
  ({
    theme: {
      palette: { custom }
    }
  }) => {
    return `
      &.Mui-selected {
        background-color: ${custom.grey.main};
        
        &:hover {
          background-color: ${custom.grey.light};
        }
      }

      &.Mui-disabled {
        color: ${custom.white.dark};
        opacity: 1;
      }

      &:hover {
        background-color: ${custom.grey.light};
      }
    `;
  }
);

export const Popper = styled(MuiPopper)(({ anchorEl }) => {
  return `
    min-width: ${(anchorEl as HTMLDivElement)?.offsetWidth}px;
  `;
});
