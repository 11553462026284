const dateTypes = {
  year(date, val) {
    date.setFullYear(date.getFullYear() + val);
    return date;
  },
  minutes(date, val) {
    date.setMinutes(date.getMinutes() + val);
    return date;
  },
};

function getCookieExpirationDate(date, acc) {
  let dt = date;

  if (!(dt instanceof Date)) dt = new Date();

  Object.entries(acc).forEach(([type, val]) => {
    if (dateTypes[type]) {
      dt = dateTypes[type](dt, val);
    }
  });

  return dt;
}

function setCookie(name, value, opt = {}) {
  const options = {
    path: '/',
    ...opt,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}`;

  Object.entries(options).forEach(([key, val]) => {
    updatedCookie += `; ${key}`;
    if (val !== true) {
      updatedCookie += `=${val}`;
    }
  });

  document.cookie = updatedCookie;
}

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export { getCookieExpirationDate, setCookie, getCookie, deleteCookie };
