export enum QuestionnaireProduct {
  NEWSLETTER_FORMS = "NEWSLETTER_FORMS",
  ACTIVATION_POPUPS = "ACTIVATION_POPUPS",
  ABANDONMENT_MAILS = "ABANDONMENT_MAILS",
  BROWSE_ABANDONMENT = "BROWSE_ABANDONMENT",
  COME_BACK_MAILING = "COME_BACK_MAILING"
}

export type QuestionnaireProductsStepData = {
  enabledProducts: QuestionnaireProduct[];
};
