import { useCallback, useState, MouseEvent } from "react";
import { RgbaColorPicker } from "react-colorful";
import { colord } from "colord";
import { Menu as MuiMenu, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ColorizeIcon from "@mui/icons-material/Colorize";
import {
  Button,
  Label,
  PreviewWrapper,
  PreviewColor,
  PreviewText,
  Popover,
  TypeSwitcher,
  TypeMenuItem,
  makePaperStyles,
  InputsWrapper,
  AddColorButton,
  ColorizeButton,
  SavedColorButton
} from "./ColorPicker.styles";
import { ColorPickerProps } from "./ColorPicker.props";
import { ColorPickerDisplayType } from "./ColorPicker.types";
import { HexValueInputs, RgbValueInputs } from "./Inputs";
import { useSavedColors } from "../../../hooks";
import { ColorValue } from "../../../types";

export const ColorPicker = ({ value, label, onChange }: ColorPickerProps) => {
  const [intermediateValue, setIntermediateValue] = useState<string>("");

  const theme = useTheme();

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const [typeAnchor, setTypeAnchor] = useState<HTMLButtonElement | null>(null);

  const [displayType, setDisplayType] = useState(ColorPickerDisplayType.HEX);

  const { savedColors, saveColor } = useSavedColors();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElement(event.currentTarget);
      setIntermediateValue(value);
    },
    [value]
  );

  const handleClose = useCallback(() => {
    setAnchorElement(null);
    setTypeAnchor(null);
    onChange(intermediateValue);
    setIntermediateValue("");
  }, [intermediateValue, onChange]);

  const handleTypeClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setTypeAnchor(event.currentTarget);
  }, []);

  const handleTypeClose = useCallback(() => {
    setTypeAnchor(null);
  }, []);

  const handleChange = useCallback((newColor: string) => {
    setIntermediateValue(newColor.toUpperCase());
  }, []);

  const handlePickerChange = useCallback((value: ColorValue) => {
    const hex = colord(value).toHex();

    setIntermediateValue(hex.toUpperCase());
  }, []);

  const handleTypeChange = useCallback(
    (type: ColorPickerDisplayType) => () => {
      setDisplayType(type);
      handleTypeClose();
    },
    [handleTypeClose]
  );

  const saveCurrentColor = useCallback(() => {
    saveColor(value);
  }, [saveColor, value]);

  const handleColorize = useCallback(async () => {
    if (window.EyeDropper) {
      try {
        const eyeDropper = new window.EyeDropper();

        const { sRGBHex } = await eyeDropper.open();

        setIntermediateValue(sRGBHex.toUpperCase());
      } catch (error) {
        return;
      }
    }
  }, []);

  const displayColorValue = intermediateValue !== "" ? intermediateValue : value;

  const pickerValue = colord(displayColorValue).rgba;

  const opened = Boolean(anchorElement);

  const typeOpened = Boolean(typeAnchor);

  const eyeDropperAvailable = Boolean(window.EyeDropper);

  return (
    <>
      <Button onClick={handleClick}>
        <Label>{label}</Label>
        <PreviewWrapper>
          <PreviewColor color={displayColorValue} />
          <PreviewText>{displayColorValue}</PreviewText>
        </PreviewWrapper>
      </Button>
      <Popover
        open={opened}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        <RgbaColorPicker color={pickerValue} onChange={handlePickerChange} />
        <InputsWrapper>
          <TypeSwitcher value={displayType} active={typeOpened} onClick={handleTypeClick} />
          {displayType === ColorPickerDisplayType.HEX && (
            <HexValueInputs value={displayColorValue} onChange={handleChange} />
          )}
          {displayType === ColorPickerDisplayType.RGB && (
            <RgbValueInputs value={displayColorValue} onChange={handleChange} />
          )}
          {eyeDropperAvailable && (
            <ColorizeButton onClick={handleColorize}>
              <ColorizeIcon />
            </ColorizeButton>
          )}
        </InputsWrapper>
        <InputsWrapper>
          <AddColorButton onClick={saveCurrentColor}>
            <AddCircleOutlineIcon />
          </AddColorButton>
          {savedColors.map((color) => (
            <SavedColorButton key={color} onClick={() => handleChange(color)} colorValue={color} />
          ))}
        </InputsWrapper>
        <MuiMenu
          open={typeOpened}
          anchorEl={typeAnchor}
          onClose={handleTypeClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          slotProps={{
            paper: {
              sx: makePaperStyles(theme)
            }
          }}
        >
          {Object.values(ColorPickerDisplayType).map((type) => (
            <TypeMenuItem key={type} value={type} onClick={handleTypeChange(type)}>
              {type}
            </TypeMenuItem>
          ))}
        </MuiMenu>
      </Popover>
    </>
  );
};
