/**
 * App routes
 * Every route use lazy loading import and loadingState provided as a fallback
 * If async import is not needed:
 * 1. Remove lazy(() => import()) from component and use default import
 * 2. Remove loadingState
 */

import { lazy } from 'react';

import CustomerLogin from '../containers/CustomerLogin';

import { requireAuth, requireSalesAuth, requireAdminAuth } from '../requireAuth';

const routes = [
  {
    exact: true,
    path: '/create-account/success',
    component: lazy(() =>
      import('../containers/CustomerLogin/Containers/AccountCreationSuccessful'),
    ),
    parent: CustomerLogin,
    loadingState: 'login',
  },
  {
    exact: true,
    path: '/create-account/onboarding',
    onEnter: requireAuth,
    component: lazy(() =>
      import('../containers/OnBoarding/Questionnaire/components/Welcome'),
    ),
    parent: CustomerLogin,
    loadingState: 'login',
  },
  {
    exact: true,
    path: '/create-account/:key',
    component: lazy(() =>
      import('../containers/CustomerLogin/Containers/CreateAccount'),
    ),
    parent: CustomerLogin,
    loadingState: 'login',
  },
  {
    exact: true,
    path: '/dashboard',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/LiveDashboard')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/features/activation-popups',
    onEnter: requireAuth,
    component: lazy(() =>
      import('../containers/ProductSettings/ActivationSettings'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/features/abandonment-mails',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/ProductSettings/EmailSettings')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/features/newsletter-forms',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/ProductSettings/OptinSettings')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/integrations',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/IntegrationSettings')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/algorithm',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/CustomerSettings')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/account/my-account',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Settings')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/activationDesigner',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/ActivationDesigner')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/activationManager',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/ActivationManager')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/editor/content',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/Cms/Editor/app')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/template-editor',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/TemplateEditor')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/abtests',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/ABTestDesigner')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/mailTemplates',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/MailTemplateDesigner')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/products/tracking',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/ShopSettings/Tracking')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/products/shop-configuration',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/ShopConfiguration')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/fuzzyEditor',
    onEnter: requireAdminAuth,
    component: lazy(() => import('../containers/FuzzyEditor')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/reported-regains',
    onEnter: requireAuth,
    component: lazy(() =>
      import('../containers/Admin/ReportedData/ReportedRegains'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/reported-subscriptions',
    onEnter: requireAuth,
    component: lazy(() =>
      import('../containers/Admin/ReportedData/ReportedSubscriptions'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/store-administration',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Admin/StoreAdministration')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/user-administration',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Admin/UserAdministration')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/feature-toggles',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Admin/FeatureToggles')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/admin/test-pricing-form',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Admin/TestPricingForm')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/sales/provision',
    onEnter: requireAuth,
    component: lazy(() => import('../containers/Sales/Dashboard')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/mainCancellationDrops',
    onEnter: requireSalesAuth,
    component: lazy(() =>
      import('../containers/Analysis/Drops/MailCancellationDrops'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/activationCancellationDrops',
    onEnter: requireSalesAuth,
    component: lazy(() =>
      import('../containers/Analysis/Drops/ActivationCancellationDrops'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/activationRegainDrops',
    onEnter: requireSalesAuth,
    component: lazy(() =>
      import('../containers/Analysis/Drops/ActivationRegainDrops'),
    ),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/mailRegainDrops',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Drops/MailRegainDrops')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/sessionDrops',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Drops/SessionDrops')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/orderDrops',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Drops/OrderDrops')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/zeroScv',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Irregular/ZeroScv')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/invoiceDrops',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Drops/Invoices')),
    loadingState: 'dashboard',
  },
  {
    exact: true,
    path: '/analysis/invoices',
    onEnter: requireSalesAuth,
    component: lazy(() => import('../containers/Analysis/Invoices/Overview')),
    loadingState: 'dashboard',
  },
  {
    path: '*',
    component: lazy(() => import('../containers/NotFoundPage')),
    loadingState: 'dashboard',
  },
];

export default routes;
