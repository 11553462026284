/* eslint-disable prefer-template */
import Immutable from 'immutable';
import {
  GET_ALL_SHOPS,
  CREATE_INVOICE,
  CREATE_SHOP,
  DELETE_SHOP,
  RESET_SHOP_DELETION,
  CREATE_REPORT,
  REQUEST_SHOP_REGISTRATION_TOKEN,
} from './actions';

const GET_ALL_SHOPS_LOADING =
  'react-boilerplate/Admin/StoreAdministration/GET_ALL_SHOPS_LOADING';
const CREATE_INVOICE_LOADING =
  'react-boilerplate/Admin/StoreAdministration/CREATE_INVOICE_LOADING';
const CREATE_INVOICE_ERROR =
  'react-boilerplate/Admin/StoreAdministration/CREATE_INVOICE_ERROR';
const DELETE_SHOP_LOADING =
  'react-boilerplate/Admin/StoreAdministration/DELETE_SHOP_LOADING';
const RESET_SHOP_DELETION_LOADING =
  'react-boilerplate/Admin/StoreAdministration/RESET_SHOP_DELETION_LOADING';
const RESET_SHOP_DELETION_ERROR =
  'react-boilerplate/Admin/StoreAdministration/RESET_SHOP_DELETION_ERROR';
const CREATE_REPORT_LOADING =
  'react-boilerplate/Admin/StoreAdministration/CREATE_REPORT_LOADING';

export default function storeAdministrationReducer(
  state = Immutable.Map(),
  action,
) {
  switch (action.type) {
    case GET_ALL_SHOPS: {
      return state.merge({ shops: action.data.data, loading: false });
    }
    case GET_ALL_SHOPS_LOADING: {
      return state.set('loading', true);
    }
    case CREATE_INVOICE_LOADING: {
      return state.merge({
        creatingInvoice: true,
        invoiceError: false,
        invoiceData: {},
      });
    }
    case CREATE_INVOICE_ERROR: {
      return state.merge({
        creatingInvoice: false,
        invoiceError: true,
        invoiceData: {},
      });
    }
    case CREATE_INVOICE: {
      return state.merge({
        creatingInvoice: false,
        invoiceError: false,
        invoiceData: action.data[0],
      });
    }
    case CREATE_SHOP + '_LOADING': {
      return state.set('creatingShop', true);
    }
    case CREATE_SHOP: {
      return state.set('creatingShop', false);
    }
    case DELETE_SHOP_LOADING: {
      return state.merge({
        deletingShop: true,
        deletingShopError: '',
      });
    }
    case DELETE_SHOP: {
      return state.merge({
        deletingShop: false,
        deletingShopError: '',
      });
    }
    case DELETE_SHOP + '_ERROR': {
      return state.merge({
        deletingShop: false,
        deletingShopError: action.message,
      });
    }
    case RESET_SHOP_DELETION_LOADING: {
      return state.merge({
        resetingShopDeletion: true,
        resetShopDeletionError: false,
      });
    }
    case RESET_SHOP_DELETION_ERROR: {
      return state.merge({
        resetingShopDeletion: false,
        resetShopDeletionError: true,
      });
    }
    case RESET_SHOP_DELETION: {
      return state.merge({
        resetingShopDeletion: false,
        resetShopDeletionError: false,
      });
    }
    case CREATE_REPORT_LOADING: {
      return state.set('creatingMonthlyReport', true);
    }
    case CREATE_REPORT: {
      return state.set('creatingMonthlyReport', false);
    }
    case REQUEST_SHOP_REGISTRATION_TOKEN + '_LOADING': {
      return state.set('requestingToken', true);
    }
    case REQUEST_SHOP_REGISTRATION_TOKEN: {
      // eslint-disable-next-line no-alert
      alert(
        window.location.protocol +
          '//' +
          window.location.host +
          '/register?token=' +
          action.data.data,
      );
      return state.set('requestingToken', false);
    }
    default: {
      return state;
    }
  }
}
