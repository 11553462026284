import React, { useCallback, useState } from "react";
import { Tooltip, useMediaQuery, useTheme, Zoom } from "@mui/material";
import { TextInputProps } from "../TextInput.props";
import { TextField, IconButton, Popper } from "../TextInput.styled";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const PasswordInput = ({
  errorMessage,
  error,
  value,
  infoText,
  ...props
}: TextInputProps) => {
  const { breakpoints } = useTheme();

  const smallScreen = useMediaQuery(breakpoints.down("md"));

  const [hidden, setHidden] = useState(true);

  const switchState = useCallback(() => {
    setHidden((value) => !value);
  }, []);

  return (
    <TextField
      {...props}
      value={value}
      error={error}
      helperText={error && errorMessage}
      type={hidden ? "password" : "text"}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {infoText && (
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                TransitionComponent={Zoom}
                title={infoText}
                placement={smallScreen ? "bottom" : "right"}
                PopperComponent={Popper}
                arrow
              >
                <IconButton infoPlacementMode={"password"} disableTouchRipple>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton disableTouchRipple onClick={switchState}>
              {hidden ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </IconButton>
          </React.Fragment>
        )
      }}
    />
  );
};
