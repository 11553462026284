import { FieldValidator } from "final-form";

export const minimumWaitingHoursValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  const parsedValue = Number(value);

  if (isNaN(parsedValue)) {
    return "featuresModule.errors.shouldBeANumber";
  }

  if (parsedValue < 24) {
    return "featuresModule.errors.biggerThan24";
  }

  if (parsedValue > 4320) {
    return "featuresModule.errors.lessThan4320";
  }
};
