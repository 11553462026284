export const GET_SALES_PROVISION =
  'react-boilerplate/sales/dashboard/GET_SALES_PROVISION';
export const GET_SINGLE_PAYMENT_PROVISION =
  'react-boilerplate/sales/dashboard/GET_SINGLE_PAYMENT_PROVISION';

export const getSalesProvision = (employeeId) => ({
  type: GET_SALES_PROVISION,
  meta: {
    type: 'api',
    url: `sales/provision/?employeeId=${employeeId}`,
    method: 'get',
  },
});

export const getSalesSingleTimeProvision = (employeeId) => ({
  type: GET_SINGLE_PAYMENT_PROVISION,
  meta: {
    type: 'api',
    url: `sales/singleTime/?employeeId=${employeeId}`,
    method: 'get',
  },
});
