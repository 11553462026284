import { apiClient } from "shared";

export const putBrowseAbandonmentConfig = async ({
  shopId,
  minimumWaitingHours
}: {
  shopId: number;
  minimumWaitingHours: number;
}) => {
  const { data } = await apiClient.put<void>(`/${shopId}/triggers/browse-abandonment`, {
    minimumWaitingHours
  });

  return data;
};
