export enum QuestionnaireStepType {
  ABOUT_US = "aboutUs",
  ALGORITHM = "algorithm",
  REFERRER = "referrer",
  ACCOUNT = "account",
  SHOP_SYSTEM = "shopSystem",
  PRODUCTS = "products",
  ADD_OPTIONS = "addOptions",
  FORM_OF_ADDRESS = "formOfAddress",
  TEMPLATE_DESIGN = "templateDesign",
  SIGNATURE = "signature",
  LEGAL_TEXT = "legalText",
  CUSTOMER_SUPPORT = "customerSupport",
  CUSTOMER_SUPPORT_AVAILABILITY = "customerSupportAvailability",
  ABANDONMENT_MAILS_WAITING_TIME_MIN_MINUTES = "abandonmentMailsWaitingTimeMinMinutes",
  ACTIVATION_POPUPS_VOUCHER_SETTINGS = "activationPopupsVoucherSettings",
  ABANDONMENT_MAILS_VOUCHER_SETTINGS = "abandonmentMailsVoucherSettings",
  NEWSLETTER_FORMS_VOUCHER_SETTINGS = "newsletterFormsVoucherSettings",
  BROWSE_ABANDONMENT_VOUCHER_SETTINGS = "browseAbandonmentVoucherSettings",
  COME_BACK_MAILING_VOUCHER_SETTINGS = "comeBackMailingVoucherSettings",
  MAIL_SETTINGS = "mailSettings"
}
