import { useCallback, useState } from "react";
import { Features } from "api";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { TextSettingsFormBase } from "../TextSettingsFormBase";
import { ComeBackMailingTextInitialSettingsValues } from "../../types";
import {
  useDeleteComeBackMailingText,
  useDisableComeBackMailingText,
  useEnableComeBackMailingText,
  useUpdateComeBackMailingText
} from "../../hooks";

export const ComeBackMailingTextItemForm = ({
  initialState
}: {
  initialState: ComeBackMailingTextInitialSettingsValues;
}) => {
  const [opened, setOpened] = useState(false);

  const submitMethod = useUpdateComeBackMailingText();

  const deleteMethod = useDeleteComeBackMailingText();

  const enableMethod = useEnableComeBackMailingText();

  const disableMethod = useDisableComeBackMailingText();

  const handleActivationSwitch = useCallback(
    (id: number, value: boolean) => {
      if (value) {
        enableMethod(id);
      } else {
        disableMethod(id);
      }
    },
    [enableMethod, disableMethod]
  );

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <TextSettingsFormBase
      translationKey="comeBackMailing"
      conditionFeatureName="come_back_mailing_conditions_enabled"
      possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.COME_BACK_MAILING]}
      initialState={initialState}
      formOpened={opened}
      expandIcon={<Chevron />}
      onOpen={handleOpen}
      onClose={handleClose}
      onSubmit={submitMethod}
      onDelete={deleteMethod}
      onSwitchActive={handleActivationSwitch}
    />
  );
};
