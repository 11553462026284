import { ContactPerson } from "api";
import { FieldValidator } from "final-form";

export const titleValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  if (value.length < 2) {
    return "featuresModule.errors.tooShort";
  }

  if (value.length > 128) {
    return "featuresModule.errors.tooLong";
  }
};

export const contactPersonValidator: FieldValidator<ContactPerson> = (value) => {
  if (!value.contactPersonId) {
    return "featuresModule.errors.required";
  }
};
