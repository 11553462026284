import Immutable from "immutable";
import {ADD_SETTING_LINE, DELETE_SETTING_LINE, GET_PRODUCT_TYPES, GET_SETTING_LINE, SAVE_SETTING_LINE} from "./actions";
import {productConditionMap} from "../../components/ConditionLine";

function filterOutRemovedConditions(conditions, product){
  return conditions.filter(conditionLine => productConditionMap[product].includes(conditionLine.category))
}

function productReducer(state =  Immutable.Map(), action) {
  switch (action.type) {
    case GET_PRODUCT_TYPES + '_LOADING': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex], Immutable.fromJS({loading: true, error: ''}));
    } case GET_PRODUCT_TYPES + '_ERROR': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex], Immutable.fromJS({loading: false, error: action.errorMessage}));
    } case GET_PRODUCT_TYPES: {

      //sort items by ID
      let data = action.data.data;
      const itemsById = Immutable.fromJS(data.items).reduce( (lookup, item) => lookup.set(item.get('id'), item), Immutable.Map());
      delete data.items;
      data = {items: itemsById, ...data};

      let newState = state.setIn([action.shopId, action.product, 'data', action.typeIndex], Immutable.fromJS(data));
      return newState.setIn([action.shopId, action.product, 'UIStates', action.typeIndex], Immutable.fromJS({loading: false, error: ''}));
    }
    case ADD_SETTING_LINE: {
      let newState = state.setIn([action.shopId, action.product, 'data', action.typeIndex, 'items', action.itemIndex], Immutable.fromJS(action.newData));
      return newState.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.itemIndex], Immutable.fromJS({loading: false, adding: false, error: ''}));
    }
    case GET_SETTING_LINE + '_LOADING': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex,  action.settingLineId], Immutable.fromJS({loading: true, error: ''}));
    } case GET_SETTING_LINE + '_ERROR': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId], Immutable.fromJS({loading: false, error: action.errorMessage}));
    } case GET_SETTING_LINE: {
      action.data.data.conditions = filterOutRemovedConditions(action.data.data.conditions, action.product);
      let newState = state.setIn([action.shopId, action.product, 'data', action.typeIndex, 'items', action.settingLineId], Immutable.fromJS(action.data.data));
      return newState.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId], Immutable.fromJS({loading: false, error: ''}));
    }
    case SAVE_SETTING_LINE + '_LOADING': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex,  action.settingLineId], Immutable.fromJS({[action.buttonAction]: true, error: ''}));
    } case SAVE_SETTING_LINE + '_ERROR': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId], Immutable.fromJS({[action.buttonAction]: false, error: action.errorMessage}));
    } case SAVE_SETTING_LINE: {
      let newState = state.mergeIn([action.shopId, action.product, 'data', action.typeIndex, 'items', action.settingLineId], Immutable.fromJS(action.values));
      return newState.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId], Immutable.fromJS({[action.buttonAction]: false, error: ''}));
    }
    case DELETE_SETTING_LINE + '_LOADING': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex,  action.settingLineId], Immutable.fromJS({deleting: true, error: ''}));
    } case DELETE_SETTING_LINE + '_ERROR': {
      return state.mergeIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId], Immutable.fromJS({deleting: false, error: action.errorMessage}));
    } case DELETE_SETTING_LINE: {
      let newState = state.deleteIn([action.shopId, action.product, 'data', action.typeIndex, 'items', action.settingLineId]);
      return newState.deleteIn([action.shopId, action.product, 'UIStates', action.typeIndex, action.settingLineId]);
    }
    default: {
      return state;
    }
  }
}

export default productReducer;
