import { Checkbox as MuiCheckbox, styled, Typography, FormControlLabel } from "@mui/material";
import Unchecked from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import Checked from "@mui/icons-material/CheckBoxOutlined";
import { CheckboxTypographyProps, CheckboxProps, LabelProps } from "./Checkbox.props";

const defaultIconViewBox = "1 1 22 22";

export const Checkbox = styled(
  (props: CheckboxProps) => (
    <MuiCheckbox
      {...props}
      icon={<Unchecked viewBox={defaultIconViewBox} />}
      checkedIcon={<Checked viewBox={defaultIconViewBox} />}
      disableRipple
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== "error"
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    error,
    marginBottom
  }) => `
    color: ${error ? custom.coral.main : custom.white.main};
    margin-bottom: ${marginBottom ?? 0}px;

    &.Mui-checked {
      color: ${error ? custom.coral.main : custom.turquoise.main};
    }

    &.Mui-disabled {
      color: ${custom.white.dark};
    }
  `
);

export const CheckboxTypography = styled((props: CheckboxTypographyProps) => (
  <Typography {...props} />
))(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    },
    disabled
  }) => {
    const color = disabled ? custom.white.dark : custom.white.main;

    return `
      color: ${color};
      margin-left: 8px;
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${body1.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    `;
  }
);

export const LabelWrapper = styled((props: LabelProps) => <FormControlLabel {...props} />, {
  shouldForwardProp: (prop) => prop !== "marginBottom"
})(
  ({ marginBottom }) => `
    margin: 0 0 ${marginBottom ?? 0}px;

    & .MuiButtonBase-root {
      padding: 0;
    }
  `
);
