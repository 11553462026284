import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentSettings } from "api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import {
  useQueryConfig,
  useSelectedShop,
  NetworkError,
  useErrorFormatter,
  useNotifications
} from "shared";
import { FormProps } from "react-final-form";
import { putBrowseAbandonmentPayment } from "../../api";

export const useUpdateBrowseAbandonmentPayment = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: updatePaymentSettings } = useMutation<
    void,
    AxiosError<{ message: string }>,
    PaymentSettings & { shopId: number }
  >(putBrowseAbandonmentPayment);

  const executeUpdateBrowseAbandonmentSettings: FormProps<
    PaymentSettings,
    PaymentSettings
  >["onSubmit"] = useCallback(
    async (values, form) => {
      try {
        await updatePaymentSettings({
          ...values,
          shopId: selectedShopId!
        });

        queryClient.invalidateQueries([queryName, selectedShopId]);

        form.reset();

        showNotification("settingsModule.triggerMailsPayment.updateSuccessMessage", "success");
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [queryName, selectedShopId, showNotification]
  );

  return executeUpdateBrowseAbandonmentSettings;
};
