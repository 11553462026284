import { apiClient } from "shared";
import { BrowseAbandonmentTextSettingsPayload } from "../../types";

export const putBrowseAbandonmentText = async ({
  shopId,
  id,
  ...values
}: BrowseAbandonmentTextSettingsPayload & { shopId: number; id: number }) => {
  const { data } = await apiClient.put<void>(
    `/${shopId}/triggers/browse-abandonment/text-settings/${id}`,
    values
  );

  return data;
};
