import Immutable, { fromJS } from 'immutable';
import {GET_REPORTED_DATA, ACCEPT_REPORTED_DATA, DECLINE_REPORTED_DATA, CHANGE_SHOPPING_CART_VALUE} from "./actions";

export default function reportedDataReducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case GET_REPORTED_DATA + '_LOADING': {
      return state.mergeIn([action.field], fromJS({loading: true, error: ''}));
    } case GET_REPORTED_DATA + '_ERROR': {
      return state.mergeIn([action.field], fromJS({loading: false, error: action.errorMessage}));
    } case GET_REPORTED_DATA: {
      return state.mergeIn([action.field], fromJS({data: action.data.data, totalCount: action.data.totalCount, loading: false}));
    } case ACCEPT_REPORTED_DATA + '_LOADING': {
      return state.mergeIn([action.field], fromJS({accepting: true, error: ''}));
    } case ACCEPT_REPORTED_DATA + '_ERROR': {
      return state.mergeIn([action.field], fromJS({accepting: false, error: action.errorMessage}));
    } case ACCEPT_REPORTED_DATA: {
      return state.mergeIn([action.field], fromJS({accepting: false, error: ''}));
    } case DECLINE_REPORTED_DATA + '_LOADING': {
      return state.mergeIn([action.field], fromJS({declining: true, error: ''}));
    } case DECLINE_REPORTED_DATA + '_ERROR': {
      return state.mergeIn([action.field], fromJS({declining: false, error: action.errorMessage}));
    } case DECLINE_REPORTED_DATA: {
      return state.mergeIn([action.field], fromJS({declining: false, error: ''}));
    } case CHANGE_SHOPPING_CART_VALUE + '_LOADING': {
      return state.mergeIn(['reports'], fromJS({changing: true, error: ''}));
    } case CHANGE_SHOPPING_CART_VALUE + '_ERROR': {
      return state.mergeIn(['reports'], fromJS({changing: false, error: action.errorMessage}));
    } case CHANGE_SHOPPING_CART_VALUE: {
      return state.mergeIn(['reports'], fromJS({changing: false, error: ''}));
    } default: {
      return state;
    }
  }
}
