import { useMemo } from "react";
import { importedModules } from "modules-routes";
import { getNavigationConfigs, parsePages } from "./useNavigationConfig.helpers";

export const useNavigationConfig = () => {
  return useMemo(() => {
    // Temporally disabled requiredPermission fetching from modules
    const configs = getNavigationConfigs(importedModules);

    const pages = parsePages(configs);

    return pages;
  }, []);
};
