export const GET_GO_LIVE_STATUS = 'react-boilerplate/Dashboard/GET_GO_LIVE_STATUS';

export const getGoLiveStatus = (shopId, site) => ({
  type: GET_GO_LIVE_STATUS,
  shopId,
  site,
  meta: {
    type: 'api',
    url: `shop/${shopId}/versions/products/${site}`,
    method: 'get',
  },
});


