import { Field } from "react-final-form";
import { TextInput } from "shared";
import { SubmissionErrors } from "final-form";
import { useTranslation } from "react-i18next";
import { HintTypography } from "../../MailSettings.styles";
import { alternativeEmailValidator } from "../../MailSettings.validation";

export const UptainFormVariant = (submitErrors: SubmissionErrors) => {
  const { t } = useTranslation();

  return (
    <>
      <HintTypography>
        {t("questionnaireModule.steps.integrations.mailSettings.form.hint")}
      </HintTypography>
      <Field
        name="alternativeEmail"
        validate={alternativeEmailValidator}
        render={({
          input: { value, onBlur, onChange, onFocus },
          meta: { error, modifiedSinceLastSubmit, touched }
        }) => (
          <TextInput
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            label={t(
              "questionnaireModule.steps.integrations.mailSettings.form.alternativeEmail.label"
            )}
            placeholder={t(
              "questionnaireModule.steps.integrations.mailSettings.form.alternativeEmail.placeholder"
            )}
            error={
              (error || (submitErrors?.["alternativeEmail"] && !modifiedSinceLastSubmit)) && touched
            }
            errorMessage={t(submitErrors?.["alternativeEmail"] ?? error)}
            marginTop={24}
          />
        )}
      />
    </>
  );
};
