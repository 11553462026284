import {
  styled,
  Box as MuiBox,
  Button as MuiButton,
  Typography as MuiTypography,
  Popover as MuiPopover,
  IconButton as MuiIconButton,
  MenuItem as MuiMenuItem,
  Theme
} from "@mui/material";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { ColorButtonProps, PreviewColorProps, TypeSwitcherProps } from "./ColorPicker.props";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: "transparent",

  "& .MuiList-root": {
    backgroundColor: custom.grey.dark,
    boxShadow: custom.shadows.navigation
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});

export const Button = styled(MuiButton)(
  () => `
    &.MuiButton-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-transform: none;
      min-width: 130px;
    }
  `
);

export const Label = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiTypography-root {
      padding-left: 4px;
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: ${body2.mobile.lineHeight};
      color: ${custom.white.light};
    }
  `
);

export const PreviewWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
    }
  `
);

export const PreviewColor = styled(
  (props: PreviewColorProps) => {
    return <MuiBox {...props} />;
  },
  {
    shouldForwardProp: (prop) => prop !== "color"
  }
)(
  ({ color }) => `
    &.MuiBox-root {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: ${color};
    }
  `
);

export const PreviewText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: ${body2.mobile.lineHeight};
      color: ${custom.white.light};
      text-transform: uppercase;
    }
  `
);

export const Popover = styled(MuiPopover)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiPopover-root {
      & .MuiPaper-root {
        width: 256px;
        background-color: ${custom.grey.dark};
        box-shadow: ${custom.shadows.navigation};
        border-radius: 12px;
        padding: 20px 0 0;
        overflow: visible;

        & .react-colorful {
          width: 256px;
          margin-bottom: 20px;

          & .react-colorful__saturation {
            height: 192px;
            border-radius: 0;
          }

          & .react-colorful__alpha {
            border-radius: 0;
            background-repeat: repeat;
          }

          & .react-colorful__pointer {
            z-index: 1000;
          }
        }
      }
    }
  `
);

export const TypeSwitcher = styled(({ active, value, ...props }: TypeSwitcherProps) => (
  <MuiIconButton {...props} disableRipple>
    <MuiTypography variant="body2">{value}</MuiTypography>
    <Chevron
      sx={{
        transition: "transform 0.1s ease-in-out",
        transform: `rotate(${active ? "180" : "0"}deg)`
      }}
    />
  </MuiIconButton>
))(
  ({
    theme: {
      palette: { custom }
    },
    active
  }) => {
    const color = active ? custom.turquoise.main : custom.white.light;

    return `
      color: ${color};
      padding: 0;

      & .MuiTypography-root {
        text-transform: none;
      }
    `;
  }
);

export const TypeMenuItem = styled(MuiMenuItem)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiMenuItem-root {
      color: ${custom.white.main};
      text-transform: none;
    }
  `
);

export const InputsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      padding: 0 10px;
      align-items: center;
      margin-bottom: 20px;
    }
  `
);

export const ColorizeButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      color: ${custom.white.light};
    }
  `
);

export const AddColorButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      width: 20px;
      height: 20px;
      color: ${custom.white.light};
    }
  `
);

export const SavedColorButton = styled((props: ColorButtonProps) => <MuiIconButton {...props} />, {
  shouldForwardProp: (prop) => prop !== "colorValue"
})(
  ({ colorValue }) => `
    &.MuiIconButton-root {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: ${colorValue};
      margin-left: 6px;
    }
  `
);
