import { getVoucherLists } from "../../api";
import { useSelectedShop } from "../useSelectedShop";
import { useQueryWrapper } from "../useQueryWrapper";

const queryName = "getVoucherListsList";

export const useVoucherListsList = () => {
  const { selectedShop } = useSelectedShop();

  const { data, isLoading, refetch } = useQueryWrapper(
    [queryName, selectedShop],
    async () => await getVoucherLists(selectedShop!),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const resultData = data?.data ?? [];
  return { data: resultData, loading: isLoading, refetch };
};
