/* eslint-disable no-console */
import base64 from 'base-64';
import { localStorage, LocalStorageKey } from 'shared';

import { localStorageKeys } from './containers/CustomerLogin/utils/submit';
import { deleteCookie } from './utils/cookies';

// this function checks if the user has an authorization to visit a private route and if not will send the user to the login site
export function requireAuth() {
  process.env.NODE_ENV !== 'production' && console.log('Checking login status...', isLoggedIn());
  return (!isLoggedIn()) ? setLoggedOut() : true;
}

export function requireAdminAuth() {
  const loggedIn = isLoggedIn(1);
  process.env.NODE_ENV !== 'production' && console.log('Checking admin status...', loggedIn);
  return (!loggedIn) ? setLoggedOut() : true;
}

export function requireSalesAuth() {
  const loggedIn = isLoggedIn(2);
  process.env.NODE_ENV !== 'production' && console.log('Checking admin status...', loggedIn);
  return (!loggedIn) ? setLoggedOut() : true;
}


export function isLoggedIn(role = 4) {
  if (localStorage.getItem(LocalStorageKey.TOKEN) != null) {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    const exp = JSON.parse(base64.decode(token.split('.')[1])).exp;
    const userRole = JSON.parse(base64.decode(token.split('.')[1])).role;
    const currentTime = new Date().getTime() / 1000;

    if (exp - currentTime <= 0) {
      return false;
    }

    if (role < userRole) {
      return false;
    }
    return true;
  }
  return false;
}

export function setLoggedOut() {
  localStorage.removeItem(LocalStorageKey.TOKEN);
  return false;
}

/**
 * When logging out remove cookies, localStorage and redirect to the login page.
 * @param {Function} cb - callback for custom action
 */
export function logout(cb) {
  localStorageKeys.forEach((el) => localStorage.removeItem(el));
  deleteCookie('upcuid');
  cb();
}

// if the home page will be loaded this function checks if the user is already logged in and then loads the dashboard
// export function checkLogin(nextState, replace, cb) {
//   if (isLoggedIn()) {
//     replace({
//       pathname: '/dashboard',
//     });
//     cb();
//   } else {
//     cb();
//   }
// }
