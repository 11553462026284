import {
  styled,
  Box as MuiBox,
  Button as MuiButton,
  IconButton as MuiIconButton
} from "@mui/material";
import { TimeTextButtonProps } from "./TimeRow.props";

export const TimeRowStyles = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not:last-of-type {
        margin-bottom: 10px;
      }
    }
  `
);

export const TimeTextButton = styled((props: TimeTextButtonProps) => <MuiButton {...props} />, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "error"
})(
  ({
    theme: {
      palette: { custom }
    },
    selected,
    error
  }) => {
    const selectedColor = selected ? custom.white.main : custom.white.light;

    const resultColor = error ? custom.coral.main : selectedColor;

    return `
      padding: 0;
      color: ${resultColor};

      &.Mui-disabled {
        color: ${custom.white.dark};
      }
    `;
  }
);

export const TimeRowDeleteButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      position: absolute;
      width: 20px;
      height: 20px;
      color: ${custom.white.light};
      right: -24px;
    }
  `
);
