import {fromJS} from "immutable";
import productLines from './productLines';
export const GET_PRODUCT_TYPES = 'react-boilerplate/PRODUCTS/GET_PRODUCT_TYPES';
export const ADD_SETTING_LINE = 'react-boilerplate/PRODUCTS/ADD_SETTING_LINE';
export const GET_SETTING_LINE = 'react-boilerplate/PRODUCTS/GET_SETTING_LINE';
export const SAVE_SETTING_LINE = 'react-boilerplate/PRODUCTS/SAVE_SETTING_LINE';
export const DELETE_SETTING_LINE = 'react-boilerplate/PRODUCTS/DELETE_SETTING_LINE';

export const getProductTypes = (shopId, product, type, typeIndex) => ({
  type: GET_PRODUCT_TYPES,
  shopId,
  product,
  typeIndex,
  meta: {
    type: 'api',
    url: `shop/${shopId}/products/${product}/${type}`,
    method: 'get',
  },
});

export const addSettingLine = (shopId, product, typeIndex, itemIndex, type) => ({
  type: ADD_SETTING_LINE,
  shopId,
  product,
  typeIndex,
  itemIndex,
  newData: fromJS(productLines(itemIndex)[product][type]),
});

export const getSettingLine = (shopId, product, type, typeIndex, settingLineId) => ({
  type: GET_SETTING_LINE,
  shopId,
  product,
  settingLineId,
  typeIndex,
  meta: {
    type: 'api',
    url: `shop/${shopId}/products/${product}/${type}/items/${settingLineId}`,
    method: 'get',
  },
});

export const saveSettingLine = (shopId, product, type, typeIndex, settingLineId, values, buttonAction, newSettingLine) => ({
  type: SAVE_SETTING_LINE,
  shopId,
  product,
  typeIndex,
  settingLineId,
  values,
  buttonAction,
  meta: {
    type: 'api',
    url: newSettingLine ? `shop/${shopId}/products/${product}/${type}` : `shop/${shopId}/products/${product}/${type}/items/${settingLineId}`,
    method: newSettingLine ? 'post' : 'put',
    data: values,
  },
});


export const deleteSettingLine = (shopId, product, type, typeIndex, settingLineId) => ({
  type: DELETE_SETTING_LINE,
  shopId,
  product,
  typeIndex,
  settingLineId,
  meta: {
    type: 'api',
    url: `shop/${shopId}/products/${product}/${type}/items/${settingLineId}`,
    method: 'delete',
  },
});


