import { styled, Box as MuiBox } from "@mui/material";
import { VideoPlayerProps } from "./VideoPlayer.props";

export const Wrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    margin: 24px auto 0;
    position: relative;
    overflow: hidden;
    width: 320px;
    height: 180px;

    ${[breakpoints.up("sm")]} {
      margin: 24px 0 0;
      width: 500px;
      height: 281px;
    }

    ${[breakpoints.up("md")]} {
      width: 760px;
      height: 427px;
    }

    ${[breakpoints.up("xl")]} {
      width: 950px;
      height: 534px;
    }
  `
);

export const Frame = styled((props: VideoPlayerProps) => (
  <iframe {...props} width={320} height={180} />
))(
  () => `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  `
);
