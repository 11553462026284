import { Routes } from "config";
import module from "module";
import { Permission, PageWrapper, NavigationBar } from "shared";
import { Stepper, StepRenderer } from "../components";

const Questionnaire = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <Stepper />
      <StepRenderer />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.QUESTIONNAIRE,
  disabled: false,
  requiredPermission: Permission.USER
});

export default Questionnaire;
