import { useQueryClient, useMutation } from "@tanstack/react-query";
import { PutLinksConfigRequest } from "api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import {
  useSelectedShop,
  useErrorFormatter,
  useNotifications,
  NetworkError,
  useQueryConfig
} from "shared";
import { putLinksConfig } from "../../api";
import { LinksValues } from "../../types";

export const useUpdateLinksConfig = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId, selectedShop } = useSelectedShop();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const client = useQueryClient();

  const { mutateAsync: updateLinksConfig } = useMutation<
    void,
    AxiosError<{ message: string }>,
    PutLinksConfigRequest
  >(putLinksConfig);

  const executeUpdateLinksConfig: FormProps<LinksValues, LinksValues>["onSubmit"] = useCallback(
    async (values, form) => {
      try {
        await updateLinksConfig({
          link: values.link,
          unsubscribeLink: values.unsubscribeLink ?? values.link,
          shopId: selectedShopId!,
          shopMongoId: selectedShop!
        });

        await client.invalidateQueries([queryName, selectedShopId]);

        form.reset();

        showNotification("settingsModule.shopLinks.successMessage", "success");
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [queryName, selectedShopId, showNotification, selectedShop]
  );

  return executeUpdateLinksConfig;
};
