import styled from 'styled-components';

const UpContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  padding: ${(props) => props.padding || '0'};
  background: ${(props) => props.background || 'none'};;
  ${(props) => props.flex && `flex: ${props.flex}`}
`;

export default UpContainerLoading;
