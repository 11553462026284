import { apiClient } from "shared";
import { GetNewsletterFormsInputsResponse } from "api";

export const getNewsletterFormsInputs = async (mongoShopId: string) => {
  const { data } = await apiClient.get<GetNewsletterFormsInputsResponse>(
    `/shop/${mongoShopId}/settings/common/optinInputs`
  );

  return data;
};
