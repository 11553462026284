import { apiClient } from "shared";
import { QuestionnaireAlgorithmStepData, ShopIdParam } from "api";

export const putSubmitAlgorithm = async ({
  shopId,
  ...values
}: QuestionnaireAlgorithmStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/algorithm`, values);

  return data;
};
