import { combineReducers } from 'redux-immutable';
import storeAdministrationReducer from './StoreAdministration/reducer';
import userAdministrationReducer from './UserAdministration/reducer';
import reportedDataReducer from "./ReportedData/reducer";

const adminReducer = combineReducers({
  reportedData: reportedDataReducer,
  storeAdministration: storeAdministrationReducer,
  userAdministration: userAdministrationReducer,
});

export default adminReducer;
