export const START_AUTH = 'START_AUTH';
export const CHECK_AUTH_STATUS = 'CHECK_AUTH_STATUS';

export const startAuthProcess = (
  integrationType,
  shopId,
  id,
  additionalInfo,
) => ({
  type: START_AUTH,
  integrationType,
  shopId,
  id,
  meta: {
    type: 'api',
    url: `shop/${shopId}/auth/start`,
    method: 'post',
    data: { additionalInfo, type: integrationType, id },
  },
});

export const checkStatus = (integrationType, shopId, token, id) => ({
  type: CHECK_AUTH_STATUS,
  integrationType,
  shopId,
  id,
  meta: {
    type: 'api',
    url: `shop/${shopId}/auth/status/${token}`,
    method: 'get',
  },
});
