import { Button as MuiButton, styled } from "@mui/material";
import { StepperSubTabWrapperProps } from "./StepperSubTab.props";
import CheckIcon from "@mui/icons-material/Check";

export const StepperSubTabWrapper = styled(
  (props: StepperSubTabWrapperProps) => <MuiButton {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "completed" && prop !== "selected"
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    },
    completed,
    selected
  }) => {
    const defaultColor = completed ? custom.white.dark : custom.white.light;

    const color = selected ? custom.white.main : defaultColor;

    return `
      &.MuiButton-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${color};
        text-transform: none;
        font-size: ${body1.desktop.fontSize};
        font-weight: ${body1.desktop.fontWeight};
        line-height: ${body1.desktop.lineHeight};
        padding: 5px;
        height: 24px;
        box-sizing: content-box;
        min-width: 0;

        &.Mui-disabled {
          color: ${custom.white.dark};
        }
      }
    `;
  }
);

export const StepperSubTabCheckIcon = styled(CheckIcon)(
  () => `
    margin-left: 8px
  `
);
