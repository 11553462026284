import { createSelector } from 'reselect';
import { List, Iterable } from 'immutable';

const selectCurrShop = createSelector(
  (state: Iterable<string, unknown>) =>
    state.getIn(['customerBackendDashboard', 'userData', 'selectedShop']),
  (state: Iterable<string, unknown>) =>
    state.getIn(['customerBackendDashboard', 'userData', 'selectedShopId']),
  (selectedShop: string, shopId: number) => ({
    selectedShop,
    shopId,
  }),
);

const getShops = (state: Iterable<string, unknown>): List<any> =>
  state.getIn(['customerBackendDashboard', 'userData', 'shops']);

const selectUserShops = createSelector(getShops, (shops) => ({
  shops,
  showShopSwitch: shops.size > 1,
}));

export { getShops, selectCurrShop, selectUserShops };
