import { FieldValidator } from "final-form";
import { isEmptyString } from "shared/utils/validation";
import { QuestionnaireVoucherSettingsData, LegacyVoucherTypes, VoucherTypes } from "api";
import { VoucherListControlData, VoucherListControlMode } from "../../types";

const checkVoucherValuesLength = (value: string) => {
  const voucherValues = value.split("\n");

  if (voucherValues.some((voucherValue) => voucherValue.trim().length > 64)) {
    return "questionnaireModule.errors.lessThan64Characters";
  }
};

export const newVoucherListNameValidator: FieldValidator<string> = (value, form) => {
  const { voucherKind, voucherListControlMode } = form as QuestionnaireVoucherSettingsData &
    VoucherListControlData;

  if (
    (voucherKind === LegacyVoucherTypes.LIST || voucherKind === VoucherTypes.LIST) &&
    voucherListControlMode === VoucherListControlMode.CREATE &&
    !value
  ) {
    return "questionnaireModule.errors.required";
  }
};

export const newVoucherListValuesValidator: FieldValidator<string> = (value, form) => {
  const { voucherKind, voucherListControlMode } = form as QuestionnaireVoucherSettingsData &
    VoucherListControlData;

  if (
    (voucherKind === LegacyVoucherTypes.LIST || voucherKind === VoucherTypes.LIST) &&
    voucherListControlMode === VoucherListControlMode.CREATE
  ) {
    if (isEmptyString(value)) {
      return "questionnaireModule.errors.voucherListNotEmpty";
    }

    return checkVoucherValuesLength(value);
  }
};

export const listNameValidator: FieldValidator<string> = (value, form) => {
  const { voucherKind, voucherListControlMode } = form as QuestionnaireVoucherSettingsData &
    VoucherListControlData;

  if (
    (voucherKind === LegacyVoucherTypes.LIST || voucherKind === VoucherTypes.LIST) &&
    voucherListControlMode === VoucherListControlMode.SELECT &&
    !value
  ) {
    return "questionnaireModule.errors.required";
  }
};

export const extendVoucherListValuesValidator: FieldValidator<string> = (value, form) => {
  const { voucherKind, voucherListControlMode } = form as QuestionnaireVoucherSettingsData &
    VoucherListControlData;

  if (
    (voucherKind === LegacyVoucherTypes.LIST || voucherKind === VoucherTypes.LIST) &&
    voucherListControlMode === VoucherListControlMode.SELECT &&
    value
  ) {
    if (isEmptyString(value)) {
      return "questionnaireModule.errors.voucherListNotEmpty";
    }

    return checkVoucherValuesLength(value);
  }
};
