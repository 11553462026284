import { ButtonGroup, DeviceType, PreviewProduct, SplitButton } from "shared";
import { useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import { Wrapper, LanguageSwitcherWrapper } from "./PreviewModeControl.styles";
import { PreviewModeControlProps } from "./PreviewModeControl.props";

export const PreviewModeControl = ({
  state: { device, language, product },
  activationLanguages,
  optinLanguages,
  onChange
}: PreviewModeControlProps) => {
  const languageOptions = useMemo(() => {
    let options = [];

    switch (product) {
      case PreviewProduct.ACTIVATION_POPUP:
        options = activationLanguages;
        break;
      case PreviewProduct.NEWSLETTER_FORMS:
        options = optinLanguages;
        break;
    }

    return options.map((language) => ({
      key: language,
      title: language.toUpperCase()
    }));
  }, [product]);

  const handleDisplayStateChange = useCallback(
    (value: DeviceType) => () => {
      onChange((prev) => ({ ...prev, device: value }));
    },
    [onChange]
  );

  const handleLanguageChange = useCallback(
    (value: string) => {
      onChange((prev) => ({ ...prev, language: value }));
    },
    [onChange]
  );

  return (
    <Wrapper>
      <ButtonGroup variant="contained">
        {Object.values(DeviceType).map((key) => (
          <Button
            color={device === key ? "success" : "primary"}
            key={key}
            onClick={handleDisplayStateChange(key as DeviceType)}
            disabled={device === key}
          >
            {key}
          </Button>
        ))}
      </ButtonGroup>
      <LanguageSwitcherWrapper>
        <SplitButton value={language} options={languageOptions} onChange={handleLanguageChange} />
      </LanguageSwitcherWrapper>
    </Wrapper>
  );
};
