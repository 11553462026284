import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Routes } from "config";
import { LOGOUT } from "legacy/actions/actions";
import { localStorage, deleteCookie } from "../../utils";
import { LocalStorageKey } from "../../types";

export const useLogout = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const logoutMethod = useCallback(() => {
    localStorage.removeItem(LocalStorageKey.TOKEN);
    localStorage.removeItem(LocalStorageKey.SESSION_ID);
    queryClient.clear();
    dispatch({ type: LOGOUT });
    deleteCookie("upcuid");
    navigate(Routes.LOGIN);
  }, [queryClient]);

  return logoutMethod;
};
