import { styled, Alert as MuiAlert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { AlertProps } from "./Alert.props";

const InfoIcon = styled(InfoOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.custom.turquoise.main};
  `
);

const WarningIcon = styled(WarningAmberRoundedIcon)(
  ({ theme }) => `
    color: ${theme.palette.custom.orange.main};
  `
);

const ErrorIcon = styled(ErrorOutlineOutlinedIcon)(
  ({ theme }) => `
    color: ${theme.palette.custom.coral.main};
  `
);

export const Alert = styled(({ severity = "info", ...props }: AlertProps) => {
  return (
    <MuiAlert
      {...props}
      severity={severity}
      iconMapping={{
        info: <InfoIcon viewBox="2 2 20 20" />,
        warning: <WarningIcon viewBox="2 2 20 20" />,
        error: <ErrorIcon viewBox="2 2 20 20" />
      }}
    />
  );
})(
  ({
    theme: {
      palette: { custom }
    },
    severity = "info"
  }) => {
    let color;
    let backgroundColor;
    let borderColor;

    switch (severity) {
      case "info":
        color = custom.turquoise.main;
        backgroundColor = "transparent";
        borderColor = custom.turquoise.main;
        break;

      case "warning":
        color = custom.orange.light;
        backgroundColor = custom.orange.dark;
        borderColor = "transparent";
        break;

      case "error":
        color = custom.coral.main;
        backgroundColor = "transparent";
        borderColor = custom.coral.main;
        break;
    }

    return `
      color: ${color};
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};
      border-radius: 4px;

      & .MuiAlert-action {
        padding-top: 2px;
      }

      & .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
    `;
  }
);
