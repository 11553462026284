import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }
  `
);

export const PreviewModeWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
    }
  `
);

export const PreviewComponentWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `
);

export const LanguageSwitcherWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin-left: 0;

      ${breakpoints.up("md")} {
        margin-left: 16px;
      }
    }
  `
);

export const LoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `
);
