import { apiClient } from "shared";

export const postBrowseAbandonmentVoucherDisable = async ({
  shopId,
  id
}: {
  shopId: number;
  id: number;
}) => {
  const { data } = await apiClient.post<void>(
    `/${shopId}/triggers/browse-abandonment/voucher-settings/${id}/disable`
  );

  return data;
};
