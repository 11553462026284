import { apiClient } from "shared";
import { GetNewsletterFormsPositionsResponse } from "api";

export const getNewsletterFormsPositions = async (shopId: number) => {
  const { data } = await apiClient.get<GetNewsletterFormsPositionsResponse>(
    `/${shopId}/newsletter-forms/positions`
  );

  return data;
};
