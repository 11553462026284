import Immutable from 'immutable';
import {
  SAVE_DEFINITIONS,
  LOAD_DEFINITIONS,
  LOAD_MAPPINGS,
  LOAD_RULES,
} from "./actions";

const initialState = Immutable.fromJS({
    inputs: [],
    sets: [],
    mappings: {},
    rules: [],
});

function fuzzyReducer(state = initialState, action = null) {

  switch (action.type) {
    case SAVE_DEFINITIONS: {
      return state.merge(Immutable.fromJS({
        inputs: action.definitions.inputs,
        sets: action.definitions.sets,
      }));
    }
    case `${LOAD_DEFINITIONS}_LOADING`: {
      return state.merge(Immutable.fromJS({
        inputs: [],
        sets: [],
      }));
    }
    case `${LOAD_DEFINITIONS}_ERROR`: {
      return state.merge(Immutable.fromJS({
        inputs: [],
        sets: [],
      }));
    }
    case LOAD_DEFINITIONS: {
      return state.merge(Immutable.fromJS({
        inputs: action.data.data.inputs || [],
        sets: action.data.data.sets || [],
      }));
    }
    case `${LOAD_MAPPINGS}_LOADING`: {
      return state.merge(Immutable.fromJS({
        mappings: {},
      }));
    }
    case `${LOAD_MAPPINGS}_ERROR`: {
      return state.merge(Immutable.fromJS({
        mappings: {},
      }));
    }
    case LOAD_MAPPINGS: {
      return state.merge(Immutable.fromJS({
        mappings: action.data.data || {},
      }));
    }
    case `${LOAD_RULES}_LOADING`: {
      return state.merge(Immutable.fromJS({
        rules: [],
      }));
    }
    case `${LOAD_RULES}_ERROR`: {
      return state.merge(Immutable.fromJS({
        rules: [],
      }));
    }
    case LOAD_RULES: {
      return state.merge(Immutable.fromJS({
        rules: action.data.data || [],
      }));
    }
    default: {
      return state;
    }
  }
}

export default fuzzyReducer;

