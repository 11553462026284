import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;

      & .MuiButtonGroup-root {
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
      }
    }
  `
);
