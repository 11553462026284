import { useSelector } from "react-redux";
import { ShopData } from "../../types";

/**
 * Unsafe because it uses legacy store
 * Should be removed after legacy store is removed
 *
 * @deprecated
 */
export const UNSAFE_useLegacyShopListDataSelector = () => {
  return useSelector((state: { getIn: <T = unknown>(path: string[]) => T }) => ({
    shopsReceived: state.getIn<boolean>(["customerBackendDashboard", "userData", "shopsRecieved"]),
    loading: state.getIn<boolean>(["customerBackendDashboard", "userData", "shopsLoading"]),
    shops: state
      .getIn<{ toJS: () => ShopData[] }>(["customerBackendDashboard", "userData", "shops"])
      .toJS(),
    selectedShopId: state.getIn<string>(["customerBackendDashboard", "userData", "selectedShopId"]),
    selectedShop: state.getIn<string>(["customerBackendDashboard", "userData", "selectedShop"])
  }));
};
