import { LoadingIndicator } from "shared";
import { useTranslation } from "react-i18next";
import { ComeBackMailingVoucherItemForm } from "../ComeBackMailingVoucherItemForm";
import { CreateComeBackMailingVoucherForm } from "../CreateComeBackMailingVoucherForm";
import { useComeBackMailingVoucherData } from "../../hooks";
import {
  SettingsListHeader,
  SettingsListWrapper,
  LoadingWrapper
} from "../TriggerSettingsFormStyles";
import { DEFAULT_QUERY_LIMIT } from "config";

export const ComeBackMailingVoucherSettingsList = () => {
  const { t } = useTranslation();

  const { initialState, loading } = useComeBackMailingVoucherData();

  const comeBackMailingVoucherCountThresholdMet = initialState.length >= DEFAULT_QUERY_LIMIT;

  return (
    <SettingsListWrapper>
      <SettingsListHeader>
        {t("featuresModule.comeBackMailing.form.voucherSettings.heading")}
      </SettingsListHeader>
      {loading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        initialState.map((voucherSettings) => (
          <ComeBackMailingVoucherItemForm key={voucherSettings.id} initialState={voucherSettings} />
        ))
      )}
      <CreateComeBackMailingVoucherForm
        disabled={loading || comeBackMailingVoucherCountThresholdMet}
      />
    </SettingsListWrapper>
  );
};
