import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const FormWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin: 0 20px;
      padding: 80px 0 65px;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 500px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);

export const EmptyStateText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      margin-top: 50px;
      text-align: center;
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: ${body2.mobile.lineHeight};
      color: ${custom.white.light};

      ${[breakpoints.up("md")]} { 
        font-size: ${body2.tablet.fontSize};
        font-weight: ${body2.tablet.fontWeight};
        line-height: ${body2.tablet.lineHeight};
      }

      ${[breakpoints.up("xl")]} { 
        font-size: ${body2.desktop.fontSize};
        font-weight: ${body2.desktop.fontWeight};
        line-height: ${body2.desktop.lineHeight};
      }
    } 
  `
);
