import { apiClient } from "shared";

export const postComeBackMailingVoucherEnable = async ({
  shopId,
  id
}: {
  shopId: number;
  id: number;
}) => {
  const { data } = await apiClient.post<void>(
    `/${shopId}/triggers/come-back-mailing/voucher-settings/${id}/enable`
  );

  return data;
};
