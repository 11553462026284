import { FieldValidator } from "final-form";

export const rateValidator: FieldValidator<string> = (value) => {
  let numberValue: number;

  try {
    numberValue = Number(value);
  } catch (e) {
    return "settingsModule.errors.shouldBeANumber";
  }

  if (numberValue >= 100000) {
    return "settingsModule.errors.lessThan100k";
  }
};
