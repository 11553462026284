export enum SummernoteCustomEventType {
  CHANGE = "change",
  CODE_VIEW_TOGGLE = "code_view_toggle"
}

export type WysiwygProps = {
  /**
   * The initial value of the wysiwyg
   * Only referring to the initial value, not the current value
   * Updates to this value will not be reflected in the wysiwyg
   *
   * @required
   */
  value: string;
  /**
   * Unique identifier of the wysiwyg
   */
  index: string;
  /**
   * The language for the wysiwyg initialization
   * Unfortunately, the summernote library does not support dynamic language changes
   * Therefore, wysiwyg should be reinitialized on language change
   *
   * @required
   */
  language: string;
  /**
   * Method that returns new value on view mode change
   *
   * @required
   */
  onCodeViewChange: (value: boolean) => void;
  /**
   * Method that returns new value on value change
   *
   * @required
   */
  onChange: (value: string) => void;
};

type ChangeEvent = {
  type: SummernoteCustomEventType.CHANGE;
  value: string;
  index: string;
};

type CodeViewToggleEvent = {
  type: SummernoteCustomEventType.CODE_VIEW_TOGGLE;
  index: string;
  state: boolean;
};

export type SummernoteEvent = {
  data: ChangeEvent | CodeViewToggleEvent;
};

export type SummernoteElement = Window & {
  switchLanguage: (language: string, initialValue: string) => void;
};
