import { DEFAULT_QUERY_LIMIT } from "config";
import { usePagination, useQueryWrapper, useSelectedShop, getContactPersonList } from "shared";

const queryName = "contactPersons";

export const useContactPersonsList = () => {
  const { selectedShopId } = useSelectedShop();

  const { offset, limit } = usePagination(DEFAULT_QUERY_LIMIT);

  const { data, isLoading, refetch } = useQueryWrapper(
    [queryName, selectedShopId, limit, offset],
    async () => await getContactPersonList(selectedShopId!, limit, offset),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  const resultData = data?.items ?? [];

  return { data: resultData, loading: isLoading, refetch };
};
