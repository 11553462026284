import { useCallback, useMemo } from "react";
import {
  QuestionnaireActivationPopupsVoucherSettingsStepData,
  QuestionnaireStepType,
  LegacyVoucherTypes,
  LegacyCurrencies
} from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { putSubmitActivationPopupsVoucherSettings } from "../../api";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import {
  QuestionnaireActivationPopupsVoucherSettingsFormValues,
  VoucherListControlMode
} from "../../types";
import { mapLabelValuePairToKeyTitlePair, parseVoucherSettingsFormStepMetadata } from "../../utils";
import { activationPopupsVoucherSettingsFormMapper } from "./useActivationPopupsVoucherSettingsStep.helpers";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useActivationPopupsVoucherSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ACTIVATION_POPUPS_VOUCHER_SETTINGS);

  const data: QuestionnaireActivationPopupsVoucherSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      voucherValueType: stepData?.data?.voucherValueType || LegacyCurrencies.PERCENTAGE,
      voucherKind: stepData?.data?.voucherKind || LegacyVoucherTypes.CODE,
      excludedCustomerGroups: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedCustomerGroups
      ),
      excludedPagesToShowVoucher: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedPagesToShowVoucher
      ),
      voucherListControlMode:
        stepData?.data?.listName && stepData?.data?.voucherKind === LegacyVoucherTypes.LIST
          ? VoucherListControlMode.SELECT
          : VoucherListControlMode.CREATE
    }),
    [stepData?.data]
  );

  const metadata = useMemo(
    () => parseVoucherSettingsFormStepMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitActivationPopupsVoucherSettings =
    useQuestionnaireSubmitMutation<QuestionnaireActivationPopupsVoucherSettingsStepData>(
      putSubmitActivationPopupsVoucherSettings
    );

  const executeSubmitActivationPopupsVoucherSettings: FormProps<
    QuestionnaireActivationPopupsVoucherSettingsFormValues,
    QuestionnaireActivationPopupsVoucherSettingsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const activationPopupsVoucherSettingsSubmitData = activationPopupsVoucherSettingsFormMapper(
          formValues,
          metadata
        );

        await submitActivationPopupsVoucherSettings(activationPopupsVoucherSettingsSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitActivationPopupsVoucherSettings, metadata]
  );

  return {
    data,
    metadata,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitActivationPopupsVoucherSettings
  };
};
