/**
 * Create the store with asynchronously loaded reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';

import apiMiddleware from './middleware/apiMiddleware';
import createReducer from './createReducers';

function configureStore(initialState = {}) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      });

  }

  const middlewares = [thunk, apiMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  return store;
}

export const store = configureStore({});


