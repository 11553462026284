import { useEffect } from "react";
import { useOpenFeatureState } from "./useOpenFeatureState.hook";

export const useOpenFeatureFlag = (featureFlagKey: string) => {
  const { loading, error, flagsState, addFlagToContext } = useOpenFeatureState();

  useEffect(() => {
    addFlagToContext(featureFlagKey);
  }, []);

  // If the flag is not in the context yet, we return a loading state
  // Because we know yet what is the actual value of the flag
  const valueLoadingState = flagsState[featureFlagKey] === null || loading;

  // Set flag value to false in case of loading or null to prevent missing values
  if (valueLoadingState) {
    return { value: false, loading: valueLoadingState, error };
  }

  // In case of an error return the current state and error to prevent dynamic updates
  // and allow error administration on the component side
  // The state might be updated with page reload
  return { value: flagsState[featureFlagKey], loading: valueLoadingState, error };
};
