import { apiClient } from "shared";

export const deleteComeBackMailingVoucher = async ({
  shopId,
  id
}: {
  shopId: number;
  id: number;
}) => {
  const { data } = await apiClient.delete<void>(
    `/${shopId}/triggers/come-back-mailing/voucher-settings/${id}`
  );

  return data;
};
