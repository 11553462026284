export enum ReferrerSources {
  TIK_TOK = "TikTok",
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  LINKED_IN = "LinkedIn",
  XING = "Xing",
  AGENCY = "Agency",
  RECOMMENDATION = "Recommendation",
  SHOP_SYSTEM = "Shop System",
  OTHER = "Other"
}

export type QuestionnaireReferrerStepData = {
  additionalReferrerSource: string;
  referrer: ReferrerSources;
};
