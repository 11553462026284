import { abandonmentMailsVoucherSettingsFormMapper } from "./useAbandonmentMailsVoucherSettingsStep.helpers";
import { useCallback, useMemo } from "react";
import {
  LegacyCurrencies,
  QuestionnaireAbandonmentMailsVoucherSettingsStepData,
  QuestionnaireStepType,
  LegacyVoucherTypes
} from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { putSubmitAbandonmentMailsVoucherSettings } from "../../api";
import {
  QuestionnaireAbandonmentMailsVoucherSettingsFormValues,
  VoucherListControlMode
} from "../../types";
import { mapLabelValuePairToKeyTitlePair, parseVoucherSettingsFormStepMetadata } from "../../utils";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useAbandonmentMailsVoucherSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ABANDONMENT_MAILS_VOUCHER_SETTINGS);

  const data: QuestionnaireAbandonmentMailsVoucherSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      voucherValueType: stepData?.data?.voucherValueType || LegacyCurrencies.PERCENTAGE,
      voucherKind: stepData?.data?.voucherKind || LegacyVoucherTypes.CODE,
      excludedCustomerGroups: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedCustomerGroups
      ),
      excludedPagesToShowVoucher: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedPagesToShowVoucher
      ),
      voucherListControlMode:
        stepData?.data?.listName && stepData?.data?.voucherKind === LegacyVoucherTypes.LIST
          ? VoucherListControlMode.SELECT
          : VoucherListControlMode.CREATE
    }),
    [stepData?.data]
  );

  const metadata = useMemo(
    () => parseVoucherSettingsFormStepMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitAbandonmentMailsVoucherSettings =
    useQuestionnaireSubmitMutation<QuestionnaireAbandonmentMailsVoucherSettingsStepData>(
      putSubmitAbandonmentMailsVoucherSettings
    );

  const executeSubmitAbandonmentMailsVoucherSettings: FormProps<
    QuestionnaireAbandonmentMailsVoucherSettingsFormValues,
    QuestionnaireAbandonmentMailsVoucherSettingsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const abandonmentMailsVoucherSettingsSubmitData = abandonmentMailsVoucherSettingsFormMapper(
          formValues,
          metadata
        );

        await submitAbandonmentMailsVoucherSettings(abandonmentMailsVoucherSettingsSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAbandonmentMailsVoucherSettings, metadata]
  );

  return {
    data,
    metadata,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitAbandonmentMailsVoucherSettings
  };
};
