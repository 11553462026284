import { Link } from "shared";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./PublicFooter.styles";
import { useInfoLinks } from "../../../hooks";

export const PublicFooter = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { imprint, privacyPolicy, disclaimer } = useInfoLinks();

  const imprintLink = `${imprint}?${searchParams.toString()}`;

  const privacyPolicyLink = `${privacyPolicy}?${searchParams.toString()}`;

  const disclaimerLink = `${disclaimer}?${searchParams.toString()}`;

  return (
    <Wrapper>
      <Link type="secondary" underline="none" href={imprintLink} target="_blank">
        {t("authModule.links.imprint")}
      </Link>
      <Link type="secondary" underline="none" href={privacyPolicyLink} target="_blank">
        {t("authModule.links.privacyPolicy")}
      </Link>
      <Link type="secondary" underline="none" href={disclaimerLink} target="_blank">
        {t("authModule.links.disclaimer")}
      </Link>
    </Wrapper>
  );
};
