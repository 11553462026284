import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import Dashboard from '../containers/Dashboard';

import Loadable from './Loadable';

const PrivateRoute = ({
  component: Component,
  parent: ParentComponent = Dashboard,
  loadingState = 'dashboard',
  checkAuth,
}) => {
  const { search, hash } = useLocation();

  return checkAuth() ? (
    <ParentComponent>
      <Loadable component={Component} loadingState={loadingState} />
    </ParentComponent>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        search,
        hash
      }}
      replace
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  parent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  loadingState: PropTypes.string,
  checkAuth: PropTypes.func,
};

export default PrivateRoute;
