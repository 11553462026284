import { OpenFeature } from "@openfeature/web-sdk";
import { FlagdWebProvider } from "@openfeature/flagd-web-provider";

OpenFeature.setProvider(
  new FlagdWebProvider({
    host: `${process.env.REACT_APP_OPEN_FEATURE_HOST}`,
    tls: process.env.REACT_APP_OPEN_FEATURE_USE_TLS === "true",
    maxRetries: Number(process.env.REACT_APP_OPEN_FEATURE_MAX_RETRIES),
    ...(process.env.REACT_APP_OPEN_FEATURE_PORT
      ? { port: Number(process.env.REACT_APP_OPEN_FEATURE_PORT) }
      : {})
  })
);

export const openFeatureClient = OpenFeature.getClient("customer-frontend");

export { ProviderEvents } from "@openfeature/web-sdk";

export type { ConfigChangeEvent } from "@openfeature/web-sdk";
