import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl } from 'react-intl';

import { selectBox } from '../../SelectBox';
import { renderField } from '../../UpInput';
import UpFlexRow from '../../UpFlexRow';

const Filter = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  display: flex;
  flex: 1 1 auto;
  @media only screen and (max-width: 700px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 550px) {
    margin-bottom: 0px;
  }
`;

const ShoppingCartValue = (props) => (
  <Filter>
    <Row>
      <Field
        name={`${props.member}.filter.operator`}
        component={selectBox}
        customProps={{
          options: [
            {
              value: 'smaller-or-equals',
              label: props.intl.formatMessage({
                id: 'components.ConditionLine.lessEqual',
                defaultMessage: 'less than or equals',
              }),
            },
            {
              value: 'greater-or-equals',
              label: props.intl.formatMessage({
                id: 'components.ConditionLine.greaterEqual',
                defaultMessage: 'greater than or equals',
              }),
            },
          ],
          margin: '0 10px 0 0',
          minWidth: '200px',
        }}
      />
    </Row>
    <UpFlexRow>
      <Field
        name={`${props.member}.filter.attribute`}
        component={renderField}
        customProps={{
          inputWithInfo: true,
        }}
        placeholder={props.intl.formatMessage({
          id: 'components.ConditionLine.onlyDecimalNumbersError.value',
          defaultMessage: 'e.g. 100.00',
        })}
        infoTextInput={props.intl.formatMessage({
          id: 'containers.Dashboard.Form.onlyDecimalNumbersError',
          defaultMessage:
            'Only numbers and a period as decimal separator are allowed',
        })}
      />
    </UpFlexRow>
  </Filter>
);

ShoppingCartValue.propTypes = {
  member: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(ShoppingCartValue);
