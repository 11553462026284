import React from 'react';
import PropTypes from 'prop-types';
import UpSwitchShop from '../UpSwitchShop';

import { Row } from './styles';

const UpSwitchLanguageShop = ({ shopSwitch, languageSwitch }) => (
  <Row>
    {shopSwitch && <UpSwitchShop />}
  </Row>
);

UpSwitchLanguageShop.propTypes = {
  shopSwitch: PropTypes.bool,
  languageSwitch: PropTypes.bool,
};

export default UpSwitchLanguageShop;
