import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      position: fixed;
      top: 64px;
      left: 0;
      bottom: 0;
      width: 415px;
      padding: 80px 0 80px 35px;
      background-color: ${custom.white.main}0D;
      backdrop-filter: blur(5px);
      overflow-x: auto;
    }
  `
);
