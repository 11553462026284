import Immutable from 'immutable';
import {
  GET_ALL_USERS,
  RESET_USER_DASHBOARD,
  RESET_USER_PASSWORD,
  SAVE_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
} from './actions';

const GET_ALL_USERS_LOADING =
  'react-boilerplate/Admin/UserAdministration/GET_ALL_USERS_LOADING';
const RESET_USER_DASHBOARD_LOADING =
  'react-boilerplate/Admin/UserAdministration/RESET_USER_DASHBOARD_LOADING';
const RESET_USER_PASSWORD_LOADING =
  'react-boilerplate/Admin/UserAdministration/RESET_USER_PASSWORD_LOADING';
const SAVE_NEW_USER_LOADING =
  'react-boilerplate/Admin/UserAdministration/SAVE_NEW_USER_LOADING';
const SAVE_NEW_USER_ERROR =
  'react-boilerplate/Admin/UserAdministration/SAVE_NEW_USER_ERROR';
const UPDATE_USER_LOADING =
  'react-boilerplate/Admin/UserAdministration/UPDATE_USER_LOADING';
const DELETE_USER_LOADING =
  'react-boilerplate/Admin/UserAdministration/DELETE_USER_LOADING';
const DELETE_USER_ERROR =
  'react-boilerplate/Admin/UserAdministration/DELETE_USER_ERROR';

export default function userAdministrationReducer(
  state = Immutable.Map(),
  action,
) {
  switch (action.type) {
    case GET_ALL_USERS: {
      return state.merge({ users: action.data.data, loading: false });
    }
    case GET_ALL_USERS_LOADING: {
      return state.set('loading', true);
    }
    case RESET_USER_DASHBOARD: {
      return state.set('resettingDashboard', false);
    }
    case RESET_USER_DASHBOARD_LOADING: {
      return state.set('resettingDashboard', true);
    }
    case RESET_USER_PASSWORD: {
      return state.set('resettingPassword', false);
    }
    case RESET_USER_PASSWORD_LOADING: {
      return state.set('resettingPassword', true);
    }
    case SAVE_NEW_USER_LOADING: {
      return state.set('savingUser', true);
    }
    case UPDATE_USER_LOADING: {
      return state.set('savingUser', true);
    }
    case UPDATE_USER: {
      return state.merge({ savingUserError: '', savingUser: false });
    }
    case SAVE_NEW_USER: {
      return state.merge({ savingUserError: '', savingUser: false });
    }
    case SAVE_NEW_USER_ERROR: {
      return state.merge({
        savingUserError: action.errorMessage,
        savingUser: false,
      });
    }
    case DELETE_USER_LOADING: {
      return state.merge({
        deletingUser: true,
      });
    }
    case DELETE_USER_ERROR: {
      return state.merge({
        deletingUserError: action.errorMessage,
        deletingUser: false,
      });
    }
    case DELETE_USER: {
      return state.merge({
        deletingUserError: '',
        deletingUser: false,
      });
    }
    default: {
      return state;
    }
  }
}
