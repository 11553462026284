export const GET_REPORTED_DATA = 'react-boilerplate/Admin/GET_REPORTED_DATA';
export const ACCEPT_REPORTED_DATA = 'react-boilerplate/Admin/ACCEPT_REPORTED_DATA';
export const DECLINE_REPORTED_DATA = 'react-boilerplate/Admin/DECLINE_REPORTED_DATA';
export const CHANGE_SHOPPING_CART_VALUE = 'react-boilerplate/Admin/CHANGE_SHOPPING_CART_VALUE';

export const getReportedData = (params, api) => ({
  type: GET_REPORTED_DATA,
  field: api,
  meta: {
    type: 'api',
    url: `admin/${api}/pending?${params}`,
    method: 'get',
  },
});

export const acceptReportedData = (reportedDataIDs, api) => ({
  type: ACCEPT_REPORTED_DATA,
  field: api,
  meta: {
    type: 'api',
    url: `admin/${api}/accept`,
    method: 'post',
    data: { ids: reportedDataIDs },
  },
});

export const declineReportedData = (reportedDataIDs, api) => ({
  type: DECLINE_REPORTED_DATA,
  field: api,
  meta: {
    type: 'api',
    url: `admin/${api}/decline`,
    method: 'post',
    data: { ids: reportedDataIDs },
  },
});

export const changeShoppingCartValue = (reportId, newCartValue) => ({
  type: CHANGE_SHOPPING_CART_VALUE,
  meta: {
    type: 'api',
    url: 'admin/reports/edit',
    method: 'post',
    data: { id: reportId, newValue: newCartValue },
  },
  newValue: newCartValue,
});
