import module from "module";
import { DEFAULT_QUERY_LIMIT, Routes } from "config";
import { PageWrapper, NavigationBar, Permission, QueryConfigProvider } from "shared";
import { ContactPersonList } from "../components";

const ContactPersonsPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <QueryConfigProvider queryName="contactPersonList" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ContactPersonList />
      </QueryConfigProvider>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.CONTACT_PERSONS,
  requiredPermission: Permission.USER
});

export default ContactPersonsPage;
