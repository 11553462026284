import Icon from "@mui/icons-material/ExpandMoreOutlined";
import { styled, Theme, MenuItem as MuiMenuItem, Typography as MuiTypography } from "@mui/material";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  marginTop: "24px",
  maxHeight: "224px",
  backgroundColor: "transparent",

  "& .MuiList-root": {
    backgroundColor: custom.grey.main
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});

export const Chevron = styled(Icon)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiSvgIcon-root {
      color: ${custom.white.light};
      margin-right: 4px;

      &.MuiSelect-iconOpen {
        color: ${custom.white.main};
        transform: none;
      }
    }
  `
);

export const MenuItem = styled(MuiMenuItem)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    padding: 14px 12px;
    color: ${custom.white.main};
    font-size: ${body1.mobile.fontSize};
    line-height: ${body1.mobile.lineHeight};
    font-weight: ${body1.mobile.fontWeight};

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      line-height: ${body1.tablet.lineHeight};
      font-weight: ${body1.tablet.fontWeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      line-height: ${body1.desktop.lineHeight};
      font-weight: ${body1.desktop.fontWeight};
    }

    &.Mui-disabled {
      color: ${custom.white.dark};
    }

    &:hover {
      background-color: ${custom.grey.dark};
    }

    &.Mui-selected {
      background-color: ${custom.grey.light};

      &:hover {
        background-color: ${custom.white.dark};
      }
    }
  `
);

export const Placeholder = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    color: ${custom.white.light};
    font-size: ${body1.mobile.fontSize};
    line-height: ${body1.mobile.lineHeight};
    font-weight: ${body1.mobile.fontWeight};

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      line-height: ${body1.tablet.lineHeight};
      font-weight: ${body1.tablet.fontWeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      line-height: ${body1.desktop.lineHeight};
      font-weight: ${body1.desktop.fontWeight};
    }
  `
);
