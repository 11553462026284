export const FETCH_TABLE_DATA = 'react-boilerplate/LiveDashboard/Graphs/FETCH_TABLE_DATA';
export const FETCH_GRAPH_LINE_DATA = 'react-boilerplate/LiveDashboard/Graphs/FETCH_GRAPH_LINE_DATA';
export const CHANGE_CHART_CONFIG = 'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART_CONFIG';
export const BASE_64_STRING = 'react-boilerplate/LiveDashboard/Graphs/BASE_64_STRING';
export const GET_TABLE_MAPPING = 'react-boilerplate/LiveDashboard/Graphs/GET_TABLE_MAPPING';
export const CHANGE_CHART = 'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART';
export const CHANGE_TABLE = 'react-boilerplate/LiveDashboard/Graphs/CHANGE_TABLE';
export const CLEAR_TABLE_DATA = 'react-boilerplate/LiveDashboard/Graphs/CLEAR_TABLE_DATA';

export const changeChart = (data) => ({
  type: CHANGE_CHART,
  index: data.index,
  newConfig: data,
  meta: {
    type: 'api',
    url: 'dashboard/views',
    method: 'put',
    data: {
      as: data.as,
      configs: data.configs,
      element: data.element,
      end: data.end,
      index: data.index,
      interval: data.interval,
      start: data.start,
      title: data.title,
      type: data.type,
      help: data.help,
    },
  },
});

export const fetchGraphLineData = (index, params, configIndex) => ({
  type: FETCH_GRAPH_LINE_DATA,
  index,
  configIndex,
  meta: {
    type: 'api',
    url: `data?${params}`,
    // url: `data`,
    method: 'get',
    // params,
  },
});

export const changeChartConfig = (data) => ({
  type: CHANGE_CHART_CONFIG,
  index: data.index,
  newConfig: data,
  meta: {
    type: 'api',
    url: 'dashboard/views',
    method: 'put',
    data: {
      as: data.as,
      configs: data.configs,
      element: data.element,
      end: data.end,
      index: data.index,
      interval: data.interval,
      start: data.start,
      title: data.title,
      type: data.type,
      help: data.help,
    },
    error: {
      400: 'Wrong request.',
      500: 'Server temporary unavailable.',
    },
  },
});

export const fetchTableData = (index, params, api='transactions') => ({
  type: FETCH_TABLE_DATA,
  index,
  meta: {
    type: 'api',
    url: `${api}?${params}`,
    // url: 'transactions',
    method: 'get',
    // params,
  },
});

export const clearTableData = (index) => ({
  type: CLEAR_TABLE_DATA,
  index,
});

export const changeTable = (data) => ({
  type: CHANGE_TABLE,
  index: data.index,
  newConfig: data,
  meta: {
    type: 'api',
    url: 'dashboard/views',
    method: 'put',
    data: {
      columns: data.columns,
      element: data.element,
      end: data.end,
      help: data.help,
      index: data.index,
      shops: data.shops,
      start: data.start,
      title: data.title,
      type: data.type,
      pageSize: data.pageSize,
      api: data.api,
    },
  },
});

export const base64String = (id, base64) => ({
  type: BASE_64_STRING,
  base64,
  id,
});

export const getTableMapping = (index, api='regainTableColumns') => ({
  type: GET_TABLE_MAPPING,
  index,
  meta: {
    type: 'api',
    url: `dashboard/${api}`,
    method: 'get',
  },
});
