import { apiClient } from "shared";
import { QuestionnaireAddOptionStepData, ShopIdParam } from "api";

export const putSubmitAddOptions = async ({
  shopId,
  ...values
}: QuestionnaireAddOptionStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/add-options`, values);

  return data;
};
