/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import DOMPurify from 'dompurify';
import classNames from 'classnames';

import UpInput from '../UpInput';
import messages from './messages';
import FieldsHOC from '../FieldsHOC/index';

const Input = styled(UpInput)`
  margin-right: 5px;
  border: none;
  padding: 0px;
  background: none;
  overflow-x: auto;
  height: 100%;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  &:disabled {
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    background: none;
    cursor: pointer;
  }
`;

const Options = styled.div`
  position: absolute;
  background: #14171c;
  width: ${(props) => props.width}px;
  border-radius: 3px;
  z-index: 10;
  max-height: 250px;
  overflow-y: scroll;
  bottom: ${(props) => (props.optionsPosition === 'top' ? '56px' : 'auto')};
`;

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  &.activeOption {
    background: #3DD3CE;
  }
  &.focusedOption {
    background: #1f242e;
  }
`;

const SelectBoxWrapper = styled.div`
  border-radius: 3px;
  background: #14171c;
  &.disabled {
    background: #EFEFEF26;
  }
`;

const Wrapper = styled.div`
  margin: ${(props) => props.margin ?? '0px'};
  padding: ${(props) => props.padding ?? '0px'};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => props.width || 'auto'};
`;

const InputWrapper = styled.div`
  padding: 7px 15px 7px 10px;
  border-radius: 3px;
  width: 100%;
  border: ${(props) => props.theme.inputBorder};
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.fontColor};
  position: relative;
  height: 36px;
  svg {
    height: 15px;
    width: 15px;
    fill: ${(props) => props.theme.fontColor};
  }
  &.disabled {
    background-color: #EFEFEF26;
    &.focused {
      box-shadow: 0px 0px 5px #EFEFEF26;
    }
  }
  &.focused:not(.disabled) {
    ${(props) => props.theme.inputFocus}
  }
  &.error {
    padding-right: 22px;
    border-color: ${(props) => props.theme.primaryActionColor};
    background-color: #f5d1d2;
  }
`;

class SelectBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      optionsVisible: false,
      visibleOptions: props.sort
        ? props.options
          .filter((option) => !option.disabled)
          .sort((a, b) => this.alphabeticOrder(a, b))
        : props.options.filter((option) => !option.disabled),
      input: this.getLabel(false, props.value),
      width: 0,
      selectedOption: '',
      focusedOption: 0,
      controls: '',
      focused: false,
      mouseOverOptions: true,
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true,
      input: this.getLabel(
        this.state.mounted,
        this.props.value,
        this.props.options,
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.value !== this.props.value ||
      !fromJS(prevProps.options).equals(fromJS(this.props.options))
    ) {
      this.updateInput();
    }

    if (
      prevState.focusedOption !== this.state.focusedOption &&
      this.state.controls === 'keys'
    ) {
      /** when the user uses the arrow keys to scroll through the options it will automatically scroll */
      if (this.focusedOption) {
        this.textInput.scrollTop = this.focusedOption.offsetTop - 40;
      }
    }
    if (
      this.state.optionsVisible === true &&
      prevState.optionsVisible !== this.state.optionsVisible
    ) {
      /** on opening the options it scrolls automatically to the selected value */
      if (this.focusedOption) {
        this.textInput.scrollTop = this.focusedOption.offsetTop - 40;
      }
    }
  }

  updateInput = () => {
    this.setState({
      input: this.getLabel(
        this.state.mounted,
        this.props.value,
        this.props.options,
      ),
    });
  };

  /**
   * close options component when the user clicks anywhere
   */
  closeOptions = (e) => {
    if (this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.setState({
        optionsVisible: false,
        input: this.getLabel(this.props.value),
        focused: false,
      });
      document.removeEventListener('click', this.closeOptions, false);
      window.removeEventListener('resize', this.updateDimensions, false);
    }
  };

  /**
   * when the browser window is resized the options menu must be resized too
   */
  updateDimensions = () => {
    if (this.node) {
      this.setState({
        width: this.node.offsetWidth,
      });
    }
  };

  /**
   * function to open the options menu
   */
  openOptions() {
    document.addEventListener('click', this.closeOptions, false);
    window.addEventListener('resize', this.updateDimensions, false);
    if (!this.props.disabled) {
      this.input.focus();
      this.setState({
        optionsVisible: true,
        width: this.node.offsetWidth,
        focusedOption:
          this.props.value === ''
            ? 0
            : this.props.options.indexOf(
              this.props.options.find(
                (option) => option.value === this.props.value,
              ),
            ),
        visibleOptions: this.props.sort
          ? this.props.options
            .filter((option) => !option.disabled)
            .sort((a, b) => this.alphabeticOrder(a, b))
          : this.props.options.filter((option) => !option.disabled),
        focused: true,
      });
    }
  }

  // function to select an option
  selectOption(option) {
    this.setState({
      optionsVisible: false,
      input: option.label,
      focused: false,
    });
    this.props.onChange(this.props.name, option.value);
  }

  /**
   * this functions handles the arrow keys control and the close of the options on tab
   * @param {object} e - event interface is a reference to the object onto which the event was dispatched.
   */
  handleKeyDown(e) {
    if (e.keyCode === 38) {
      /** on arrow key up */
      e.preventDefault();
      if (!this.state.optionsVisible) {
        this.openOptions();
      } else {
        this.setState((prevState) => ({
          focusedOption:
            (((prevState.focusedOption - 1) %
              this.state.visibleOptions.length) +
              this.state.visibleOptions.length) %
            this.state.visibleOptions.length,
          controls: 'keys',
        }));
      }
    } else if (e.keyCode === 40) {
      /** on arrow key down */
      e.preventDefault();
      if (!this.state.optionsVisible) {
        this.openOptions();
      } else {
        this.setState((prevState) => ({
          focusedOption:
            (prevState.focusedOption + 1) % this.state.visibleOptions.length,
          controls: 'keys',
        }));
      }
    } else if (e.keyCode === 9 || e.keyCode === 27) {
      /** on tab or esc close the options */
      this.setState({
        optionsVisible: false,
        input: this.getLabel(this.props.value),
        focused: false,
      });
    } else if (e.keyCode === 13) {
      /** on enter select the focused option */
      e.preventDefault();
      if (this.state.visibleOptions.length !== 0) {
        this.setState({
          input: this.state.visibleOptions[this.state.focusedOption].label,
        });
        this.selectOption(this.state.visibleOptions[this.state.focusedOption]);
      }
    }
  }

  /**
   * handles the hover function for the options
   * @param {number} index - index from the map function
   */
  handleMouseOver(index) {
    this.setState({
      focusedOption: index,
      controls: 'mouse',
    });
  }

  /**
   * sort function for the options
   */
  alphabeticOrder(a, b) {
    const A = a?.label?.toLowerCase();
    const B = b?.label?.toLowerCase();
    if (A === B) return 0;
    return A < B ? -1 : 1;
  }

  /**
   * gets called when the user types something in the input field and then filters the options based on the input value
   * @param {object} e - event interface is a reference to the object onto which the event was dispatched.
   */
  inputChange(e) {
    this.setState({
      input: e.target.value,
      optionsVisible: true,
      focusedOption: 0,
      focused: true,
      visibleOptions: this.props.sort
        ? this.props.options
          .filter((option) =>
            !option.disabled && option?.label?.toLowerCase().includes(e.target.value.toLowerCase()),
          )
          .sort((a, b) => this.alphabeticOrder(a, b))
        : this.props.options.filter((option) =>
          !option.disabled && option?.label?.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
    });
  }

  handleMouseLeave() {
    this.setState({
      mouseOverOptions: false,
      focusedOption: -1,
    });
  }

  /**
   * Get default option for selectBox
   * @param {boolean} mounted - check if component has been mounted for the first render
   * set to 'true' after componentDidMount
   * @param {string} value - value for the option coming from props
   * @param {options} options - list of select options coming from props
   */
  getLabel(mounted, value = this.props.value, options = this.props.options) {
    const option = options.find((opt) => opt.value === value);

    if (
      !option &&
      (value === '' || value === undefined) &&
      mounted &&
      this.props.placeholder
    ) {
      return '';
    }

    if (!option && (value === '' || value === undefined) && mounted) {
      // eslint-disable-next-line no-console
      console.warn(
        `no default value found value: ${value} type ${typeof value} options: ${JSON.stringify(
          options,
        )}`,
      );
      return '--';
    }

    if (!option && mounted) {
      // eslint-disable-next-line no-console
      console.error(
        `NO OPTION FOUND with value: ${value} type ${typeof value} options: ${JSON.stringify(
          options,
        )}`,
      );
      return '-!-';
    }

    if (option && typeof option.label === 'string' && mounted) {
      return option.label.replace(/(<([^>]+)>)/gi, '');
    }
    return option ? `${option.label}` : '';
  }

  handleMouseEnter() {
    this.setState({
      mouseOverOptions: true,
    });
  }

  render() {
    return (
      <Wrapper
        className={this.props.className}
        margin={this.props.margin}
        padding={this.props.padding}
        minWidth={this.props.minWidth}
        width={this.props.width}
      >
        <SelectBoxWrapper
          className={`${this.props.disabled ? 'disabled' : ''}`}
        >
          <div
            ref={(node) => {
              this.node = node;
            }}
          >
            <InputWrapper
              tabIndex={`${this.props.disabled ? '-1' : '0'}`}
              onClick={(e) => {
                this.openOptions();
                if (e.target.select) {
                  e.target.select();
                }
              }}
              onFocus={() => this.openOptions()}
              onKeyDown={(e) => this.handleKeyDown(e)}
              className={classNames(this.props.className, {
                focused: this.state.focused,
                disabled: this.state.input && this.props.options.some((option) =>
                  option.value === this.props.value && option.disabled
                )
              })}
            >
              <Input
                tabIndex="-1"
                placeholder={this.props.placeholder}
                value={this.state.input}
                onChange={(e) => this.inputChange(e)}
                ref={(input) => {
                  this.input = input;
                }}
                disabled={this.props.inputDisabled}
                width="100%"
              />
              <svg viewBox="0 0 2.13 3.64">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      className="cls-1"
                      points="0 1.49 1.07 0 2.13 1.49 0 1.49"
                    />
                    <polygon
                      className="cls-1"
                      points="2.13 2.15 1.07 3.64 0 2.15 2.13 2.15"
                    />
                  </g>
                </g>
              </svg>
            </InputWrapper>
            {this.state.optionsVisible && (
              <Options
                className="selectBox--options"
                onMouseLeave={() => this.handleMouseLeave()}
                onMouseEnter={() => this.handleMouseEnter()}
                width={this.state.width}
                ref={(thisInput) => {
                  this.textInput = thisInput;
                }}
                optionsPosition={this.props.optionsPosition}
              >
                {this.state.visibleOptions.map((option, index) => (
                  <Option
                    className={classNames({
                      activeOption: this.props.value === option.value,
                      focusedOption: this.state.focusedOption === index &&
                        this.props.value !== option.value &&
                        (this.state.mouseOverOptions || this.state.controls === 'keys')
                    })}
                    key={index}
                    onClick={() => this.selectOption(option)}
                    onMouseMove={() => this.handleMouseOver(index)}
                    ref={
                      this.state.focusedOption === index &&
                      ((focusedOption) => {
                        this.focusedOption = focusedOption;
                      })
                    }
                    title={`${this.props.intl.formatMessage(messages.select, {
                      option: option.label,
                    })}`}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(option.label),
                    }}
                  ></Option>
                ))}
              </Options>
            )}
          </div>
        </SelectBoxWrapper>
      </Wrapper>
    );
  }
}

SelectBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sort: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  minWidth: PropTypes.string,
  width: PropTypes.string,
  optionsPosition: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(SelectBox);

const StyledSelectBox = styled(SelectBox)`
  margin: ${(props) => props.margin || '0 5px 0 0'};
  @media only screen and (max-width: 470px) {
    margin: ${(props) => props.margin || '0 10px 0 0'};
  }
`;

const unwrappedSelectBox = (props) => (
  <StyledSelectBox
    options={props.customProps.options}
    value={props.input.value || props.defaultValue}
    name={props.input.name}
    onChange={(name, value) => {
      props.input.onChange(value);
      if (props.customProps.onChange) {
        props.customProps.onChange(name, value);
      }
    }}
    minWidth={props.customProps.minWidth}
    inputDisabled={props.customProps.inputDisabled === undefined}
    placeholder={props.placeholder}
    className={props.meta.error && props.meta.submitFailed && 'error'}
    margin={props.margin}
    padding={props.padding}
    intl={props.intl}
  />
);

const selectBox = injectIntl(FieldsHOC(unwrappedSelectBox));

export { selectBox };

unwrappedSelectBox.defaultProps = {
  defaultValue: '',
};

unwrappedSelectBox.propTypes = {
  customProps: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  padding: PropTypes.string,
  intl: PropTypes.object,
};
