import { PropsWithChildren, memo, useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { ProviderEvents } from "@openfeature/web-sdk";
import { OpenFeatureStateContextProvider } from "../../../context";
import { openFeatureClient } from "../../../utils";

const OpenFeatureContextProviderComponent = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [flagsState, setFlagsState] = useState<Record<string, boolean | null>>({});

  // Now all flags are stored inside global context to prevent unnecessary state invalidations
  const onFlagsChangeHandler = useCallback(
    debounce(() => {
      setFlagsState((prevState) => {
        const flagsCopy = { ...prevState };

        const flagsNames = Object.keys(flagsCopy);

        flagsNames.forEach((flagName) => {
          const value = openFeatureClient.getBooleanValue(flagName, false);

          flagsCopy[flagName] = value;
        });

        return flagsCopy;
      });
    }, 1000),
    []
  );

  const onReadyHandler = useCallback(() => {
    setIsLoading(false);
    // Get all requested flags at the moment when client are ready and connected
    onFlagsChangeHandler();
  }, [onFlagsChangeHandler]);

  const onErrorHandler = useCallback(() => {
    setIsLoading(false);
    setIsError(true);
  }, []);

  // This method should be called to enqueue flag to global context
  // Calls count are not necessary but at least one call is required to enqueue flag
  const addFlagToContext = useCallback(
    (flagKey: string) => {
      setFlagsState((prevState) => {
        const flagsCopy = { ...prevState };

        // If flags are not loaded yet, we should set as uninitialized value
        if (isLoading) {
          flagsCopy[flagKey] = null;
        } else {
          flagsCopy[flagKey] = openFeatureClient.getBooleanValue(flagKey, false);
        }

        return flagsCopy;
      });
    },
    [isLoading]
  );

  useEffect(() => {
    openFeatureClient.addHandler(ProviderEvents.Ready, onReadyHandler);

    openFeatureClient.addHandler(ProviderEvents.Error, onErrorHandler);

    openFeatureClient.addHandler(ProviderEvents.ConfigurationChanged, onFlagsChangeHandler);

    return () => {
      openFeatureClient.removeHandler(ProviderEvents.Ready, onReadyHandler);

      openFeatureClient.removeHandler(ProviderEvents.Error, onErrorHandler);

      openFeatureClient.removeHandler(ProviderEvents.ConfigurationChanged, onFlagsChangeHandler);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      loading: isLoading,
      error: isError,
      flagsState,
      addFlagToContext,
      onFlagsChangeHandler
    }),
    [isLoading, isError, flagsState, addFlagToContext, onFlagsChangeHandler]
  );

  return (
    <OpenFeatureStateContextProvider value={contextValue}>
      {children}
    </OpenFeatureStateContextProvider>
  );
};

export const OpenFeatureContextProvider = memo(OpenFeatureContextProviderComponent);
