const optin = (newId) => ({
  vouchers: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-' + newId,
    voucherCode: '',
    voucherCodeFootnote: '',
    currencySymbol: '&euro;',
    value: '',
    voucherType: 'name',
    conditions: [],
    trackingOptions: [],
  },
  fomo: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-' + newId,
    conditions: [],
    trackingOptions: [],
  },
});

const mail = (newId) => ({
  vouchers: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    voucherCode: '',
    voucherCodeFootnote: '',
    currencySymbol: '&euro;',
    value: '',
    voucherType: 'name',
    conditions: [],
  },
  shoppingcart: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    conditions: [],
  },
  links: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    conditions: [],
  },
  custom: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    type: 2,
    language: 'de',
    productId: 1,
    html: '',
    plain: '',
    activationTemplateRef: '',
    subject: '',
    conditions: [],
  },
});

const activation = (newId) => ({
  vouchers: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    voucherCode: '',
    voucherCodeFootnote: '',
    currencySymbol: '&euro;',
    value: '',
    voucherType: 'name',
    conditions: [],
    trackingOptions: [],
  },
  activemails: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    contactPerson: '',
    serviceCategory: '',
    email: '',
    conditions: [],
    trackingOptions: [],
  },
  passivemails: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    mailAddress: '',
    mailSubject: '',
    mailContent: '',
    conditions: [],
    trackingOptions: [],
  },
  phonenumbers: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    phoneNumber: '',
    // availability: '',
    phoneNumberFootnote: '',
    conditions: [],
    trackingOptions: [],
  },
  callbacks: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    availability: '',
    conditions: [],
    trackingOptions: [],
  },
  shoppingcart: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    conditions: [],
    trackingOptions: [],
  },
  links: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    linkTitle: '',
    linkURL: '',
    linkTarget: '_self',
    heading: '',
    subtitle: '',
    subtitleSecondLine: '',
    footnote: '',
    conditions: [],
    trackingOptions: [],
  },
  custom: {
    id: newId,
    enabled: true,
    new: true,
    title: 'ID-'+newId,
    regainType: '',
    activationWindow: ['', ''],
    mobileTrigger: [],
    desktopTrigger: [],
    fallbackTrigger: [],
    exitTimeout: '',
    blockDurationMinutes: '',
    conditions: [],
    trackingOptions: [],
  },
});


const productLines = (newId) => ({
  optin: optin(newId),
  mail: mail(newId),
  activation: activation(newId),
});

export default productLines;
