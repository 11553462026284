import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const InfoListWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      ${[breakpoints.up("md")]} {
        margin-top: 80px;
      }
    }
  `
);

export const InfoListItem = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin-bottom: 14px;
    }
  `
);

export const InfoListItemHeading = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
    }
  `
);

export const InfoListItemHeadingTypography = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.turquoise.light};
      font-size: ${body1.desktop.fontSize};
      font-weight: ${body1.desktop.fontWeight};
      line-height: ${body1.desktop.lineHeight};
      text-transform: uppercase;
      margin-left: 8px;
    }
  `
);

export const InfoListItemTypography = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${body1.desktop.fontSize};
      font-weight: ${body1.desktop.fontWeight};
      line-height: ${body1.desktop.lineHeight};
      margin-left: 38px;
    }
  `
);
