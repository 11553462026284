import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TriggerConfig } from "api";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import {
  NetworkError,
  useErrorFormatter,
  useNotifications,
  useQueryConfig,
  useSelectedShop
} from "shared";
import { putBrowseAbandonmentConfig } from "../../api";

export const useUpdateBrowseAbandonmentConfig = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: updateBrowseAbandonmentConfig } = useMutation<
    void,
    AxiosError<{ message: string }>,
    { shopId: number; minimumWaitingHours: number }
  >(putBrowseAbandonmentConfig);

  const executeUpdateBrowseAbandonmentConfig: FormProps<TriggerConfig, TriggerConfig>["onSubmit"] =
    useCallback(
      async ({ minimumWaitingHours }, form) => {
        try {
          await updateBrowseAbandonmentConfig({
            shopId: selectedShopId!,
            minimumWaitingHours
          });

          queryClient.invalidateQueries([queryName, selectedShopId]);

          form.reset();

          showNotification("featuresModule.browseAbandonment.updateSuccessMessage", "success");
        } catch (error) {
          return formatError(error as NetworkError);
        }
      },
      [selectedShopId, queryName, showNotification]
    );

  return executeUpdateBrowseAbandonmentConfig;
};
