import { apiClient } from "shared";

export const putComeBackMailingConfig = async ({
  shopId,
  minimumWaitingHours
}: {
  shopId: number;
  minimumWaitingHours: number;
}) => {
  const { data } = await apiClient.put<void>(`/${shopId}/triggers/come-back-mailing`, {
    minimumWaitingHours
  });

  return data;
};
