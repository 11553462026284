import { styled, TextField as MuiTextField, Theme } from "@mui/material";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: custom.background,
  boxShadow: custom.shadows.navigation,

  "& .MuiAutocomplete-listbox": {
    padding: "24px 0",

    "& .MuiAutocomplete-option": {
      color: custom.white.light,

      "&:hover": {
        background: "none",
        color: custom.white.main
      },

      '&[aria-selected="true"]': {
        background: "none",
        color: custom.turquoise.main,

        "&.Mui-focused": {
          background: "none"
        },

        "&:hover": {
          background: "none",
          color: custom.white.main
        }
      }
    }
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});

export const Switcher = styled(MuiTextField)(
  ({
    theme: {
      palette: { custom },
      typography: { body1, body2 }
    }
  }) => {
    return `
      &:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      & .MuiFormLabel-root {
        color: ${custom.white.light};
        font-size: ${body2.mobile.fontSize};
        line-height: ${body2.mobile.lineHeight};
        font-weight: ${body2.mobile.fontWeight};
        text-transform: uppercase;
        margin-bottom: 0;
        left: -8px;
        top: 6px;
  
        & .MuiInputLabel-asterisk {
          color: ${custom.white.light};
        }
  
        &.Mui-disabled {
          color: ${custom.white.light};
        }
  
        &.Mui-error {
          color: ${custom.white.light};
        }
  
        &.Mui-focused {
          color: ${custom.white.light};
        }
      }

      & .MuiInputBase-root {
        margin: 30px 0 15px;
        padding: 0 0 0 8px;

        & .MuiAutocomplete-popupIndicator {
          color: ${custom.white.main};
        }

        &:hover {
          & .MuiInputBase-input {
            color: ${custom.turquoise.main};
          }

          & .MuiAutocomplete-popupIndicator {
            color: ${custom.turquoise.main};
          }
        }

        & .MuiInputBase-input {
          padding: 5px 0;
          color: ${custom.white.main};
          font-size: ${body1.mobile.fontSize};
          line-height: ${body1.mobile.lineHeight};
          font-weight: ${body1.mobile.fontWeight};
        }

        & .MuiOutlinedInput-notchedOutline {
          padding: 0 18px;
          border: none;
  
          & legend {
            margin-bottom: 0;
  
            & span {
              padding: 0 4px;
            }
          }
        }
      }
    `;
  }
);
