import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContactPerson } from "api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import {
  useQueryConfig,
  useSelectedShop,
  NetworkError,
  useErrorFormatter,
  useNotifications
} from "shared";
import { postContactPerson } from "../../api";

export const useCreateContactPerson = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: createContactPerson } = useMutation<
    ContactPerson,
    AxiosError<{ message: string }>,
    ContactPerson & { shopId: number }
  >(postContactPerson);

  const executeCreateContactPerson: FormProps<ContactPerson, ContactPerson>["onSubmit"] =
    useCallback(
      async (values) => {
        try {
          await createContactPerson({
            ...values,
            shopId: selectedShopId!
          });

          queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);

          showNotification("settingsModule.contactPersons.createSuccessMessage", "success");
        } catch (error) {
          return formatError(error as NetworkError);
        }
      },
      [selectedShopId, queryName, limit, offset, showNotification]
    );

  return executeCreateContactPerson;
};
