import React from 'react';
import PropTypes from 'prop-types';

import UpContainerLoading from '../UpContainerLoading';
import LoadingSpinner from '../LoadingSpinner';
import FancyLoadingIcon from '../FancyLoadingIcon';

const FALLBACK_STATES = {
  login: (
    <UpContainerLoading width="100%" flex="auto" padding="0 0 150px">
      <LoadingSpinner borderColor="rgba(255,255,255,0.3)" />
    </UpContainerLoading>
  ),
  dashboard: (
    <UpContainerLoading width="100%" height="calc(100vh - 101px)">
      <FancyLoadingIcon />
    </UpContainerLoading>
  ),
  component: (
    <UpContainerLoading width="100%">
      <LoadingSpinner />
    </UpContainerLoading>
  ),
};

const LoadingFallback = ({ path = 'component' }) => (
  <>{FALLBACK_STATES[path]}</>
);

LoadingFallback.propTypes = {
  path: PropTypes.string,
};

export default LoadingFallback;
