import module from "module";
import { DEFAULT_QUERY_LIMIT, Routes } from "config";
import { PageWrapper, NavigationBar, Permission, QueryConfigProvider } from "shared";
import { ShopLinksForm } from "../components";

const ShopLinksPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <QueryConfigProvider queryName="linksConfig" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ShopLinksForm />
      </QueryConfigProvider>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.SHOP_LINKS,
  requiredPermission: Permission.USER
});

export default ShopLinksPage;
