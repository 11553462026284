import { useCallback } from "react";
import { QuestionnaireProductsStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitProducts } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useProductsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.PRODUCTS);

  const data = stepData?.data;

  const submitProducts =
    useQuestionnaireSubmitMutation<QuestionnaireProductsStepData>(putSubmitProducts);

  const executeSubmitProducts: FormProps<
    QuestionnaireProductsStepData,
    QuestionnaireProductsStepData
  >["onSubmit"] = useCallback(
    async ({ enabledProducts }) => {
      try {
        await submitProducts({
          enabledProducts
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitProducts]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitProducts
  };
};
