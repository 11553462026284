import { createGlobalStyle } from 'styled-components';
import background_lines_small from "assets/images/background_lines/small.png";
import background_lines_large from "assets/images/background_lines/large.png";

/* eslint no-unused-expressions: 0 */
export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    position: relative;
    min-height: 100%;
    color: #EFEFEF;
    background-color: #0D1015;
  }

  body.hideOverflow{
    overflow: hidden;
  }

  #root {
    background-color: #0D1015;
    min-height: 100%;
    min-width: 100%;
    background-image: url(${background_lines_small});
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-position: center;
    background-attachment: fixed;

    @media (min-width: 1200px) {
      background-image: url(${background_lines_large});
    }
  }

  p,
  label {
    line-height: 1.5em;
  }

  .tippy-popper{
    z-index: 299 !important;
  }

  .notification-warning {
    background-color: #fb6200 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    &:disabled {
      -webkit-box-shadow: 0 0 0px 1000px #c7c7c7 inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  button, input, select, textarea {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font-size: 1em;
    margin: 0;
  }
`;
