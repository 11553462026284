import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl } from 'react-intl';

import { creatable } from '../../UpStyledCreatable';
import { selectBox } from '../../SelectBox';
import UpRegex from '../../UpRegex';
import FormValueSelector from '../../UpFormValueSelector';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  display: flex;
  flex: 1 1 auto;
  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const CustomerGroup = (props) => (
  <Filter>
    <Row>
      <Field
        name={`${props.member}.filter.operator`}
        component={selectBox}
        customProps={{
          options: [
            {
              value: 'contains',
              label: props.intl.formatMessage(messages.contains),
            },
            {
              value: 'contains-not',
              label: props.intl.formatMessage(messages.containsNot),
            },
            {
              value: 'regex',
              label: props.intl.formatMessage(messages.matchesRegex),
            },
          ],
          minWidth: '250px',
          margin: '0 10px 0 0',
          onChange: () => props.resetOnChange(),
        }}
      />
    </Row>
    <FormValueSelector
      fieldName={`conditions[${props.member
        .split('[')[1]
        .charAt(0)}]filter.operator`}
    >
      {(operator) =>
        operator === 'regex' ? (
          <UpRegex
            name={`${props.member}.filter.attribute[0].value`}
            margin="0 10px 0 0"
            width="100%"
            fieldValidation={false}
          />
        ) : (
          <Field
            name={`${props.member}.filter.attribute`}
            component={creatable}
          />
        )
      }
    </FormValueSelector>
  </Filter>
);

CustomerGroup.propTypes = {
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(CustomerGroup);
