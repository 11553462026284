import moment from 'moment';

export const GET_DWH_DATA = 'react-boilerplate/sales/analysis/GET_DWH_DATA';
export const GET_ALL_SHOPS =
  'react-boilerplate/Admin/StoreAdministration/GET_ALL_SHOPS';

export const loadData = (
  shopId,
  dataType,
  shopSqlId,
  referenceIntervalDays,
  interval,
  start,
  end,
) => ({
  type: GET_DWH_DATA,
  shopId,
  shopSqlId,
  dataType,
  meta: {
    type: 'api',
    url: `admin/data?shop=${shopId}&start=${
      start ||
      moment().subtract(referenceIntervalDays, 'days').format('YYYY-MM-DD')
    }&end=${
      end || moment().format('YYYY-MM-DD')
    }&interval=${interval}&as=count&${dataType
      .split(',')
      .map((d) => `product=${d}`)
      .join('&')}`,
    method: 'get',
  },
});

export const getAllShops = () => ({
  type: GET_ALL_SHOPS,
  meta: {
    type: 'api',
    url: 'admin/shoplist',
    method: 'get',
  },
});
