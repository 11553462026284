import { styled, Typography as MuiTypography, Box as MuiBox } from "@mui/material";
import { StepperCircleTypographyProps, StepperCircleWrapperProps } from "./NumberCircle.props";

export const Typography = styled(
  ({ ...props }: StepperCircleTypographyProps) => <MuiTypography {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "completed" && prop !== "selected"
  }
)(
  ({
    theme: {
      typography: { stepperNumber },
      palette: { custom }
    },
    completed,
    selected
  }) => {
    const defaultColor = completed ? custom.white.dark : custom.white.light;

    const color = selected ? custom.turquoise.light : defaultColor;

    return `
      color: ${color};
      font-size: ${stepperNumber.desktop.fontSize};
      font-weight: ${stepperNumber.desktop.fontWeight};
      line-height: ${stepperNumber.desktop.lineHeight};
      text-transform: uppercase;
    `;
  }
);

export const Wrapper = styled(({ ...props }: StepperCircleWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "completed" && prop !== "selected"
})(
  () =>
    ({
      theme: {
        palette: { custom }
      },
      completed,
      selected
    }) => {
      const defaultColor = completed ? custom.white.dark : custom.white.light;

      const color = selected ? custom.turquoise.light : defaultColor;

      return `
        border: ${!completed ? `1px solid ${color}` : "none"};
        color: ${custom.turquoise.light};
        width: 24px;
        height: 24px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }
);
