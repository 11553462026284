import { apiClient } from "shared";
import { QuestionnaireFormOfAddressStepData, ShopIdParam } from "api";

export const putSubmitFormOfAddress = async ({
  shopId,
  ...values
}: QuestionnaireFormOfAddressStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/form-of-address`, values);

  return data;
};
