import React, { useCallback, useRef, useState } from "react";
import { Button, ClickAwayListener, Grow, MenuList, styled } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { SplitButtonProps } from "./SplitButton.props";
import { Paper, MenuItem, Popper } from "./SplitButton.styles";

import { ButtonGroup } from "../ButtonGroup";

const defaultIconViewBox = "7 9 10 5";

export const SplitButton = styled(
  ({ value, onChange, options, placement, ...props }: SplitButtonProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = useCallback(
      (newKey: string) => () => {
        onChange(newKey);
        setOpen(false);
      },
      [onChange]
    );

    const handleToggle = useCallback(() => {
      setOpen((prevOpen) => !prevOpen);
    }, []);

    const handleClose = useCallback((event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
    }, []);

    const selectedValue = options.find(({ key }) => key === value);

    return (
      <React.Fragment>
        <ButtonGroup {...props} ref={anchorRef}>
          <Button>{selectedValue?.title}</Button>
          <Button onClick={handleToggle}>
            {open ? (
              <ArrowDropUpIcon viewBox={defaultIconViewBox} />
            ) : (
              <ArrowDropDownIcon viewBox={defaultIconViewBox} />
            )}
          </Button>
        </ButtonGroup>
        <Popper
          placement={placement}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem>
                    {options.map(({ key, title, disabled = false }) => (
                      <MenuItem
                        key={key}
                        disabled={disabled}
                        selected={key === value}
                        onClick={handleMenuItemClick(key)}
                      >
                        {title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
)(
  () => `
    & .MuiButton-root {
      & .MuiSvgIcon-root {
        font-size: 10px;
      }
    }
  `
);
