import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  color: #ac231d;
  font-size: 15px;
  box-sizing: content-box;
  padding: 8px;
`;
