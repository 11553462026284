export const SAVE_DEFINITIONS = 'customerFrontend/fuzzyeditor/SAVE_DEFINITIONS';
export const LOAD_DEFINITIONS = 'customerFrontend/fuzzyeditor/LOAD_DEFINITIONS';
export const SAVE_MAPPINGS = 'customerFrontend/fuzzyeditor/SAVE_MAPPINGS';
export const LOAD_MAPPINGS = 'customerFrontend/fuzzyeditor/LOAD_MAPPINGS';
export const SAVE_RULES = 'customerFrontend/fuzzyeditor/SAVE_RULES';
export const LOAD_RULES = 'customerFrontend/fuzzyeditor/LOAD_RULES';

const DEFINITIONS_URL = 'fuzzyEditor/definitions';
const MAPPINGS_URL = 'fuzzyEditor/mapping';
const RULES_URL = 'fuzzyEditor/rules';

export const saveDefinitions = (definitions) => ({
  type: SAVE_DEFINITIONS,
  definitions,
  meta: {
    type: 'api',
    url: DEFINITIONS_URL,
    method: 'post',
    data: definitions,
  },
});

export const loadDefinitions = () => ({
  type: LOAD_DEFINITIONS,
  meta: {
    type: 'api',
    url: DEFINITIONS_URL,
    method: 'get',
  },
});

export const saveMappings = (inputGroup, value, setGroup, set) => ({
  type: SAVE_MAPPINGS,
  meta: {
    type: 'api',
    url: `${MAPPINGS_URL}/${inputGroup}/${value}/${setGroup}`,
    method: 'put',
    data: set,
  },
});

export const loadMappings = () => ({
  type: LOAD_MAPPINGS,
  meta: {
    type: 'api',
    url: MAPPINGS_URL,
    method: 'get',
  },
});

export const saveRules = (rules) => ({
  type: SAVE_RULES,
  meta: {
    type: 'api',
    url: RULES_URL,
    method: 'post',
    data: {data: rules},
  },
});

export const loadRules = () => ({
  type: LOAD_RULES,
  meta: {
    type: 'api',
    url: RULES_URL,
    method: 'get',
  },
});

