import { GetBrowseAbandonmentVoucherListResponse } from "api";
import { apiClient } from "shared";
import { BrowseAbandonmentVoucherInitialSettingsValues } from "../../types";
import { mapCustomConditionsToForm } from "../../utils";

export const getBrowseAbandonmentVoucherList = async (
  shopId: number,
  limit: number,
  offset: number
) => {
  const { data } = await apiClient.get<GetBrowseAbandonmentVoucherListResponse>(
    `/${shopId}/triggers/browse-abandonment/voucher-settings`,
    {
      params: {
        offset,
        limit
      }
    }
  );

  return {
    ...data,
    items: data.items.map<BrowseAbandonmentVoucherInitialSettingsValues>(
      ({ browseAbandonmentTriggerItemId: id, ...item }) => ({
        ...item,
        id,
        conditions: mapCustomConditionsToForm(item.conditions)
      })
    )
  };
};
