import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';

import 'react-tippy/dist/tippy.css';

import { Icon } from './styles';

const propTypes = {
  infoText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  /**
   * Specifies which direction to position the tooltip on the element.
   * Add the suffix -start or -end to shift the position. top-end is an example.
   *
   * @default 'bottom'
   */
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  /**
   * Makes a tooltip interactive, i.e. will not close when
   * the user hovers over or clicks on the tooltip.
   */
  interactive: PropTypes.bool,
  /**
   * Specifies which type of events will trigger a tooltip to show. Separate each by a space.
   * Use manual if you want to show/hide the tooltip manually/programmatically
   * (@see https://github.com/tvkhoa/react-tippy#showhide-your-tooltip-manually).
   *
   * Types 'mouseenter', 'focus', 'click', 'manual'.
   *
   * @default 'click'
   */
  trigger: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  icon: PropTypes.string,
  iconcolor: PropTypes.string,
};

const UpCustomerSettingsInfo = ({
  infoText,
  placement = 'bottom',
  interactive,
  trigger = 'click',
  width = '280px',
  margin,
  icon = 'question-circle',
  iconcolor,
}) => {
  /**
   * Prevent bubbling when click on tooltip icon with onMouseDown event.
   * Used to show the popover without opening the menu dropdown from react-select.
   * @param {object} event - the mousedown event is fired at an Element when a pointing device button is pressed while the pointer is inside the element.
   */
  function onMouseDown(event) {
    event.stopPropagation();
  }

  return (
    <Tooltip
      html={
        <div style={{ fontSize: '12px', textAlign: 'left', width }}>
          {infoText}
        </div>
      }
      position={placement}
      trigger={trigger}
      arrow
      style={{ margin: margin || '-2px 0 0 2px' }}
      interactive={interactive}
    >
      <Icon color={iconcolor} icon={icon} onMouseDown={onMouseDown} />
    </Tooltip>
  );
};

UpCustomerSettingsInfo.propTypes = propTypes;

export default UpCustomerSettingsInfo;
