import { apiClient } from "shared";
import { QuestionnaireLegalTextStepData, ShopIdParam } from "api";

export const putSubmitLegalText = async ({
  shopId,
  ...values
}: QuestionnaireLegalTextStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/legal-text`, values);

  return data;
};
