import React from 'react';
import styled from 'styled-components';
import { LinkHrefStyled } from '../../components/LinkStyled';
import { FormattedMessage, useIntl } from 'react-intl';

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  text-align: right;
  width: 100%;
  color: #fff;
  font-family: 'Maven Pro';
  font-weight: 400;
  font-size: 14px;
  padding: 0px 20px 0px 15px;
  @media only screen and (max-width: 360px) {
    font-size: 12px;
  }
`;

const Li = styled.li`
  list-style: none;
  display: inline-block;
  padding-right: 10px;

  &:not(:last-child)::after {
    content: '|';
    padding-left: 10px;
  }
`;

const Footer = () => {
  const intl = useIntl();

  const generateUrl = (german, english) => {
    const url = {
      de: german,
      en: `en/${english || german}`,
    }[intl.locale];
    return `https://uptain.de/${url}/`;
  };

  return (
    <FooterWrapper>
      <ul>
        <Li>
          <LinkHrefStyled
            href={generateUrl('impressum', 'imprint')}
            target="_blank"
          >
            <FormattedMessage
              id="containers.CustomerLogin.Footer.Link.imprint"
              defaultMessage="Imprint"
            />
          </LinkHrefStyled>
        </Li>
        <Li>
          <LinkHrefStyled
            href={generateUrl('datenschutz', 'privacy-policy')}
            target="_blank"
          >
            <FormattedMessage
              id="containers.CustomerLogin.Footer.Link.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </LinkHrefStyled>
        </Li>
        <Li>
          <LinkHrefStyled href={generateUrl('disclaimer')} target="_blank">
            <FormattedMessage
              id="containers.CustomerLogin.Footer.Link.disclaimer"
              defaultMessage="Disclaimer"
            />
          </LinkHrefStyled>
        </Li>
      </ul>
    </FooterWrapper>
  );
};

export default Footer;
