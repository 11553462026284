import { Theme, styled, Autocomplete as MuiAutocomplete } from "@mui/material";
import MuiChevron from "@mui/icons-material/ExpandMoreOutlined";
import { VoucherList } from "api";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: custom.background,
  boxShadow: custom.shadows.navigation,
  marginTop: "24px",

  "& .MuiAutocomplete-listbox": {
    padding: "24px 0",

    "& .MuiAutocomplete-option": {
      color: custom.white.light,

      "&:hover": {
        background: "none",
        color: custom.white.main
      },

      '&[aria-selected="true"]': {
        background: "none",
        color: custom.turquoise.main,

        "&.Mui-focused": {
          background: "none"
        },

        "&:hover": {
          background: "none",
          color: custom.white.main
        }
      }
    }
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  },

  "& .MuiAutocomplete-loading": {
    color: custom.white.light
  },

  "& .MuiAutocomplete-noOptions": {
    color: custom.white.light
  }
});

export const Chevron = styled(MuiChevron)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiSvgIcon-root {
      color: ${custom.white.light};

      &.MuiSelect-iconOpen {
        color: ${custom.white.main};
        transform: none;
      }
    }
  `
);

export const Autocomplete = styled(MuiAutocomplete<VoucherList, false, true>)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    & > .MuiBox-root {
      
      & .MuiFormControl-root {
    
        & .MuiAutocomplete-inputRoot {
          padding: 0;

          & .MuiAutocomplete-input {
            padding: 16px 10px 16px 22px
          }

          & .MuiAutocomplete-popupIndicatorOpen {
            transform: none;

            & .MuiSvgIcon-root {
              color: ${custom.white.main};
            }
          }
        }
      }
    }
  `
);
