import { useCallback, useState } from "react";
import { Menu as MuiMenu, useTheme } from "@mui/material";
import { locales } from "config";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makePaperStyles, LanguageButton, MenuItem } from "./LanguageSwitch.styles";
import { localStorage } from "../../../utils";
import { changeLocale } from "legacy/containers/LanguageProvider/actions";
import { useLocalStorageValue } from "../../../hooks";
import { LocalStorageKey } from "../../../types";

export const LanguageSwitch = () => {
  const theme = useTheme();

  const { i18n } = useTranslation();

  // TODO: remove as son as redux can be removed
  const dispatch = useDispatch();

  const language = useLocalStorageValue(LocalStorageKey.LANGUAGE);

  const [languageAnchor, setLanguageAnchor] = useState<null | HTMLElement>(null);

  const handleOpenLanguage = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchor(event.currentTarget);
  }, []);

  const handleCloseLanguage = useCallback(() => {
    setLanguageAnchor(null);
  }, []);

  const handleLanguageChange = useCallback(
    (locale: string) => () => {
      localStorage.setItem(LocalStorageKey.LANGUAGE, locale);
      // TODO: remove as son as redux can be removed
      // Rudimentary code to change locale in redux storage
      dispatch(changeLocale(locale));

      i18n.changeLanguage(locale);
      handleCloseLanguage();
    },
    [handleCloseLanguage]
  );

  const active = Boolean(languageAnchor);

  const selectValue = language || "";

  const availableLocales = Object.keys(locales);

  return (
    <>
      <LanguageButton active={active} value={selectValue} onClick={handleOpenLanguage} />
      <MuiMenu
        anchorEl={languageAnchor}
        open={active}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        slotProps={{
          paper: {
            sx: makePaperStyles(theme)
          }
        }}
        onClose={handleCloseLanguage}
      >
        {availableLocales.map((locale) => (
          <MenuItem key={locale} value={locale} onClick={handleLanguageChange(locale)}>
            {locale}
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  );
};
