export const GET_CANCELLATIONS =
  'react-boilerplate/sales/dashboard/GET_CANCELLATIONS';

export const getDWHDrops = (
  hours,
  factor,
  type,
  productFilter,
  referenceIntervalDays,
) => ({
  type: GET_CANCELLATIONS,
  hours,
  factor,
  productFilter,
  tableType: type,
  meta: {
    type: 'api',
    url: `admin/analysis/drops/?hours=${hours}&factor=${factor}&type=${type}&product=${productFilter}&referenceIntervalDays=${referenceIntervalDays}`,
    method: 'get',
  },
});
