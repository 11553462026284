import { defineMessages } from 'react-intl';

export default defineMessages({
  contains: {
    id: 'components.ConditionLine.contains',
    defaultMessage: 'contains',
  },
  containsNot: {
    id: 'components.ConditionLine.containsNot',
    defaultMessage: 'contains not',
  },
  equals: {
    id: 'components.ConditionLine.equals',
    defaultMessage: 'equals',
  },
  matchesRegex: {
    id: 'components.ConditionLine.matchesRegex',
    defaultMessage: 'matches regex',
  },
  shoppingCartValue: {
    id: 'components.ConditionLine.shoppingCartValue',
    defaultMessage: 'Shopping Cart Value',
  },
  value: {
    id: 'components.ConditionLine.value',
    defaultMessage: 'Value',
  },
  daysTimes: {
    id: 'components.ConditionLine.daysTimes',
    defaultMessage: 'Days/Times',
  },
  within: {
    id: 'components.ConditionLine.within',
    defaultMessage: 'within the availability',
  },
  outsideOf: {
    id: 'components.ConditionLine.outsideOf',
    defaultMessage: 'outside of the availability',
  },
  exists: {
    id: 'components.ConditionLine.exists',
    defaultMessage: 'exists',
  },
  existsNot: {
    id: 'components.ConditionLine.existsNot',
    defaultMessage: 'exists not',
  },
  empty: {
    id: 'components.ConditionLine.empty',
    defaultMessage: 'empty',
  },
  notEmpty: {
    id: 'components.ConditionLine.notEmpty',
    defaultMessage: 'not empty',
  },
  customerGroup: {
    id: 'components.ConditionLine.customerGroup',
    defaultMessage: 'Customer Group',
  },
  condition: {
    id: 'components.ConditionLine.condition',
    defaultMessage: 'Condition',
  },
  revenue: {
    id: 'components.ConditionLine.revenue',
    defaultMessage: 'Revenue',
  },
  less: {
    id: 'components.ConditionLine.less',
    defaultMessage: 'less than',
  },
  lessEqual: {
    id: 'components.ConditionLine.lessEqual',
    defaultMessage: 'less than or equals',
  },
  greater: {
    id: 'components.ConditionLine.greater',
    defaultMessage: 'greater than',
  },
  greaterEqual: {
    id: 'components.ConditionLine.greaterEqual',
    defaultMessage: 'greater than or equals',
  },
  language: {
    id: 'components.ConditionLine.language',
    defaultMessage: 'Language',
  },
  oneOf: {
    id: 'components.ConditionLine.oneOf',
    defaultMessage: 'one of',
  },
  notOneOf: {
    id: 'components.ConditionLine.notOneOf',
    defaultMessage: 'not one of',
  },
});
