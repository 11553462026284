import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { useIntl } from 'react-intl';

import { creatable } from '../../UpStyledCreatable';
import { selectBox } from '../../SelectBox';
import UpRegex from '../../UpRegex';
import FormValueSelector from '../../UpFormValueSelector';

import { getOptions } from '../utils';

const Filter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  display: flex;
  flex: 1 1 auto;
  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const Referrer = ({ member, product, resetOnChange }) => {
  const defaultOptions = ['contains', 'contains-not'];
  const intl = useIntl();

  const optionsByProduct = {
    mail: ['regex'],
  };

  return (
    <Filter>
      <Row>
        <Field
          name={`${member}.filter.operator`}
          component={selectBox}
          customProps={{
            options: getOptions(optionsByProduct[product], defaultOptions).map(
              ({ value, label }) => ({
                value,
                label: intl.formatMessage(label),
              }),
            ),
            minWidth: '250px',
            margin: '0 10px 0 0',
            onChange: () => resetOnChange(),
          }}
        />
      </Row>
      <FormValueSelector
        fieldName={`conditions[${member
          .split('[')[1]
          .charAt(0)}]filter.operator`}
      >
        {(operator) => {
          if (operator === 'regex') {
            return (
              <UpRegex
                name={`${member}.filter.attribute[0].value`}
                margin="0 10px 0 0"
                width="100%"
                fieldValidation={false}
              />
            );
          }

          return (
            <Field name={`${member}.filter.attribute`} component={creatable} />
          );
        }}
      </FormValueSelector>
    </Filter>
  );
};

Referrer.propTypes = {
  product: PropTypes.oneOf(['activation', 'mail', 'optin']).isRequired,
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func.isRequired,
};

export default Referrer;
