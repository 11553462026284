import { useQueryWrapper } from "shared";
import { QuestionnaireIntegration } from "api";
import { getIntegrations } from "../../api";

const queryName = "getIntegrations";

export const useIntegrations = () => {
  const { data, isLoading } = useQueryWrapper([queryName], async () => await getIntegrations(), {
    refetchOnWindowFocus: false,
    retry: false
  });

  const resultData = data ?? [];

  return { data: resultData as QuestionnaireIntegration[], loading: isLoading };
};
