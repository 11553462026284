import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QuestionnaireVideos } from "../../types";

export const useQuestionnaireVideos = () => {
  const { i18n } = useTranslation();

  const links: QuestionnaireVideos = useMemo(() => {
    try {
      return JSON.parse(process.env.REACT_APP_QUESTIONNAIRE_VIDEO_LINKS || "{}");
    } catch {
      console.error(
        "Invalid questionnaire videos configuration provided, make sure that REACT_APP_QUESTIONNAIRE_VIDEO_LINKS is a valid JSON string."
      );
      return {};
    }
  }, []);

  const result = links[i18n.language as keyof typeof links];

  if (!result) {
    console.error(
      `No questionnaire videos configuration found for language ${i18n.language}, falling back to en.`
    );
    return links.en || {};
  }

  return result;
};
