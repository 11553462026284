import { FieldValidator } from "final-form";
import isEmail from "validator/es/lib/isEmail";

export const emailValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "authModule.errors.required";
  }

  if (!isEmail(value)) {
    return "authModule.errors.invalidEmail";
  }
};
