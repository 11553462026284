import { styled, Divider as MuiDivider } from "@mui/material";

export const Divider = styled(MuiDivider)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
  &.MuiDivider-root {
    background-color: ${custom.white.light};
    margin: 12px 0;
  }
`
);
