import { createSelector } from 'reselect';
import { getFormNames, isDirty } from 'redux-form/immutable';
import { Map } from 'immutable';

import { getShops } from '../../containers/App/selectors';

const getParsedShops = createSelector(getShops, (userShops) => {
  const shops: { value: string; label: string }[] = [];

  userShops.forEach((shop: Map<string, any>) => {
    shops.push({
      value: shop.get('id'),
      label: `${shop.get('shopId')} - ${shop.get('name')}`,
    });
  });

  return shops;
});

const getAllFormNames = (state: any) => getFormNames()(state);
const getState = (state: any) => state;

const checkIfDirty = createSelector(
  [getAllFormNames, getState],
  (formNames, state) => formNames.some((form: any) => isDirty(form)(state)),
);

export { getParsedShops, checkIfDirty };
