import Immutable from 'immutable';
import { GET_FEATURE_TOGGLES, UPDATE_FEATURE_TOGGLES } from './actions';

const GET_FEATURE_TOGGLES_LOADING =
  'react-boilerplate/App/FeatureToggles/GET_FEATURE_TOGGLES_LOADING';
const GET_FEATURE_TOGGLES_ERROR =
  'react-boilerplate/App/FeatureToggles/GET_FEATURE_TOGGLES_ERROR';
const UPDATE_FEATURE_TOGGLES_LOADING =
  'react-boilerplate/App/FeatureToggles/UPDATE_FEATURE_TOGGLES_LOADING';
const UPDATE_FEATURE_TOGGLES_ERROR =
  'react-boilerplate/App/FeatureToggles/UPDATE_FEATURE_TOGGLES_ERROR';

export function featureTogglesReducer(
  state = Immutable.fromJS({
    data: {},
    UIStates: {},
  }),
  action,
) {
  switch (action.type) {
    case GET_FEATURE_TOGGLES_LOADING: {
      return state.mergeIn(['UIStates'], {
        fetchingError: '',
        fetching: true,
      });
    }
    case GET_FEATURE_TOGGLES_ERROR: {
      return state.mergeIn(['UIStates'], {
        fetchingError: action.errorMessage,
        fetching: false,
      });
    }
    case GET_FEATURE_TOGGLES: {
      const newState = state.mergeIn(['UIStates'], {
        fetchingError: '',
        fetching: false,
      });

      return state.merge({
        data: action.data,
        UIStates: newState.get('UIStates'),
      });
    }
    case UPDATE_FEATURE_TOGGLES_LOADING: {
      return state.mergeIn(['UIStates'], {
        savingError: '',
        saving: true,
      });
    }
    case UPDATE_FEATURE_TOGGLES_ERROR: {
      return state.mergeIn(['UIStates'], {
        savingError: action.errorMessage,
        saving: false,
      });
    }
    case UPDATE_FEATURE_TOGGLES: {
      return state.mergeIn(['UIStates'], {
        savingError: '',
        saving: false,
      });
    }
    default: {
      return state;
    }
  }
}
