import React from 'react';
import styled from 'styled-components';

import Logo from './Images/uptain-logo.png';

const Img = styled.img`
  display: block;
  width: 250px;
  margin: 20px auto;

  @media only screen and (min-width: 768px) {
    width: 300px;
    margin-top: 0;
  }
`;

const Header = () => <Img src={Logo} alt="Uptain Logo" />;

export default Header;
