import base64 from 'base-64';
import axios from 'axios';
import { localStorage, LocalStorageKey } from 'shared';

import { LOGOUT } from '../actions/actions';
import { URL_WHITELIST } from '../utils/constants';
import history from '../utils/history';
import { logout } from '../requireAuth';

import { STATUS_META, notify, dispatchAction, nextAction } from './utils';

const apiMiddleware = (store) => (next) => (action) => {
  // if user logs out
  if (action.type === LOGOUT) {
    logout(() => history.push('/'));
    return next(action);
  }

  // if action is not an api call
  if (!action.meta || action.meta.type !== 'api') {
    return next(action);
  }

  // if action is an api call dispatch a loading action while api call is loading
  const loadingAction = { ...action };
  delete loadingAction.type; // delete old action type to create loading action type
  delete loadingAction.meta;

  store.dispatch({ type: `${action.type}_LOADING`, ...loadingAction });

  // make the api call
  axios({
    method: `${action.meta.method}`,
    url: `${process.env.REACT_APP_API_URL}${action.meta.url}`,
    headers: {
      Authorization: localStorage.getItem(LocalStorageKey.TOKEN),
    },
    params: action.meta.params, // for getting data
    data: action.meta.data, // for posting data
  })
    .then((response) => {
      const {
        headers: { authorization },
        config: { url: requestUrl },
      } = response;
      if (URL_WHITELIST.some((path) => requestUrl.indexOf(path) >= 0)) {
        nextAction(next)(action)(response);
      }

      if (localStorage.getItem(LocalStorageKey.TOKEN)) {
        if (
          authorization &&
          JSON.parse(base64.decode(authorization.split('.')[1])).session ===
          localStorage.getItem(LocalStorageKey.SESSION_ID)
        ) {
          localStorage.setItem(LocalStorageKey.TOKEN, authorization);
        }

        nextAction(next)(action)(response);
      }
    })
    .catch((error) => {
      const {
        response,
        response: {
          status,
          data: { details },
        },
      } = error;

      // Show error in the console
      console.error('The was a problem with API call.', error);

      if (status !== 406) {
        // Notify user about error in frontend
        notify(error);
      }

      if (!localStorage.getItem(LocalStorageKey.TOKEN)) {
        return;
      }

      if (
        !JSON.parse(base64.decode(localStorage.getItem(LocalStorageKey.TOKEN).split('.')[1]))
          .session === localStorage.getItem(LocalStorageKey.SESSION_ID)
      ) {
        return;
      }

      if (!error || !response) {
        const meta = {
          status: '500',
          msg: `Unhandled errorCode ${JSON.stringify(
            error,
          )}. Please try again in a few minutes.`,
        };

        dispatchAction(store, action, meta);
        return;
      }

      switch (status) {
        case 403:
        case 401:
          store.dispatch({ type: LOGOUT });
          break;
        case 500:
          dispatchAction(store, action, STATUS_META(details)[500]);
          break;
        case 400:
          dispatchAction(store, action, STATUS_META()[400]);
          break;
        case 404:
          dispatchAction(store, action, STATUS_META()[404]);
          break;
        case 406:
          dispatchAction(
            store,
            { ...action, ...response.data },
            STATUS_META(details)[406],
          );
          break;
        default:
          break;
      }
    });
};

export default apiMiddleware;
