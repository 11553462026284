import axios from 'axios';
import base64 from 'base-64';

import { localStorage, LocalStorageKey } from 'shared';
import { getCookieExpirationDate, setCookie } from '../../../utils/cookies';

export const localStorageKeys = [LocalStorageKey.TOKEN, LocalStorageKey.SESSION_ID];

function updateLocalStore(values) {
  Object.entries(values).forEach(([key, value]) => {
    const item = localStorageKeys.find((el) => el.toLowerCase() === key);
    if (item) localStorage.setItem(item, value);
  });
}

function mapAndSetCookies(values) {
  values.forEach(({ name, value, options }) => setCookie(name, value, options));
}

async function submit(data, path) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}${path}`, data);

    const initialStorage = {
      token: response.headers.authorization,
    };

    const initialCookies = [
      {
        name: 'upcuid',
        value: response.data?.data?.shopId ?? '',
        options: {
          domain: '.uptain.de',
          expires: getCookieExpirationDate(
            new Date(),
            data.remember ? { year: 1 } : { minutes: 240 },
          ),
        },
      },
    ];

    if (response.status === 200) {
      if (path === 'login') {
        const local = {
          ...initialStorage,
          get sessionid() {
            const decodeVal = localStorage.getItem(LocalStorageKey.TOKEN) || this.token;
            return JSON.parse(base64.decode(decodeVal.split('.')[1])).session;
          },
        };

        updateLocalStore(local);
        mapAndSetCookies(initialCookies);

        /**
         * Push the User ID to the Data Layer
         * needed for GA
         */
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'login',
          userId: response.data?.data?.id ?? '',
        });
      }

      if (path === 'create-account/submit') {
        updateLocalStore(initialStorage);
        mapAndSetCookies(initialCookies);
      }

      if (path === 'user/password/set-new') {
        updateLocalStore(initialStorage);
        mapAndSetCookies(initialCookies);
      }
    }

    return response;
  } catch (error) {
    throw new Error(error.response.status);
  }
}

export { submit };
