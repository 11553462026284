/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { localStorage, LocalStorageKey } from 'shared';
import { useTranslation } from "react-i18next";
import { FormattedMessage } from 'react-intl';
import { changeLocale } from '../../containers/LanguageProvider/actions';
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors';
import { appLocales } from '../../i18n';

const LanguageSwitch = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.primaryColor};

  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    text-decoration: none;
    color: ${(props) => props.theme.fontColor};
    font-weight: 500;
  }
  &:focus {
    outline: none;
  }
`;

const Separator = styled.span`
  padding: 0 10px;
`;

// eslint-disable-next-line no-shadow
const UpLanguageSwitch = ({ locale, changeLocale }) => {

  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    localStorage.setItem(LocalStorageKey.LANGUAGE, language);
    // Added additional language change for i18n
    i18n.changeLanguage(language);
    changeLocale(language);
  };

  return (
    <LanguageSwitch>
      {appLocales.map((item, index, arr) => (
        <React.Fragment key={index}>
          <Button
            type="button"
            disabled={locale === item}
            onClick={() => changeLanguage(item)}
          >
            <FormattedMessage
              id={`components.${item}`}
              defaultMessage={
                {
                  en: 'English',
                  de: 'Deutsch',
                }[item]
              }
            />
          </Button>
          {arr.length - 1 !== index && <Separator>|</Separator>}
        </React.Fragment>
      ))}
    </LanguageSwitch>
  );
};

UpLanguageSwitch.propTypes = {
  changeLocale: PropTypes.func.isRequired,
  locale: PropTypes.string,
};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

const matchDispatchToProps = (dispatch) =>
  bindActionCreators({ changeLocale }, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(UpLanguageSwitch);
