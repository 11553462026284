import { FieldValidator } from "final-form";
import { isSecureUrl } from "shared/utils/validation";

export const linkValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "settingsModule.errors.required";
  }

  if (value.length > 128) {
    return "settingsModule.errors.lessThan128Characters";
  }

  if (!isSecureUrl(value)) {
    return "settingsModule.errors.invalidUrl";
  }
};

export const unsubscribeLinkValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!isSecureUrl(value)) {
      return "settingsModule.errors.invalidUrl";
    }

    if (value.length > 128) {
      return "settingsModule.errors.lessThan128Characters";
    }
  }
};
