import { styled, Box as MuiBox } from "@mui/material";
import { OptionsWrapperProps } from "./ShopSystem.props";

export const OptionsWrapper = styled((props: OptionsWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "error"
})(
  ({ theme: { breakpoints }, error }) => `
    &.MuiBox-root {
      display: grid;
      grid-template-columns: repeat(auto-fill, 180px);
      grid-column-gap: auto;
      justify-content: center;
      grid-auto-rows: 180px;
      grid-row-gap: 24px;
      grid-column-gap: 12px;
      margin: 32px 0 ${error ? "0px" : "56px"};

      ${[breakpoints.up("md")]} {
        margin: 80px 0 ${error ? "0px" : "56px"};
      }
    }
  `
);
