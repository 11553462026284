import { withFeatureVisibility } from "../../../HoC";
import { NavigationButtonProps } from "../../../types";
import {
  NavigationDrawerButtonWithSubroutes,
  NavigationDrawerButtonWithoutSubroutes
} from "./variants";

export const NavigationDrawerButton = ({ route, shopId, onClick }: NavigationButtonProps) => {
  const { subroutes, path, translationKey, requiredPermission, featureFlag } = route;

  if (subroutes) {
    if (featureFlag) {
      const Component = withFeatureVisibility(NavigationDrawerButtonWithSubroutes, featureFlag);

      return (
        <Component
          translationKey={translationKey}
          path={path}
          shopId={shopId}
          onClick={onClick}
          subroutes={subroutes}
          requiredPermission={requiredPermission}
        />
      );
    } else {
      return (
        <NavigationDrawerButtonWithSubroutes
          translationKey={translationKey}
          path={path}
          shopId={shopId}
          onClick={onClick}
          subroutes={subroutes}
          requiredPermission={requiredPermission}
        />
      );
    }
  }

  if (path) {
    if (featureFlag) {
      const Component = withFeatureVisibility(NavigationDrawerButtonWithoutSubroutes, featureFlag);

      return (
        <Component
          translationKey={translationKey}
          path={path}
          shopId={shopId}
          onClick={onClick}
          requiredPermission={requiredPermission}
        />
      );
    } else {
      return (
        <NavigationDrawerButtonWithoutSubroutes
          translationKey={translationKey}
          path={path}
          shopId={shopId}
          onClick={onClick}
          requiredPermission={requiredPermission}
        />
      );
    }
  }

  return null;
};
