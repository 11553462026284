import { styled, TextField as MuiTextField } from "@mui/material";
import { ColorInputsProps } from "./ValueInputs.props";

export const InputField = styled((props: ColorInputsProps) => <MuiTextField {...props} />, {
  shouldForwardProp: (prop) => prop !== "width"
})(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    },
    width
  }) => `
    &.MuiTextField-root {
      margin-left: 10px;
      border-radius: 0;
      width: ${width ? `${width}px` : "auto"};

      & .MuiInputBase-root {
        padding: 0;
        color: ${custom.white.light};
        font-size: ${body2.mobile.fontSize};
        font-weight: ${body2.mobile.fontWeight};
        line-height: ${body2.mobile.lineHeight};

        & .MuiInputBase-input {
          padding: 0;
        }
  
        & .MuiOutlinedInput-notchedOutline {
          border: none;
          border-bottom: 1px solid ${custom.white.dark};
          border-radius: 0;
        }
      }
    }
  `
);

export const rgbInputWidth = 25;
export const hexInputWidth = 65;
export const alphaInputWidth = 36;
