import { apiClient } from "shared";
import { TriggerReprocessStatus } from "api";

export const getComeBackMailingReprocessStatus = async (shopId: number) => {
  const { data } = await apiClient.get<TriggerReprocessStatus>(
    `/${shopId}/triggers/come-back-mailing/reprocess`
  );

  return data;
};
