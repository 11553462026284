import { QueryFunction, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useNotifications } from "../useNotifications";
import { NetworkError } from "../../types";

export const useQueryWrapper = <
  Data,
  QueryKey extends (string | number | undefined)[] = (string | number | undefined)[]
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<Data, QueryKey>,
  options: Omit<
    UseQueryOptions<Data, NetworkError, Data, QueryKey>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { showNotification } = useNotifications();

  return useQuery<Data, NetworkError, Data, QueryKey>(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
    onError: (error) => {
      let errorTranslationKey = "global.error.serverUnavailable";

      if (error.response?.data?.errorKey) {
        errorTranslationKey = error.response.data.errorKey;
      }

      showNotification(errorTranslationKey, "error");
    }
  });
};
