import { Frame, Wrapper } from "./VideoPlayer.styles";
import { VideoPlayerProps } from "./VideoPlayer.props";

export const VideoPlayer = ({ title, src }: VideoPlayerProps) => {
  return (
    <Wrapper>
      <Frame title={title} src={src} />
    </Wrapper>
  );
};
