import { useCallback } from "react";
import { QuestionnaireAccountStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitAccount } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useAccountStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ACCOUNT);

  const data = stepData?.data;

  const submitAccount =
    useQuestionnaireSubmitMutation<QuestionnaireAccountStepData>(putSubmitAccount);

  const executeSubmitAccount: FormProps<
    QuestionnaireAccountStepData,
    QuestionnaireAccountStepData
  >["onSubmit"] = useCallback(
    async ({ shopName, webUrl }) => {
      try {
        await submitAccount({
          shopName: shopName.trim(),
          webUrl
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAccount]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitAccount
  };
};
