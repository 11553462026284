import styled, { keyframes } from 'styled-components';
import logo from './logo_small.png';

// save spinner
export const puls = keyframes`
  0% {height: 50px; width: 50px;}
  50% {height: 40px; width: 40px;}
  100% {height: 50px; width: 50px;}
`;

export const pulsSafari = keyframes`
  0% {height: 50px; width: 50px;}
  50% {height: 40px; width: 40px;}
  100% {height: 50px; width: 50px;}
`;

export const FancyLoadingIcon = styled.div`
  width: 50px;
  height: 50px;
  background: url(${logo});
  background-size: cover;
  -webkit-animation-name: ${pulsSafari}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  -webkit-animatoin-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-name: ${puls};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

export default FancyLoadingIcon;
