import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const UpSiteTitle = ({ messageId, defaultMessage, title }) => {
  const intl = useIntl();
  return (
    <Helmet
      title={`uptain | ${
        messageId
          ? intl.formatMessage({ id: messageId, defaultMessage })
          : title
      }`}
    />
  );
};

UpSiteTitle.propTypes = {
  messageId: PropTypes.string,
  defaultMessage: PropTypes.string,
  title: PropTypes.string,
};

export default UpSiteTitle;
