import { PublicNavigationBar } from "../PublicNavigationBar";
import { PrivateNavigationBar } from "../PrivateNavigationBar";
import { NavbarProps } from "./NavigationBar.props";

export const NavigationBar = ({ variant }: NavbarProps) => {
  if (variant === "private") {
    return <PrivateNavigationBar />;
  } else {
    return <PublicNavigationBar />;
  }
};
