import styled from 'styled-components';

interface UpContainerProps {
  superSmall?: string;
  small?: string;
  medium?: string;
  large?: string;
}

const UpContainer = styled.div<UpContainerProps>`
  background-color: ${(props) => props.theme.wrapperBackgroundColor};
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  flex-basis: 100%;
  min-width: 0;
  margin: 20px 5px;
  hr{
    border-color: #F5E7E7;
    margin: 0 0 10px 0;
  }
 @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    flex-basis: ${(props) => props.superSmall || '100%'};
    min-width: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.small}) {
    flex-basis: ${(props) => props.small || '100%'};
    min-width: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.medium}) { 
    flex-basis: ${(props) => props.medium || '70%'};
    min-width: 0;
  }
  @media screen and (min-width: ${(props) => props.theme.large}) {
    /* flex-grow: ${(props) => props.large === 'auto' ? 1 : 0}; */
    /* flex-basis: ${(props) => props.large === 'auto' ? 'auto' : `calc(${props.large || '50%'} - 40px)`}; */
    flex-basis: ${(props) => `calc(${props.large || '50%'} - 40px)`};
    min-width: 0;
    margin: 20px;
  }
`;

export default UpContainer;
