import { Box as MuiBox, styled } from "@mui/material";
import { ScreenImageProps } from "./ScreenImage.props";
import expired_token_small from "assets/images/expired_token/small.png";
import expired_token_large from "assets/images/expired_token/large.png";
import sent_email_small from "assets/images/sent_email/small.png";
import sent_email_large from "assets/images/sent_email/large.png";

export const ScreenImage = styled((props: ScreenImageProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "variant"
})(({ theme: { breakpoints }, variant }) => {
  let smallImage: string = expired_token_small;
  let largeImage: string = expired_token_large;

  switch (variant) {
    case "setPassword":
      smallImage = expired_token_small;
      largeImage = expired_token_large;
      break;
    case "forgotPassword":
      smallImage = sent_email_small;
      largeImage = sent_email_large;
      break;
  }

  return `
    margin: 0 auto 39px;
    background-image: url(${smallImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-position: center;
    width: 200px;
    height: 200px;

    ${[breakpoints.up("lg")]} {
      background-image: url(${largeImage});
      width: 329px;
      height: 329px;
    }
  `;
});
