import { css } from 'styled-components';

const sharedButtonStyles = css`
  float: ${(props) => props.float};
  padding: ${(props) => props.padding || '10px 20px'};
  border-radius: 3px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  line-height: normal;
  background: ${(props) =>
    props.buttonColor || props.theme.secondaryActionColor};
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
  position: ${(props) => props.position || 'static'};

  &:hover :enabled,
  &:hover:not([disabled]) {
    background: ${(props) => props.buttonColor || '#f8351b'};
  }

  /** Add hover and active styles for links unsing as buttons */
  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
  }
`;

export { sharedButtonStyles };
