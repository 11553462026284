import { LoadingIndicator } from "shared";
import { useTranslation } from "react-i18next";
import { ComeBackMailingTextItemForm } from "../ComeBackMailingTextItemForm";
import { CreateComeBackMailingTextForm } from "../CreateComeBackMailingTextForm";
import {
  SettingsListHeader,
  SettingsListWrapper,
  LoadingWrapper
} from "../TriggerSettingsFormStyles";
import { useComeBackMailingTextData } from "../../hooks";
import { DEFAULT_QUERY_LIMIT } from "config";

export const ComeBackMailingTextSettingsList = () => {
  const { t } = useTranslation();

  const { initialState, loading } = useComeBackMailingTextData();

  const comeBackMailingTextCountThresholdMet = initialState.length >= DEFAULT_QUERY_LIMIT;

  return (
    <SettingsListWrapper>
      <SettingsListHeader>
        {t("featuresModule.comeBackMailing.form.textSettings.heading")}
      </SettingsListHeader>
      {loading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        initialState.map((textSettings) => (
          <ComeBackMailingTextItemForm key={textSettings.id} initialState={textSettings} />
        ))
      )}
      <CreateComeBackMailingTextForm disabled={loading || comeBackMailingTextCountThresholdMet} />
    </SettingsListWrapper>
  );
};
