import { styled, Switch as MuiSwitch } from "@mui/material";

import { SwitchProps } from "./Switch.props";

export const Switch = styled(({ color = "default", ...props }: SwitchProps) => {
  return <MuiSwitch color={color} {...props} />;
})(
  ({
    theme: {
      palette: { custom }
    },
    color: variant = "default"
  }) => {
    let color;
    let backgroundColor;
    let checkedBackgroundColor;

    switch (variant) {
      case "error":
        color = custom.coral.light;
        backgroundColor = `${custom.coral.light}80`;
        checkedBackgroundColor = `${custom.coral.light}80`;
        break;
      default:
        color = custom.white.main;
        backgroundColor = custom.white.light;
        checkedBackgroundColor = custom.turquoise.main;
    }

    const hoverBackgroundColor = `${color}14`;

    return `
      & .MuiSwitch-switchBase {
        color: ${color};

        &.Mui-checked {  
          & + .MuiSwitch-track {
            background-color: ${checkedBackgroundColor};
            opacity: 1;
          }
  
          &:hover {
            background-color: ${hoverBackgroundColor}; 
          }
        }

        &.Mui-disabled {
          & .MuiSwitch-thumb {
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${custom.white.main};
          }

          & + .MuiSwitch-track {
            background-color: ${custom.white.main};
          }
        }

        &:hover {
          background-color: ${hoverBackgroundColor};
        }
      }

      & .MuiSwitch-track {
        background-color: ${backgroundColor};
        opacity: 1;
      }
    `;
  }
);
