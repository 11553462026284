import { Wrapper, Typography } from "./NumberCircle.styles";
import { StepperCircleProps } from "./NumberCircle.props";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const NumberCircle = ({ completed, selected, index }: StepperCircleProps) => {
  const CompletedIcon = selected ? CheckCircleOutlineIcon : CheckCircleIcon;

  return (
    <Wrapper selected={selected} completed={completed}>
      {completed ? (
        <CompletedIcon />
      ) : (
        <Typography selected={selected} completed={completed}>
          {index}
        </Typography>
      )}
    </Wrapper>
  );
};
