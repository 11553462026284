import { useCallback, useMemo } from "react";
import { QuestionnaireAddOptionStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitAddOptions } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { addOptionsFormMapper, parseAddOptionsFormMetadata } from "./useAddOptionsStep.helpers";

export const useAddOptionsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ADD_OPTIONS);

  const data = stepData?.data;

  const metadata = useMemo(
    () => parseAddOptionsFormMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitAddOptions =
    useQuestionnaireSubmitMutation<QuestionnaireAddOptionStepData>(putSubmitAddOptions);

  const executeSubmitFormOfAddress: FormProps<
    QuestionnaireAddOptionStepData,
    QuestionnaireAddOptionStepData
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const addOptionSubmitData = addOptionsFormMapper(formValues, metadata);

        await submitAddOptions(addOptionSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAddOptions, metadata]
  );

  return {
    data,
    hasPrev: !!prevStep,
    metadata,
    handlePrevStep,
    executeSubmitFormOfAddress
  };
};
