import { FieldValidator } from "final-form";
import isEmail from "validator/es/lib/isEmail";
import isURL from "validator/lib/isURL";
import { isEmptyString } from "shared/utils/validation";

export const vendorValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }
};

export const senderAddressValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (!isEmail(value)) {
    return "questionnaireModule.errors.invalidEmail";
  }

  if (value.length > 128) {
    return "questionnaireModule.errors.lessThan128Characters";
  }
};

export const usernameValidator: FieldValidator<string> = (value) => {
  if (isEmptyString(value)) {
    return "questionnaireModule.errors.required";
  }

  if (value.length <= 2) {
    return "questionnaireModule.errors.biggerThanTwoCharacters";
  }
};

export const hostValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (!isURL(value)) {
    return "questionnaireModule.errors.invalidUrl";
  }

  if (value.length <= 4) {
    return "questionnaireModule.errors.biggerThanFourCharacters";
  }
};

export const passwordValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }
};

export const portValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (isNaN(Number(value))) {
    return "questionnaireModule.errors.shouldBeANumber";
  }

  if (Number(value) < 1) {
    return "questionnaireModule.errors.minPortValue";
  }

  if (Number(value) > 65535) {
    return "questionnaireModule.errors.maxPortValue";
  }
};

export const senderNameValidator: FieldValidator<string> = (value) => {
  if (isEmptyString(value)) {
    return "questionnaireModule.errors.required";
  }

  if (value.length <= 2) {
    return "questionnaireModule.errors.biggerThanTwoCharacters";
  }
};

export const alternativeEmailValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (!isEmail(value)) {
    return "questionnaireModule.errors.invalidEmail";
  }
};
