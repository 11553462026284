import module from "module";
import { Routes } from "config";
import { PageWrapper, NavigationBar, PublicFooter } from "shared";
import { ContentWrapper, ResetPasswordForm } from "../components";

const ResetPasswordPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="public" />
      <ContentWrapper>
        <ResetPasswordForm />
        <PublicFooter />
      </ContentWrapper>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.RESET_PASSWORD
});

export default ResetPasswordPage;
