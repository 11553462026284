import { Box as MuiBox, styled } from "@mui/material";

export const LoadingContainer = styled(MuiBox)(
  ({
    theme: {
      palette: {
        custom: { turquoise }
      }
    }
  }) => `
    color: ${turquoise.main};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `
);
