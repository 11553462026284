import { QuestionnaireComeBackMailingVoucherSettingsStepData, VoucherTypes } from "api";
import { QuestionnaireBrowseAbandonmentVoucherSettingsFormValues } from "../../types";
import { prepareListNameValue, prepareListValuesValue } from "../../utils";

export const comeBackMailingVoucherSettingsFormMapper = ({
  voucherValue,
  voucherValueType,
  voucherKind,
  voucherListControlMode,
  listName,
  newVoucherListName,
  voucher,
  extendVoucherListValues,
  newVoucherListValues,
  listVoucherCodes
}: QuestionnaireBrowseAbandonmentVoucherSettingsFormValues): QuestionnaireComeBackMailingVoucherSettingsStepData => {
  return {
    voucherValue,
    voucherValueType,
    voucherKind,
    voucher: voucherKind === VoucherTypes.CODE ? voucher : undefined,
    listName: prepareListNameValue({
      voucherKind,
      voucherListControlMode,
      listName,
      newVoucherListName
    }),
    listVoucherCodes: prepareListValuesValue({
      voucherKind,
      voucherListControlMode,
      extendVoucherListValues,
      newVoucherListValues,
      listVoucherCodes
    })
  };
};
