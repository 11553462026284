import { LegacyCurrencies, LegacyVoucherTypes, Features } from "api";
import { TextButton } from "shared";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { VoucherSettingsFormBase } from "../VoucherSettingsFormBase";
import {
  BrowseAbandonmentVoucherSettingsValues,
  BrowseAbandonmentVoucherInitialSettingsValues
} from "../../types";
import { CreateFormCollapse, CreateButtonWrapper } from "../TriggerSettingsFormStyles";
import { CreateBrowseAbandonmentVoucherFormProps } from "./CreateBrowseAbandonmentVoucherForm.props";
import { useCreateBrowseAbandonmentVoucher } from "../../hooks";

const getInitialValues = (closed: boolean): BrowseAbandonmentVoucherSettingsValues => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      id: 0,
      contactPerson: {
        contactPersonId: 0,
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      enabled: false,
      title: t("featuresModule.defaults.voucherSettingsTitle"),
      voucherType: LegacyVoucherTypes.CODE,
      voucherCode: "",
      isVoucherExclusive: false,
      voucherValue: 0,
      voucherCurrency: LegacyCurrencies.PERCENTAGE,
      voucherListName: "",
      voucherFootnote: "",
      utmSource: "",
      utmMedium: "",
      utmCampaign: "",
      utmContent: "",
      customParameter: ""
    }),
    [closed]
  );
};

export const CreateBrowseAbandonmentVoucherForm = ({
  disabled
}: CreateBrowseAbandonmentVoucherFormProps) => {
  const { t } = useTranslation();

  const [formVisible, setFormVisible] = useState(false);

  const [formClosed, setFormClosed] = useState(true);

  const FORM_INITIAL_STATE = getInitialValues(formClosed);

  const submitMethod = useCreateBrowseAbandonmentVoucher();

  const handleCreateToggle = useCallback(() => {
    setFormVisible((prev) => !prev);
    setFormClosed(false);
  }, []);

  useEffect(() => {
    if (disabled) {
      setFormVisible(false);
    }
  }, [disabled]);

  const handleFormSubmit = useCallback(
    async (
      values: BrowseAbandonmentVoucherSettingsValues,
      form: FormApi<
        BrowseAbandonmentVoucherSettingsValues,
        BrowseAbandonmentVoucherInitialSettingsValues
      >
    ) => {
      const res = await submitMethod(values, form);
      if (res) return res;
      setFormVisible(false);
    },
    [submitMethod]
  );

  const handleCloseTransitionFinished = useCallback(() => {
    setFormClosed(true);
  }, []);

  return (
    <>
      <CreateButtonWrapper>
        <TextButton
          iconPosition="start"
          disabled={disabled}
          icon={
            <AddIcon
              sx={{
                transform: `rotate(${formVisible ? "225" : "0"}deg)`,
                transition: "transform 0.3s ease"
              }}
            />
          }
          variant="tertiary"
          onClick={handleCreateToggle}
        >
          {t(
            formVisible
              ? "featuresModule.closeForm"
              : "featuresModule.browseAbandonment.form.voucherSettings.addMoreButton"
          )}
        </TextButton>
      </CreateButtonWrapper>
      <CreateFormCollapse in={formVisible} unmountOnExit onExited={handleCloseTransitionFinished}>
        <VoucherSettingsFormBase
          localizationKey="browseAbandonment"
          conditionFeatureName="browse_abandonment_conditions_enabled"
          possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.BROWSE_ABANDONMENT]}
          initialState={FORM_INITIAL_STATE}
          formOpened={true}
          onSubmit={handleFormSubmit}
        />
      </CreateFormCollapse>
    </>
  );
};
