import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import LoadingFallback from '../components/LoadingFallback';

const Loadable = ({ component: Component, loadingState, ...rest }) =>
  loadingState ? (
    <Suspense fallback={<LoadingFallback path={loadingState} />}>
      <Component {...rest} />
    </Suspense>
  ) : (
    <Component {...rest} />
  );

Loadable.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  loadingState: PropTypes.string,
};

export default Loadable;
