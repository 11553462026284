import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';

import LoadingSpinner from '../LoadingSpinner';

import ErrorBoundary from '../../containers/App/addons/ErrorBoundary';

import { Loading, Value, StyledUpButton } from './styles';

const UpButton = ({
  value,
  loading,
  margin,
  type,
  disabled,
  onClick,
  padding,
  successfull,
  successfullText,
  buttonColor,
  disabledButtonColor,
  gTagId,
  ...props
}) => (
  <ErrorBoundary>
    <StyledUpButton
      id={gTagId}
      buttonColor={buttonColor}
      disabledButtonColor={disabledButtonColor}
      onClick={() => {
        if (type !== 'submit') {
          onClick();
        }
      }}
      margin={margin}
      type={type}
      disabled={disabled}
      position="relative"
      padding={padding}
      {...props}
    >
      {loading && (
        <Loading>
          <LoadingSpinner size="17px" />
        </Loading>
      )}

      <CSSTransition
        in={successfull}
        classNames="successfull"
        timeout={200}
        unmountOnExit
      >
        <Loading>
          {successfullText}
          <FontAwesomeIcon
            icon="check"
            style={{ marginLeft: '6px', fontSize: '12px' }}
          />
        </Loading>
      </CSSTransition>
      <Value className={loading || successfull ? 'loading' : ''}>{value}</Value>
    </StyledUpButton>
  </ErrorBoundary>
);

UpButton.defaultProps = {
  loading: false,
  disabled: false,
  successfullText: 'Saved',
};

UpButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  loading: PropTypes.bool,
  margin: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  successfull: PropTypes.bool,
  successfullText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  disabledButtonColor: PropTypes.string,
};

export default UpButton;
