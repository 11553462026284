import { useState, useEffect, useCallback } from "react";
import { localStorage } from "../../utils";
import { LocalStorageKey } from "../../types";

export const useLocalStorageValue = (key: LocalStorageKey) => {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));

  useEffect(() => {
    localStorage.addListener(key, languageChangedListener);

    return () => {
      localStorage.removeListener(key, languageChangedListener);
    };
  }, []);

  const languageChangedListener = useCallback((value: string | null) => {
    setValue(value);
  }, []);

  return value;
};
