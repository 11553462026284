import messages from './messages';

export const labelByOption = {
  smaller: 'less',
  'smaller-or-equals': 'lessEqual',
  equals: 'equals',
  greater: 'greater',
  'greater-or-equals': 'greaterEqual',
  empty: 'empty',
  'not-empty': 'notEmpty',
  contains: 'contains',
  'contains-not': 'containsNot',
  regex: 'matchesRegex',
};

const getOption = (value, label) => {
  if (!value || !label || !messages[label]) return;

  return {
    value,
    label: messages[label],
  };
};

export const getOptions = (options, defaultOptions) =>
  defaultOptions.concat(options).reduce((acc, value) => {
    const option = getOption(value, labelByOption[value]);
    if (option) acc.push(option);

    return acc;
  }, []);
