import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FieldsHOC from '../FieldsHOC/index';

const UpInput = styled.input`
  padding: ${(props) =>
    props.inputWithInfo ? '7px 22px 7px 10px' : '7px 10px'};
  border-radius: 3px;
  border: ${(props) => props.theme.inputBorder};
  background: #14171c;
  cursor: ${(props) => props.cursor || 'auto'};
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.minWidth || 'auto'};
  margin: ${(props) => props.margin || 'auto'};
  flex: 1;
  &.error {
    border-color: ${(props) => props.theme.primaryActionColor};
    background-color: #FF614C;
    ${(props) => !props.inputWithInfo && 'padding-right: 22px;'}
    // if the user uses autofill to fill in the input Chrome will color the input field with a light blue color.
    // To prevent that we need the following:
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000px #FF614C inset;
    }
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 1;
    color: #aaa;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #aaa;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #aaa;
    opacity: 1;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #aaa;
    opacity: 1;
  }
  &:focus {
    ${(props) => props.theme.inputFocus}
  }
  &:disabled {
    background-color: #EFEFEF26;
    -webkit-text-fill-color: ${(props) => props.theme.fontColor};
  }
  &:disabled&::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 1;
    -webkit-text-fill-color: #aaa;
  }
  &:disabled&::-moz-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 1;
    -webkit-text-fill-color: #aaa;
  }
  &:disabled&:-ms-input-placeholder {
    /* IE 10+ */
    -webkit-text-fill-color: #aaa;
    opacity: 1;
  }
  &:disabled&:-moz-placeholder {
    /* Firefox 18- */
    -webkit-text-fill-color: #aaa;
    opacity: 1;
  }
`;

export default UpInput;

const unwrappedRenderField = ({
  input,
  meta,
  type,
  placeholder,
  customProps,
  padding,
}) => (
  <UpInput
    autoComplete="off"
    className={meta.error && meta.submitFailed && 'error'}
    {...input}
    type={type}
    placeholder={placeholder}
    width={customProps ? customProps.width : '100%'}
    padding={padding}
    inputWithInfo={customProps && customProps.inputWithInfo}
  />
);

const onError = (meta) => meta.error;

const unwrappedRenderFieldLiveCheck = ({
  input,
  meta,
  type,
  placeholder,
  customProps,
}) => (
  <UpInput
    autoComplete="off"
    className={onError(meta) && 'error'}
    {...input}
    type={type}
    placeholder={placeholder}
    width={customProps ? customProps.width : '100%'}
  />
);

const renderField = FieldsHOC(unwrappedRenderField);
const renderFieldLiveCheck = FieldsHOC(unwrappedRenderField, onError);

export { renderField, renderFieldLiveCheck };

unwrappedRenderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customProps: PropTypes.object,
  padding: PropTypes.string,
};

unwrappedRenderFieldLiveCheck.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customProps: PropTypes.object,
};
