import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { useIntl } from 'react-intl';

import { selectBox } from '../../SelectBox';
import { renderField } from '../../UpInput';
import messages from '../messages';
import { getOptions } from '../utils';

const Filter = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  width: 100%;
  @media only screen and (max-width: 700px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 550px) {
    margin-bottom: 0px;
  }
`;

const defaultOptions = [
  'smaller',
  'smaller-or-equals',
  'greater',
  'greater-or-equals',
];

const optionsByProduct = {
  activation: ['equals'],
  mail: ['equals'],
};

const Revenue = ({ member, product }) => {
  const intl = useIntl();

  return (
    <Filter>
      <Row>
        <Field
          name={`${member}.filter.operator`}
          component={selectBox}
          customProps={{
            options: getOptions(optionsByProduct[product], defaultOptions).map(
              ({ value, label }) => ({
                value,
                label: intl.formatMessage(label),
              }),
            ),
            margin: '0 10px 0 0',
            minWidth: '200px',
          }}
        />
      </Row>
      <Field
        name={`${member}.filter.attribute`}
        component={renderField}
        placeholder={intl.formatMessage(messages.value)}
      />
    </Filter>
  );
};

Revenue.propTypes = {
  member: PropTypes.string.isRequired,
  product: PropTypes.oneOf(['activation', 'mail', 'optin']).isRequired,
};

export default Revenue;