import styled, { keyframes } from 'styled-components';

// save spinner
export const spin = keyframes`
  0% { transform: rotate(0deg);}
  5% { transform: rotate(18deg);}
  10% { transform: rotate(36deg);}
  100% { transform: rotate(360deg);}
`;

export const spinSafari = keyframes`
  0% { -webkit-transform: rotate(0deg);}
  5% { -webkit-transform: rotate(18deg);}
  10% { -webkit-transform: rotate(36deg);}
  100% { -webkit-transform: rotate(360deg);}
`;

export const LoadingSpinner = styled.div`
  border: 3px solid ${(props) => props.borderColor || '#f3f3f3'};
  border-top: 3px solid ${(props) => props.borderTop || props.theme.fontColor};
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size : '20px')};
  height: ${(props) => (props.size ? props.size : '20px')};
  animation: ${spin} 0.8s linear infinite;
  -webkit-animation: ${spinSafari} 0.8s linear infinite;
  text-align: center;
  margin: ${(props) => props.margin || '0 auto'};
`;

export default LoadingSpinner;
