function dispatchAction(store, action, { status, msg, details = '' }) {
  let errorMessage;
  const newAction = { ...action };
  const { error } = newAction.meta;

  if (error && error[status]) {
    errorMessage = error[status];
  }

  if (details) {
    errorMessage = details;
  }

  if (!errorMessage) {
    errorMessage = msg;
  }

  delete newAction.type;
  delete newAction.meta;

  store.dispatch({
    type: `${action.type}_ERROR`,
    ...newAction,
    errorMessage, // Server temporary unavailable. Please try again in a few minutes.
  });
}

export { dispatchAction };
