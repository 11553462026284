import { Menu as MuiMenu, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLocation } from "react-router-dom";
import { AccountMenuProps } from "./AccountMenu.props";
import { makePaperStyles } from "./AccountMenu.styles";
import { AccountBlock } from "../AccountBlock";
import { AccountMenuButton } from "../AccountMenuButton";
import { Divider } from "../Divider";
import {
  useLogout,
  useQuestionnaireCompleted,
  useShopListData,
  useAccountNavigation,
  useAccountNavigationConfig,
  useAuthState
} from "../../../hooks";
import { ShopSwitch } from "../ShopSwitch";
import { withFeatureVisibility } from "../../../HoC";

export const AccountMenu = ({ anchorEl, onClose, shopId }: AccountMenuProps) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { data, loading } = useShopListData();

  const questionnaireIncomplete = !useQuestionnaireCompleted();

  const accountMenuRoutes = useAccountNavigationConfig();

  const { checkPermission } = useAuthState();

  const shopDataLoading = !loading && data && data[0];

  const displayShopId = shopDataLoading ? data[0]?.shopId?.toString() : "...";

  const open = Boolean(anchorEl);

  const badgesConfig: Record<string, boolean> = {
    questionnaireIncomplete
  };

  const handleNavigate = useAccountNavigation();

  const handleLogout = useLogout();

  const parsedButtons = useMemo(
    () =>
      accountMenuRoutes.map(
        ({ translationKey, path, badgeKey, includeShopId, requiredPermission, featureFlag }) => {
          if (requiredPermission && !checkPermission(requiredPermission)) return null;

          const active = pathname === path;

          const badge = badgeKey ? badgesConfig[badgeKey] : undefined;

          const navigateTo = {
            path,
            includeShopId
          };

          const href = includeShopId ? `${path}?shop=${shopId}` : path;

          if (featureFlag) {
            const Component = withFeatureVisibility(AccountMenuButton, featureFlag);
            return (
              <Component
                active={active}
                key={translationKey}
                href={href}
                showBadge={badge}
                onClick={handleNavigate(navigateTo)}
              >
                {t(translationKey)}
              </Component>
            );
          } else {
            return (
              <AccountMenuButton
                active={active}
                key={translationKey}
                href={href}
                showBadge={badge}
                onClick={handleNavigate(navigateTo)}
              >
                {t(translationKey)}
              </AccountMenuButton>
            );
          }
        }
      ),
    [pathname, accountMenuRoutes, badgesConfig, shopId, handleNavigate]
  );

  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      slotProps={{
        paper: {
          sx: makePaperStyles(theme)
        }
      }}
      onClose={onClose}
    >
      <AccountBlock shopId={displayShopId} />
      <Divider />
      <ShopSwitch />
      {parsedButtons}
      <Divider />
      <AccountMenuButton
        onClick={handleLogout}
        iconPosition="start"
        icon={<ExitToAppIcon viewBox={"0 0 22 22"} />}
      >
        {t("navigation.accountMenu.logout")}
      </AccountMenuButton>
    </MuiMenu>
  );
};
