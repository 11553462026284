import { Box as MuiBox, styled } from "@mui/material";

export const LoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `
);

export const LoginButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
    }
  `
);
