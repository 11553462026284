import {
  TextField as MuiTextField,
  styled,
  TextFieldProps,
  Box as MuiBox,
  IconButton as MuiIconButton,
  Popper as MuiPopper,
  Typography
} from "@mui/material";
import React from "react";
import { EndAdornmentIconButtonProps, InputWrapperProps } from "./TextInput.props";

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField {...props} InputLabelProps={{ ...props.InputLabelProps, shrink: true }} />
))(
  ({
    theme: {
      palette: { custom },
      typography: { body1, body2, errorText },
      breakpoints
    },
    error,
    InputProps
  }) => `
    &:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border-color: ${custom.white.main};
    }

    & .MuiFormLabel-root {
      color: ${custom.white.main};
      font-size: ${body2.mobile.fontSize};
      line-height: ${body2.mobile.lineHeight};
      font-weight: ${body2.mobile.fontWeight};
      text-transform: uppercase;
      margin-bottom: 0;
      left: 9px;
      top: 2px;

      ${[breakpoints.up("md")]} {
        font-size: ${body2.tablet.fontSize};
        line-height: ${body2.tablet.lineHeight};
        font-weight: ${body2.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body2.desktop.fontSize};
        line-height: ${body2.desktop.lineHeight};
        font-weight: ${body2.desktop.fontWeight};
      }

      & .MuiInputLabel-asterisk {
        color: ${custom.coral.main};
      }

      &.Mui-disabled {
        color: ${custom.white.main};
      }

      &.Mui-error {
        color: ${custom.white.main};
      }

      &.Mui-focused {
        color: ${custom.white.main};
      }
    }

    & .MuiInputBase-root {
      border-radius: 28px;
      font-size: ${body2.mobile.fontSize};
      line-height: ${body2.mobile.lineHeight};
      font-weight: ${body2.mobile.fontWeight};
      ${InputProps?.endAdornment ? "padding-right: 6px;" : ""}
      
      ${[breakpoints.up("md")]} {
        font-size: ${body2.tablet.fontSize};
        line-height: ${body2.tablet.lineHeight};
        font-weight: ${body2.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body2.desktop.fontSize};
        line-height: ${body2.desktop.lineHeight};
        font-weight: ${body2.desktop.fontWeight};
      }

      &.MuiInputBase-multiline {
        padding: 16px 0;
      }

      & .MuiInputBase-input {
        padding: 16px 10px 16px 22px; 
        border-radius: 28px;
        color: ${custom.white.main};
        font-size: ${body1.mobile.fontSize};
        line-height: ${body1.mobile.lineHeight};
        font-weight: ${body1.mobile.fontWeight};

        ${[breakpoints.up("md")]} {
          font-size: ${body1.tablet.fontSize};
          line-height: ${body1.tablet.lineHeight};
          font-weight: ${body1.tablet.fontWeight};
        }

        ${[breakpoints.up("lg")]} {
          font-size: ${body1.desktop.fontSize};
          line-height: ${body1.desktop.lineHeight};
          font-weight: ${body1.desktop.fontWeight};
        }
      }

      & .MuiOutlinedInput-notchedOutline {
        padding: 0 18px;
        border-color: ${error ? custom.coral.main : custom.white.light};

        & legend {
          margin-bottom: 0;

          & span {
            text-transform: uppercase;
            padding: 0 5px 0 4px;
          }
        }
      }

      &.Mui-focused {

        & .MuiFormLabel-root {
          color: ${custom.white.main};
        }

        & .MuiOutlinedInput-notchedOutline {
          border-color: ${custom.turquoise.main};
          border-width: 2px;
        }
      }

      &.Mui-disabled {

        & .MuiOutlinedInput-notchedOutline {
          border-color: ${custom.white.dark};
          border-style: dashed;
        }

        & .MuiInputBase-input {
          text-fill-color: ${custom.white.dark};

          &:placeholder {
            color: ${custom.white.dark};
          }
        }
      }
    }

    & .MuiFormHelperText-root {
      margin: 5px 14px 0;
      color: ${custom.coral.main};
      font-size: ${errorText.fontSize};
      line-height: ${errorText.lineHeight};
      font-weight: ${errorText.fontWeight};

      &.Mui-error {
        color: ${custom.coral.main};
      }
    }
  `
);

export const Box = styled((props: InputWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "error" && prop !== "marginBottom" && prop !== "marginTop"
})(({ error, marginBottom, marginTop }) => {
  const defaultMarginBottom = marginBottom ?? 44;

  const errorTextHeight = 19;

  return `
      display: flex;
      flex-direction: column;
      margin-bottom: ${error ? defaultMarginBottom - errorTextHeight : defaultMarginBottom}px;
      margin-top: ${marginTop ?? 0}px;

      & .MuiFormControlLabel-root {
        margin-top: 7px;
      }
    `;
});

export const IconButton = styled(
  React.forwardRef<HTMLButtonElement, EndAdornmentIconButtonProps>(function ForwardedIconButton(
    props,
    ref
  ) {
    return <MuiIconButton {...props} ref={ref} />;
  }),
  {
    shouldForwardProp: (prop) => prop !== "infoPlacementMode"
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    infoPlacementMode
  }) => {
    let positionStyle;

    switch (infoPlacementMode) {
      case "select":
        positionStyle = "position: absolute; right: 28px;";
        break;
      case "password":
        positionStyle = "position: absolute; right: 38px;";
        break;
      default:
        positionStyle = "margin-right: 4px;";
    }

    return `
      &.MuiIconButton-root {
        color: ${custom.white.light};
        ${positionStyle}
      }
    `;
  }
);

export const Popper = styled(MuiPopper)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    & .MuiTooltip-tooltip {
      background-color: ${custom.grey.light};
      color: ${custom.white.main};
      margin: 0 5px;

      & .MuiTooltip-arrow {
        color: ${custom.grey.light};
      }
    }
  `
);

export const ErrorTypography = styled(Typography)(
  ({
    theme: {
      typography: { errorText },
      palette: { custom }
    }
  }) => `
    margin: 5px 14px 0;
    color: ${custom.coral.main};
    font-size: ${errorText.fontSize};
    line-height: ${errorText.lineHeight};
    font-weight: ${errorText.fontWeight};
  `
);
