import { useCallback } from "react";
import { TimeRowStyles, TimeTextButton, TimeRowDeleteButton } from "./TimeRow.styles";
import { TimeRowProps } from "./TimeRow.props";
import DeleteIcon from "@mui/icons-material/Delete";

export const TimeRow = ({
  index,
  start,
  end,
  disabled,
  selected,
  error,
  onClick,
  onDeleteClick
}: TimeRowProps) => {
  const displayValue = `${start}-${end}`;

  const handleClick = useCallback(() => {
    onClick(index);
  }, [index]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick();
  }, [onDeleteClick]);

  return (
    <TimeRowStyles>
      <TimeTextButton onClick={handleClick} disabled={disabled} selected={selected} error={error}>
        {displayValue}
      </TimeTextButton>
      {selected && (
        <TimeRowDeleteButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </TimeRowDeleteButton>
      )}
    </TimeRowStyles>
  );
};
