import { SnackbarProvider } from "notistack";
import { PropsWithChildren } from "react";
import { NotificationAlert } from "../../utils";
import ErrorIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

export const NotificationProvider = ({ children }: PropsWithChildren) => (
  <SnackbarProvider
    maxSnack={3}
    iconVariant={{
      default: <InfoIcon sx={{ marginInlineEnd: "10px" }} />,
      error: <ErrorIcon sx={{ marginInlineEnd: "10px" }} />,
      success: <SuccessIcon sx={{ marginInlineEnd: "10px" }} />,
      warning: <WarningAmberOutlinedIcon sx={{ marginInlineEnd: "10px" }} />
    }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    Components={{
      default: NotificationAlert,
      error: NotificationAlert,
      success: NotificationAlert,
      warning: NotificationAlert
    }}
  >
    {children}
  </SnackbarProvider>
);
