import { useMemo } from "react";
import { QuestionnaireVendorsLinks } from "../../types";

export const useQuestionnaireVendorLinks = () => {
  const result: QuestionnaireVendorsLinks = useMemo(() => {
    try {
      return JSON.parse(process.env.REACT_APP_QUESTIONNAIRE_VENDORS_LINKS || "{}");
    } catch (error) {
      console.error(
        "Invalid questionnaire vendors configuration provided, make sure that REACT_APP_QUESTIONNAIRE_VENDORS_LINKS is a valid JSON string."
      );
      return {};
    }
  }, []);

  return result;
};
