import { useTranslation } from "react-i18next";
import { Form, Field, FormSpy } from "react-final-form";
import { colord } from "colord";
import {
  DescriptionText,
  Heading,
  Subheading,
  SelectDropdown,
  ColorPicker,
  TextInput
} from "shared";
import {
  FieldsWrapper,
  InputsWrapper,
  ColorWrapper,
  PreviewWrapper,
  FontInputsWrapper,
  FontSelectWrapper
} from "./Design.styles";
import { manualFontBoldValidator, manualFontValidator } from "./Design.validation";
import { StepWrapper, StepsContent } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useTemplateDesignStep } from "../../../hooks";
import { Preview } from "./Preview";

export const Design = () => {
  const { t } = useTranslation();

  const {
    data,
    extendedFontFamilies,
    activationTemplatesVisible,
    newsletterFormsVisible,
    hasPrev,
    executeSubmitTemplateDesign,
    handlePrevStep
  } = useTemplateDesignStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitTemplateDesign}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.design.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.design.templateDesign.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.design.templateDesign.description")}
            </DescriptionText>
            <FieldsWrapper>
              <InputsWrapper>
                <FontInputsWrapper>
                  <Field
                    name="font"
                    render={({
                      input: { value, onChange, onBlur, onFocus },
                      meta: { modifiedSinceLastSubmit, touched }
                    }) => (
                      <FontSelectWrapper>
                        <SelectDropdown
                          label={t(
                            "questionnaireModule.steps.design.templateDesign.form.font.label"
                          )}
                          options={extendedFontFamilies}
                          infoText={t(
                            "questionnaireModule.steps.design.templateDesign.form.font.infoText"
                          )}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={submitErrors?.["font"] && !modifiedSinceLastSubmit && touched}
                          errorMessage={t(submitErrors?.["font"])}
                        />
                      </FontSelectWrapper>
                    )}
                  />
                  <FormSpy
                    subscription={{ values: true }}
                    render={({ values: { font } }) =>
                      font === "other" && (
                        <Field
                          name="manualFont"
                          validate={manualFontValidator}
                          render={({
                            input: { value, onChange, onBlur, onFocus },
                            meta: { error, modifiedSinceLastSubmit, touched }
                          }) => (
                            <TextInput
                              required
                              label={t(
                                "questionnaireModule.steps.design.templateDesign.form.font.label"
                              )}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                              error={
                                (error || (submitErrors?.["font"] && !modifiedSinceLastSubmit)) &&
                                touched
                              }
                              errorMessage={t(submitErrors?.["font"] ?? error)}
                            />
                          )}
                        />
                      )
                    }
                  />
                </FontInputsWrapper>
                <FontInputsWrapper>
                  <Field
                    name="fontBold"
                    render={({
                      input: { value, onBlur, onChange, onFocus },
                      meta: { modifiedSinceLastSubmit, touched }
                    }) => (
                      <FontSelectWrapper>
                        <SelectDropdown
                          label={t(
                            "questionnaireModule.steps.design.templateDesign.form.fontBold.label"
                          )}
                          options={extendedFontFamilies}
                          infoText={t(
                            "questionnaireModule.steps.design.templateDesign.form.fontBold.infoText"
                          )}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={submitErrors?.["fontBold"] && !modifiedSinceLastSubmit && touched}
                          errorMessage={t(submitErrors?.["fontBold"])}
                        />
                      </FontSelectWrapper>
                    )}
                  />
                  <FormSpy
                    subscription={{ values: true }}
                    render={({ values: { fontBold } }) =>
                      fontBold === "other" && (
                        <Field
                          name="manualFontBold"
                          validate={manualFontBoldValidator}
                          render={({
                            input: { value, onChange, onBlur, onFocus },
                            meta: { error, modifiedSinceLastSubmit, touched }
                          }) => (
                            <TextInput
                              required
                              label={t(
                                "questionnaireModule.steps.design.templateDesign.form.font.label"
                              )}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                              error={
                                (error ||
                                  (submitErrors?.["fontBold"] && !modifiedSinceLastSubmit)) &&
                                touched
                              }
                              errorMessage={t(submitErrors?.["fontBold"] ?? error)}
                            />
                          )}
                        />
                      )
                    }
                  />
                </FontInputsWrapper>
              </InputsWrapper>
              <ColorWrapper>
                <Field
                  name="buttonColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.buttonColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
                <Field
                  name="textColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.textColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
                <Field
                  name="backgroundColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.backgroundColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
                <Field
                  name="buttonFontColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.buttonFontColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
                <Field
                  name="inputColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.inputColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
                <Field
                  name="headingColor"
                  render={({ input: { value, onChange } }) => (
                    <ColorPicker
                      label={t(
                        "questionnaireModule.steps.design.templateDesign.form.headingColor.label"
                      )}
                      value={colord(value).toHex()}
                      onChange={(value) => onChange(colord(value).rgba)}
                    />
                  )}
                />
              </ColorWrapper>
              <PreviewWrapper>
                <FormSpy
                  subscription={{ values: true }}
                  render={({ values }) => (
                    <Preview
                      activationPreviewVisible={!!activationTemplatesVisible}
                      newsletterPreviewVisible={!!newsletterFormsVisible}
                      designSettings={values}
                    />
                  )}
                />
              </PreviewWrapper>
            </FieldsWrapper>
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
