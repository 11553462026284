import { combineReducers } from 'redux-immutable';
import { dropsReducer } from './Drops/reducer';
import irregularReducer from './Irregular/reducer';
import lineDataReducer from './LIneChart/reducer';

const analysisReducer = combineReducers({
  drops: dropsReducer,
  irregular: irregularReducer,
  lineData: lineDataReducer,
});

export default analysisReducer;
