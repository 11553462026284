/** Check if passed argument is an object and not equal to null,
 * because typeof null === "object"
 * @param {*} operand - any passed value
 */
function checkIfObject(operand) {
  return typeof operand === 'object' && operand !== null;
}

/** Check if passed argument is a string mathing {}.
 * If there are {} inside of the string, the return would be an array of substrings inside {},
 * if no the null will be returned. It doesn't matter if there is one substring with {} or more,
 * the main check if it’s not null, because even empty array is always truthy.
 * @param {*} operand - any passed value
 */
function checkIfStringWithObject(operand) {
  return typeof operand === 'string' && operand.match(/\{([^}]+)\}/g);
}

export { checkIfObject, checkIfStringWithObject };
