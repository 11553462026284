import styled from 'styled-components';

import SelectBox from '../SelectBox';

export const SelectShop = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10000;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #3e3e3e;
  border-radius: 0;
  box-shadow: 0 0 8px #717171;

  @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    bottom: 20px;
    right: 15px;
    width: 190px;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 3px;
    justify-content: center;
  }

  > span {
    font-size: 12px;
    font-weight: 500;
    color: #a7a7a7;
    padding-left: 9px;
    padding-top: 3px;
  }
`;

export const InvertedSelectBox = styled(SelectBox)`
  > div {
    background: #3e3e3e;
    > div > div {
      border: none;
      color: #fff;
      &.focused {
        border: none;
        outline: none;
        box-shadow: none;
      }
      > input {
        &::selection {
          background: #fff;
          color: ${(props) => props.theme.fontColor};
        }
      }
      > svg {
        fill: #fff;
      }
    }
    .selectBox--options {
      background: #3e3e3e;
      bottom: 36px;
      box-shadow: 0px -6px 14px -3px #717171;
      border-bottom: 2px solid #6d6d6d;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .activeOption {
        background: ${(props) => props.theme.primaryColor};
      }
      .focusedOption {
        color: ${(props) => props.theme.fontColor};
        background: #d3eeec;
      }
    }
  }
`;
