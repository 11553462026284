import { styled, Box as MuiBox } from "@mui/material";

export const OperatorWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: 100%;

      ${breakpoints.up("md")} {
        min-width: 160px;
        max-width: 214px;
        width: auto;
        margin-right: 8px;
      }
    }
  `
);

export const BetweenValueWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: 100%;
      flex-direction: column;
      display: flex;
      flex: 1;

      ${breakpoints.up("md")} {
        flex-direction: row;
        width: auto;

        & > .MuiBox-root {
          flex: 1;

          &:last-of-type {
            margin-left: 8px;
          }
        }
      }
    }
  `
);

export const ValueWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: 100%;
      flex: 1;

      ${breakpoints.up("md")} {
        width: auto;
      }
    }
  `
);
