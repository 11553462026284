import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Icon from '../Icon';

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: ${(props) => props.breakpoint || '800px'}) {
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: column;
  }
`;


const UpRow = ({ breakpoint, remove, children, hideDeleteIcon }) =>
  <RowWrapper>
    <InputWrapper breakpoint={breakpoint}>
      {children}
    </InputWrapper>
    {!hideDeleteIcon &&
      <Icon float="none" margin="0px 0px 0px 0px">
        <FontAwesomeIcon onClick={() => remove()} icon="minus-circle" />
      </Icon>
    }
  </RowWrapper>
;

UpRow.defaultProps = {
  hideDeleteIcon: false,
};

UpRow.propTypes = {
  breakpoint: PropTypes.string,
  remove: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideDeleteIcon: PropTypes.bool,
};

export default UpRow;
