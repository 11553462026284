import { GetBrowseAbandonmentTextListResponse } from "api";
import { apiClient } from "shared";
import { BrowseAbandonmentTextInitialSettingsValues } from "../../types";
import { mapCustomConditionsToForm } from "../../utils";

export const getBrowseAbandonmentTextList = async (
  shopId: number,
  limit: number,
  offset: number
) => {
  const { data } = await apiClient.get<GetBrowseAbandonmentTextListResponse>(
    `/${shopId}/triggers/browse-abandonment/text-settings`,
    {
      params: {
        offset,
        limit
      }
    }
  );

  return {
    ...data,
    items: data.items.map<BrowseAbandonmentTextInitialSettingsValues>(
      ({ browseAbandonmentTriggerItemId: id, ...item }) => ({
        ...item,
        id,
        conditions: mapCustomConditionsToForm(item.conditions)
      })
    )
  };
};
