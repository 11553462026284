import { apiClient } from "shared";
import { GetNewsletterFormsTranslationsResponse } from "api";

export const getNewsletterFormsTranslations = async (shopId: number) => {
  const { data } = await apiClient.get<GetNewsletterFormsTranslationsResponse>(
    `/${shopId}/newsletter-forms/translations`
  );

  return data;
};
