import {
  styled,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  ToolbarProps,
  Box as MuiBox,
  BoxProps
} from "@mui/material";
import { LanguageSwitch as LanguageSwitchBase } from "../LanguageSwitch";
import { ReactComponent as LogoIconPublic } from "../../../../assets/icons/uptain_logo_public.svg";

export const AppBar = styled(MuiAppBar)(
  () => `
    z-index: 10000;
    background: none;
    box-shadow: none;
  `
);

export const Toolbar = styled((props: ToolbarProps) => <MuiToolbar {...props} disableGutters />)(
  () => `
    display: flex;
    justify-content: flex-start;
    padding: 30px 30px 0;
  `
);

export const Logo = styled(LogoIconPublic)(() => ``);

export const LanguageSwitch = styled((props: BoxProps) => (
  <MuiBox {...props}>
    <LanguageSwitchBase />
  </MuiBox>
))(
  () => `
    &.MuiBox-root {
      display: flex;
      margin-left: auto;

      & > .MuiButtonBase-root {
        padding: 0;
      }
    }
  `
);
