import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UpInputWithLabel from '../UpInputWithLabel';

import { ErrorMessage, ErrorIcon, Field } from './styles';
import classNames from 'classnames';

interface UpFieldWithErrorStateProps {
  label?: string | React.ReactElement;
  flex?: string;
  margin?: string;
  width?: string;
  errorMessageVisible?: string | boolean;
  position?: string;
  error?: boolean;
  errorMessage?: string | React.ReactElement;
  children: React.ReactNode;
  infoText?: string | React.ReactElement; 
  required?: boolean;
  className?: string;
}

const UpFieldWithErrorState: React.FC<UpFieldWithErrorStateProps> = ({
  label = '',
  infoText = '',
  flex = '',
  position = '',
  width = '',
  margin = '',
  required,
  errorMessageVisible,
  error = false,
  errorMessage = '',
  className,
  children,
}) => {
  const [errorMsgVisible, setErrorMessageVisible] = useState(false);
  return (
    <UpInputWithLabel
      className={className}
      infoText={infoText}
      margin={margin}
      label={label}
      flex={flex}
      required={required}
    >
      <Field
        width={width}
        onFocus={() => setErrorMessageVisible(true)}
        onBlur={() => setErrorMessageVisible(false)}
        className={classNames({ errorAnimation: error })}
      >
        {error && (
          <ErrorIcon>
            <FontAwesomeIcon icon="exclamation-circle" />
          </ErrorIcon>
        )}
        {children}
        {error && (errorMsgVisible || errorMessageVisible) && (
          <ErrorMessage position={position}>{errorMessage}</ErrorMessage>
        )}
      </Field>
    </UpInputWithLabel>
  );
};

export default UpFieldWithErrorState;
