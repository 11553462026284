import { LocalStorageKey } from "../../types";
import { LocalStorageListener } from "./localStorage.types";

export class LocalStorageService {
  _listeners: LocalStorageListener[] = [];

  addListener(key: LocalStorageKey, listener: (value: string | null) => void) {
    this._listeners.push({ key, listener });
  }

  removeListener(key: LocalStorageKey, listener: (value: string | null) => void) {
    this._listeners = this._listeners.filter(
      ({ key: listenerKey, listener: listenerCallback }) =>
        !(listenerKey === key && listenerCallback === listener)
    );
  }

  getItem = (key: LocalStorageKey): string | null => {
    return localStorage.getItem(key);
  };

  setItem = (key: LocalStorageKey, value: string) => {
    localStorage.setItem(key, value);
    this._listeners
      .filter(({ key: listenerKey }) => listenerKey === key)
      .forEach(({ listener }) => listener(value));
  };

  removeItem = (key: LocalStorageKey) => {
    localStorage.removeItem(key);
    this._listeners
      .filter(({ key: listenerKey }) => listenerKey === key)
      .forEach(({ listener }) => listener(null));
  };

  clear = () => {
    localStorage.clear();
  };
}

export const instance = new LocalStorageService();
