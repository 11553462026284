import React from "react";
import { Chip as MuiChip, styled } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { ChipProps } from "./Chip.props";

const defaultIconViewBox = "2 2 20 20";

export const Chip = styled(
  ({ color = "default", icon: Icon, deleteIcon: DeleteIcon, ...props }: ChipProps) => {
    return (
      <MuiChip
        {...props}
        color={color}
        icon={Icon && React.cloneElement(Icon, { viewBox: defaultIconViewBox, ...Icon.props })}
        deleteIcon={
          DeleteIcon ? (
            React.cloneElement(DeleteIcon, { viewBox: defaultIconViewBox, ...DeleteIcon.props })
          ) : (
            <CancelIcon viewBox={defaultIconViewBox} />
          )
        }
      />
    );
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    color = "default"
  }) => {
    let backgroundColor;
    let hoverBackgroundColor;
    let opacity = 0.38;

    switch (color) {
      case "success":
        backgroundColor = custom.turquoise.main;
        hoverBackgroundColor = custom.turquoise.light;
        break;
      case "error":
        backgroundColor = custom.coral.main;
        hoverBackgroundColor = custom.coral.light;
        break;
      default:
        backgroundColor = custom.grey.main;
        hoverBackgroundColor = custom.grey.light;
        opacity = 0.5;
    }

    return `
      height: auto;
      color: ${custom.white.main};
      background-color: ${backgroundColor};
      padding: 3px 0;

      &.Mui-disabled {
        background-color: ${hoverBackgroundColor};
        opacity: ${opacity};

        & .MuiChip-deleteIcon {
          pointer-events: none;
        }
      }

      & .MuiChip-label {
        display: block;
        white-space: break-spaces;
      },

      & .MuiSvgIcon-root {
        color: ${custom.white.light};
      }

      & .MuiChip-icon {
        font-size: 18px;
      }

      & .MuiChip-deleteIcon {
        font-size: 14px;

        &:hover {
          color: ${custom.white.light};
        }
      }

      &:not([disabled]):hover {
        background-color: ${hoverBackgroundColor};
      }
    `;
  }
);
