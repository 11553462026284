import { useMemo } from "react";
import { QuestionnaireVendorsConfig } from "../../types";

export const useQuestionnaireVendorsConfig = () => {
  const result: QuestionnaireVendorsConfig = useMemo(() => {
    try {
      return JSON.parse(process.env.REACT_APP_QUESTIONNAIRE_VENDORS_CONFIG || "{}");
    } catch (error) {
      console.error(
        "Invalid questionnaire vendors configuration provided, make sure that REACT_APP_QUESTIONNAIRE_VENDORS_CONFIG is a valid JSON string."
      );
      return {};
    }
  }, []);

  return result;
};
