import { QuestionnaireAddOptionStepData } from "api";

export const questionnaireAddOptionsFieldsConfig: (keyof QuestionnaireAddOptionStepData)[] = [
  "phoneSupportEnabled",
  "emailSupportEnabled",
  "activationPopupVoucherEnabled",
  "abandonmentMailVoucherEnabled",
  "newsletterVoucherEnabled",
  "browseAbandonmentVoucherEnabled",
  "comeBackMailingVoucherEnabled"
];
