import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  ContainedButton,
  DescriptionText,
  Heading,
  LoadingIndicator,
  Subheading,
  TextButton,
  TextInput
} from "shared";
import { Routes } from "config";
import { FormWrapper } from "../FormWrapper";
import { FormContainer } from "../FormContainer";
import { ErrorText } from "../ErrorText";
import { RegisterValues } from "../../types";
import { useRegisterMutation, useRegistrationNavigation } from "../../hooks";
import { LoadingWrapper, LoginButtonWrapper } from "./RegisterForm.styles";
import { emailValidator } from "./RegisterForm.validation";

const FORM_INITIAL_STATE: RegisterValues = { email: "", registrationToken: "" };

export const RegisterForm = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const token = searchParams.get("token");

  useRegistrationNavigation(token);

  const submit = useRegisterMutation(token);

  const handleLoginButton = useCallback(() => {
    navigate({
      pathname: Routes.LOGIN
    });
  }, []);

  const renderDescription = useCallback((success: boolean) => {
    return success ? (
      <DescriptionText>{t("authModule.registerScreen.successDescription")}</DescriptionText>
    ) : (
      <DescriptionText withMargin>{t("authModule.registerScreen.description")}</DescriptionText>
    );
  }, []);

  return !token ? (
    <LoadingWrapper>
      <LoadingIndicator />
    </LoadingWrapper>
  ) : (
    <FormWrapper>
      <Form
        initialValues={FORM_INITIAL_STATE}
        onSubmit={submit}
        render={({ dirty, handleSubmit, submitting, submitErrors, submitSucceeded }) => (
          <React.Fragment>
            <Heading>{t("authModule.registerScreen.heading")}</Heading>
            <Subheading>
              {t(
                `authModule.registerScreen.${submitSucceeded ? "successSubheading" : "subheading"}`
              )}
            </Subheading>
            {renderDescription(submitSucceeded)}
            {submitSucceeded ? (
              <LoginButtonWrapper>
                <TextButton iconPosition="start" variant="secondary" onClick={handleLoginButton}>
                  {t("authModule.registerScreen.loginButton")}
                </TextButton>
              </LoginButtonWrapper>
            ) : (
              <FormContainer>
                <Field
                  name="email"
                  validate={emailValidator}
                  render={({
                    input: { value, onChange, onBlur, onFocus },
                    meta: { error, touched }
                  }) => (
                    <TextInput
                      placeholder={t("global.placeholders.email")}
                      label={t("authModule.registerScreen.form.email")}
                      value={value}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      error={error && touched}
                      errorMessage={t(error)}
                    />
                  )}
                />
                <ContainedButton
                  loading={submitting}
                  disabled={!dirty}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("authModule.registerScreen.form.submitButton")}
                </ContainedButton>
                {submitErrors && <ErrorText>{t(submitErrors[FORM_ERROR])}</ErrorText>}
              </FormContainer>
            )}
          </React.Fragment>
        )}
      />
    </FormWrapper>
  );
};
