import { Theme } from "@mui/material";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: "transparent",
  marginTop: "20px",
  minWidth: "308px",

  "& .MuiList-root": {
    padding: "5px 16px 25px",
    backgroundColor: custom.background,
    boxShadow: custom.shadows.navigation
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});
