import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const referrerParameterName = "handl_original_ref";

export const useReferrerEnhancer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (document.referrer) {
      searchParams.set(referrerParameterName, document.referrer);
      setSearchParams(searchParams);
    }
  }, []);
};
