import { TriggerMailsPaymentSettingsFormBase } from "../TriggerMailsPaymentSettingsFormBase";
import { useUpdateBrowseAbandonmentPayment, useGetBrowseAbandonmentPayment } from "../../hooks";

export const BrowseAbandonmentPaymentSettingsForm = () => {
  const { initialState, loading } = useGetBrowseAbandonmentPayment();

  const updateMethod = useUpdateBrowseAbandonmentPayment();

  return (
    <TriggerMailsPaymentSettingsFormBase
      initialValues={initialState}
      loading={loading}
      translationKey="browseAbandonment"
      onSubmit={updateMethod}
    />
  );
};
