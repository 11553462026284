import { apiClient } from "shared";
import { QuestionnaireAboutUsStepData, ShopIdParam } from "api";

export const putSubmitAboutUs = async ({
  shopId,
  ...values
}: QuestionnaireAboutUsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/about-us`, values);

  return data;
};
