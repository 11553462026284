import { styled } from "@mui/material";
import { forwardRef, DetailedHTMLProps, IframeHTMLAttributes } from "react";

export const WysiwygFrame = styled(
  forwardRef<
    HTMLIFrameElement,
    DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>
  >(function ForwardRefIframe(props, ref) {
    return <iframe ref={ref} {...props} />;
  })
)(
  () => `
    width: 100%;
    height: 380px;
    border: none;
  `
);
