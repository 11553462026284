import { ContactPerson } from "api";
import { useCallback, useState } from "react";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { ContactPersonFormBase } from "../ContactPersonFormBase";
import { useDeleteContactPerson, useUpdateContactPerson } from "../../hooks";

export const ContactPersonItemForm = ({ initialState }: { initialState: ContactPerson }) => {
  const [opened, setOpened] = useState(false);

  const updateMethod = useUpdateContactPerson();

  const deleteMethod = useDeleteContactPerson();

  const handleFormOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleFormClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <ContactPersonFormBase
      initialState={initialState}
      formOpened={opened}
      expandIcon={<Chevron />}
      onOpen={handleFormOpen}
      onClose={handleFormClose}
      onSubmit={updateMethod}
      onDelete={deleteMethod}
    />
  );
};
