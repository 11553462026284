import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const FULL_DATE_WITH_TIME = 'DD/MM/YYYY, h:mm:ss';

export const FULL_DATE = 'YYYY-MM-DD';

export const TIME_WITHOUT_SECONDS = 'HH:mm';

export const FIRST_CALENDAR_DAY = {
  en: 0,
  de: 1, // Use Monday as first day of the week
};

export const DEFAULT_ITEMS_IN_ARRAY_CHUNK = 1000;

export const NO_WHITESPACE_REGEX = /^[^\s]+(\s+[^\s]+)*$/;

export const MAX_SIGNATURE_SIZE_KB = 50;

export const KEYBOARD_CODES = {
  esc: 27,
  enter: 13,
  tab: 9,
};

export const LOCALE_BY_LANGUAGE = { en, de };

export const URL_WHITELIST = ['feature-toggles'];

export const QUESTIONNAIRE_PATHNAME = '/account/onboarding-questionnaire';

export const AVAILABILITY_WEEKDAYS = [
  'monday',
  'tuesday',
  'wedensday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
