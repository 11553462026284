import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const sharedStyle = css`
  display: ${(props) => (props.display === 'block' ? 'block' : 'inline-block')};
  color: ${(props) => props.color || props.theme.linkColor};
  text-decoration: ${(props) => props.textDecoration || 'none'};
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.color || props.theme.linkColor};
    text-decoration: underline;
  }
`;

const LinkStyled = styled(Link)`
  ${sharedStyle}
`;

const LinkHrefStyled = styled.a`
  ${sharedStyle}
`;

export { LinkStyled, LinkHrefStyled };
