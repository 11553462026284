import { Box as MuiBox, styled } from "@mui/material";

export const BackButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
    }
  `
);
