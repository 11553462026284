import CloseIcon from "@mui/icons-material/Close";
import { Container, DeleteButton } from "./ConditionItem.styles";
import { ConditionItemProps } from "./ConditionItem.props";

export const ConditionItem = ({ children, onDelete }: ConditionItemProps) => {
  return (
    <Container onDelete={onDelete}>
      {children}
      {onDelete && (
        <DeleteButton onClick={onDelete}>
          <CloseIcon />
        </DeleteButton>
      )}
    </Container>
  );
};
