import { useTranslation } from "react-i18next";
import { Wrapper, ButtonRow, InputRow, ControlButton, TimeField, Label } from "./TimeInput.styles";
import { TimeInputProps } from "./TimeInput.props";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChangeEvent, useCallback } from "react";

export const TimeInput = ({
  labelKey,
  value,
  onChangeValue,
  error,
  onBlur,
  onFocus
}: TimeInputProps) => {
  const { t } = useTranslation();

  const hours = value.split(":")[0];

  const minutes = value.split(":")[1];

  const handleHoursIncrement = useCallback(() => {
    const number = Number(hours);

    const increasedNumber = number + 1;

    if (increasedNumber > 23) {
      onChangeValue(`00:${minutes}`);
      return;
    }

    const result = increasedNumber.toString().padStart(2, "0");

    onChangeValue(`${result}:${minutes}`);
  }, [onChangeValue, hours, minutes]);

  const handleMinutesIncrement = useCallback(() => {
    const number = Number(minutes);

    const increasedNumber = number + 1;

    if (increasedNumber > 59) {
      onChangeValue(`${hours}:00`);
      return;
    }

    const result = increasedNumber.toString().padStart(2, "0");

    onChangeValue(`${hours}:${result}`);
  }, [onChangeValue, hours, minutes]);

  const handleMinutesDecrement = useCallback(() => {
    const number = Number(minutes);

    const decreasedNumber = number - 1;

    if (decreasedNumber < 0) {
      onChangeValue(`${hours}:59`);
      return;
    }

    const result = decreasedNumber.toString().padStart(2, "0");

    onChangeValue(`${hours}:${result}`);
  }, [onChangeValue, hours, minutes]);

  const handleHoursDecrement = useCallback(() => {
    const number = Number(hours);

    const decreasedNumber = number - 1;

    if (decreasedNumber < 0) {
      onChangeValue(`23:${minutes}`);
      return;
    }

    const result = decreasedNumber.toString().padStart(2, "0");

    onChangeValue(`${result}:${minutes}`);
  }, [onChangeValue, hours, minutes]);

  const handleMinutesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let numbersOnly = Number(value.replace(/[^0-9]/g, "").slice(0, 2));

      if (numbersOnly > 59) {
        numbersOnly = 59;
      }

      onChangeValue(`${hours}:${numbersOnly}`);
    },
    [onChangeValue, hours]
  );

  const handleHoursChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let numbersOnly = Number(value.replace(/[^0-9]/g, "").slice(0, 2));

      if (numbersOnly > 23) {
        numbersOnly = 23;
      }

      onChangeValue(`${numbersOnly}:${minutes}`);
    },
    [onChangeValue, minutes]
  );

  const handleHoursBlur = useCallback(() => {
    onChangeValue(`${hours.padStart(2, "0")}:${minutes}`);

    if (onBlur) {
      onBlur();
    }
  }, [hours, minutes, onBlur, onChangeValue]);

  const handleMinutesBlur = useCallback(() => {
    onChangeValue(`${hours}:${minutes.padStart(2, "0")}`);

    if (onBlur) {
      onBlur();
    }
  }, [hours, minutes, onBlur, onChangeValue]);

  return (
    <Wrapper>
      <Label>{t(labelKey)}</Label>
      <ButtonRow>
        <ControlButton onClick={handleHoursIncrement}>
          <ExpandLessIcon />
        </ControlButton>
        <ControlButton onClick={handleMinutesIncrement}>
          <ExpandLessIcon />
        </ControlButton>
      </ButtonRow>
      <InputRow error={error}>
        <TimeField
          fullWidth
          value={hours}
          onChange={handleHoursChange}
          onBlur={handleHoursBlur}
          onFocus={onFocus}
          error={error}
        />
        <TimeField
          fullWidth
          value={minutes}
          onChange={handleMinutesChange}
          onBlur={handleMinutesBlur}
          onFocus={onFocus}
          error={error}
        />
      </InputRow>
      <ButtonRow>
        <ControlButton onClick={handleHoursDecrement}>
          <ExpandMoreIcon />
        </ControlButton>
        <ControlButton onClick={handleMinutesDecrement}>
          <ExpandMoreIcon />
        </ControlButton>
      </ButtonRow>
    </Wrapper>
  );
};
