import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, ReduxFormContext } from 'redux-form/immutable';

function FormValueSelector(props) {
  const { children, values } = props;

  return children(values) || null;
}

FormValueSelector.propTypes = {
  children: PropTypes.func.isRequired,
  values: PropTypes.any,
};

const mapStateToProps = (state, props) => {
  const { form, sectionPrefix, fieldName, selector } = props;
  const valueSelector = formValueSelector(form);

  if (typeof selector === 'function') {
    const valueSelectorBoundToState = (...args) =>
      valueSelector(state, ...args);

    return {
      values: selector(
        valueSelectorBoundToState,
        form,
        sectionPrefix,
        form in state.form ? state.form[form].values : {},
      ),
    };
  }

  return {
    values: valueSelector(
      state,
      sectionPrefix ? `${sectionPrefix}.${fieldName}` : fieldName,
    ),
  };
};

// eslint-disable-next-line no-func-assign
FormValueSelector = connect(mapStateToProps)(FormValueSelector);

export default function FormValueSelectorContextContainer(props) {
  return (
    <ReduxFormContext.Consumer>
      {(reduxForm) => (
        <FormValueSelector
          {...props}
          form={props.form || reduxForm.form}
          sectionPrefix={props.sectionPrefix || reduxForm.sectionPrefix}
        />
      )}
    </ReduxFormContext.Consumer>
  );
}

FormValueSelectorContextContainer.propTypes = {
  form: PropTypes.string,
  sectionPrefix: PropTypes.string,
};
