import { FieldValidator } from "final-form";
import { isEmptyString } from "shared/utils/validation";
import { QuestionnaireTemplateDesignFormState } from "../../../types";

export const manualFontValidator: FieldValidator<string> = (value, allValues) => {
  if (
    (allValues as QuestionnaireTemplateDesignFormState).font === "other" &&
    isEmptyString(value)
  ) {
    return "questionnaireModule.errors.required";
  }
};

export const manualFontBoldValidator: FieldValidator<string> = (value, allValues) => {
  if (
    (allValues as QuestionnaireTemplateDesignFormState).fontBold === "other" &&
    isEmptyString(value)
  ) {
    return "questionnaireModule.errors.required";
  }
};
