import { Tooltip, useMediaQuery, useTheme, Zoom } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextInputProps } from "../TextInput.props";
import { TextField, IconButton, Popper } from "../TextInput.styled";

export const TooltipInput = ({
  errorMessage,
  error,
  infoText,
  value,
  select,
  ...props
}: TextInputProps) => {
  const { breakpoints } = useTheme();

  const smallScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <TextField
      {...props}
      select={select}
      value={value}
      error={error}
      helperText={error && errorMessage}
      InputProps={{
        ...props.InputProps,
        endAdornment: props.InputProps?.endAdornment
          ? props.InputProps?.endAdornment
          : infoText && (
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                TransitionComponent={Zoom}
                title={infoText}
                placement={smallScreen ? "bottom" : "right"}
                PopperComponent={Popper}
                arrow
              >
                <IconButton infoPlacementMode={select ? "select" : undefined} disableTouchRipple>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )
      }}
    />
  );
};
