import styled from 'styled-components';

export const ContentWrapper = styled.div`
  min-height: calc(100vh - 70px);
  padding-bottom: ${(props) => (props.dashboard ? '70px' : '40px')};
  padding-top: 56px;

  @media only screen and (min-width: 600px) {
    padding-top: 64px;
  }

  @media only screen and (max-width: ${(props) =>
    `${props.theme.navBreakpoint}px`}) {
    min-height: 100vh;
  }

  @media screen and (min-width: ${(props) => props.theme.small}) {
    padding-bottom: 30px;
  }
`;
