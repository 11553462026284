import { useMemo, memo } from "react";
import { Navigate } from "react-router-dom";
import { Permission } from "../types";
import { useAuthState } from "../hooks";

export const withAuth = <P extends object>(
  Component: React.ComponentType<P>,
  requiredPermission: Permission
): React.FC<P> => {
  const WithAuthComponent: React.FC<P> = (props) => {
    const { token, checkPermission } = useAuthState();

    const permissionGranted = useMemo(
      () => checkPermission(requiredPermission),
      [requiredPermission, checkPermission]
    );

    // Check that the page requires permission but no token found
    // Redirect to login
    if (requiredPermission && !token) {
      return (
        <Navigate
          to={{
            pathname: "/login"
          }}
          replace
        />
      );
    }

    // Check that the page requires permission but user does not have this permission
    // Redirect to dashboard
    if (!permissionGranted) {
      return (
        <Navigate
          to={{
            pathname: "/dashboard"
          }}
          replace
        />
      );
    }

    return <Component {...(props as P)} />;
  };

  return memo(WithAuthComponent);
};
