import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared";
import { getComeBackMailingTextList } from "../../api";

export const useComeBackMailingTextData = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper(
    [queryName, selectedShopId, limit, offset],
    async () => getComeBackMailingTextList(selectedShopId!, limit, offset),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const resultData = data?.items ?? [];

  return { initialState: resultData, loading: isLoading };
};
