import { styled, Box as MuiBox, IconButton as MuiIconButton } from "@mui/material";
import { ContainerProps } from "./ConditionItem.props";

export const Container = styled((props: ContainerProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "onDelete"
})(
  ({
    theme: {
      palette: { custom },
      breakpoints
    },
    onDelete
  }) => `
    &.MuiBox-root {
      position: relative;
      padding: 30px ${onDelete ? 58 : 16}px 0 16px;
      background-color: ${custom.background};
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      ${[breakpoints.up("md")]} {
        flex-direction: row;
      }
    }
  `
);

export const DeleteButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      position: absolute;
      right: 8px;
      top: 8px;
      color: ${custom.white.light};
    }
  `
);
