import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 268px;
      position: absolute;
      bottom: 23px;
      right: 20px;

      ${[breakpoints.up("sm")]} {
        right: 69px;
      }
    }
  `
);
