import { Autocomplete as MuiAutocomplete, useTheme } from "@mui/material";
import { memo, useState, useCallback, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useShopSwitchState } from "../../../hooks";
import { makePaperStyles, Switcher } from "./ShopSwitch.styles";
import { ShopData } from "../../../types";
import { Divider } from "../Divider";
import { useQueryClient } from "@tanstack/react-query";

const ShopSwitchComponent = () => {
  const theme = useTheme();

  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    currentlySelectedShop,
    loading,
    shopsList,
    onShopChange,
    shopsListLoading,
    refetchShopList
  } = useShopSwitchState();

  const handleOpen = useCallback(() => {
    setOpened(true);
    refetchShopList();
  }, [refetchShopList]);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, { id, shopId }: ShopData) => {
      onShopChange(id, shopId);
      queryClient.resetQueries({ stale: false });
    },
    [onShopChange]
  );

  if (shopsListLoading) {
    return null;
  }

  if (shopsList.length <= 1) {
    return null;
  }

  return (
    <>
      <MuiAutocomplete
        options={shopsList}
        value={currentlySelectedShop}
        open={opened}
        loading={loading}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        getOptionLabel={({ shopId, name }: ShopData) => `${shopId} - ${name}`}
        isOptionEqualToValue={(option, selected) => option.shopId === selected.shopId}
        renderInput={(params) => (
          <Switcher
            {...params}
            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
            label={t("navigation.accountMenu.shopSwitcherLabel")}
          />
        )}
        popupIcon={<UnfoldMoreIcon />}
        disableClearable
        slotProps={{
          paper: {
            sx: makePaperStyles(theme)
          }
        }}
      />
      <Divider />
    </>
  );
};

export const ShopSwitch = memo(ShopSwitchComponent);
