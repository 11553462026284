import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavigationBarDrawer } from "../NavigationBarDrawer";
import { ButtonsWrapper } from "./AccountDrawer.styles";
import { AccountDrawerProps } from "./AccountDrawer.props";
import { AccountBlock } from "../AccountBlock";
import { AccountMenuButton } from "../AccountMenuButton";
import { Divider } from "../Divider";
import {
  useQuestionnaireCompleted,
  useShopListData,
  useAccountNavigation,
  useLogout,
  useAccountNavigationConfig,
  useAuthState
} from "../../../hooks";
import { withFeatureVisibility } from "../../../HoC";
import { ShopSwitch } from "../ShopSwitch";

export const AccountDrawer = ({ onClose, open, shopId }: AccountDrawerProps) => {
  const { t } = useTranslation();

  const { data, loading } = useShopListData();

  const { pathname } = useLocation();

  const questionnaireIncomplete = !useQuestionnaireCompleted();

  const accountMenuRoutes = useAccountNavigationConfig();

  const { checkPermission } = useAuthState();

  const badgesConfig: Record<string, boolean> = {
    questionnaireIncomplete
  };

  const shopDataLoading = !loading && data && data[0];

  const displayShopId = shopDataLoading ? data[0]?.shopId?.toString() : "...";

  const handleLogout = useLogout();

  const handleNavigate = useAccountNavigation();

  const parsedButtons = useMemo(
    () =>
      accountMenuRoutes.map(
        ({ translationKey, path, badgeKey, includeShopId, requiredPermission, featureFlag }) => {
          if (requiredPermission && !checkPermission(requiredPermission)) return null;

          const active = pathname === path;

          const badge = badgeKey ? badgesConfig[badgeKey] : undefined;

          const navigateTo = {
            path,
            includeShopId
          };

          const href = includeShopId ? `${path}?shop=${shopId}` : path;

          if (featureFlag) {
            const Component = withFeatureVisibility(AccountMenuButton, featureFlag);
            return (
              <Component
                active={active}
                key={translationKey}
                href={href}
                showBadge={badge}
                onClick={handleNavigate(navigateTo)}
              >
                {t(translationKey)}
              </Component>
            );
          } else {
            return (
              <AccountMenuButton
                active={active}
                key={translationKey}
                href={href}
                showBadge={badge}
                onClick={handleNavigate(navigateTo)}
              >
                {t(translationKey)}
              </AccountMenuButton>
            );
          }
        }
      ),
    [pathname, accountMenuRoutes, badgesConfig, shopId, handleNavigate]
  );

  return (
    <NavigationBarDrawer variant="temporary" anchor="right" open={open} onClose={onClose}>
      <ButtonsWrapper>
        <AccountBlock shopId={displayShopId} />
        <Divider />
        <ShopSwitch />
        {parsedButtons}
        <Divider />
        <AccountMenuButton
          onClick={handleLogout}
          iconPosition="start"
          icon={<ExitToAppIcon viewBox={"0 0 22 22"} />}
        >
          {t("navigation.accountMenu.logout")}
        </AccountMenuButton>
      </ButtonsWrapper>
    </NavigationBarDrawer>
  );
};
