import { styled, Box as MuiBox } from "@mui/material";

export const ButtonGroupWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
  `
);

export const VoucherFieldWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    width: 100%;
    margin: 0 auto;

    ${[breakpoints.up("md")]} {
      width: 506px;
    }
`
);

export const CreateFieldsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
    }
  `
);
