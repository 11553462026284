import { FieldValidator } from "final-form";
import { isEmptyString, isSecureUrl } from "shared/utils/validation";

export const shopNameValidator: FieldValidator<string> = (value) => {
  if (isEmptyString(value)) {
    return "questionnaireModule.errors.required";
  }

  if (value.length < 2) {
    return "questionnaireModule.errors.biggerThanTwoCharacters";
  }
};

export const webUrlValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (!isSecureUrl(value)) {
    return "questionnaireModule.errors.invalidUrl";
  }
};
