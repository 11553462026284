import { apiClient } from "shared";
import { QuestionnaireAccountStepData, ShopIdParam } from "api";

export const putSubmitAccount = async ({
  shopId,
  ...values
}: QuestionnaireAccountStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/account`, values);

  return data;
};
