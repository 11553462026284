import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useShopListData } from "../useShopListData";
import { useAuthState } from "../useAuthState";

export const useSelectedShop = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Extract token from auth state
  const { token } = useAuthState();

  // And prevent shop list fetch in case if user is not logged in
  const { data, loading, refetch } = useShopListData(!!token);

  // Not relate only to query params
  const selectedShopId = searchParams.get("shop");

  // But also to shop list data, and return data only in case if shop list is loaded and selected shop are present in it
  const selectedShopValue =
    !loading && selectedShopId
      ? data?.find((shop) => shop.shopId === parseInt(selectedShopId))
      : undefined;

  const onSelectedShopChange = useCallback(
    (shopId: number) => {
      searchParams.set("shop", shopId.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return {
    selectedShopId: selectedShopValue?.shopId,
    selectedShop: selectedShopValue?.id,
    selectedShopValue: selectedShopValue,
    shopList: data,
    loading,
    onSelectedShopChange,
    refetchShopList: refetch
  };
};
