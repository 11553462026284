/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl } from 'react-intl';

import { creatable } from '../../UpStyledCreatable';
import { renderField } from '../../UpInput';
import { selectBox } from '../../SelectBox';
import FormValueSelector from '../../UpFormValueSelector';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
    /* margin-right: 10px; */
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
  @media only screen and (max-width: 680px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const UptainQuery = (props) => (
  <Filter>
    <Row>
      <Field
        name={`${props.member}.filter.parameter`}
        component={renderField}
        placeholder="Query parameter"
      />
      <Field
        name={`${props.member}.filter.operator`}
        component={selectBox}
        customProps={{
          options: [
            { value: 'empty', label: props.intl.formatMessage(messages.empty) },
            {
              value: 'not-empty',
              label: props.intl.formatMessage(messages.notEmpty),
            },
            {
              value: 'contains',
              label: props.intl.formatMessage(messages.contains),
            },
            {
              value: 'contains-not',
              label: props.intl.formatMessage(messages.containsNot),
            },
          ],
          minWidth: '130px',
          margin: '0 0 0 10px',
          onChange: () => props.resetOnChange(),
        }}
      />
    </Row>

    <FormValueSelector
      fieldName={`conditions[${props.member
        .split('[')[1]
        .charAt(0)}]filter.operator`}
    >
      {(operator) => {
        if (operator === 'contains' || operator === 'contains-not') {
          return (
            <Row margin="10px 0 0 0">
              <Field
                name={`${props.member}.filter.attribute`}
                component={creatable}
              />
            </Row>
          );
        }
      }}
    </FormValueSelector>
  </Filter>
);

UptainQuery.propTypes = {
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(UptainQuery);
