import { ReachabilityDays, ReachabilityDayErrors } from "shared";

export const findFirstError = (
  errors?: Partial<Record<ReachabilityDays | "global", ReachabilityDayErrors>>
) => {
  if (!errors) {
    return undefined;
  }

  // Ignore global error to exclude it from error display
  if (errors.global) {
    return undefined;
  }

  // Find first day with errors
  const firstDayWithError = Object.values(errors).find((error) => error.length > 0);

  // In case of error message was received from backend, day array contains exact string
  // Return this string as error
  if (typeof firstDayWithError === "string") {
    return firstDayWithError;
  }

  // Find first value inside the day that contains error
  const firstValueWithError = firstDayWithError?.find((error) => error?.length > 0);

  // Return first error that relates to the value
  return firstValueWithError?.[0];
};

export const hasError = (errors?: Partial<Record<ReachabilityDays, ReachabilityDayErrors>>) => {
  if (!errors) {
    return undefined;
  }

  return Object.keys(errors).length > 0;
};
