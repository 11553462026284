import { GetContactPersonListResponse } from "api";
import { useQueryConfig, useQueryWrapper, useSelectedShop, getContactPersonList } from "shared";

export const useContactPersonListData = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper<GetContactPersonListResponse>(
    [queryName, selectedShopId, limit, offset],
    async () => await getContactPersonList(selectedShopId!, limit, offset),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const resultData = data?.items ?? [];

  return { initialState: resultData, loading: isLoading };
};
