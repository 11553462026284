import axios from "axios";
import { localStorage } from "../localStorage";
import { LocalStorageKey } from "../../types";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: localStorage.getItem(LocalStorageKey.TOKEN) || ""
  }
}));

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(LocalStorageKey.TOKEN);
    }
    return Promise.reject(error);
  }
);

export const apiClient = instance;
