import { FieldValidator } from "final-form";
import { RevenueConditionFormValues, SettingsConditionsFormValues } from "../../../../types";

export const operatorValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }
};

export const betweenLessValidator =
  (index: number): FieldValidator<string> =>
  (value, form) => {
    if (value === undefined) {
      return "featuresModule.errors.required";
    }

    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "featuresModule.errors.shouldBeANumber";
    }

    if (numberValue >= 10000000) {
      return "featuresModule.errors.lessThan10m";
    }

    const lowerValue = (
      (form as SettingsConditionsFormValues).conditions?.[index] as RevenueConditionFormValues
    )?.betweenValue.more;

    if (lowerValue !== undefined && numberValue <= lowerValue) {
      return "featuresModule.errors.moreThanMore";
    }
  };

export const betweenMoreValidator =
  (index: number): FieldValidator<string> =>
  (value, form) => {
    if (value === undefined) {
      return "featuresModule.errors.required";
    }

    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "featuresModule.errors.shouldBeANumber";
    }

    if (numberValue >= 10000000) {
      return "featuresModule.errors.lessThan10m";
    }

    const higherValue = (
      (form as SettingsConditionsFormValues).conditions?.[index] as RevenueConditionFormValues
    )?.betweenValue.less;

    if (higherValue !== undefined && numberValue >= higherValue) {
      return "featuresModule.errors.lessThanLess";
    }
  };

export const valueValidator: FieldValidator<string> = (value) => {
  if (value === undefined) {
    return "featuresModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "featuresModule.errors.shouldBeANumber";
  }

  if (numberValue >= 10000000) {
    return "featuresModule.errors.lessThan10m";
  }
};
