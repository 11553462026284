import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  DayCircleWrapper,
  DayCircleStyles,
  DayText,
  DayWrapper,
  AddTimeIconButton
} from "./DayCircle.styles";
import { DayCircleProps } from "./DayCircle.props";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TimeRow } from "../TimeRow";

export const DayCircle = ({
  active,
  disabled,
  error,
  selected,
  day,
  values,
  selectedIndex,
  errors,
  onDayClick,
  onValueClick,
  onValueDeleteClick,
  onValueAddClick,
  onFocus,
  onBlur
}: DayCircleProps) => {
  const { t } = useTranslation();

  const handleDayClick = useCallback(() => {
    onDayClick(day);
  }, [day, onDayClick]);

  const handleValueClick = useCallback(
    (index: number) => {
      onValueClick(day, index);
    },
    [day, onValueClick]
  );

  const handleValueAdd = useCallback(() => {
    onValueAddClick(day);
  }, [onValueAddClick]);

  const handleValueDelete = useCallback(() => {
    if (selectedIndex === null) return;

    onValueDeleteClick(day, selectedIndex);
  }, [day, selectedIndex, onValueDeleteClick]);

  const errorsPresent = errors !== undefined && Object.keys(errors).length > 0;

  return (
    <DayWrapper>
      <DayCircleWrapper active={active} disabled={disabled} error={error} selected={selected}>
        <DayCircleStyles
          onClick={handleDayClick}
          onFocus={onFocus}
          onBlur={onBlur}
          active={active}
          disabled={disabled}
          error={error}
          selected={selected}
        >
          <DayText active={active} disabled={disabled} error={error} selected={selected}>
            {t(`questionnaireModule.form.reachability.${day}`)}
          </DayText>
        </DayCircleStyles>
      </DayCircleWrapper>
      {values.map(({ start, end }, index) => (
        <TimeRow
          key={`${day}/${index}`}
          start={start}
          end={end}
          disabled={disabled}
          selected={selectedIndex === index && selected}
          error={errorsPresent && errors[index] && errors[index].length > 0}
          index={index}
          onClick={handleValueClick}
          onDeleteClick={handleValueDelete}
        />
      ))}
      <AddTimeIconButton disabled={disabled} onClick={handleValueAdd}>
        <AddCircleOutlineIcon />
      </AddTimeIconButton>
    </DayWrapper>
  );
};
