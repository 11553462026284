import { styled, Typography as MuiTypography, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 20px 16px;
      background-color: ${custom.background};
    }
  `
);

export const Typography = styled(MuiTypography)(
  ({
    theme: {
      typography: { body2 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body2.mobile.fontSize};
      line-height: ${body2.mobile.lineHeight};
      font-weight: ${body2.mobile.fontWeight};
      color: ${custom.white.light};
    }
  `
);

export const Line = styled(MuiBox)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      flex: 1;
      height: 1px;
      background-color: ${custom.white.light};
      margin-right: 11px;
      margin-left: 15px;

      ${[breakpoints.up("md")]} {
        margin-right: 0;
      }
    }
  `
);
