import { CheckboxWrapperProps } from "./Checkbox.props";
import { Checkbox as StyledCheckbox, CheckboxTypography, LabelWrapper } from "./Checkbox.styles";

export const Checkbox = ({ label, disabled, marginBottom, ...props }: CheckboxWrapperProps) => {
  return (
    <LabelWrapper
      marginBottom={marginBottom}
      control={<StyledCheckbox {...props} />}
      disabled={disabled}
      label={<CheckboxTypography disabled={disabled}>{label}</CheckboxTypography>}
    />
  );
};
