import { apiClient } from "shared";
import { SetPasswordResponse, SetPasswordRequest } from "api";

export const postSetPassword = async (values: SetPasswordRequest) => {
  const { data, headers } = await apiClient.post<SetPasswordResponse>(
    "/user/password/set-new",
    values
  );

  return { ...data, headers };
};
