import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared";
import { getComeBackMailingConfig } from "../../api";

export const useComeBackMailingConfigData = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading: configLoading } = useQueryWrapper(
    [queryName, selectedShopId],
    async () => getComeBackMailingConfig(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { initialState: data, loading: configLoading };
};
