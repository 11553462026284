import { useCallback, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuthState } from "../../../../hooks";
import { NavigationBarButtonStyles } from "../NavigationBarButton.styles";
import { NavigationButtonWithoutSubroutesProps } from "../../../../types";

export const NavigationBarButtonWithoutSubroutes = ({
  translationKey,
  path,
  onClick,
  shopId,
  requiredPermission
}: NavigationButtonWithoutSubroutesProps) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const { checkPermission } = useAuthState();

  const handleRouteClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick(path);
    },
    [onClick, path]
  );

  const href = path.includeShopId ? `${path.path}?shop=${shopId}` : path.path;

  if (requiredPermission && !checkPermission(requiredPermission)) {
    return null;
  }

  return (
    <NavigationBarButtonStyles
      href={href}
      onClick={handleRouteClick}
      selected={pathname === path.path}
    >
      {t(translationKey)}
    </NavigationBarButtonStyles>
  );
};
