import { Box as MuiBox, styled, Badge as MuiBadge } from "@mui/material";
import { AccountButtonProps } from "./AccountMenuButton.props";
import { TextButton } from "../../form";

export const AccountMenuButton = styled(
  ({
    showBadge,
    children,
    onClick,
    icon,
    iconPosition,
    active,
    href,
    ...props
  }: AccountButtonProps) => (
    <MuiBox {...props}>
      <MuiBadge variant="dot" invisible={!showBadge}>
        <TextButton
          disabled={active}
          icon={icon}
          href={href}
          iconPosition={iconPosition}
          onClick={onClick}
          variant="secondary"
        >
          {children}
        </TextButton>
      </MuiBadge>
    </MuiBox>
  )
)(
  ({
    theme: {
      palette: { custom }
    },
    active
  }) => {
    const color = active ? custom.turquoise.main : custom.white.light;

    return `
      &.MuiBox-root {
        & .MuiBadge-root {
          & .MuiButtonBase-root {
            text-transform: initial;
            font-weight: normal;
            color: ${color};

            &:active {
              color: ${custom.white.main};
            }
          }

          & .MuiBadge-dot {
            background-color: ${custom.coral.main};
            top: 10px;
            right: 4px;
          }
        }
      }
    `;
  }
);
