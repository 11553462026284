import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      z-index: 100;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 127px;
      background-color: ${custom.background}59;
      backdrop-filter: blur(10px);
      padding: 8px 0 40px;
    }
  `
);

export const CirclesWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 18px;
    }
  `
);

export const CircleWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin: 0 12px;

      & > .MuiBox-root {
        margin: 0;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  `
);

export const ButtonsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2px;
      align-items: center;
      width: 100%;
      padding: 0 6px;
      top: 42px;
    }
  `
);

export const StepName = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { button }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.turquoise.main};
      font-size: ${button.mobile.fontSize};
      font-weight: normal;
      line-height: ${button.mobile.lineHeight};
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      padding: 0 119px;
    }
  `
);

export const StepNumber = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiTypography-root {
      margin-top: 4px;
      color: ${custom.white.main};
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: ${body2.mobile.lineHeight};
      text-align: center;
    }
  `
);
