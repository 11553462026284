import { useCallback, SyntheticEvent, MouseEvent, FocusEvent, FocusEventHandler } from "react";
import { Form as FinalForm, Field, FormSpy, FormProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormApi } from "final-form";
import { LegacyVoucherTypes } from "api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TitleInput,
  FieldLine,
  TextInput,
  SelectDropdown,
  ButtonsWrapper,
  Switch,
  Tile,
  ContainedButton,
  Alert,
  IconButton,
  Checkbox,
  Divider,
  VoucherListSelect,
  VoucherTypeSelectionWrapper,
  FeatureVisibilityWrapper
} from "shared";
import { LEGACY_CURRENCIES } from "config";
import VoucherCode from "assets/images/voucher_code.png";
import VoucherList from "assets/images/voucher_list.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { VoucherFieldWrapper } from "./VoucherSettingsFormBase.styles";
import { ContactPersonSelect } from "../ContactPersonSelect";
import {
  BrowseAbandonmentVoucherSettingsValues,
  BrowseAbandonmentVoucherInitialSettingsValues,
  ComeBackMailingVoucherSettingsValues,
  ComeBackMailingVoucherInitialSettingsValues
} from "../../types";
import { TriggerSettingsParamsFields } from "../TriggerSettingsParamsFields";
import { SubmitButtonWrapper } from "../TriggerSettingsFormStyles";
import {
  titleValidator,
  contactPersonValidator,
  voucherCodeValidator,
  voucherCurrencyValidator,
  voucherListNameValidator,
  voucherValueValidator,
  voucherFootnoteValidator
} from "./VoucherSettingsFormBase.validation";
import { VoucherSettingsFormBaseProps } from "./VoucherSettingsFormBase.props";
import { TriggerSettingsConditionsFields } from "../TriggerSettingsConditionsFields";

const Form = FinalForm<
  BrowseAbandonmentVoucherSettingsValues | ComeBackMailingVoucherSettingsValues,
  BrowseAbandonmentVoucherInitialSettingsValues | ComeBackMailingVoucherInitialSettingsValues
>;

export const VoucherSettingsFormBase = ({
  initialState,
  formOpened,
  expandIcon,
  localizationKey,
  conditionFeatureName,
  possibleConditions,
  conditionsNotificationKey,
  onOpen,
  onClose,
  onDelete,
  onSubmit,
  onSwitchActive
}: VoucherSettingsFormBaseProps) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(
    (
        form: FormApi<BrowseAbandonmentVoucherSettingsValues | ComeBackMailingVoucherSettingsValues>
      ) =>
      (event: SyntheticEvent) => {
        event.stopPropagation();
        if (onDelete) onDelete(initialState.id, form);
      },
    [initialState, onDelete]
  );

  const handleActivationSwitch = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      if (onSwitchActive) onSwitchActive(initialState.id, !initialState.enabled);
    },
    [initialState, onSwitchActive]
  );

  const handleFormOpen = useCallback(() => {
    if (!formOpened && onOpen) onOpen();
    if (formOpened && onClose) onClose();
  }, [formOpened, onOpen]);

  const handleTitleFocus = useCallback(
    (onFocus: FocusEventHandler<HTMLInputElement>) => (e: FocusEvent<HTMLInputElement>) => {
      if (!formOpened && onOpen) {
        onOpen();
      }
      onFocus(e);
    },
    [formOpened, onOpen]
  );

  const handleTitleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const handleFormSubmit: FormProps<
    BrowseAbandonmentVoucherSettingsValues | ComeBackMailingVoucherSettingsValues,
    BrowseAbandonmentVoucherInitialSettingsValues | ComeBackMailingVoucherInitialSettingsValues
  >["onSubmit"] = useCallback(
    (values, form) => {
      return onSubmit(values, form);
    },
    [onSubmit]
  );

  const displayConditions = conditionFeatureName && possibleConditions;

  return (
    <Form
      initialValues={initialState}
      onSubmit={handleFormSubmit}
      render={({ submitErrors, handleSubmit, submitting, dirty, form }) => (
        <Accordion expanded={formOpened} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={expandIcon} onClick={handleFormOpen}>
            <Field
              name="title"
              validate={titleValidator}
              render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                <TitleInput
                  placeholder={t(
                    `featuresModule.${localizationKey}.form.voucherSettings.title.placeholder`
                  )}
                  {...input}
                  onClick={handleTitleClick}
                  onFocus={handleTitleFocus(input.onFocus)}
                  error={
                    (error || (submitErrors?.["title"] && !modifiedSinceLastSubmit)) && touched
                  }
                />
              )}
            />
            <ButtonsWrapper>
              {onSwitchActive && (
                <Switch checked={initialState.enabled} onClick={handleActivationSwitch} />
              )}
              {onDelete && (
                <IconButton disabled={submitting} onClick={handleDelete(form)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ButtonsWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <FieldLine>
              <Field
                name="voucherValue"
                validate={voucherValueValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t(`featuresModule.${localizationKey}.form.voucherSettings.value.label`)}
                    placeholder={t(
                      `featuresModule.${localizationKey}.form.voucherSettings.value.placeholder`
                    )}
                    value={value ? value : ""}
                    onChange={(value) => {
                      const numericOnly = value.replace(/[^0-9.]/g, "");
                      const roundedDecimals = numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

                      onChange(roundedDecimals);
                    }}
                    onBlur={() => {
                      onChange(Number(value));
                      onBlur();
                    }}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["voucherValue"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["voucherValue"] ?? error)}
                  />
                )}
              />
              <Field
                name="voucherCurrency"
                validate={voucherCurrencyValidator}
                type="select"
                render={({
                  input: { value, onBlur, onChange, onFocus },
                  meta: { touched, error, modifiedSinceLastSubmit }
                }) => (
                  <SelectDropdown
                    required
                    options={LEGACY_CURRENCIES}
                    label={t(
                      `featuresModule.${localizationKey}.form.voucherSettings.currency.label`
                    )}
                    placeholder={t(
                      `featuresModule.${localizationKey}.form.voucherSettings.currency.placeholder`
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["voucherCurrency"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["voucherCurrency"] ?? error)}
                  />
                )}
              />
            </FieldLine>
            <Field
              name="contactPerson"
              validate={contactPersonValidator}
              type="select"
              allowNull
              render={({
                input: { value, onBlur, onChange, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <ContactPersonSelect
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  required
                  marginBottom={24}
                  error={
                    (error || (submitErrors?.["contactPerson"] && !modifiedSinceLastSubmit)) &&
                    touched
                  }
                  errorMessage={t(submitErrors?.["contactPerson"] ?? error)}
                />
              )}
            />
            <Divider />
            <Field
              name="voucherType"
              render={({ input: { onChange, value } }) => (
                <VoucherTypeSelectionWrapper>
                  <Tile
                    value={LegacyVoucherTypes.CODE}
                    label={t(
                      `featuresModule.${localizationKey}.form.voucherSettings.voucherType.codeLabel`
                    )}
                    icon={VoucherCode}
                    onClick={onChange}
                    active={value === LegacyVoucherTypes.CODE}
                    width={260}
                    iconParams={{
                      width: 126,
                      height: 79
                    }}
                  />
                  <Tile
                    value={LegacyVoucherTypes.LIST}
                    label={t(
                      `featuresModule.${localizationKey}.form.voucherSettings.voucherType.listLabel`
                    )}
                    icon={VoucherList}
                    onClick={onChange}
                    active={value === LegacyVoucherTypes.LIST}
                    width={260}
                    iconParams={{
                      width: 126,
                      height: 79
                    }}
                  />
                </VoucherTypeSelectionWrapper>
              )}
            />
            <FormSpy
              subscription={{ values: true }}
              render={({ values }) => {
                if (values.voucherType === LegacyVoucherTypes.CODE) {
                  return (
                    <>
                      <Field
                        name="voucherCode"
                        validate={voucherCodeValidator}
                        render={({
                          input: { value, onBlur, onChange, onFocus },
                          meta: { error, modifiedSinceLastSubmit, touched }
                        }) => (
                          <VoucherFieldWrapper>
                            <TextInput
                              required
                              label={t(
                                `featuresModule.${localizationKey}.form.voucherSettings.code.label`
                              )}
                              placeholder={t(
                                `featuresModule.${localizationKey}.form.voucherSettings.code.placeholder`
                              )}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                              marginBottom={24}
                              error={
                                (error ||
                                  (submitErrors?.["voucherCode"] && !modifiedSinceLastSubmit)) &&
                                touched
                              }
                              errorMessage={t(submitErrors?.["voucherCode"] ?? error)}
                            />
                          </VoucherFieldWrapper>
                        )}
                      />
                      <Field
                        name="isVoucherExclusive"
                        type="checkbox"
                        render={({
                          input: { checked, onBlur, onChange, onFocus },
                          meta: { error, modifiedSinceLastSubmit, touched }
                        }) => (
                          <VoucherFieldWrapper>
                            <Checkbox
                              label={t(
                                `featuresModule.${localizationKey}.form.voucherSettings.uptainExclusive.label`
                              )}
                              marginBottom={24}
                              checked={checked}
                              onChange={onChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                              error={
                                (error ||
                                  (submitErrors?.["voucherCode"] && !modifiedSinceLastSubmit)) &&
                                touched
                              }
                            />
                          </VoucherFieldWrapper>
                        )}
                      />
                    </>
                  );
                }
              }}
            />
            <FormSpy
              subscription={{ values: true }}
              render={({ values }) => {
                if (values.voucherType === "LIST") {
                  return (
                    <Field
                      name="voucherListName"
                      validate={voucherListNameValidator}
                      render={({ input, meta: { modifiedSinceLastSubmit, touched, error } }) => (
                        <VoucherFieldWrapper>
                          <VoucherListSelect
                            {...input}
                            required
                            marginBottom={24}
                            error={
                              (error ||
                                (submitErrors?.["voucherListName"] && !modifiedSinceLastSubmit)) &&
                              touched
                            }
                            errorMessage={t(submitErrors?.["voucherListName"] ?? error)}
                          />
                        </VoucherFieldWrapper>
                      )}
                    />
                  );
                }
              }}
            />
            <Alert severity="info">
              {t(`featuresModule.${localizationKey}.form.voucherSettings.voucherInfo`)}
            </Alert>
            <Field
              name="voucherFootnote"
              validate={voucherFootnoteValidator}
              render={({
                input: { value, onBlur, onChange, onFocus },
                meta: { modifiedSinceLastSubmit, touched, error }
              }) => (
                <TextInput
                  label={t(`featuresModule.${localizationKey}.form.voucherSettings.footnote.label`)}
                  placeholder={t(
                    `featuresModule.${localizationKey}.form.voucherSettings.footnote.placeholder`
                  )}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  marginTop={24}
                  marginBottom={24}
                  error={
                    (error || (submitErrors?.["voucherFootnote"] && !modifiedSinceLastSubmit)) &&
                    touched
                  }
                  errorMessage={t(submitErrors?.["voucherFootnote"] ?? error)}
                />
              )}
            />
            <Divider />
            <TriggerSettingsParamsFields />
            {displayConditions && (
              <FeatureVisibilityWrapper requiredFeature={conditionFeatureName}>
                <Divider />
                <TriggerSettingsConditionsFields
                  possibleConditions={possibleConditions}
                  notificationKey={conditionsNotificationKey}
                />
              </FeatureVisibilityWrapper>
            )}
            <SubmitButtonWrapper>
              <ContainedButton disabled={!dirty} loading={submitting} onClick={handleSubmit}>
                {t(`featuresModule.saveButton`)}
              </ContainedButton>
            </SubmitButtonWrapper>
          </AccordionDetails>
        </Accordion>
      )}
    ></Form>
  );
};
