import { MouseEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StepperSubTabWrapper, StepperSubTabCheckIcon } from "./StepperSubTab.styles";
import { StepperSubTabProps } from "./StepperSubTab.props";

export const StepperSubTab = ({
  type,
  title,
  selected,
  completed,
  disabled,
  onClick
}: StepperSubTabProps) => {
  const { t } = useTranslation();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (onClick) {
        onClick(type);
      }
    },
    [onClick, type]
  );

  return (
    <StepperSubTabWrapper
      selected={selected}
      completed={completed}
      disabled={disabled}
      onClick={handleClick}
    >
      {t(title)}
      {completed && <StepperSubTabCheckIcon />}
    </StepperSubTabWrapper>
  );
};
