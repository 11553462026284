import { apiClient } from "shared";
import { BrowseAbandonmentTextSettings } from "api";
import { BrowseAbandonmentTextSettingsPayload } from "../../types";

export const postBrowseAbandonmentText = async ({
  shopId,
  ...values
}: BrowseAbandonmentTextSettingsPayload & { shopId: number }) => {
  const { data } = await apiClient.post<BrowseAbandonmentTextSettings>(
    `/${shopId}/triggers/browse-abandonment/text-settings`,
    values
  );

  return data;
};
