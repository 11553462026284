import { Badge, IconButton, styled } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { AccountButtonProps } from "./AccountButton.props";

export const AccountButton = styled(({ showBadge, active, ...props }: AccountButtonProps) => (
  <IconButton {...props} disableRipple>
    <Badge variant="dot" invisible={!showBadge}>
      <AccountCircleIcon />
    </Badge>
    <Chevron
      sx={{
        transition: "transform 0.1s ease-in-out",
        transform: `rotate(${active ? "180" : "0"}deg)`
      }}
    />
  </IconButton>
))(
  ({
    theme: {
      palette: { custom }
    },
    active
  }) => {
    const color = active ? custom.turquoise.main : custom.white.main;

    return `
      color: ${color};

      & .MuiBadge-root {

        & .MuiBadge-dot {
          background-color: ${custom.coral.main};
          top: 4px;
          right: 4px;
        }
      }
    `;
  }
);
