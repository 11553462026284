import { styled, Typography as MuiTypography, Box as MuiBox } from "@mui/material";

export const HintTypography = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1, h4 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      margin: 20px 0 40px;
      font-size: ${body1.mobile.fontSize};
      line-height: ${h4.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      color: ${custom.white.main};

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${h4.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${h4.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    }
  `
);

export const TestButtonWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      justify-content: center;
      margin: 0 auto 8px;
      width: 240px;

      ${[breakpoints.up("md")]} {
        justify-content: flex-start;
        margin: 0 0 8px;
      }
    }
  `
);
