export enum EncryptionType {
  TLS = "tls",
  SSL = "ssl"
}

export enum EmailVendor {
  Google = "google",
  Namecheap = "namecheap",
  Strato = "strato",
  Outlook = "outlook",
  Ionos = "ionos",
  Mailbox = "mailbox",
  Other = "other",
  Uptain = "uptain"
}

export type QuestionnaireMailSettingsStepData = {
  host?: string;
  port?: number;
  username?: string;
  password?: string;
  encryption?: EncryptionType;
  senderName?: string;
  senderAddress?: string;
  alternativeEmail?: string;
  vendor?: EmailVendor;
};
