import Immutable from 'immutable';
import { CHECK_AUTH_STATUS, START_AUTH } from './actions';
import { DELETE_DATA } from '../../../actionTypes';

function authReducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case START_AUTH: {
      return state.mergeIn(
        [action.shopId, action.integrationType, action.id],
        Immutable.fromJS({
          success: true,
          loading: false,
          error: '',
          ...action.data.data,
        }),
      );
    }
    case `${START_AUTH}_LOADING`: {
      return state.mergeIn(
        [action.shopId, action.integrationType, action.id],
        Immutable.fromJS({
          success: false,
          loading: true,
          error: '',
        }),
      );
    }
    case `${START_AUTH}_ERROR`: {
      return state.mergeIn(
        [action.shopId, action.integrationType, action.id],
        Immutable.fromJS({
          success: false,
          loading: false,
          error: action.errorMessage,
        }),
      );
    }

    case CHECK_AUTH_STATUS: {
      return state.mergeIn(
        [action.shopId, action.integrationType, action.id],
        Immutable.fromJS({
          status: action.data.data.status,
          error: action.data.data.error,
          redirectUrl: undefined,
        }),
      );
    }

    case `${CHECK_AUTH_STATUS}_ERROR`: {
      return state.mergeIn(
        [action.shopId, action.integrationType, action.id],
        Immutable.fromJS({
          status: 'error',
        }),
      );
    }

    case DELETE_DATA: {
      if (action.field === 'integrations') {
        return state.deleteIn([
          action.shopId,
          action.pathInObject,
          action.index,
        ]);
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

export default authReducer;
