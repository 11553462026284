import React, { useMemo, memo, FC } from "react";
import { useOpenFeatureFlag } from "../hooks";

export const withFeatureVisibility = <P extends object>(
  Component: React.ComponentType<P>,
  requiredFeature: string
): FC<P> => {
  const WithFeatureVisibilityComponent: FC<P> = (props) => {
    const { value } = useOpenFeatureFlag(requiredFeature);

    const componentHidden = useMemo(() => value !== true, [value]);

    if (componentHidden) {
      return null;
    }

    return <Component {...(props as P)} />;
  };

  return memo(WithFeatureVisibilityComponent);
};
