import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const Container = styled(MuiBox)(
  ({ theme: { palette } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      padding: 25px;
      background-color: ${palette.custom.grey.dark};
      border-radius: 5px;
      margin-bottom: 25px;
    }
  `
);

export const Title = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body1.mobile.fontSize};
      font-weight: ${body1.mobile.fontWeight};
      line-height: ${body1.mobile.lineHeight};
      color: ${custom.white.main};
      margin-bottom: 25px;
    }
  `
);
