import styled from 'styled-components';

const UpH3 = styled.h3`
  font-family: 'Exo 2', sans-serif;
  text-align: left;
  margin: 10px 10px 10px 0px;
  font-size: 20px;
  display: inline-block;
  flex-grow: 2;
`;

export default UpH3;
