import styled from 'styled-components';

import UpButton from '../../components/UpButton';
import UpFlexRow from '../../components/UpFlexRow';

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0 15px;
`;

export const Row = styled(UpFlexRow)`
  flex-direction: column;
  @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    flex-direction: row;
  }
  > input {
    margin-right: 0px;
    @media screen and (min-width: ${(props) => props.theme.xsmall}) {
      margin-right: 10px;
    }
  }
`;

export const Button = styled(UpButton)`
  display: block;
  text-transform: uppercase;
  margin: 20px auto 30px;
`;
