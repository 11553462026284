import { useCallback, useMemo } from "react";
import { FONT_FAMILIES } from "config";
import { QuestionnaireStepType, QuestionnaireTemplateDesignStepData } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitTemplateDesign } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { QuestionnaireTemplateDesignFormState } from "../../types";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { templateDesignFormMapper } from "./useTemplateDesignStep.helpers";

export const useTemplateDesignStep = () => {
  const extendedFontFamilies = [...FONT_FAMILIES, { key: "other", title: "Other/manual input" }];

  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.TEMPLATE_DESIGN);

  const data = useMemo(() => {
    const stepValues = stepData?.data as QuestionnaireTemplateDesignStepData | undefined;

    if (!stepValues) return undefined;

    const font = stepValues.font ? stepValues.font : "Arial";

    const fontBold = stepValues.fontBold ? stepValues.fontBold : "Arial";

    const manualFont = font && !extendedFontFamilies.find(({ key }) => key === font);

    const manualFontBold = fontBold && !extendedFontFamilies.find(({ key }) => key === fontBold);

    return {
      ...stepValues,
      font: manualFont ? "other" : font,
      fontBold: manualFontBold ? "other" : fontBold,
      manualFont: manualFont ? font : "",
      manualFontBold: manualFontBold ? fontBold : ""
    };
  }, [stepData?.data]);

  const metadata = stepData?.metadata;

  const activationTemplatesVisible = metadata?.config?.displayActivationPopupsPreview;

  const newsletterFormsVisible = metadata?.config?.displayNewsletterFormsPreview;

  const submitTemplateDesign =
    useQuestionnaireSubmitMutation<QuestionnaireTemplateDesignStepData>(putSubmitTemplateDesign);

  const executeSubmitTemplateDesign: FormProps<
    QuestionnaireTemplateDesignFormState,
    QuestionnaireTemplateDesignFormState
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const templateDesignSubmitData = templateDesignFormMapper(formValues);

        await submitTemplateDesign(templateDesignSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitTemplateDesign]
  );

  return {
    data,
    hasPrev: !!prevStep,
    activationTemplatesVisible,
    newsletterFormsVisible,
    extendedFontFamilies,
    handlePrevStep,
    executeSubmitTemplateDesign
  };
};
