import { useCallback, useState } from "react";
import { Features } from "api";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { VoucherSettingsFormBase } from "../VoucherSettingsFormBase";
import { ComeBackMailingVoucherInitialSettingsValues } from "../../types";
import {
  useDisableComeBackMailingVoucher,
  useEnableComeBackMailingVoucher,
  useUpdateComeBackMailingVoucher,
  useDeleteComeBackMailingVoucher
} from "../../hooks";

export const ComeBackMailingVoucherItemForm = ({
  initialState
}: {
  initialState: ComeBackMailingVoucherInitialSettingsValues;
}) => {
  const [opened, setOpened] = useState(false);

  const submitMethod = useUpdateComeBackMailingVoucher();

  const deleteMethod = useDeleteComeBackMailingVoucher();

  const enableMethod = useEnableComeBackMailingVoucher();

  const disableMethod = useDisableComeBackMailingVoucher();

  const handleActivationSwitch = useCallback(
    (id: number, value: boolean) => {
      if (value) {
        enableMethod(id);
      } else {
        disableMethod(id);
      }
    },
    [enableMethod, disableMethod]
  );

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <VoucherSettingsFormBase
      localizationKey="comeBackMailing"
      conditionFeatureName="come_back_mailing_conditions_enabled"
      possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.COME_BACK_MAILING]}
      initialState={initialState}
      formOpened={opened}
      expandIcon={<Chevron />}
      onOpen={handleOpen}
      onClose={handleClose}
      onSubmit={submitMethod}
      onDelete={deleteMethod}
      onSwitchActive={handleActivationSwitch}
    />
  );
};
