import { IconButton as MuiIconButton, styled, Tooltip as MuiTooltip } from "@mui/material";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import { ReprocessStatusIconProps } from "./ReprocessStatusIcon.props";

export const ReprocessStatusIcon = styled(
  (props: ReprocessStatusIconProps) => (
    <MuiTooltip title={props.tooltip} disableFocusListener enterTouchDelay={0} arrow>
      <MuiIconButton {...props} disableTouchRipple>
        <AccessTimeFilledRoundedIcon />
      </MuiIconButton>
    </MuiTooltip>
  ),
  {
    shouldForwardProp: (prop) => !["scheduled", "tooltip"].includes(prop as string)
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    scheduled
  }) => {
    const color = scheduled ? custom.turquoise.main : custom.white.dark;

    return `
        &.MuiIconButton-root {
          color: ${color};
          width: 24px;
          height: 24px;
          padding: 0;
        }
      `;
  }
);
