import { useCallback, useState, MouseEvent } from "react";
import { Fade } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { TileProps } from "./Tiles.props";
import {
  Button,
  Check,
  Uncheck,
  Label,
  Img,
  InfoButton,
  InfoBox,
  InfoBoxText,
  Wrapper
} from "./Tiles.styles";

export const Tile = ({
  icon,
  onClick,
  value,
  active,
  iconParams,
  width,
  height,
  label,
  preserveCheck,
  infoText
}: TileProps) => {
  const [infoActive, setInfoActive] = useState(false);

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const handleInfoClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setInfoActive((state) => !state);
  }, []);

  return (
    <Wrapper width={width} height={height}>
      <Button onClick={handleClick} active={active} disabled={infoActive}>
        <Img src={icon} active={active} {...iconParams} />
        {label && <Label>{label}</Label>}
      </Button>
      {infoText && (
        <InfoButton onClick={handleInfoClick}>
          <InfoOutlined />
        </InfoButton>
      )}
      {infoText && (
        <Fade unmountOnExit in={infoActive}>
          <InfoBox>
            <InfoBoxText>{infoText}</InfoBoxText>
          </InfoBox>
        </Fade>
      )}
      {active ? (
        <Check onClick={handleClick} />
      ) : preserveCheck ? (
        <Uncheck onClick={handleClick} />
      ) : null}
    </Wrapper>
  );
};
