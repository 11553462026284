import { memo, useCallback, useEffect, useRef } from "react";
import { WysiwygFrame } from "./Wysiwyg.styles";
import { prepareHtml } from "./summernote.helper";
import { WysiwygProps, SummernoteEvent, SummernoteCustomEventType } from "./Wysiwyg.props";

const Component = ({ value, index, language, onChange, onCodeViewChange }: WysiwygProps) => {
  const ref = useRef<HTMLIFrameElement>(null);

  const handleChange = useCallback(
    ({ data }: SummernoteEvent) => {
      const { index: eventIndex, type: eventType } = data;

      if (eventIndex !== index || !eventType) return;

      switch (eventType) {
        case SummernoteCustomEventType.CHANGE:
          onChange(data.value);
          break;
        case SummernoteCustomEventType.CODE_VIEW_TOGGLE:
          onCodeViewChange(data.state);
          break;
        default:
          // default case never should happen
          var exhaustiveCheck: never = eventType as never;
          throw new Error(exhaustiveCheck);
      }
    },
    [index, onChange, onCodeViewChange]
  );

  const buildComponent = () => {
    const html = prepareHtml(index, language, value.replace(/\n/g, "\\n").replace(/"/g, '\\"'));

    if (!ref.current?.contentWindow) return;

    ref.current.contentWindow.document.write(html);
  };

  useEffect(() => {
    window.addEventListener("message", handleChange);
    buildComponent();

    return () => {
      window.removeEventListener("message", handleChange);
    };
  }, []);

  return <WysiwygFrame ref={ref} />;
};

export const Wysiwyg = memo(Component, () => false);
