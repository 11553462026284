import {
  styled,
  Box as MuiBox,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  TextField as MuiTextField,
  IconButton as MuiIconButton
} from "@mui/material";

export const Accordion = styled(MuiAccordion)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiAccordion-root {
      background-color: ${custom.grey.light};
      width: 100%;

      &.Mui-expanded {
        margin: 0;
      }

      &:first-of-type {
        border-radius: 5px 5px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 5px 5px;
      }
    }
  `
);

export const AccordionSummary = styled(MuiAccordionSummary)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiAccordionSummary-root {
      color: ${custom.white.main};
      background-color: ${custom.grey.light};
      border-radius: 5px;
      padding: 0 25px;

      & .MuiAccordionSummary-expandIconWrapper {
        color: ${custom.white.main};
      }

      & .MuiAccordionSummary-content {
        margin: 12px 0;
        display: flex;
        align-items: center;
      }

      &.Mui-expanded { 
        border-radius: 5px 5px 0 0;

        & .MuiAccordionSummary-content {
          margin: 12px 0;
        }
      }
    }
  `
);

export const AccordionDetails = styled(MuiAccordionDetails)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiAccordionDetails-root {
      padding: 55px 25px 35px;
      background-color: ${custom.grey.main};
      border-radius: 0 0 5px 5px;
      display: flex;
      flex-direction: column;
    }
  `
);

export const FieldLine = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      justify-content: initial;
      
      ${[breakpoints.up("md")]} {
        flex-direction: row;
        justify-content: space-between;
      }

      & > .MuiBox-root {
        flex: 1;

        &:first-of-type {
          margin-right: 0;

          ${[breakpoints.up("md")]} {
            margin-right: 24px;
          }
        }
      }
    }
  `
);

export const TitleInput = styled(MuiTextField)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiFormControl-root {
      flex: 1;
      max-width: 50%;
      
      & .MuiInputBase-input {
        color: ${custom.white.main};
        padding: 0;
      }

      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      & .Mui-error {
        & .MuiInputBase-input {
          color: ${custom.coral.main};

          &::placeholder {
            opacity: 1;
          }
        }
      }
    }
  `
);

export const ButtonsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin: 0 0 0 auto;
    }
  `
);

export const IconButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root { 
      margin-right: 8px;
      color: ${custom.white.main};

      &.Mui-disabled {
        color: ${custom.white.dark};
      }
    }
  `
);

export const VoucherTypeSelectionWrapper = styled(MuiBox)(
  () => `
    margin: 60px 0 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > .MuiBox-root {
      &:first-of-type {
        margin-right: 4px;
      }

      &:last-of-type {
        margin-left: 4px;
      }
    }
  `
);
