import { useCallback } from "react";
import { QuestionnaireReferrerStepData, QuestionnaireStepType, ReferrerSources } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitReferrer } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useReferrerStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.REFERRER);

  const data = stepData?.data;

  const submitReferrer =
    useQuestionnaireSubmitMutation<QuestionnaireReferrerStepData>(putSubmitReferrer);

  const executeSubmitReferrer: FormProps<
    QuestionnaireReferrerStepData,
    QuestionnaireReferrerStepData
  >["onSubmit"] = useCallback(
    async ({ additionalReferrerSource }) => {
      try {
        await submitReferrer({
          referrer: ReferrerSources.OTHER,
          additionalReferrerSource: additionalReferrerSource.trim()
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitReferrer]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitReferrer
  };
};
