import { apiClient } from "shared";
import { PaymentSettings } from "api";

export const getBrowseAbandonmentPayment = async (shopId: number) => {
  const { data } = await apiClient.get<PaymentSettings>(
    `${shopId}/triggers/browse-abandonment/payment`
  );

  return data;
};
