import { KeyTitlePair } from "shared";
import { FieldValidator } from "final-form";

export const operatorValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }
};

export const valueValidator: FieldValidator<KeyTitlePair[]> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  if (value.length === 0) {
    return "featuresModule.errors.required";
  }
};
