import styled from 'styled-components';
import PropTypes from 'prop-types';

import { sharedButtonStyles } from './styles';

const Button = styled.button`
  ${sharedButtonStyles}

  &:disabled {
    background: #f9b0a6;
  }
`;

Button.propTypes = {
  float: PropTypes.string,
  width: PropTypes.string,
};

export default Button;
