import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Maven Pro",
    h1: {
      desktop: {
        fontSize: "60px",
        lineHeight: "55px",
        fontWeight: "bold"
      },
      tablet: {
        fontSize: "60px",
        lineHeight: "55px",
        fontWeight: "bold"
      },
      mobile: {
        fontSize: "60px",
        lineHeight: "55px",
        fontWeight: "bold"
      }
    },
    h2: {
      desktop: {
        fontSize: "47px",
        lineHeight: "55px",
        fontWeight: "bold"
      },
      tablet: {
        fontSize: "47px",
        lineHeight: "55px",
        fontWeight: "bold"
      },
      mobile: {
        fontSize: "47px",
        lineHeight: "55px",
        fontWeight: "bold"
      }
    },
    h3: {
      desktop: {
        fontSize: "28px",
        lineHeight: "38px",
        fontWeight: "bold"
      },
      tablet: {
        fontSize: "28px",
        lineHeight: "38px",
        fontWeight: "bold"
      },
      mobile: {
        fontSize: "28px",
        lineHeight: "38px",
        fontWeight: "bold"
      }
    },
    h4: {
      desktop: {
        fontSize: "25px",
        lineHeight: "28px",
        fontWeight: "bold"
      },
      tablet: {
        fontSize: "25px",
        lineHeight: "28px",
        fontWeight: "bold"
      },
      mobile: {
        fontSize: "25px",
        lineHeight: "28px",
        fontWeight: "bold"
      }
    },
    stepperNumber: {
      desktop: {
        fontSize: "20px",
        lineHeight: "22px",
        fontWeight: "normal"
      },
      tablet: {
        fontSize: "20px",
        lineHeight: "22px",
        fontWeight: "normal"
      },
      mobile: {
        fontSize: "20px",
        lineHeight: "22px",
        fontWeight: "normal"
      }
    },
    body1: {
      desktop: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "normal"
      },
      tablet: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "normal"
      },
      mobile: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "normal"
      }
    },
    body2: {
      desktop: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "normal"
      },
      tablet: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "normal"
      },
      mobile: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "normal"
      }
    },
    button: {
      desktop: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "bold"
      },
      tablet: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: "bold"
      },
      mobile: {
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: "bold"
      }
    },
    errorText: {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "normal"
    }
  },
  palette: {
    custom: {
      white: {
        main: "#EFEFEF",
        light: "#EFEFEF80",
        dark: "#EFEFEF26"
      },
      turquoise: {
        main: "#31B9B5",
        light: "#3DD3CE",
        dark: "#3DD3CE61"
      },
      coral: {
        main: "#FF614C",
        light: "#FA3B22",
        dark: "#FF614C61"
      },
      grey: {
        main: "#14171c",
        light: "#1f242e",
        dark: "#1B1E22"
      },
      background: "#0D1015",
      green: {
        main: "#43A047"
      },
      orange: {
        main: "#FF6C02",
        light: "#FFC799",
        dark: "#251F1D"
      },
      shadows: {
        navigation: "0px 3px 3px #00000061"
      }
    }
  }
});

export const muiTheme = theme;
