/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import UpCustomerSettingsInfo from '../../UpCustomerSettingsInfo';
import { selectBox } from '../../SelectBox';
import { creatable } from '../../UpStyledCreatable';
import UpFlexRow from '../../UpFlexRow';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  display: flex;
  flex: 1 1 auto;
  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const Language = (props) => (
  <Filter>
    <Row>
      <Field
        name={`${props.member}.filter.operator`}
        component={selectBox}
        customProps={{
          options: [
            { value: 'oneOf', label: props.intl.formatMessage(messages.oneOf) },
            {
              value: 'notOneOf',
              label: props.intl.formatMessage(messages.notOneOf),
            },
          ],
          margin: '0 10px 0 0',
          minWidth: '250px',
          onChange: () => props.resetOnChange(),
        }}
      />
    </Row>
    <UpFlexRow width="100%" alignItems="center">
      <UpCustomerSettingsInfo
        infoText={
          <IntlProvider
            defaultLocale={props.intl.defaultLocale}
            locale={props.intl.locale}
            messages={props.intl.messages}
          >
            <FormattedMessage
              id="components.ConditionLine.Filters.Language.InfoText"
              values={{
                link: (
                  <a
                    href="https://wiki.selfhtml.org/wiki/Sprachk%C3%BCrzel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      id="components.ConditionLine.Filters.Language.InfoText.List"
                      defaultMessage="List"
                    />
                  </a>
                ),
              }}
            />
          </IntlProvider>
        }
        interactive
        margin="0 3px"
      />
      <Field
        name={`${props.member}.filter.attribute`}
        component={creatable}
      />
    </UpFlexRow>
  </Filter>
);

Language.propTypes = {
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(Language);
