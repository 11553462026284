import styled from 'styled-components';
import background from './Images/uptain-bg.jpg';

export const CustomerLoginWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 154, 163, 0.85), rgba(0, 154, 163, 0.85)),
    url(${background});
  padding-top: 30px;
  padding-bottom: 40px;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: ${(props) => props.theme.fontColor};
`;

export const Main = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    min-height: calc(100vh - 130px);
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 36.5px;
  height: 100%;
  font-size: 18px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  svg path {
    fill: ${(props) => (props.valid === 'check' ? '#3c763d' : '#a94442')};
  }
`;
