import { GlobalStyles as MuiGlobalStyles, useTheme } from "@mui/material";

export const GlobalStyles = () => {
  const {
    palette: {
      custom: { background }
    }
  } = useTheme();

  return (
    <MuiGlobalStyles
      styles={{
        body: {
          backgroundColor: background
        }
      }}
    />
  );
};
