export const GET_ZERO_SCV = 'react-boilerplate/sales/dashboard/GET_ZERO_SCV';


export const getZeroScv = () => ({
  type: GET_ZERO_SCV,

  meta: {
    type: 'api',
    url: `admin/analysis/zeroscv`,
    method: 'get',
  },
});

