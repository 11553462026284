import { GetComeBackMailingCanEnableResponse } from "api";
import { apiClient } from "shared";

export const getComeBackMailingCanEnable = async (
  shopId: number
): Promise<GetComeBackMailingCanEnableResponse> => {
  const { data } = await apiClient.get<GetComeBackMailingCanEnableResponse>(
    `/${shopId}/triggers/come-back-mailing/can-enable`
  );

  return data;
};
