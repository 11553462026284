import { CircularProgress } from "@mui/material";
import { PageWrapper } from "../../wrappers";
import { LoadingContainer } from "./PageLoading.styles";

export const PageLoading = () => {
  return (
    <PageWrapper>
      <LoadingContainer>
        <CircularProgress color="inherit" disableShrink />
      </LoadingContainer>
    </PageWrapper>
  );
};
