import { styled, Typography as MuiTypography } from "@mui/material";
import { DescriptionTextProps } from "./DescriptionText.props";

export const DescriptionText = styled(
  (props: DescriptionTextProps) => <MuiTypography {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "withMargin"
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body1, h4 },
      breakpoints
    },
    withMargin
  }) => `
  &.MuiTypography-root {
    color: ${custom.white.main};
    font-size: ${body1.desktop.fontSize};
    line-height: ${h4.desktop.lineHeight};
    font-weight: ${body1.desktop.fontWeight};
    white-space: pre-line;
    ${withMargin ? "margin-bottom: 55px;" : ""}

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      line-height: ${h4.tablet.lineHeight};
      font-weight: ${body1.tablet.fontWeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      line-height: ${h4.desktop.lineHeight};
      font-weight: ${body1.desktop.fontWeight};
    }
  }
`
);
