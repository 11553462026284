import {
  Theme,
  MenuItem as MuiMenuItem,
  styled,
  IconButton as MuiIconButton,
  Typography as MuiTypography
} from "@mui/material";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { LanguageButtonProps } from "./LanguageSwitch.props";

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: "transparent",
  marginTop: "20px",

  "& .MuiList-root": {
    padding: "10px 0",
    backgroundColor: custom.background,
    boxShadow: custom.shadows.navigation
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});

export const LanguageButton = styled(({ active, value, ...props }: LanguageButtonProps) => (
  <MuiIconButton {...props} disableRipple>
    <MuiTypography variant="body2">{value}</MuiTypography>
    <Chevron
      sx={{
        transition: "transform 0.1s ease-in-out",
        transform: `rotate(${active ? "180" : "0"}deg)`
      }}
    />
  </MuiIconButton>
))(
  ({
    theme: {
      palette: { custom }
    },
    active
  }) => {
    const color = active ? custom.turquoise.main : custom.white.main;

    return `
      color: ${color};

      & .MuiTypography-root {
        text-transform: uppercase;
      }
    `;
  }
);

export const MenuItem = styled(MuiMenuItem)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiMenuItem-root {
      color: ${custom.white.main};
      text-transform: uppercase;
    }
  `
);
