import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import liveDashboard from '../containers/LiveDashboard/reducer';

import productReducer from '../containers/ProductSettings/reducer';
import adminReducer from '../containers/Admin/reducer';
import salesReducer from '../containers/Sales/reducer';
import {
  LOGOUT,
  GET_SHOP_LIST,
  SET_SHOP_NAME,
  GET_PRODUCT_LIST,
  SET_USER_ROLE,
  SWITCH_SHOP,
  GET_USER_PERMISSIONS,
} from '../actions/actions';
import globalSettingsReducer from '../reducers/globalsReducer';
import analysisReducer from '../containers/Analysis/reducer';
import activationDesignerReducer from '../containers/ActivationDesigner/reducer';
import mailTemplatesDesignerReducer from '../containers/MailTemplateDesigner/reducer';
import abtestDesignerReducer from '../containers/ABTestDesigner/reducer';
import fuzzyReducer from '../containers/FuzzyEditor/reducer';

import { Testable } from '../utils/testingUtils';
import shopDataReducer from './shopDataReducer';

const initialState = Immutable.fromJS({
  shops: [],
  shopsLoading: false,
  products: [],
  role: null,
  shopsRecieved: false,
  selectedShop: undefined,
  selectedShopId: undefined,
  permissions: [],
});

function userDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PERMISSIONS: {
      return state.merge({
        permissions: action.data.data,
      });
    }
    case `${GET_SHOP_LIST}_LOADING`: {
      return state.merge({
        shopsLoading: true,
      });
    }
    case GET_SHOP_LIST: {
      const currentShop = state.get('selectedShop');
      const currentShopId = state.get('selectedShopId');

      const newState = state.merge({
        shops: Immutable.fromJS(action.data),
        shopsRecieved: true,
        selectedShop:
          currentShop ||
          (action.data.length > 0 ? action.data[0].id : undefined),
        selectedShopId:
          currentShopId ||
          (action.data.length > 0 ? action.data[0].shopId.toString() : ''),
        shopsLoading: false,
      });

      return newState;
    }
    case `${SET_SHOP_NAME}_LOADING`: {
      return state.merge({
        shopsLoading: true,
      });
    }
    case SET_SHOP_NAME: {
      const currentShop = state.get('selectedShop');
      const shops = state.get("shops");
      const index = shops.findIndex((item) => item.get("id") === currentShop);

      const updatedShops = shops.update(
        index,
        (item) => item.set("name", action.name),
      );

      const newState = state.merge({
        shops: updatedShops,
        shopsLoading: false,
      });

      return newState;
    }
    case GET_PRODUCT_LIST: {
      return state.set('products', Immutable.fromJS(action.data.data));
    }
    case SET_USER_ROLE: {
      return state.set('role', action.userRole);
    }
    case SWITCH_SHOP: {
      return state.merge({
        selectedShop: action.shop,
        selectedShopId: action.shopId.toString(),
      });
    }
    default: {
      return state;
    }
  }
}

const customerBackendDashboardReducer = combineReducers({
  userData: userDataReducer,
  global: globalSettingsReducer,
  liveDashboard,
  admin: adminReducer,
  sales: salesReducer,
  analysis: analysisReducer,
  shops: shopDataReducer,
  products: productReducer,
  activationDesigner: activationDesignerReducer,
  mailTemplates: mailTemplatesDesignerReducer,
  abTests: abtestDesignerReducer,
  fuzzyEditor: fuzzyReducer,
});

const customerBackendDashboardRootReducer = (state, action) => {
  let newState = state;
  if (action.type === LOGOUT) {
    newState = undefined;
  }

  return customerBackendDashboardReducer(newState, action);
};

export default Testable(customerBackendDashboardRootReducer, {
  userDataReducer,
});
