import { apiClient } from "shared";
import { QuestionnaireSignatureStepData, ShopIdParam } from "api";

export const putSubmitSignature = async ({
  shopId,
  ...values
}: QuestionnaireSignatureStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/signature`, values);

  return data;
};
