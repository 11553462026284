import { Autocomplete, Theme, styled } from "@mui/material";
import { KeyTitlePair } from "../../../types";

export const Wrapper = styled(Autocomplete<KeyTitlePair, true, true, true>)(
  () => `
    &.MuiAutocomplete-root {
      & .MuiInputBase-root {
        & .MuiInputBase-input {
          padding: 8px 8px 8px 14px;
        }
      }
    }
  `
);

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: custom.grey.main,
  boxShadow: custom.shadows.navigation,
  marginTop: "24px",

  "& .MuiAutocomplete-listbox": {
    padding: "24px 0",

    "& .MuiAutocomplete-option": {
      color: custom.white.light,

      "&:hover": {
        background: "none",
        color: custom.white.main
      },

      '&[aria-selected="true"]': {
        background: "none",
        color: custom.turquoise.main,

        "&.Mui-focused": {
          background: "none"
        },

        "&:hover": {
          background: "none",
          color: custom.white.main
        }
      }
    }
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  },

  "& .MuiAutocomplete-loading": {
    color: custom.white.light
  },

  "& .MuiAutocomplete-noOptions": {
    color: custom.white.light
  }
});
