export const nextAction = (next) => (action) => (response) => {
  const { data, status } = response;

  // Dispatch next action only for success or no content statuses
  if (status === 200 || status === 204) {
    const newAction = { ...action, data };

    delete newAction.meta;
    next(newAction);
  }
};
