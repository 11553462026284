import { useState, useCallback, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  NavigationDrawerButtonStyles,
  SubrouteButton,
  Accordion,
  AccordionDetails
} from "../NavigationDrawerButton.styles";
import { useAuthState } from "../../../../hooks";
import { NavigationButtonWithSubroutesProps, PathConfig } from "../../../../types";
import { withFeatureVisibility } from "../../../../HoC";

export const NavigationDrawerButtonWithSubroutes = ({
  translationKey,
  subroutes,
  onClick,
  shopId,
  requiredPermission
}: NavigationButtonWithSubroutesProps) => {
  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  const { checkPermission } = useAuthState();

  const [subroutesOpened, setSubroutesOpened] = useState<boolean>(false);

  const handleOpenSubroutes = useCallback(() => {
    setSubroutesOpened((state) => !state);
  }, []);

  const handleSubrouteClick = useCallback(
    (path: PathConfig) => (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick(path);
      setSubroutesOpened(false);
    },
    [onClick]
  );

  const parsedSubroutes = useMemo(
    () =>
      subroutes.map(({ translationKey, path, requiredPermission, featureFlag }) => {
        if (requiredPermission && !checkPermission(requiredPermission)) return null;

        const active = pathname === path.path;

        const href = path.includeShopId ? `${path.path}?shop=${shopId}` : path.path;

        if (featureFlag) {
          const Component = withFeatureVisibility(SubrouteButton, featureFlag);
          return (
            <Component
              active={active}
              href={href}
              key={translationKey}
              onClick={handleSubrouteClick(path)}
            >
              {t(translationKey)}
            </Component>
          );
        } else {
          return (
            <SubrouteButton
              active={active}
              href={href}
              key={translationKey}
              onClick={handleSubrouteClick(path)}
            >
              {t(translationKey)}
            </SubrouteButton>
          );
        }
      }),
    [subroutes, pathname, handleSubrouteClick, i18n.language]
  );

  const routeActive = subroutes.some(({ path }) => path.path === pathname);

  if (requiredPermission && !checkPermission(requiredPermission)) {
    return null;
  }

  return (
    <Accordion expanded={subroutesOpened} onChange={handleOpenSubroutes}>
      <NavigationDrawerButtonStyles
        withSubroutes
        selected={subroutesOpened}
        active={routeActive}
        onClick={handleOpenSubroutes}
      >
        {t(translationKey)}
      </NavigationDrawerButtonStyles>
      <AccordionDetails>{parsedSubroutes}</AccordionDetails>
    </Accordion>
  );
};
