import module from "module";
import { Routes } from "config";
import { PageWrapper, NavigationBar, Permission } from "shared";
import { BrowseAbandonmentSettingsContent } from "../components";

const BrowseAbandonmentPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <BrowseAbandonmentSettingsContent />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.BROWSE_ABANDONMENT,
  requiredPermission: Permission.USER
});

export default BrowseAbandonmentPage;
