import { useCallback, useMemo } from "react";
import {
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData,
  QuestionnaireStepType
} from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { putSubmitAbandonmentMailsWaitingTimeMinMinutes } from "../../api";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import {
  abandonmentMailsWaitingTimeMinMinutesFormMapper,
  parseAbandonmentMailsWaitingTimeMinMinutesFormMetadata
} from "./useAbandonmentMailsWaitingTimeMinMinutesStep.helpers";
import { QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues } from "../../types";

export const useAbandonmentMailsWaitingTimeMinMinutesStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(
    QuestionnaireStepType.ABANDONMENT_MAILS_WAITING_TIME_MIN_MINUTES
  );

  const data: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues = useMemo(
    () => ({
      ...stepData?.data,
      browseAbandonmentWaitingTimeMinHours:
        stepData?.data?.browseAbandonmentWaitingTimeMinHours ?? 168,
      comeBackMailingWaitingTimeMinHours: stepData?.data?.comeBackMailingWaitingTimeMinHours ?? 2160
    }),
    [stepData?.data]
  );

  const metadata = useMemo(
    () => parseAbandonmentMailsWaitingTimeMinMinutesFormMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitAbandonmentMailsWaitingTimeMinMinutes =
    useQuestionnaireSubmitMutation<QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData>(
      putSubmitAbandonmentMailsWaitingTimeMinMinutes
    );

  const executeSubmitAbandonmentMailsWaitingTimeMinMinutes: FormProps<
    QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues,
    QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const abandonmentMailsWaitingTimeMinMinutesSubmitData =
          abandonmentMailsWaitingTimeMinMinutesFormMapper(formValues, metadata);

        await submitAbandonmentMailsWaitingTimeMinMinutes(
          abandonmentMailsWaitingTimeMinMinutesSubmitData
        );
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAbandonmentMailsWaitingTimeMinMinutes, metadata]
  );

  return {
    data,
    hasPrev: !!prevStep,
    metadata,
    handlePrevStep,
    executeSubmitAbandonmentMailsWaitingTimeMinMinutes
  };
};
