import {
  styled,
  Box as MuiBox,
  Button as MuiButton,
  Typography as MuiTypography,
  IconButton as MuiIconButton
} from "@mui/material";
import {
  DayWrapperProps,
  DayCircleStylesProps,
  DayTextProps,
  AddTimeButtonProps
} from "./DayCircle.props";

export const DayWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 120px;
    }
  `
);

export const DayCircleWrapper = styled((props: DayWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) =>
    prop !== "active" && prop !== "selected" && prop !== "disabled" && prop !== "error"
})(
  ({
    theme: {
      palette: { custom }
    },
    selected,
    disabled,
    error
  }) => {
    const selectedBorderColor = error ? custom.coral.main : custom.turquoise.main;

    const resultBorderColor = selected && !disabled ? selectedBorderColor : "transparent";

    return `
      &.MuiBox-root {
        width: 80px;
        height: 80px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid ${resultBorderColor};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
      }
    `;
  }
);

export const DayCircleStyles = styled((props: DayCircleStylesProps) => <MuiButton {...props} />, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "selected" && prop !== "error"
})(
  ({
    theme: {
      palette: { custom }
    },
    error,
    selected,
    active,
    disabled
  }) => {
    const selectedColor = error ? custom.coral.main : custom.turquoise.main;

    const resultColor = selected || active ? selectedColor : "transparent";

    const disabledBorder = disabled
      ? `2px dashed ${custom.white.dark}`
      : `2px solid ${custom.white.light}`;

    const resultBorder = selected || active ? "none" : disabledBorder;

    return `
      &.MuiButton-root {
        padding: 0;
        margin: 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: ${resultBorder};
        background-color: ${resultColor};
      }
    `;
  }
);

export const DayText = styled((props: DayTextProps) => <MuiTypography {...props} />, {
  shouldForwardProp: (prop) =>
    prop !== "active" && prop !== "selected" && prop !== "disabled" && prop !== "error"
})(
  ({
    theme: {
      palette: { custom },
      typography: { h4 },
      breakpoints
    },
    disabled,
    selected,
    active
  }) => {
    const selectedColor = selected || active ? custom.white.main : custom.white.light;

    const resultColor = disabled ? custom.white.dark : selectedColor;

    return `
      &.MuiTypography-root {
        font-size: ${h4.mobile.fontSize};
        font-weight: ${h4.mobile.fontWeight};
        line-height: ${h4.mobile.lineHeight};
        color: ${resultColor};

        ${[breakpoints.up("md")]} { 
          font-size: ${h4.tablet.fontSize};
          font-weight: ${h4.tablet.fontWeight};
          line-height: ${h4.tablet.lineHeight};
        }

        ${[breakpoints.up("lg")]} { 
          font-size: ${h4.desktop.fontSize};
          font-weight: ${h4.desktop.fontWeight};
          line-height: ${h4.desktop.lineHeight};
        }
      }
    `;
  }
);

export const AddTimeIconButton = styled((props: AddTimeButtonProps) => (
  <MuiIconButton {...props} />
))(
  ({
    theme: {
      palette: { custom }
    },
    disabled
  }) => {
    const color = disabled ? custom.white.dark : custom.white.light;

    return `
      &.MuiIconButton-root {
        width: 20px;
        height: 20px;
        color: ${color}
      }
    `;
  }
);
