import { useTranslation } from "react-i18next";
import { Wrapper, Typography, Line } from "./ConditionDelimeter.styles";

export const ConditionDelimeter = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typography>{t("featuresModule.conditions.delimeter")}</Typography>
      <Line />
    </Wrapper>
  );
};
