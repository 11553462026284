import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFeatureToggles } from '../../Admin/FeatureToggles/actions';

function useFeatureToggles() {
  const dispatch = useDispatch();
  const featureToggles = useSelector((state) =>
    state.getIn(['global', 'featureToggles', 'data']),
  );

  const features = useMemo(
    () =>
      featureToggles.reduce(
        (acc, value, key) => (value ? [...acc, key] : acc),
        [],
      ),
    [featureToggles],
  );

  useEffect(() => {
    dispatch(getFeatureToggles());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return features;
}

export { useFeatureToggles };
