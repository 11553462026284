import { DeviceType } from "shared";
import { ActivationType } from "./activationType.type";

export enum RegainType {
  VOUCHER = "voucher",
  MANUAL = "manual"
}

export type ActivationTemplate = {
  id: number;
  templateSet: string;
  active: number;
  description: string;
  stage: string;
  activationType: ActivationType;
  device: DeviceType;
  shopId: number | null; // ??? null in all templates from response
  regainType: RegainType;
  mobileTrigger: string | null;
  desktopTrigger: string | null;
  html: string;
  less: string;
  js: string | null;
  blockDurationMinutes: number;
  exitTimeout: number;
  timeoutTimeout: null; // ??? null in all templates from response
  mailTemplateRef: "SCV" | null;
  trails: number;
  rewards: number;
  herzlich: number;
  serioes: number;
  du: number;
  sie: number;
  professionell: number;
  sachlich: number;
  fromIch: number;
  fromTeam: number;
  language: string;
};

export type GetActivationTemplatesResponse = {
  message: string;
  data: ActivationTemplate[];
};
