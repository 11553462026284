import { MessageDescriptor, defineMessages } from 'react-intl';

export default defineMessages<string | number | symbol, MessageDescriptor, { [key: string]: MessageDescriptor }>({
  startDateTitle: {
    id: 'containers.Dashboard.startDate.title',
    defaultMessage: 'Set start date',
  },
  endDateTitle: {
    id: 'containers.Dashboard.endDate.title',
    defaultMessage: 'Set end date',
  },
  saveButton: {
    id: 'containers.Dashboard.saveButton',
    defaultMessage: 'Save',
  },
  resetButton: {
    id: 'containers.Dashboard.resetButton',
    defaultMessage: 'Reset',
  },
  discardChangesButton: {
    id: 'containers.Dashboard.discardChangesButton',
    defaultMessage: 'Discard changes',
  },
  privacyPolicy: {
    id: 'containers.Dashboard.Footer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  emailError: {
    id: 'containers.Dashboard.Form.emailError',
    defaultMessage: 'Please enter a valid email address',
  },
  phoneError: {
    id: 'containers.Dashboard.Form.phoneError',
    defaultMessage: 'Please only use numbers, spaces, +, (, ), and /',
  },
  phoneCountryCodeError: {
    id: 'containers.Dashboard.Form.phoneCountryCodeError',
    defaultMessage: 'Invalid country calling code',
  },
  requiredError: {
    id: 'containers.Dashboard.Form.requiredError',
    defaultMessage: 'Please enter a value',
  },
  syntaxError: {
    id: 'containers.Dashboard.Form.syntaxError',
    defaultMessage: 'Unrecoverable syntax error',
  },
  syntaxDOMError: {
    id: 'containers.Dashboard.Form.syntaxDOMError',
    defaultMessage:
      'Using window or document interface is forbidden for the selected feature',
  },
  duplicateError: {
    id: 'containers.Dashboard.Form.duplicateError',
    defaultMessage: 'Remove duplicate items',
  },
  urlError: {
    id: 'containers.Dashboard.Form.urlError',
    defaultMessage: 'Please enter a complete URL like https://www.example.de',
  },
  onlyNumbersError: {
    id: 'containers.Dashboard.Form.onlyNumbersError',
    defaultMessage: 'Only numbers allowed',
  },
  onlyDecimalNumbersError: {
    id: 'containers.Dashboard.Form.onlyDecimalNumbersError',
    defaultMessage:
      'Only numbers and a period as decimal separator are allowed',
  },
  percentageError: {
    id: 'containers.Dashboard.Form.percentageError',
    defaultMessage: 'Must be number between 0 and 100',
  },
  regexError: {
    id: 'containers.Dashboard.Form.regexError',
    defaultMessage: 'Please enter a valid regex expression',
  },
  noTimeSelectedError: {
    id: 'containers.Dashboard.Form.noTimeSelectedError',
    defaultMessage: 'Please select a time',
  },
  noCustomerGroupSelectedError: {
    id: 'containers.Dashboard.Form.noCustomerGroupSelectedError',
    defaultMessage: 'Please select a Customer Group',
  },
  cancelButton: {
    id: 'containers.Dashboard.cancelButton',
    defaultMessage: 'Cancel',
  },
  closeButton: {
    id: 'containers.Dashboard.closeButton',
    defaultMessage: 'Close',
  },
  noOptionSelectedError: {
    id: 'containers.Dashboard.Form.noOptionSelectedError',
    defaultMessage: 'Please select an option',
  },
  noValidOptionSelectedError: {
    id: 'containers.Dashboard.Form.noValidOptionSelectedError',
    defaultMessage: 'Please select a valid option',
  },
  noYesOptionSelectedError: {
    id: 'containers.Dashboard.Form.noYesOptionSelectedError',
    defaultMessage: 'Please select at lease one positive value.',
  },
  noWhitespaceError: {
    id: 'containers.Dashboard.Form.noWhitespaceError',
    defaultMessage:
      'Entered voucher code contains a space at the beginning or end of the string. Please remove it before continuing.',
  },
  noDuplicateEntry: {
    id: 'containers.Dashboard.Form.noDuplicateEntry',
    defaultMessage:
      'There are duplicate entries in voucher list. Please remove duplicates before continuing.',
  },
  noVoucherEntry: {
    id: 'containers.Dashboard.Form.noVoucherEntry',
    defaultMessage:
      'Undefined voucher entry. Please check if you have empty lines or unrecognized symbols inside entered voucher list.',
  },
  minVoucherCode: {
    id: 'containers.Dashboard.Form.minVoucherCode',
    defaultMessage: 'Voucher code should contain at least 2 characters',
  },
  previewCouldntLoaded: {
    id: 'components.Preview',
    defaultMessage:
      "Preview couldn't be loaded because there is no template available",
  },
  maxLengthError: {
    id: 'containers.Dashboard.Form.maxLengthError',
    defaultMessage: 'Please use only {value} characters or less.',
  },
  examplePreview: {
    id: 'containers.examplePreview',
    defaultMessage: 'Exemplary Preview',
  },
  formContainsErrors: {
    id: 'components.formContainsErrors',
    defaultMessage:
      'This form contains errors. Please fix them all before saving.',
  },
  availability: {
    id: 'components.availability',
    defaultMessage: 'availability',
  },
  voucherList: {
    id: 'components.voucherList',
    defaultMessage: 'voucher lists',
  },
  duplicateListName: {
    id: 'containers.Dashboard.CustomerSettings.voucherlists.AddVoucherList.NameInputError',
    defaultMessage:
      'A voucher list with this name already exists. Please use a different name.',
  },
  minListName: {
    id: 'containers.Dashboard.CustomerSettings.voucherlists.AddVoucherList.minListName',
    defaultMessage: 'List name should contain at least 2 characters',
  },
  newPasswordError: {
    id: 'components.newPasswordError',
    defaultMessage: 'Your new password cannot be the same as your current one.',
  },
  newPasswordNotSecure: {
    id: 'components.newPasswordNotSecure',
    defaultMessage:
      'Password is not secure. The password must obey the current password rule.',
  },
  newPasswordConfirmError: {
    id: 'components.newPasswordConfirmError',
    defaultMessage: 'Password mismatch.',
  },
  badRequest: {
    id: 'app.components.Error.badRequest',
    defaultMessage:
      'Bad request. The server cannot or will not process the request due to an apparent error in the app.',
  },
  notFound: {
    id: 'app.components.Error.notFound',
    defaultMessage: 'Not found. Server could not find what was requested.',
  },
  serverUnavailable: {
    id: 'app.components.Error.serverUnavailable',
    defaultMessage:
      'Server temporary unavailable and cannot process the request. Please try again.',
  },
  formValidationError: {
    id: 'containers.Questionnaire.Steps.Errors.formValidationError',
    defaultMessage:
      'There are errors on the form. Please fix them before continuing.',
  },
  noShopSystemSelected: {
    id: 'containers.Questionnaire.Steps.Errors.noShopSystemSelected',
    defaultMessage: 'At least one shopsystem should be selected',
  },
  noFeatureSelected: {
    id: 'containers.Questionnaire.Steps.Errors.noFeatureSelected',
    defaultMessage: 'At least one feature should be selected',
  },
  minSMTPEmailPort: {
    id: 'containers.Questionnaire.Steps.Errors.minSMTPEmailPort',
    defaultMessage: 'Port could not be equal to zero',
  },
  maxSMTPEmailPort: {
    id: 'containers.Questionnaire.Steps.Errors.maxSMTPEmailPort',
    defaultMessage: 'Port could not be greater than 65535',
  },
  minShopName: {
    id: 'containers.Questionnaire.Steps.Errors.minShopName',
    defaultMessage: 'Shop Name should contain at least 2 characters',
  },
  minFont: {
    id: 'containers.Questionnaire.Steps.Errors.minFont',
    defaultMessage: 'Font should contain at least 2 characters',
  },
  minFontBold: {
    id: 'containers.Questionnaire.Steps.Errors.minFontBold',
    defaultMessage: 'Heading font should contain at least 2 characters',
  },
  minContactPersonFirstname: {
    id: 'containers.Questionnaire.Steps.Errors.minContactPersonFirstname',
    defaultMessage:
      'Contact person firstname should contain at least 2 characters',
  },
  minContactPersonLastName: {
    id: 'containers.Questionnaire.Steps.Errors.minContactPersonLastName',
    defaultMessage:
      'Contact person lastname should contain at least 2 characters',
  },
  minUserName: {
    id: 'containers.Questionnaire.Steps.Errors.minUserName',
    defaultMessage: 'Username should contain at least 2 characters',
  },
  minSenderName: {
    id: 'containers.Questionnaire.Steps.Errors.minSenderName',
    defaultMessage: 'Sender name should contain at least 2 characters',
  },
  minSMTPHost: {
    id: 'containers.Questionnaire.Steps.Errors.minSMTPHost',
    defaultMessage: 'Port should contain at least 4 characters',
  },
  minVoucherValue: {
    id: 'containers.Questionnaire.Steps.Errors.minVoucherValue',
    defaultMessage: 'Voucher value could not be equal to zero',
  },
  minOrderValue: {
    id: 'containers.Questionnaire.Steps.Errors.minOrderValue',
    defaultMessage: 'Order value could not be equal to zero',
  },
  minTotalOrderingValue: {
    id: 'containers.Questionnaire.Steps.Errors.minTotalOrderingValue',
    defaultMessage: 'Total ordering value could not be equal to zero',
  },
  minWaitingTimeMinMinutes: {
    id: 'containers.Questionnaire.Steps.Errors.minWaitingTimeMinMinutes',
    defaultMessage: 'Waiting time could not be equal to zero',
  },
  noVoucherListSelected: {
    id: 'containers.Questionnaire.Steps.Errors.noVoucherListSelected',
    defaultMessage: 'Voucher list could not be empty',
  },
  disableSaveCodeView: {
    id: 'containers.Dashboard.EmailSettings.Signature.disableSaveCodeViewMessage',
    defaultMessage: 'Saving is not possible while in the code view',
  },
  outOfLimit: {
    id: 'containers.Dashboard.EmailSettings.Signature.outOfLimitMessage',
    defaultMessage:
      'Uploaded file is too large. Try to use smaller image files (scale files before inserting them into the signature)',
  },
  invalidEmailSettings: {
    id: 'containers.Questionnaire.Steps.Errors.invalidEmailSettings',
    defaultMessage:
      'Settings are invalid. Please test your settings before proceeding and make sure to correct the settings.',
  },
});
