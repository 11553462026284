import React from 'react';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';

import { checkIfObject, checkIfStringWithObject } from './checkHelpers';

const tooManyInvalidationsErrorMessageTitle = (
  <FormattedMessage
    id="containers.ApiMiddleware.ErrorMessage.TooManyInvalidations.Title"
    defaultMessage="Caching failed"
  />
);

const tooManyInvalidationsErrorMessageDescription = (
  <FormattedMessage
    id="containers.ApiMiddleware.ErrorMessage.TooManyInvalidations.Description"
    defaultMessage={
      'All data was saved but changes will not be live on your website. ' +
      'Please wait for 5 minutes and save your changes afterwards again.'
    }
  />
);

function notify(error) {
  const {
    response,
    response: {
      data,
      data: { details, message },
      status,
    },
  } = error;

  if (response && data) {
    /** Check if server is not ready to handle the request. */
    if (status === 503) {
      NotificationManager.error(
        tooManyInvalidationsErrorMessageDescription,
        tooManyInvalidationsErrorMessageTitle,
        9000,
      );
      return;
    }

    /** Check if typeof details value inside error.response.data is a string */
    if (typeof details === 'string') {
      NotificationManager.error(details, 'API Error');
      return;
    }

    /** Check if typeof details value inside error.response.data is an object */
    if (checkIfObject(details)) {
      const { message: detailsMessage, Payload } = details;

      /** If details object inside error.response.data contains message string */
      if (detailsMessage) {
        NotificationManager.error(detailsMessage, 'API Error');
        return;
      }

      /** If details object inside error.response.data contains Payload */
      if (Payload) {
        let responseError = JSON.parse(`${Payload}`).errorMessage;

        /** If responseError is an object or a string which contains an object,
         * parse it again to get errorMessage
         */
        if (
          checkIfObject(responseError) ||
          checkIfStringWithObject(responseError)
        ) {
          responseError = JSON.parse(`${responseError}`).errorMessage;
        }

        NotificationManager.error(responseError, 'API Error');
        return;
      }
    }

    /** Check if error.response.data contains message */
    if (message) {
      NotificationManager.error(message, 'API Error');
    }
  }
}

export { notify };
