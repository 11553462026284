import { TextButton } from "shared";
import { Features } from "api";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { TextSettingsFormBase } from "../TextSettingsFormBase";
import {
  ComeBackMailingTextSettingsValues,
  ComeBackMailingTextInitialSettingsValues
} from "../../types";
import { CreateFormCollapse, CreateButtonWrapper } from "../TriggerSettingsFormStyles";
import { CreateComeBackMailingTextFormProps } from "./CreateComeBackMailingTextForm.props";
import { useCreateComeBackMailingText } from "../../hooks";

const getInitialValues = (closed: boolean): ComeBackMailingTextSettingsValues => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      id: 0,
      contactPerson: {
        contactPersonId: 0,
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      enabled: false,
      title: t("featuresModule.defaults.textSettingsTitle"),
      utmSource: "",
      utmMedium: "",
      utmCampaign: "",
      utmContent: "",
      customParameter: ""
    }),
    [closed]
  );
};

export const CreateComeBackMailingTextForm = ({ disabled }: CreateComeBackMailingTextFormProps) => {
  const { t } = useTranslation();

  const [formVisible, setFormVisible] = useState(false);

  const [formClosed, setFormClosed] = useState(true);

  const FORM_INITIAL_STATE = getInitialValues(formClosed);

  const submitMethod = useCreateComeBackMailingText();

  const handleCreateToggle = useCallback(() => {
    setFormVisible((prev) => !prev);
    setFormClosed(false);
  }, []);

  useEffect(() => {
    if (disabled) {
      setFormVisible(false);
    }
  }, [disabled]);

  const handleFormSubmit = useCallback(
    async (
      values: ComeBackMailingTextSettingsValues,
      form: FormApi<ComeBackMailingTextSettingsValues, ComeBackMailingTextInitialSettingsValues>
    ) => {
      const res = await submitMethod(values, form);
      if (res) return res;
      setFormVisible(false);
    },
    [submitMethod]
  );

  const handleCloseTransitionFinished = useCallback(() => {
    setFormClosed(true);
  }, []);

  return (
    <>
      <CreateButtonWrapper>
        <TextButton
          iconPosition="start"
          disabled={disabled}
          icon={
            <AddIcon
              sx={{
                transform: `rotate(${formVisible ? "225" : "0"}deg)`,
                transition: "transform 0.3s ease"
              }}
            />
          }
          variant="tertiary"
          onClick={handleCreateToggle}
        >
          {t(
            formVisible
              ? "featuresModule.closeForm"
              : "featuresModule.comeBackMailing.form.textSettings.addMoreButton"
          )}
        </TextButton>
      </CreateButtonWrapper>
      <CreateFormCollapse in={formVisible} unmountOnExit onExited={handleCloseTransitionFinished}>
        <TextSettingsFormBase
          translationKey="comeBackMailing"
          conditionFeatureName="come_back_mailing_conditions_enabled"
          possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.COME_BACK_MAILING]}
          initialState={FORM_INITIAL_STATE}
          formOpened={true}
          onSubmit={handleFormSubmit}
        />
      </CreateFormCollapse>
    </>
  );
};
