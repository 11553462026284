import {
  styled,
  Button as MuiButton,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
  Theme
} from "@mui/material";

export const Button = styled(MuiButton)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    &.MuiButton-root {
      padding: 16px 12px;
      border-radius: 28px;
      margin-bottom: 30px;
      width: 100%;
      justify-content: flex-start;
      font-size: ${body1.mobile.fontSize};
      font-weight: ${body1.mobile.fontWeight};
      line-height: ${body1.mobile.lineHeight};
      color: ${custom.white.light};
      border-color: ${custom.white.light};
      text-transform: none;
      height: 57px;

      ${[breakpoints.up("md")]} {
        width: 260px;
        font-size: ${body1.tablet.fontSize};
        font-weight: ${body1.tablet.fontWeight};
        line-height: ${body1.tablet.lineHeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        font-weight: ${body1.desktop.fontWeight};
        line-height: ${body1.desktop.lineHeight};
      }
    }
  `
);

export const MenuItem = styled(MuiMenuItem)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiMenuItem-root {
      color: ${custom.white.main};
      text-transform: none;
      padding: 12px 0 12px 18px;
    }
  `
);

export const ListItemIcon = styled(MuiListItemIcon)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    color: ${custom.white.light};
  `
);

export const makePaperStyles = ({ palette: { custom } }: Theme) => ({
  backgroundColor: "transparent",
  marginTop: "20px",

  "& .MuiList-root": {
    padding: "16px 0",
    backgroundColor: custom.background,
    boxShadow: custom.shadows.navigation,
    border: `1px solid ${custom.white.dark}`,
    borderRadius: "4px"
  },

  "&::-webkit-scrollbar": {
    width: "4px"
  },

  "&::-webkit-scrollbar-track": {
    background: custom.grey.main
  },

  "&::-webkit-scrollbar-thumb": {
    background: custom.white.light,
    borderRadius: "2px",

    "&:hover": {
      background: custom.white.main
    }
  }
});
