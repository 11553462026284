import { useCallback, useState } from "react";
import { Theme, useMediaQuery, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  NavigationButtonsWrapper,
  SettingsWrapper
} from "./PrivateNavigationBar.styles";
// import from index are not working, for some reason
import { NavigationDrawer } from "../NavigationDrawer/NavigationDrawer";
import { NavigationBarButton } from "../NavigationBarButton";
import { AccountButton } from "../AccountButton";
import { AccountMenu } from "../AccountMenu";
import { HelpButton } from "../HelpButton";
import { LanguageSwitch } from "../LanguageSwitch";
import { AccountDrawer } from "../AccountDrawer";
import { UptainLogo } from "../UptainLogo";
import { PathConfig } from "../../../types";
import { useQuestionnaireCompleted, useSelectedShop, useNavigationConfig } from "../../../hooks";

export const PrivateNavigationBar = () => {
  const desktop = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("xl"));

  const navigate = useNavigate();

  const { selectedShopId } = useSelectedShop();

  const questionnaireCompleted = useQuestionnaireCompleted();

  const navigationRoutes = useNavigationConfig();

  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);

  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);

  const handleOpenNavigation = useCallback(() => {
    setNavigationDrawerOpen((state) => !state);
    setAccountAnchor(null);
  }, []);

  const handleCloseNavigation = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const handleOpenAccount = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAccountAnchor(accountAnchor ? null : event.currentTarget);
      setNavigationDrawerOpen(false);
    },
    [accountAnchor]
  );

  const handleCloseAccount = useCallback(() => {
    setAccountAnchor(null);
  }, []);

  const handleNavigate = useCallback(
    ({ path, includeShopId }: PathConfig) => {
      setAccountAnchor(null);
      setNavigationDrawerOpen(false);
      navigate({
        pathname: path,
        search: includeShopId ? `?shop=${selectedShopId}` : ""
      });
    },
    [selectedShopId]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar>
          <UptainLogo active={navigationDrawerOpen} onClick={handleOpenNavigation} />
          {desktop && (
            <NavigationButtonsWrapper>
              {navigationRoutes.map((route) => (
                <NavigationBarButton
                  key={route.translationKey}
                  route={route}
                  onClick={handleNavigate}
                  shopId={selectedShopId}
                />
              ))}
            </NavigationButtonsWrapper>
          )}
          <SettingsWrapper>
            <LanguageSwitch />
            <HelpButton />
            <AccountButton
              showBadge={!questionnaireCompleted}
              active={Boolean(accountAnchor)}
              onClick={handleOpenAccount}
            />
            {desktop && (
              <AccountMenu
                anchorEl={accountAnchor}
                onClose={handleCloseAccount}
                shopId={selectedShopId}
              />
            )}
          </SettingsWrapper>
        </Toolbar>
      </AppBar>
      {!desktop && (
        <>
          <AccountDrawer
            open={Boolean(accountAnchor)}
            onClose={handleCloseAccount}
            shopId={selectedShopId}
          />
          <NavigationDrawer
            routes={navigationRoutes}
            open={navigationDrawerOpen}
            onClose={handleCloseNavigation}
            onNavigate={handleNavigate}
            shopId={selectedShopId}
          />
        </>
      )}
    </Box>
  );
};
