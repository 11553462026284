import { apiClient } from "shared";
import { QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData, ShopIdParam } from "api";

export const putSubmitAbandonmentMailsWaitingTimeMinMinutes = async ({
  shopId,
  ...values
}: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/mail-waiting-time`, values);

  return data;
};
