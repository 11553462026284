import { ChangeEvent, useCallback, useMemo } from "react";
import { colord } from "colord";
import { convertAlphaToHex, convertHexToRGBA } from "../ColorPicker.helpers";
import { ValueInputsProps } from "./ValueInputs.props";
import { hexInputWidth, alphaInputWidth, InputField } from "./ValueInputs.styles";

export const HexValueInputs = ({ value, onChange }: ValueInputsProps) => {
  const rgbaValue = useMemo(() => convertHexToRGBA(value), [value]);

  const hexValue = useMemo(() => value.slice(1, 7), [value]);

  const onHexChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const hexOnly = value.replace(/[^0-9A-Fa-f]/g, "");

      onChange(`#${hexOnly.slice(0, 6).toUpperCase()}`);
    },
    [onChange]
  );

  const onHexBlur = useCallback(() => {
    const rgb = convertHexToRGBA(value);

    const hex = colord(rgb).toHex();

    onChange(hex.toUpperCase());
  }, [value, onChange]);

  const onAlphaChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const numberOnly = Number(value.replace(/[^0-9]/g, ""));

      const hexAlpha = convertAlphaToHex(numberOnly);

      onChange(`#${hexValue}${hexAlpha}`);
    },
    [onChange, hexValue]
  );

  return (
    <>
      <InputField
        width={hexInputWidth}
        InputProps={{
          startAdornment: "#"
        }}
        value={hexValue.toUpperCase()}
        onChange={onHexChange}
        onBlur={onHexBlur}
      />
      <InputField
        width={alphaInputWidth}
        InputProps={{
          endAdornment: "%"
        }}
        value={rgbaValue.a}
        onChange={onAlphaChange}
      />
    </>
  );
};
