import {
  styled,
  Button as MuiButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails
} from "@mui/material";
import Icon from "@mui/icons-material/ExpandMoreOutlined";
import { NavigationButtonStylesProps, NavigationSubrouteButtonStylesProps } from "../types";

export const NavigationDrawerButtonStyles = styled(
  ({ children, withSubroutes, ...props }: NavigationButtonStylesProps) => (
    <MuiButton
      {...props}
      variant="text"
      disableRipple
      endIcon={withSubroutes && <Icon viewBox="3 3 18 18" />}
    >
      {children}
    </MuiButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "active"
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    },
    active,
    selected
  }) => {
    const buttonColor = selected ? custom.turquoise.main : custom.white.main;

    return `
      &.MuiButton-root {
        color: ${buttonColor};
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
        min-width: 0;
        text-transform: uppercase;
        box-sizing: content-box;
        height: 32px;
        min-height: 32px;
        padding: 0 ${active ? "22px" : "25px"}};
        margin: 17px 0;
        border-left: ${active ? `3px solid ${custom.turquoise.main}` : "none"};
        border-radius: 0;
        justify-content: flex-start;

        & .MuiButton-endIcon {
          margin-left: 6px;
          margin-right: 0;
        }

        & .MuiButton-endIcon {
          transform: ${selected ? "rotate(180deg)" : "rotate(0deg)"};
        }

        &.Mui-selected {
          background: none;
          color: ${custom.turquoise.main};
        }

        :hover {
          background: none;
          color: ${custom.turquoise.main};
        }

        :active {
          color: ${custom.white.light};
        }
      }
    `;
  }
);

export const Accordion = styled(MuiAccordion)(
  () => `
    &.MuiAccordion-root {
      background: none;
      box-shadow: none;
      border-radius: 0;

      &.Mui-expanded { 
        margin: 0;
      }
    }
  `
);

export const AccordionDetails = styled(MuiAccordionDetails)(
  () => `
    &.MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      padding: 0;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  `
);

export const SubrouteButton = styled(
  ({ children, ...props }: NavigationSubrouteButtonStylesProps) => (
    <MuiButton {...props} variant="text" disableRipple>
      {children}
    </MuiButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "active"
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    },
    active
  }) => {
    return `
      &.MuiButton-root {
        padding: 0 ${active ? "22px" : "25px"}};
        margin: 8px 0;
        color: ${custom.white.light};
        font-size: ${body1.mobile.fontSize};
        line-height: ${body1.mobile.lineHeight};
        font-weight: ${body1.mobile.fontWeight};
        text-transform: initial;
        border-left: ${active ? `3px solid ${custom.turquoise.main}` : "none"};
        border-radius: 0;
        justify-content: flex-start;

        &.Mui-disabled {
          color: ${custom.white.light};
          opacity: 1;
        }

        &:hover {
          color: ${custom.white.main};
          background: none;
        }
      }
    `;
  }
);
