import { IconButton, IconButtonProps, Theme, styled, useMediaQuery } from "@mui/material";
import { TextButton, TextButtonProps } from "../../form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCallback } from "react";

const HelpButtonDesktop = styled(({ ...props }: TextButtonProps) => {
  return (
    <TextButton
      {...props}
      icon={<InfoOutlinedIcon viewBox="2 2 22 22" />}
      variant="secondary"
      iconPosition="end"
    >
      HELP CENTER
    </TextButton>
  );
})(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiButtonBase-root {
      padding: 8px 12px;
      color: ${custom.white.main};
      font-size: ${body2.desktop.fontSize};
      line-height: ${body2.desktop.lineHeight};
      font-weight: ${body2.desktop.fontWeight};

      :active {
        color: ${custom.white.light};
      }
    }
  `
);

const HelpButtonMobile = styled(({ ...props }: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <InfoOutlinedIcon viewBox="2 2 22 22" />
    </IconButton>
  );
})(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiButtonBase-root {
      width: 20px;
      height: 20px;
      color: ${custom.white.main};
      box-sizing: content-box;

      & .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }

      :active {
        color: ${custom.white.light};
      }
    }
  `
);

export const HelpButton = () => {
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

  const handleButtonClick = useCallback(() => {
    window.open(process.env.REACT_APP_HELP_LINK, "_blank");
  }, []);

  if (desktop) {
    return <HelpButtonDesktop onClick={handleButtonClick} />;
  }

  return <HelpButtonMobile onClick={handleButtonClick} />;
};
