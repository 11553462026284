import { TextButton } from "shared";
import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonsWrapper } from "./NavigationButtons.styles";
import { NavigationButtonsProps } from "./NavigationButtons.props";
import { useCallback } from "react";

export const NavigationButtons = ({
  nextDisabled,
  prevDisabled,
  nextLoading,
  onNextClick,
  onPrevClick
}: NavigationButtonsProps) => {
  const { t } = useTranslation();

  const desktop = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("lg"));

  const handlePrevClick = useCallback(() => {
    onPrevClick();
  }, [onPrevClick]);

  const handleNextClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);

  if (!desktop) return null;

  return (
    <ButtonsWrapper>
      <TextButton
        variant="secondary"
        iconPosition="start"
        disabled={prevDisabled}
        onClick={handlePrevClick}
      >
        {t(`questionnaireModule.buttons.previous`)}
      </TextButton>
      <TextButton
        variant="primary"
        iconPosition="end"
        loading={nextLoading}
        disabled={nextDisabled}
        onClick={handleNextClick}
      >
        {t(`questionnaireModule.buttons.save`)}
      </TextButton>
    </ButtonsWrapper>
  );
};
