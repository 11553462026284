import Immutable from 'immutable';
import {
  ACTIVATE_VERSION, CREATE_LIVE_VERSION, GET_ITEM, GET_TEMPLATES,
  GET_VERSIONS
} from "./actions";
import {combineReducers} from 'redux-immutable';

const activationDesignerReducer = combineReducers({
  templates: templatesReducer,
  items: itemsReducer,
  versions: versionsReducer,
});

export default activationDesignerReducer;


function templatesReducer(state = Immutable.fromJS({
                            loading: false,
                            data: undefined,
                            errorMessage: undefined
                          }),
                          action) {

  switch (action.type) {
    case GET_TEMPLATES: {
      return state.merge(Immutable.fromJS({
        data: action.data.data,
        loading: false,
        errorMessage: undefined
      }));
    }
    case GET_TEMPLATES + "_LOADING": {
      return state.merge({data: undefined, loading: true, errorMessage: undefined});
    }
    case GET_TEMPLATES + "_ERROR": {
      return state.merge({data: undefined, loading: false, errorMessage: JSON.stringify(action)});
    }
    default: {
      return state;
    }
  }
}

function versionsReducer(state = Immutable.fromJS({
                           loading: false,
                           data: undefined,
                           errorMessage: undefined,
                           creatingNewVersion: false,
                         }),
                         action) {

  switch (action.type) {
    case GET_VERSIONS: {
      return state.merge(Immutable.fromJS({
        data: action.data.data,
        loading: false,
        errorMessage: undefined,
      }));
    }
    case ACTIVATE_VERSION: {

      const data = state.get("data").toJS();

      const entry = data.find((d) => d.id === action.id);
      entry.active = (!entry.active) ? 1 : 0;

      return state.merge(Immutable.fromJS({
        data,
        loading: false,
        errorMessage: undefined
      }));
    }
    case CREATE_LIVE_VERSION: {
      return state.merge({creatingNewVersion: false});
    }
    case CREATE_LIVE_VERSION + "_LOADING": {
      return state.merge({creatingNewVersion: true});
    }
    case GET_VERSIONS + "_LOADING": {
      return state.merge({data: undefined, loading: true, errorMessage: undefined});
    }
    case GET_VERSIONS + "_ERROR": {
      return state.merge({data: undefined, loading: false, errorMessage: JSON.stringify(action)});
    }
    default: {
      return state;
    }
  }
}


function itemsReducer(state = Immutable.fromJS({
                        loading: false,
                        data: {},
                        errorMessage: undefined
                      }),
                      action) {

  switch (action.type) {
    case GET_ITEM: {

      const newData = state.get("data").toJS();
      newData[action.id] = action.data.data;
      return state.merge(Immutable.fromJS({
        data: newData,
        loading: false,
        errorMessage: undefined
      }));
    }
    case GET_ITEM + "_LOADING": {
      const newData = state.get("data") || {};
      newData[action.id] = undefined;
      return state.merge({data: newData, loading: true, errorMessage: undefined});
    }
    case GET_ITEM + "_ERROR": {
      const newData = state.get("data") || {};
      newData[action.id] = undefined;
      return state.merge({data: newData, loading: false, errorMessage: JSON.stringify(action)});
    }
    default: {
      return state;
    }
  }
}

