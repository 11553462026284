type ExpirationConfig = { year?: number; minutes?: number };

type CookieOptions = {
  domain?: string;
  expires?: Date | ExpirationConfig | string;
  ["max-age"]?: number;
};

const dateTypes: Record<string, (date: Date, val: number) => Date> = {
  year(date: Date, val: number) {
    date.setFullYear(date.getFullYear() + val);
    return date;
  },
  minutes(date: Date, val: number) {
    date.setMinutes(date.getMinutes() + val);
    return date;
  }
};

/**
 * Code copied from legacy
 * TODO: update this code when legacy is removed
 * @link https://uptain.atlassian.net/browse/UP-3570
 * @deprecated
 */
export const setCookie = (name: string, value: string | number, opt: CookieOptions): void => {
  const options = {
    path: "/",
    ...opt
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.entries(options).forEach(([key, val]) => {
    updatedCookie += `; ${key}`;
    if (val) {
      updatedCookie += `=${val}`;
    }
  });

  document.cookie = updatedCookie;
};

/**
 * Code copied from legacy
 * TODO: update this code when legacy is removed
 * @link https://uptain.atlassian.net/browse/UP-3570
 * @deprecated
 */
export const getCookieExpirationDate = (date: Date, acc: { year?: number; minutes?: number }) => {
  let dt = date;

  if (!(dt instanceof Date)) dt = new Date();

  Object.entries(acc).forEach(([type, val]) => {
    if (dateTypes[type]) {
      dt = dateTypes[type](dt, val);
    }
  });

  return dt;
};

/**
 * Code copied from legacy
 * TODO: update this code when legacy is removed
 * @link https://uptain.atlassian.net/browse/UP-3570
 * @deprecated
 */
export const deleteCookie = (name: string) => {
  setCookie(name, "", {
    domain: process.env.REACT_APP_COOKIE_DOMAIN ?? ".uptain.de",
    "max-age": -1
  });
};
