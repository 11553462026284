export const GET_TEMPLATES = 'customerBackend/activation/design/GET_TEMPLATES';
export const GET_VERSIONS = 'customerBackend/activation/design/GET_VERSIONS';
export const GET_ITEM = 'customerBackend/activation/design/GET_ITEM';
export const SET_ITEM = 'customerBackend/activation/design/SET_ITEM';
export const CREATE_NEW_ACTIVATION_VERSION = 'customerBackend/activation/design/CREATE_NEW_ACTIVATION_VERSION';
export const UPDATE_ACTIVATION_VERSION = 'customerBackend/activation/design/UPDATE_ACTIVATION_VERSION';
export const CREATE_LIVE_VERSION = 'customerBackend/activation/design/CREATE_LIVE_VERSION';
export const ACTIVATE_VERSION = 'customerBackend/activation/design/ACTIVATE_VERSION';


export const getTemplates = () => ({
  type: GET_TEMPLATES,
  meta: {
    type: 'api',
    url: `activationDesigner/templates`,
    method: 'get',
  },
});

export const getItem = (id) => ({
  type: GET_ITEM,
  id,
  meta: {
    type: 'api',
    url: `activationDesigner/templates/`+id,
    method: 'get',
  },
});


export const saveItem = (key, content, field) => ({
  type: SET_ITEM,
  meta: {
    type: 'api',
    url: `activationDesigner/templates/`+key+"/"+field,
    method: 'put',
    data:content,
  },
});

export const saveNewVersion = (content) => ({
  type: CREATE_NEW_ACTIVATION_VERSION,
  meta: {
    type: 'api',
    url: `activationDesigner/versions`,
    method: 'post',
    data:content,
  },
});

export const updateVersion = (id,content) => ({
  type: UPDATE_ACTIVATION_VERSION,
  meta: {
    type: 'api',
    url: `activationDesigner/versions/${id}`,
    method: 'put',
    data:content,
  },
});

export const getVersions = () => ({
  type: GET_VERSIONS,
  meta: {
    type: 'api',
    url: `activationDesigner/versions`,
    method: 'get',
  },
});

export const createLiveVersion = (id) => ({
  type: CREATE_LIVE_VERSION,
  meta: {
    type: 'api',
    url: `activationDesigner/versions/${id}/live`,
    method: 'post',
  },
  id,
});

export const activateVersion = (id) => ({
  type: ACTIVATE_VERSION,
  meta: {
    type: 'api',
    url: `activationDesigner/versions/${id}/toggle`,
    method: 'post',
  },
  id,
});
