import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, updateSyncWarnings } from 'redux-form/immutable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import AceEditor from 'react-ace';

import FieldsHOC from '../FieldsHOC/index';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-less';

const Label = styled.div`
  margin-left: 3px;
  margin-bottom: 3px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: ${(props) => props.height};
  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
  }
  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }

  & > .ace_editor {
    z-index: 0;
  }
`;

const Brackets = styled.div`
  flex: 1 0 auto;
  font-size: 23px;
  margin-right: 5px;
`;

const Editor = ({
  input,
  meta,
  mode = 'typescript',
  width,
  height,
  givenSignature = undefined,
  validateCustom = {},
}) => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      if (Object.values(validateCustom).some((item) => !!item)) {
        dispatchUpdate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function dispatchUpdate(error = '') {
    dispatch(
      updateSyncWarnings(meta.form, {
        [input.name]: error || undefined,
      }),
    );
  }

  function onValidate(value, annotations) {
    const { editor = false, dom = false } = validateCustom;
    let error;

    if (dom) {
      error = /(window|document)/.test(value) ? 'syntaxDOMError' : error;
    }

    if (editor) {
      error = annotations.some((el) => el?.type === 'error')
        ? 'syntaxError'
        : error;
    }

    dispatchUpdate(error);
  }

  function getValue(val, action) {
    switch (action) {
      case 'onChange':
        return givenSignature ? `${givenSignature}${val}}` : val;
      case 'onInit':
        return givenSignature
          ? val.replace(givenSignature, '').slice(0, -1)
          : val;
      default:
        return null;
    }
  }

  function onChange(newValue) {
    input.onChange(getValue(newValue, 'onChange'));
  }

  return (
    <Wrapper height={height || 'auto'}>
      {givenSignature && <Brackets>{givenSignature}</Brackets>}
      <AceEditor
        mode={mode}
        theme="monokai"
        value={getValue(input.value, 'onInit')}
        name={input.name}
        onChange={onChange}
        onValidate={(annotations) => onValidate(input.value, annotations)}
        width={width || '100%'}
        height={height || '150px'}
        showGutter
        editorProps={{
          $blockScrolling: Infinity,
        }}
      />
      {givenSignature && <Brackets>{'}'}</Brackets>}
    </Wrapper>
  );
};

Editor.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  mode: PropTypes.string,
  givenSignature: PropTypes.string,
  validateCustom: PropTypes.objectOf(PropTypes.bool),
};

const UpCodeEditor = ({ name, label, ...props }) => (
  <>
    {label && <Label>{label}</Label>}
    <Field name={name} component={Editor} {...props} />
  </>
);

UpCodeEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default UpCodeEditor;

export const renderEditor = FieldsHOC(Editor);
