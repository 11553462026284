import { MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PathConfig } from "../../types";
import { useSelectedShop } from "../useSelectedShop";

export const useAccountNavigation = () => {
  const navigate = useNavigate();

  const { selectedShopId } = useSelectedShop();

  return useCallback(
    ({ path, includeShopId }: PathConfig) =>
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate({
          pathname: path,
          search: includeShopId ? `?shop=${selectedShopId}` : ""
        });
      },
    [selectedShopId, navigate]
  );
};
