import { styled, Box as MuiBox } from "@mui/material";
import { InputWrapperProps } from "./CustomerSupportAvailability.props";

export const InputWrapper = styled((props: InputWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "hasError"
})(
  ({ hasError }) => `
    &.MuiBox-root {
      margin: 40px 0 ${hasError ? "0" : "46px"};
    }
  `
);
