import { styled, Box as MuiBox } from "@mui/material";
import { OptionsWrapperProps } from "./Products.props";

export const OptionsWrapper = styled((props: OptionsWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "error"
})(
  ({ theme: { breakpoints }, error }) => `
    &.MuiBox-root {
      margin: 80px 0 ${error ? "0px" : "56px"};
      display: grid;
      grid-template-columns: repeat(auto-fill, 260px);
      grid-column-gap: auto;
      justify-content: center;
      grid-row-gap: 32px;
      grid-column-gap: 32px;

      ${[breakpoints.up("md")]} {
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
  `
);
