import {
  Button as MuiButton,
  styled,
  ButtonProps,
  BoxProps,
  Typography as MuiTypography,
  Box as MuiBox,
  IconButton as MuiIconButton
} from "@mui/material";
import { ImgHTMLAttributes } from "react";
import { ActiveProps, TileSizeParams } from "./Tiles.props";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check_icon.svg";
import { ReactComponent as UncheckIcon } from "../../../../assets/icons/unchecked_icon.svg";

export const Wrapper = styled((props: TileSizeParams & BoxProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "height"
})(
  ({ width, height }) => `
    &.MuiBox-root {
      position: relative;
      width: ${width ?? 168}px;
      height: ${height ?? 168}px;
    }
  `
);

export const Check = styled(CheckIcon)(
  () => `
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 20;
    cursor: pointer;
  `
);

export const Uncheck = styled(UncheckIcon)(
  () => `
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 20;
    cursor: pointer;
  `
);

export const Button = styled(
  (props: ButtonProps & ActiveProps) => <MuiButton {...props} disableRipple />,
  {
    shouldForwardProp: (prop) => prop !== "active"
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    active
  }) => `
    &.MuiButton-root {
      width: 100%;
      height: 100%;
      background-color: ${active ? custom.grey.light : custom.grey.dark};
      border-radius: 10px;
  
      &:hover {
        background-color: ${custom.white.dark};
      }
    }
  `
);

export const Label = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
  &.MuiTypography-root {
    text-transform: initial;
    z-index: 20;
    position: absolute;
    top: 11px;
    padding: 0 30px;
    width: 100%;
    text-align: center;
    color: ${custom.white.main};
    font-size: ${body1.mobile.fontSize};
    font-weight: ${body1.mobile.fontWeight};
    line-height: ${body1.mobile.lineHeight};

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      font-weight: ${body1.tablet.fontWeight};
      line-height: ${body1.tablet.lineHeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      font-weight: ${body1.desktop.fontWeight};
      line-height: ${body1.desktop.lineHeight};
    }
  }
`
);

export const Img = styled(
  (props: ImgHTMLAttributes<HTMLImageElement> & ActiveProps) => <img {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "active"
  }
)(
  ({ active }) => `
    opacity: ${active ? 1 : 0.5};
    z-index: 1;
  `
);

export const InfoButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      z-index: 20;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      color: ${custom.white.light};
    }
  `
);

export const InfoBox = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 35px 8px 10px;
      background-color: ${custom.grey.dark}EE;
      border-radius: 10px;
    }
  `
);

export const InfoBoxText = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      text-align: center;
      font-size: ${body1.mobile.fontSize};
      font-weight: ${body1.mobile.fontWeight};
      line-height: ${body1.mobile.lineHeight};
      color: ${custom.white.light};
      text-transform: initial;
    }
  `
);
