import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const InputsWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin: 32px 0 12px;

      ${[breakpoints.up("md")]} {
        margin: 80px 0 44px;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  `
);

export const InputColumn = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      width: 200px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      ${[breakpoints.up("md")]} {
        &:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 32px;
        }
      }
    }
  `
);

export const InputColumnName = styled(MuiTypography)(
  ({
    theme: {
      typography: { body2 },
      palette: { custom }
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body2.mobile.fontSize};
      line-height: ${body2.mobile.lineHeight};
      font-weight: ${body2.mobile.fontWeight};
      color: ${custom.white.main};
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  `
);
