import {
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
  AccordionProps,
  styled,
  Typography as MuiTypography,
  AccordionDetails as MuiAccordionDetails
} from "@mui/material";
import { StepperTabTypographyProps } from "./StepperTab.props";

export const StepperTabAccordion = styled((props: AccordionProps) => (
  <MuiAccordion {...props} disableGutters />
))(
  () => `
    &.MuiAccordion-root {
      background-color: transparent;
      box-shadow: none;
      margin-bottom: 32px;

      &:before {
        display: none;
      },

      &.Mui-disabled {
        background-color: transparent;
      }
    }
  `
);

export const StepperTabSummary = styled(MuiAccordionSummary)(
  () => `
    &.MuiAccordionSummary-root {
      display: flex;
      flex-direction: row;
      padding: 0;
      min-height: initial;

      &.Mui-disabled {
        opacity: 1;
      }

      & .MuiAccordionSummary-content {
        align-items: center;
        margin: 0;
      }
    }
  `
);

export const StepperTabTypography = styled(
  ({ ...props }: StepperTabTypographyProps) => <MuiTypography {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "completed" && prop !== "selected"
  }
)(
  ({
    theme: {
      typography: { body1 },
      palette: { custom }
    },
    completed,
    selected
  }) => {
    const defaultColor = completed ? custom.white.dark : custom.white.light;

    const color = selected ? custom.turquoise.light : defaultColor;

    return `
      &.MuiTypography-root {
        color: ${color};
        font-size: ${body1.desktop.fontSize};
        font-weight: ${body1.desktop.fontWeight};
        line-height: ${body1.desktop.lineHeight};
        text-transform: uppercase;
        margin: 0 20px 0 8px;
      }
    `;
  }
);

export const StepperTabDetails = styled(MuiAccordionDetails)(
  () => `
    &.MuiAccordionDetails-root {
      padding: 5px 0 0 33px;
    }
  `
);
