import { FieldValidator } from "final-form";

export const utmSourceValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!value.trim()) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 128) {
      return "featuresModule.errors.lessThan128Characters";
    }
  }
};

export const utmMediumValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!value.trim()) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 128) {
      return "featuresModule.errors.lessThan128Characters";
    }
  }
};

export const utmCampaignValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!value.trim()) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 128) {
      return "featuresModule.errors.lessThan128Characters";
    }
  }
};

export const utmContentValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!value.trim()) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 128) {
      return "featuresModule.errors.lessThan128Characters";
    }
  }
};

export const customParameterValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (!value.trim()) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 255) {
      return "featuresModule.errors.lessThan255Characters";
    }
  }
};
