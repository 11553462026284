export enum LegacyCurrencies {
  EUR = "VOUCHER_TYPE_EUR",
  USD = "VOUCHER_TYPE_USD",
  GBP = "VOUCHER_TYPE_GBP",
  CHF = "VOUCHER_TYPE_CHF",
  SEK = "VOUCHER_TYPE_SEK",
  PLN = "VOUCHER_TYPE_PLN",
  PERCENTAGE = "VOUTHCER_TYPE_PERCENTAGE"
}

export enum Currencies {
  EUR = "VOUCHER_TYPE_EUR",
  USD = "VOUCHER_TYPE_USD",
  GBP = "VOUCHER_TYPE_GBP",
  CHF = "VOUCHER_TYPE_CHF",
  SEK = "VOUCHER_TYPE_SEK",
  PLN = "VOUCHER_TYPE_PLN",
  PERCENTAGE = "VOUCHER_TYPE_PERCENTAGE"
}
