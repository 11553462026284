import { Box as MuiBox, styled, Typography } from "@mui/material";
import Avatar from "../../../../assets/images/avatar_icon.png";
import { AccountBlockProps } from "./AccountBlock.props";
import { useTranslation } from "react-i18next";

export const AccountBlock = styled(({ shopId, ...props }: AccountBlockProps) => {
  const { t } = useTranslation();

  return (
    <MuiBox {...props}>
      <img src={Avatar} />
      <MuiBox>
        <Typography variant="body2">
          {t("navigation.accountMenu.accountBlock.customer", { shopId })}
        </Typography>
        <Typography variant="body2">uptain.de</Typography>
      </MuiBox>
    </MuiBox>
  );
})(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      padding: 20px 0 8px;

      & > img { 
        margin-right: 19px;
      }

      & > .MuiBox-root {
        & > .MuiTypography-root:first-of-type {
          color: ${custom.white.main};
          text-transform: uppercase;
        }

        & > .MuiTypography-root:last-of-type {
          color: ${custom.white.light};
        }
      }
    }
  `
);
