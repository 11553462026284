import { styled, Box as MuiBox } from "@mui/material";

export const ButtonsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 24px;
    }
  `
);
