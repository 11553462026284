import styled, { CSSObject } from 'styled-components';

interface StyleProps {
  margin: string;
  alignItems: string;
  justifyContent: string;
  flexDirection: string;
  width: string;
  flexWrap: string;
  flex: string;
  mediaQueries: string;
  styles: string | CSSObject;
}

export const UpFlexRow = styled.div<Partial<StyleProps>>`
  display: flex;
  margin: ${(props) => props.margin || 'unset'};
  align-items: ${(props) => props.alignItems || 'stretch'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  width: ${(props) => props.width || 'auto'};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
  flex: ${(props) => props.flex || '0 1 auto'};
  ${(props) => props.mediaQueries};
  ${(props) => props.styles};
`;

export default UpFlexRow;
