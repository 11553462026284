export enum Routes {
  LOGIN = "/login",
  REGISTER = "/register",
  RESET_PASSWORD = "/password-reset",
  SET_PASSWORD = "/set-password/:key",
  DASHBOARD = "/dashboard",
  MAINTENANCE = "/maintenance",
  ACTIVATION_POPUPS = "/features/activation-popups",
  ABANDONMENT_MAILS = "/features/abandonment-mails",
  NEWSLETTER_FORMS = "/features/newsletter-forms",
  BROWSE_ABANDONMENT = "/features/browse-abandonment",
  COME_BACK_MAILING = "/features/come-back-mailing",
  INTEGRATIONS = "/integrations",
  ALGORITHM = "/algorithm",
  SHOP_SETTINGS = "/products/shop-configuration",
  TRIGGER_MAILS_PAYMENT_SETTINGS = "/products/trigger-mails-payment-settings",
  SHOP_LINKS = "/shop-links",
  CONTACT_PERSONS = "/contact-persons",
  TRACKING_SETTINGS = "/products/tracking",
  REPORTED_REGAINS = "/admin/reported-regains",
  REPORTED_SUBSCRIPTION = "/admin/reported-subscriptions",
  SHOP_ADMINISTRATION = "/admin/store-administration",
  USER_ADMINISTRATION = "/admin/user-administration",
  FEATURE_TOGGLES = "/admin/feature-toggles",
  ACTIVATION_DESIGNER = "/activationDesigner",
  ACTIVATION_MANAGER = "/activationManager",
  TEMPLATE_TEXT_DESIGNER = "/admin/editor/content",
  TEMPLATE_EDITOR = "/admin/template-editor",
  MAIL_TEMPLATES = "/mailTemplates",
  AB_TEST = "/abtests",
  PROVISION_DASHBOARD = "/sales/provision",
  FUZZY_EDITOR = "/fuzzyEditor",
  TEST_PRICING_FORM = "/admin/test-pricing-form",
  MAIL_CANCELLATION_DROPS = "/analysis/mainCancellationDrops",
  ACTIVATION_CANCELLATION_DROPS = "/analysis/activationCancellationDrops",
  MAIL_REGAIN_DROPS = "/analysis/mailRegainDrops",
  ACTIVATION_REGAIN_DROPS = "/analysis/activationRegainDrops",
  SESSION_DROPS = "/analysis/sessionDrops",
  ORDER_DROPS = "/analysis/orderDrops",
  ZERO_SCV = "/analysis/zeroScv",
  INVOICE_DROPS = "/analysis/invoiceDrops",
  INVOICES = "/analysis/invoices",
  ACCOUNT_SETTINGS = "/account/my-account",
  QUESTIONNAIRE = "/account/onboarding-questionnaire"
}
