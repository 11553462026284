import styled, { keyframes } from 'styled-components';

interface FieldProps {
  width?: string;
}

interface ErrorMessageProps {
  position?: string;
}

const shake = keyframes`
  8%, 41% {
    transform: translateX(-10px);
  }
  25%, 58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%, 100% {
    transform: translateX(0);
  }
`;

const shakeSafari = keyframes`
  8%, 41% {
  -webkit-transform: translateX(-10px);
  }
  25%, 58% {
  -webkit-transform: translateX(10px);
  }
  75% {
  -webkit-transform: translateX(-5px);
  }
  92% {
  -webkit-transform: translateX(5px);
  }
  0%, 100% {
  -webkit-transform: translateX(0);
  }
`;

export const ErrorMessage = styled.div<ErrorMessageProps>`
  color: #fff;
  position: absolute;
  right: 0;
  background: ${(props) => props.theme.primaryActionColor};
  padding: 5px 5px;
  box-sizing: border-box;
  font-size: 13px;
  border-radius: 3px;
  z-index: 10;
  bottom: ${(props) => (props.position === 'top' ? '37px' : 'auto')};

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: ${(props) => (props.position === 'top' ? '8px' : '-8px')};
    top: ${(props) => (props.position === 'top' ? 'auto' : '0px')};
    bottom: ${(props) => (props.position === 'top' ? '-12px' : 'auto')};
    box-sizing: border-box;
    border: 6px solid black;
    border-color: transparent transparent
      ${(props) => props.theme.primaryActionColor}
      ${(props) => props.theme.primaryActionColor};
    transform-origin: 0 0;
    transform: ${(props) => props.position === 'top' ? 'rotate(-45deg)' : 'rotate(135deg)'};
  }
`;

export const ErrorIcon = styled.div`
  color: ${(props) => props.theme.primaryActionColor};
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 9;
`;

export const Field = styled.div<FieldProps>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width || 'auto'};
  &.errorAnimation {
    animation: ${shake} 0.5s linear;
    -webkit-animation: ${shakeSafari} 0.5s linear;
  }
`;
