import { NavigationBarDrawer } from "../NavigationBarDrawer";
import { NavigationDrawerButton } from "../NavigationDrawerButton";
import { NavigationDrawerProps } from "./NavigationDrawer.props";

export const NavigationDrawer = ({
  onClose,
  open,
  routes,
  shopId,
  onNavigate
}: NavigationDrawerProps) => {
  return (
    <NavigationBarDrawer variant="temporary" anchor="left" open={open} onClose={onClose}>
      {routes.map((route) => (
        <NavigationDrawerButton
          onClick={onNavigate}
          shopId={shopId}
          key={route.translationKey}
          route={route}
        />
      ))}
    </NavigationBarDrawer>
  );
};
