import { useCallback } from "react";
import { FormProps } from "react-final-form";
import { useErrorFormatter, NetworkError } from "shared";
import { QuestionnaireSignatureStepData, QuestionnaireStepType } from "api";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitSignature } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useSignatureStep = () => {
  const { prevStep, loading, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.SIGNATURE);

  const data = stepData?.data;

  const submitSignature =
    useQuestionnaireSubmitMutation<QuestionnaireSignatureStepData>(putSubmitSignature);

  const executeSubmitSignature: FormProps<
    QuestionnaireSignatureStepData & { codeView: boolean },
    QuestionnaireSignatureStepData & { codeView: boolean }
  >["onSubmit"] = useCallback(
    async ({ emailSignature }) => {
      try {
        await submitSignature({
          emailSignature
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitSignature]
  );

  return {
    data: { ...data, codeView: false },
    loading,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitSignature
  };
};
