import { createContext } from "react";
import { QueryConfigContextState } from "./queryConfig.types";

const initialState: QueryConfigContextState = {
  queryName: "",
  offset: 0,
  limit: 0,
  getPage: () => {},
  getFirstPage: () => {},
  setPaginationState: () => {}
};

export const queryConfigContext = createContext<QueryConfigContextState>(initialState);

export const QueryConfigContextProvider = queryConfigContext.Provider;
