import React from 'react';
import { useSelector } from 'react-redux';
import { useFeatures } from '@paralleldrive/react-feature-toggles';

import CreateNewShop from './CreateNewShop';

const OnBoarding = () => {
  const features = useFeatures();
  const isFetching = useSelector((state) =>
    state.getIn(['global', 'featureToggles', 'UIStates', 'fetching']),
  );

  if (isFetching || features.includes('onboardingQuestionnaire')) return null;

  /**
   * To prevent getting incorrect values from features array (become empty when fetching)
   * do not render component while fetching feature toggles
   */
  return <CreateNewShop />;
};

export { OnBoarding };
