/** STATUS_META function returns meta object for specified status
 * @param {string} details - response error details
 */
const STATUS_META = (details = '') => ({
  500: {
    status: '500',
    msg: 'Error on server. Please try again in a few minutes.',
    details,
  },
  400: {
    status: '400',
    msg: 'Something went wrong with the api call.',
  },
  404: {
    status: '404',
    msg: 'The resource you are trying to get or update does not seem to exists',
  },
  406: {
    status: '406',
    msg: 'Not acceptable',
    details,
  },
});

export { STATUS_META };
