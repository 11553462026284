import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { locales } from "config";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: ["localStorage"],
      lookupLocalStorage: "language",
      convertDetectedLanguage: (lng) => lng.substring(0, 2)
    },
    fallbackLng: "en",
    supportedLngs: Object.keys(locales),
    interpolation: {
      escapeValue: false
    },
    resources: locales
  });

export default i18n;
