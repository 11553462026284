import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { Field, useFormState } from "react-final-form";
import { VisibilitySwitch, FieldLine, TextInput } from "shared";
import {
  utmSourceValidator,
  utmMediumValidator,
  utmContentValidator,
  utmCampaignValidator,
  customParameterValidator
} from "./TriggerSettingsParamsFields.validation";
import { VisibilitySwitchWrapper, AdditionalFieldsCollapse } from "../TriggerSettingsFormStyles";

export const TriggerSettingsParamsFields = () => {
  const { t } = useTranslation();

  const { submitErrors } = useFormState();

  const [extraOpened, setExtraOpened] = useState(false);

  const handleExtraOpenedSwitch = useCallback(() => {
    setExtraOpened((prev) => !prev);
  }, []);

  return (
    <>
      <VisibilitySwitchWrapper>
        <VisibilitySwitch active={extraOpened} onClick={handleExtraOpenedSwitch}>
          {t("featuresModule.utmParams.moreSettingsToggle")}
        </VisibilitySwitch>
      </VisibilitySwitchWrapper>
      <AdditionalFieldsCollapse in={extraOpened}>
        <FieldLine>
          <Field
            name="utmSource"
            validate={utmSourceValidator}
            render={({ input, meta: { touched, error, modifiedSinceLastSubmit } }) => (
              <TextInput
                label={t("featuresModule.utmParams.source.label")}
                placeholder={t("featuresModule.utmParams.source.placeholder")}
                {...input}
                error={
                  (error || (submitErrors?.["utmSource"] && !modifiedSinceLastSubmit)) && touched
                }
                errorMessage={t(submitErrors?.["utmSource"] ?? error)}
              />
            )}
          />
          <Field
            name="utmMedium"
            validate={utmMediumValidator}
            render={({ input, meta: { touched, error, modifiedSinceLastSubmit } }) => (
              <TextInput
                label={t("featuresModule.utmParams.medium.label")}
                placeholder={t("featuresModule.utmParams.medium.placeholder")}
                {...input}
                error={
                  (error || (submitErrors?.["utmMedium"] && !modifiedSinceLastSubmit)) && touched
                }
                errorMessage={t(submitErrors?.["utmMedium"] ?? error)}
              />
            )}
          />
        </FieldLine>
        <FieldLine>
          <Field
            name="utmCampaign"
            validate={utmCampaignValidator}
            render={({ input, meta: { touched, error, modifiedSinceLastSubmit } }) => (
              <TextInput
                label={t("featuresModule.utmParams.campaign.label")}
                placeholder={t("featuresModule.utmParams.campaign.placeholder")}
                {...input}
                error={
                  (error || (submitErrors?.["utmCampaign"] && !modifiedSinceLastSubmit)) && touched
                }
                errorMessage={t(submitErrors?.["utmCampaign"] ?? error)}
              />
            )}
          />
          <Field
            name="utmContent"
            validate={utmContentValidator}
            render={({ input, meta: { touched, error, modifiedSinceLastSubmit } }) => (
              <TextInput
                label={t("featuresModule.utmParams.content.label")}
                placeholder={t("featuresModule.utmParams.content.placeholder")}
                {...input}
                error={
                  (error || (submitErrors?.["utmContent"] && !modifiedSinceLastSubmit)) && touched
                }
                errorMessage={t(submitErrors?.["utmContent"] ?? error)}
              />
            )}
          />
        </FieldLine>
        <Field
          name="customParameter"
          validate={customParameterValidator}
          render={({ input, meta: { touched, error, modifiedSinceLastSubmit } }) => (
            <TextInput
              label={t("featuresModule.utmParams.custom.label")}
              placeholder={t("featuresModule.utmParams.custom.placeholder")}
              marginBottom={24}
              {...input}
              error={
                (error || (submitErrors?.["customParameter"] && !modifiedSinceLastSubmit)) &&
                touched
              }
              errorMessage={t(submitErrors?.["customParameter"] ?? error)}
            />
          )}
        />
      </AdditionalFieldsCollapse>
    </>
  );
};
