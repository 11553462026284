import { styled, Box as MuiBox } from "@mui/material";

export const FieldsWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, fit-content(100%));

      ${breakpoints.up("lg")} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: fit-content(100%) 1fr fit-content(100%);
      }
    }
  `
);

export const InputsWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      padding: 23px 0 0 0;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 2;

      ${breakpoints.up("lg")} {
        padding: 23px 0 0 12px;
      }
    }
  `
);

export const FontInputsWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
    }
  `
);

export const FontSelectWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      flex: 2;
    }
  `
);

export const ColorWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      padding: 20px 0 0 0;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 3;
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      height: 260px;
      align-self: flex-start;

      ${breakpoints.up("lg")} {
        align-content: flex-start;
        padding: 40px 0 0 6px;
      }
    }
  `
);

export const PreviewWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 4;
      padding-top: 20px;

      ${breakpoints.up("lg")} {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  `
);
