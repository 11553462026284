import { CircularProgress as MuiCircularProgress, Box as MuiBox, styled } from "@mui/material";

export const LoadingProgress = styled(MuiCircularProgress)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiCircularProgress-root {
      color: ${custom.turquoise.main};
    }
  `
);

export const LoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
);
