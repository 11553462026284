import Immutable from 'immutable';
import { GET_CANCELLATIONS } from './actions';

export function dropsReducer(
  state = Immutable.fromJS({
    loading: {},
    data: {},
    errorMessage: {},
  }),
  action,
) {
  const jsState = state.toJS();
  const { data, loading, errorMessage } = jsState;

  const key = `${action.hours}-${action.factor}-${action.tableType}-${action.productFilter}`;

  switch (action.type) {
    case `${GET_CANCELLATIONS}_LOADING`: {
      data[key] = undefined;
      loading[key] = true;
      delete errorMessage[key];

      return state.merge({ data, loading, errorMessage });
    }
    case `${GET_CANCELLATIONS}_ERROR`: {
      data[key] = undefined;
      loading[key] = false;
      errorMessage[key] = JSON.stringify(action);

      return state.merge({ data, loading, errorMessage });
    }
    case GET_CANCELLATIONS: {
      data[key] = action.data;
      loading[key] = false;
      delete errorMessage[key];

      return state.merge(
        Immutable.fromJS({
          data,
          loading,
          errorMessage,
        }),
      );
    }

    default: {
      return state;
    }
  }
}
