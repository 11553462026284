export enum QuestionnaireShopSystem {
  SHOPWARE_5 = "Shopware 5",
  SHOPWARE_6 = "Shopware 6",
  MAGENTO_1 = "Magento 1.x",
  MAGENTO_2 = "Magento 2.x",
  JTL_4 = "JTL 4",
  JTL_5 = "JTL 5",
  OXID = "OXID",
  VERSA_COMMERCE = "VersaCommerce",
  POWERGAP = "Powergap",
  PRESTASHOP = "Prestashop",
  PLENTYMARKETS = "Plentymarkets",
  SHOPIFY = "Shopify",
  WOOCOMMERCE = "Woocommerce",
  XONIC = "Xonic",
  GOOGLE_TAG_MANAGER = "Google Tag Manager",
  JAVA_SCRIPT = "JavaScript"
}
