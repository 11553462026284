import { styled, Box as MuiBox, Typography } from "@mui/material";

export const CheckWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 40px;
      justify-content: space-between;
    }
  `
);

export const FooterText = styled(Typography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
  &.MuiTypography-root {
    color: ${custom.white.main};
    font-size: ${body1.mobile.fontSize};
    line-height: ${body1.mobile.lineHeight};
    font-weight: ${body1.mobile.fontWeight};
    margin-top: 32px;
    text-align: center;

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      line-height: ${body1.tablet.lineHeight};
      font-weight: ${body1.tablet.fontWeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      line-height: ${body1.desktop.lineHeight};
      font-weight: ${body1.desktop.fontWeight};
    }
  }
`
);
