import styled from 'styled-components';

export const InputGroup = styled.div<{ margin?: string; flex?: string }>`
  margin: ${(props) => props.margin || 'unset'};
  flex: ${(props) => props.flex || 'none'};
`;

export const Label = styled.div`
  display: flex;
  font-weight: 500;
  margin-left: 3px;
  margin-bottom: 3px;

  .asterisk {
    font-size: 20px;
    line-height: 20px;
  }
`;
