import { apiClient } from "shared";

export const postBrowseAbandonmentTextEnable = async ({
  shopId,
  id
}: {
  shopId: number;
  id: number;
}) => {
  const { data } = await apiClient.post<void>(
    `/${shopId}/triggers/browse-abandonment/text-settings/${id}/enable`
  );

  return data;
};
