/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import appReducer from './containers/App/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import customerBackendDashboardRootReducer from './reducers/index';
import authReducer from './containers/IntegrationSettings/Integrations/IntegrationImplementations/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: appReducer,
    language: languageProviderReducer,
    customerBackendDashboard: customerBackendDashboardRootReducer,
    form: formReducer,
    Auth: authReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
