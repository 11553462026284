import { styled, Box as MuiBox } from "@mui/material";

export const OperatorWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: 100%;

      ${breakpoints.up("md")} {
        min-width: 160px;
        width: auto;
        margin-right: 8px;
      }
    }
  `
);

export const ValueWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: 100%;
      flex: 1;

      ${breakpoints.up("md")} {
        max-width: 508px;
        width: auto;
      }

      ${breakpoints.up("xl")} {
        max-width: 658px;
      }
    }
  `
);
