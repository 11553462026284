import { Typography as MuiTypography, styled } from "@mui/material";

export const Heading = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.turquoise.main};
      font-size: ${body2.desktop.fontSize};
      line-height: ${body2.desktop.lineHeight};
      font-weight: ${body2.desktop.fontWeight};

      ${[breakpoints.up("md")]} {
        font-size: ${body2.tablet.fontSize};
        line-height: ${body2.tablet.lineHeight};
        font-weight: ${body2.tablet.fontWeight};
      }
  
      ${[breakpoints.up("lg")]} {
        font-size: ${body2.desktop.fontSize};
        line-height: ${body2.desktop.lineHeight};
        font-weight: ${body2.desktop.fontWeight};
      }
    }
  `
);
