import { ColorValue } from "../../../types";

export const convertHexToRGBA = (hex: string): ColorValue => {
  const rgbArray = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

  const alphaPart = hex.slice(7, 9) ? hex.slice(7, 9) : "FF";
  const a = Number(((parseInt(alphaPart, 16) / 255) * 100).toFixed(0));

  return {
    r: rgbArray && rgbArray[0] ? rgbArray[0] : 0,
    g: rgbArray && rgbArray[1] ? rgbArray[1] : 0,
    b: rgbArray && rgbArray[2] ? rgbArray[2] : 0,
    a
  };
};

export const convertAlphaToHex = (alpha: number): string => {
  const colorValue = Number(((alpha / 100) * 255).toFixed(0));

  if (colorValue === 0) {
    return "00";
  }

  return colorValue.toString(16).padStart(2, "0");
};
