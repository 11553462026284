/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl } from 'react-intl';
import { renderField } from '../../UpInput';

import { selectBox } from '../../SelectBox';

import { creatable } from '../../UpStyledCreatable';
import UpRegex from '../../UpRegex';
import FormValueSelector from '../../UpFormValueSelector';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
    /* margin-right: 10px; */
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
  @media only screen and (max-width: 680px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const Element = (props) => (
  <Filter>
    <Row>
      <Field
        name={`${props.member}.filter.element`}
        type="text"
        component={renderField}
        placeholder="CSS Selector"
      />
      <Field
        name={`${props.member}.filter.operator`}
        component={selectBox}
        customProps={{
          options: [
            // { value: 'clicked', label: 'clicked' },
            {
              value: 'exists',
              label: props.intl.formatMessage(messages.exists),
            },
            {
              value: 'exists-not',
              label: props.intl.formatMessage(messages.existsNot),
            },
            {
              value: 'contains',
              label: props.intl.formatMessage(messages.contains),
            },
            {
              value: 'contains-not',
              label: props.intl.formatMessage(messages.containsNot),
            },
            {
              value: 'regex',
              label: props.intl.formatMessage(messages.matchesRegex),
            },
          ],

          margin: '0 0 0 10px',
          onChange: () => props.resetOnChange(),
        }}
      />
    </Row>
    <FormValueSelector
      fieldName={`conditions[${props.member
        .split('[')[1]
        .charAt(0)}]filter.operator`}
    >
      {(operator) => {
        if (operator === 'contains' || operator === 'contains-not') {
          return (
            <Row margin="10px 0 0 0">
              <Field
                name={`${props.member}.filter.attribute`}
                component={creatable}
              />
            </Row>
          );
        }

        if (operator === 'regex') {
          return (
            <Row>
              <UpRegex
                name={`${props.member}.filter.attribute[0].value`}
                margin="10px 10px 0 0"
                width="100%"
                fieldValidation={false}
              />
            </Row>
          );
        }
      }}
    </FormValueSelector>
  </Filter>
);

Element.propTypes = {
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(Element);
