import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

export const ContainedButton = styled(LoadingButton)(
  ({
    theme: {
      palette: { custom },
      typography: { button },
      breakpoints
    }
  }) => {
    return `
      color: ${custom.white.main};
      background-color: ${custom.coral.main};
      font-size: ${button.mobile.fontSize};
      line-height: ${button.mobile.lineHeight};
      font-weight: ${button.mobile.fontWeight};
      text-transform: uppercase;
      border-radius: 24px;
      height: 48px;
      transition: all 0.2s ease-in-out;

      ${[breakpoints.up("md")]} {
        font-size: ${button.tablet.fontSize};
        line-height: ${button.tablet.lineHeight};
        font-weight: ${button.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${button.desktop.fontSize};
        line-height: ${button.desktop.lineHeight};
        font-weight: ${button.desktop.fontWeight};
      }

      :disabled {
        color: ${custom.white.light};
        background-color: ${custom.grey.light};

        &.MuiLoadingButton-loading {
          color: transparent;
        }
      }

      :hover {
        color: ${custom.white.main};
        background-color: ${custom.coral.main};
        transform: translateY(-8px);
        border: 2px solid ${custom.coral.light};
        box-shadow: 0px 3px 10px ${custom.coral.light};

        :active {
          transition: all 0.05s ease-in-out;
          transform: translateY(0);
          box-shadow: none;
        }
      }

      & .MuiLoadingButton-loadingIndicator {
        color: ${custom.white.light};
      }
    `;
  }
);
