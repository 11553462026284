import { Permission } from "shared";
import { AccountMenuRouteConfig } from "./navigation.types";
import { Routes } from "./routes.enum";

export const accountMenuConfig: AccountMenuRouteConfig[] = [
  {
    path: Routes.ACCOUNT_SETTINGS,
    translationKey: "navigation.accountMenu.accountSettings",
    includeShopId: true,
    legacy: true,
    requiredPermission: Permission.USER
  },
  {
    path: Routes.QUESTIONNAIRE,
    translationKey: "navigation.accountMenu.startingWithUptain",
    includeShopId: true,
    badgeKey: "questionnaireIncomplete",
    legacy: true,
    requiredPermission: Permission.USER
  },
  {
    path: Routes.SHOP_LINKS,
    translationKey: "navigation.accountMenu.links",
    includeShopId: true
  },
  {
    path: Routes.CONTACT_PERSONS,
    translationKey: "navigation.accountMenu.contactPersons",
    includeShopId: true
  }
];
