import { GET_DATA } from '../containers/actionTypes';

export const LOGOUT = 'react-boilerplate/Dashboard/LOGOUT';
export const GET_SHOP_LIST = 'react-boilerplate/Dashboard/GET_SHOP_LIST';
export const SET_SHOP_NAME = 'react-boilerplate/Dashboard/SET_SHOP_NAME';
export const GET_PRODUCT_LIST = 'react-boilerplate/Dashboard/GET_PRODUCT_LIST';
export const SET_USER_ROLE = 'react-boilerplate/Dashboard/SET_USER_ROLE';
export const SWITCH_SHOP = 'react-boilerplate/Dashboard/SWITCH_SHOP';
export const GET_TAX_RATES = 'react-boilerplate/Dashboard/GET_TAX_RATES';
export const GET_MAIL_TEMPLATES =
  'react-boilerplate/Dashboard/GET_MAIL_TEMPLATES';
export const GET_INVOICE_TEMPLATES =
  'react-boilerplate/Dashboard/GET_INVOICE_TEMPLATES';
export const GET_EMPLOYEES = 'react-boilerplate/Dashboard/GET_EMPLOYEES';
export const GET_USER_PERMISSIONS =
  'react-boilerplate/User/GET_USER_PERMISSIONS';
export const GET_IP_ADDR = 'react-boilerplate/User/GET_IP_ADDR';

export const logout = () => ({
  type: LOGOUT,
});

export const getProductList = () => ({
  type: GET_PRODUCT_LIST,
  meta: {
    type: 'api',
    url: 'data/productOptions',
    method: 'get',
  },
});

export const getShopList = () => ({
  type: GET_SHOP_LIST,
  meta: {
    type: 'api',
    url: 'account/shops',
    method: 'get',
  },
});

export const updateShopName = (shop, shopName) => ({
  type: SET_SHOP_NAME,
  name: shopName,
  meta: {
    type: 'api',
    url: `shop/${shop}/name`,
    method: 'post',
    data: {
      name: shopName,
    },
  },
});

export const getUserPermissions = () => ({
  type: GET_USER_PERMISSIONS,
  meta: {
    type: 'api',
    url: 'account/permissions',
    method: 'get',
  },
});

export const setUserRole = (userRole) => ({
  type: SET_USER_ROLE,
  userRole,
});

export const switchShop = (shop, shopId) => ({
  type: SWITCH_SHOP,
  shop,
  shopId,
});

export const getTaxRates = (shopId) => ({
  type: GET_TAX_RATES,
  meta: {
    type: 'api',
    url: 'settings/accounting/taxRates',
    method: 'get',
    params: {
      shopId,
    },
  },
});

export const getMailTemplates = (shopId) => ({
  type: GET_MAIL_TEMPLATES,
  meta: {
    type: 'api',
    url: 'settings/accounting/mailTemplates',
    method: 'get',
    params: {
      shopId,
    },
  },
});

export const getInvoiceTemplates = (shopId) => ({
  type: GET_INVOICE_TEMPLATES,
  meta: {
    type: 'api',
    url: 'settings/accounting/invoiceTemplates',
    method: 'get',
    params: {
      shopId,
    },
  },
});

export const getEmployees = () => ({
  type: GET_EMPLOYEES,
  meta: {
    type: 'api',
    url: 'settings/accounting/employees',
    method: 'get',
  },
});

export const getPreviewTypes = (shopId) => ({
  type: GET_DATA,
  shopId,
  field: 'previews',
  meta: {
    type: 'api',
    url: `shop/${shopId}/activationTemplates`,
    method: 'get',
  },
});

export const getMailPreview = (shopId) => ({
  type: GET_DATA,
  meta: {
    type: 'api',
    url: `shop/${shopId}/mailTemplates`,
    method: 'get',
  },
  shopId,
  field: 'mailTemplates',
});

export const getIPAddress = (shopId) => ({
  type: GET_IP_ADDR,
  shopId,
  meta: {
    type: 'api',
    url: 'ipaddr',
    method: 'get',
  },
});
