import { styled, Box as MuiBox } from "@mui/material";

export const LoadingWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      width: auto;
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      ${[breakpoints.up("lg")]} {
        margin-left: 415px;
      }
    }
  `
);
