import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LinkHrefStyled } from '../../components/LinkStyled';

const Footer = () => (
  <FormattedMessage
    id="containers.onboarding.footer"
    defaultMessage="<p>If you have questions feel free to contact us.</p> Tel: <tel>+49 (0) 40 696 39 10 30</tel> {breakingLine} email: <mail>info@uptain.de</mail>"
    values={{
      p: (msg) => <p>{msg}</p>,
      tel: (msg) => <LinkHrefStyled href={`tel:${msg}`}>{msg}</LinkHrefStyled>,
      mail: (msg) => (
        <LinkHrefStyled href={`mailto:${msg}`} target="_blank">
          {msg}
        </LinkHrefStyled>
      ),
      breakingLine: <br />,
    }}
  />
);

export default Footer;
