/*
 * AppReducer
 *
 * The reducer takes care of main app data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import { combineReducers } from 'redux-immutable';
import { featureTogglesReducer } from '../Admin/FeatureToggles/reducer';

const appReducer = combineReducers({
  featureToggles: featureTogglesReducer,
});

export default appReducer;
