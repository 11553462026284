import { apiClient } from "shared";
import { PaymentSettings } from "api";

export const getComeBackMailingPayment = async (shopId: number) => {
  const { data } = await apiClient.get<PaymentSettings>(
    `${shopId}/triggers/come-back-mailing/payment`
  );

  return data;
};
