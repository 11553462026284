// TODO: after old solution will be removed rework this component into router itself
import React from "react"
import * as modules0 from "./modules/Auth/pages/Login.page.tsx";
import * as modules1 from "./modules/Auth/pages/Register.page.tsx";
import * as modules2 from "./modules/Auth/pages/ResetPassword.page.tsx";
import * as modules3 from "./modules/Auth/pages/SetPassword.page.tsx";
import * as modules4 from "./modules/Features/pages/BrowseAbandonment.page.tsx";
import * as modules5 from "./modules/Features/pages/ComeBackMailing.page.tsx";
import * as modules6 from "./modules/Internal/pages/Maintenance.page.tsx";
import * as modules7 from "./modules/Questionnaire/pages/Questionnaire.page.tsx";
import * as modules8 from "./modules/Settings/pages/ContactPersons.page.tsx";
import * as modules9 from "./modules/Settings/pages/ShopLinks.page.tsx";
import * as modules10 from "./modules/Settings/pages/TriggerMailsPayment.page.tsx";
const modules = [{ 
            getPageConfig: modules0.getPageConfig,
            component: React.lazy(() => import("./modules/Auth/pages/Login.page.tsx")) 
          },{ 
            getPageConfig: modules1.getPageConfig,
            component: React.lazy(() => import("./modules/Auth/pages/Register.page.tsx")) 
          },{ 
            getPageConfig: modules2.getPageConfig,
            component: React.lazy(() => import("./modules/Auth/pages/ResetPassword.page.tsx")) 
          },{ 
            getPageConfig: modules3.getPageConfig,
            component: React.lazy(() => import("./modules/Auth/pages/SetPassword.page.tsx")) 
          },{ 
            getPageConfig: modules4.getPageConfig,
            component: React.lazy(() => import("./modules/Features/pages/BrowseAbandonment.page.tsx")) 
          },{ 
            getPageConfig: modules5.getPageConfig,
            component: React.lazy(() => import("./modules/Features/pages/ComeBackMailing.page.tsx")) 
          },{ 
            getPageConfig: modules6.getPageConfig,
            component: React.lazy(() => import("./modules/Internal/pages/Maintenance.page.tsx")) 
          },{ 
            getPageConfig: modules7.getPageConfig,
            component: React.lazy(() => import("./modules/Questionnaire/pages/Questionnaire.page.tsx")) 
          },{ 
            getPageConfig: modules8.getPageConfig,
            component: React.lazy(() => import("./modules/Settings/pages/ContactPersons.page.tsx")) 
          },{ 
            getPageConfig: modules9.getPageConfig,
            component: React.lazy(() => import("./modules/Settings/pages/ShopLinks.page.tsx")) 
          },{ 
            getPageConfig: modules10.getPageConfig,
            component: React.lazy(() => import("./modules/Settings/pages/TriggerMailsPayment.page.tsx")) 
          }]

export const importedModules = modules;
