import styled, {createGlobalStyle, css} from 'styled-components';

export const ConfigurationWrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background: #1B1E22;
  z-index: 2147483644;

  @media only screen and (min-width: ${(props) => props.theme.xsmall}) {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    width: 80%;
    height: ${(props) => props.height || '60%'};
    max-height: 60%;
    border-radius: 4px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 52px -9px rgba(122, 122, 122, 1);
    -moz-box-shadow: 0px 0px 52px -9px rgba(122, 122, 122, 1);
    box-shadow: 0px 0px 52px -9px rgba(122, 122, 122, 1);
  }

  @media only screen and (min-width: ${(props) => props.theme.small}) {
    width: 70%;
  }

  @media only screen and (min-width: ${(props) => props.theme.large}) {
    width: 50%;
  }
`;

// the header for the config box
export const Heading = styled.header`
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.navColor};
  padding: 0 20px;
  z-index: 400;

  @media only screen and (min-width: ${(props) => props.theme.xsmall}) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

const sharedFontStyles = css`
  font-size: 18px;

  @media only screen and (min-width: ${(props) => props.theme.xsmall}) {
    font-size: 20px;
  }
`;

export const H3 = styled.h3`
  ${sharedFontStyles}
  font-family: 'Exo 2', sans-serif;
  line-height: 30px;
  margin: 0;
`;

export const Button = styled.button`
  ${sharedFontStyles}
  font-weight: 600;
  color: rgba(255, 255, 255, 0.66);
  text-decoration: none;
  padding: 0;

  &:hover,
  &:active,
  &:link {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
`;

// black transparent background for config in modal window
export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2147483643;
  left: 0;
  top: 0;
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 60px);
  padding-top: 20px;
`;

export const HelpWidgetStyle = createGlobalStyle`
  iframe[name='JSD widget']{
    z-index: 2147483642 !important;
  }
`;
