import {
  LabelValuePair,
  QuestionnaireAbandonmentMailsVoucherSettingsStepMetadata,
  QuestionnaireActivationPopupsVoucherSettingsStepMetadata,
  QuestionnaireNewsletterFormsVoucherSettingsStepMetadata,
  LegacyVoucherTypes,
  VoucherTypes
} from "api";
import {
  PrepareListNameValueParams,
  PrepareListVoucherCodesParams,
  QuestionnaireVoucherSettingsFormMetadataState,
  VoucherListControlMode
} from "../types";
import { KeyTitlePair } from "shared";

export const prepareListNameValue = ({
  voucherKind,
  voucherListControlMode,
  listName,
  newVoucherListName
}: PrepareListNameValueParams): string | undefined => {
  const listNameByMode =
    voucherListControlMode === VoucherListControlMode.CREATE ? newVoucherListName : listName;

  //TODO: We will need to remove double types from here with api normalization
  // in scope of UP-4082 https://uptain.atlassian.net/browse/UP-4082
  return voucherKind === LegacyVoucherTypes.LIST || VoucherTypes.LIST ? listNameByMode : undefined;
};

export const prepareListValuesValue = ({
  voucherKind,
  voucherListControlMode,
  extendVoucherListValues,
  newVoucherListValues,
  listVoucherCodes
}: PrepareListVoucherCodesParams): string[] | undefined => {
  const selectModeListValues = extendVoucherListValues
    ? extendVoucherListValues
        .split("\n")
        .map((value) => value.trim())
        .filter(Boolean)
    : listVoucherCodes;

  const listValuesByMode =
    voucherListControlMode === VoucherListControlMode.CREATE
      ? newVoucherListValues
          ?.split("\n")
          .map((value) => value.trim())
          .filter(Boolean)
      : selectModeListValues;

  //TODO: We will need to remove double types from here with api normalization
  // in scope of UP-4082 https://uptain.atlassian.net/browse/UP-4082
  return voucherKind === LegacyVoucherTypes.LIST || VoucherTypes.LIST
    ? listValuesByMode
    : undefined;
};

export const prepareNumberValue = (value?: number): number | undefined => {
  return value !== undefined && value > 0 ? value : undefined;
};

export const prepareValueLabelValue = (value: KeyTitlePair[]): LabelValuePair[] | undefined => {
  return value.length > 0
    ? value.map(({ key, title }) => ({ value: key, label: title }))
    : undefined;
};

export const mapLabelValuePairToKeyTitlePair = (value?: LabelValuePair[]): KeyTitlePair[] => {
  return (value ?? []).map(({ value, label }) => ({ key: value, title: label }));
};

export const parseVoucherSettingsFormStepMetadata = <
  T extends
    | QuestionnaireActivationPopupsVoucherSettingsStepMetadata
    | QuestionnaireAbandonmentMailsVoucherSettingsStepMetadata
    | QuestionnaireNewsletterFormsVoucherSettingsStepMetadata
    | undefined
>(
  metadata: T
): QuestionnaireVoucherSettingsFormMetadataState => {
  const fields = metadata?.fields;

  const minimumOrderValueEnabled = !fields?.hasMinimumOrderValue?.disabled;

  const excludedCustomerGroupsEnabled = !fields?.hasExcludedCustomerGroups?.disabled;

  const excludedPagesToShowEnabled = !fields?.hasExcludedPagesToShowVoucher?.disabled;

  const excludeOrderingValueEnabled = !fields?.excludeWithOrderingValueHigherThan?.disabled;

  return {
    minimumOrderValueEnabled,
    excludedCustomerGroupsEnabled,
    excludedPagesToShowEnabled,
    excludeOrderingValueEnabled
  };
};
