import { QueryConfigContextProvider } from "../../../context";
import { usePagination } from "../../../hooks";
import { QueryConfigProviderProps } from "./QueryConfigProvider.props";

export const QueryConfigProvider = ({
  children,
  queryName,
  initialLimit
}: QueryConfigProviderProps) => {
  const { offset, limit, getPage, getFirstPage, setPaginationState } = usePagination(initialLimit);

  return (
    <QueryConfigContextProvider
      value={{
        queryName,
        limit,
        offset,
        getFirstPage,
        getPage,
        setPaginationState
      }}
    >
      {children}
    </QueryConfigContextProvider>
  );
};
