import { styled, Box as MuiBox } from "@mui/material";

export const VoucherFieldWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    width: 100%;
    margin: 0 auto;

    ${[breakpoints.up("md")]} {
      width: 506px;
    }
`
);
