import React from 'react';
import PropTypes from 'prop-types';

import Loadable from './Loadable';

const PublicRoute = ({
  component: Component,
  parent: ParentComponent,
  loadingState = 'dashboard',
}) =>
  ParentComponent ? (
    <ParentComponent>
      <Loadable component={Component} loadingState={loadingState} />
    </ParentComponent>
  ) : (
    <Loadable component={Component} loadingState={loadingState} />
  );

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  parent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  loadingState: PropTypes.string,
};

export default PublicRoute;
