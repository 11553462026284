import { styled, Box as MuiBox } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      z-index: 5;
      justify-content: space-between;

      ${breakpoints.up("md")} {
        justify-content: center;
      }
    }
  `
);

export const LanguageSwitcherWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-left: 16px;
    }
  `
);
