import { useDispatch } from "react-redux";
import { switchShop } from "legacy/actions/actions";
import { useCallback } from "react";

/**
 * Unsafe because it uses legacy store
 * Should be removed after legacy store is removed
 *
 * @deprecated
 */
export const UNSAFE_useLegacyShopSwitch = () => {
  const dispatch = useDispatch();

  const onShopChange = useCallback((id: string, shopId: number) => {
    dispatch(switchShop(id, shopId));
  }, []);

  return onShopChange;
};
