import { MenuProps } from "@mui/material";
import { MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PathConfig } from "../../../../types";

export const useLegacyAccountNavigation = (
  legacyShopId: number,
  closeMethod?: MenuProps["onClose"]
) => {
  const navigate = useNavigate();

  return useCallback(
    ({ path, includeShopId }: PathConfig) =>
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (closeMethod) {
          closeMethod({}, "backdropClick");
        }
        navigate({
          pathname: path,
          search: includeShopId ? `?shop=${legacyShopId}` : ""
        });
      },
    [legacyShopId, navigate, closeMethod]
  );
};
