import { styled, Typography } from "@mui/material";

export const ErrorText = styled(Typography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
  &.MuiTypography-root {
    color: ${custom.coral.main};
    font-size: ${body1.mobile.fontSize};
    line-height: ${body1.mobile.lineHeight};
    font-weight: ${body1.mobile.fontWeight};
    margin-top: 32px;
    text-align: center;

    ${[breakpoints.up("md")]} {
      font-size: ${body1.tablet.fontSize};
      line-height: ${body1.tablet.lineHeight};
      font-weight: ${body1.tablet.fontWeight};
    }

    ${[breakpoints.up("lg")]} {
      font-size: ${body1.desktop.fontSize};
      line-height: ${body1.desktop.lineHeight};
      font-weight: ${body1.desktop.fontWeight};
    }
  }
`
);
