import { useMutation } from "@tanstack/react-query";
import { LoginResponse } from "api";
import { AxiosError } from "axios";
import { FormProps } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "config";
import { postLogin } from "../../api";
import { LoginValues } from "../../types";
import {
  addGoogleAnalyticsEvent,
  setUserDataToLocalStorage,
  setUserDataToCookies
} from "../../utils";

export const useLoginMutation = () => {
  const navigate = useNavigate();

  const { mutateAsync } = useMutation<LoginResponse, AxiosError<{ message: string }>, LoginValues>(
    postLogin
  );

  const execute: FormProps<LoginValues, LoginValues>["onSubmit"] = async (values) => {
    try {
      const res = await mutateAsync({
        ...values,
        email: values.email.toLowerCase()
      });

      setUserDataToLocalStorage(res);

      setUserDataToCookies(values.remember, res.data.shopId);

      addGoogleAnalyticsEvent(res);

      navigate(Routes.DASHBOARD);
    } catch (error) {
      let errorTranslationKey = "global.error.serverUnavailable";

      if ((error as AxiosError<{ message: string }>).response?.status === 403) {
        errorTranslationKey = "authModule.errors.invalidCredentials";
      }

      return {
        [FORM_ERROR]: errorTranslationKey
      };
    }
  };

  return execute;
};
