import UpCustomerSettingsInfo from '../UpCustomerSettingsInfo';

import { InputGroup, Label } from './styles';

interface UpInputWithLabelProps {
  label?: React.ReactNode | string;
  margin?: string;
  flex?: string;
  infoText?: string | React.ReactElement;
  required?: boolean;
  children: React.ReactNode;
  width?: string; 
  className?: string;
}

const UpInputWithLabel: React.FC<UpInputWithLabelProps> = ({
  label,
  children,
  margin,
  flex,
  infoText,
  required,
  ...props
}) => (
  <InputGroup margin={margin} flex={flex} {...props}>
    {label && (
      <Label>
        {label}
        {required && <span className="asterisk">*</span>}
        {infoText && <UpCustomerSettingsInfo infoText={infoText} />}
      </Label>
    )}
    {children}
  </InputGroup>
);

export default UpInputWithLabel;
