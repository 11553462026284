import { useCallback } from "react";
import { QuestionnaireLegalTextStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitLegalText } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useLegalTextStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.LEGAL_TEXT);

  const data = stepData?.data;

  const submitLegalText =
    useQuestionnaireSubmitMutation<QuestionnaireLegalTextStepData>(putSubmitLegalText);

  const executeSubmitLegalText: FormProps<
    QuestionnaireLegalTextStepData,
    QuestionnaireLegalTextStepData
  >["onSubmit"] = useCallback(
    async ({ legalText }) => {
      try {
        await submitLegalText({
          legalText: legalText.trim()
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitLegalText]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitLegalText
  };
};
