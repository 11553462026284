import { FieldValidator } from "final-form";

export const emailSignatureValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (new Blob([value]).size / 1000 >= 50) {
    return "questionnaireModule.errors.signatureIsTooLarge";
  }
};

export const emailSignatureCodeViewValidator: FieldValidator<boolean> = (value) => {
  if (value) {
    return "questionnaireModule.errors.notAllowedCodeView";
  }
};
