import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import {Field} from 'redux-form/immutable';
import {connect} from 'react-redux';
import { injectIntl } from 'react-intl';

import { selectBox } from '../../SelectBox';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 1650px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    /* margin-top: 10px; */
    margin-left: 0px;
  }
`;

class TimeOfDay extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      editAvailabilityVisible: false,
      selectedId: "",
    };
  }

  toggleAvailability() {
    this.setState({
      editAvailabilityVisible: !this.state.editAvailabilityVisible,
    });
  }

  render() {
    return (
      <Filter>
        <Field
          name={`${this.props.member}.filter.operator`}
          component={selectBox}
          customProps={{
            options:
              [{value: 'within', label: this.props.intl.formatMessage(messages.within) },
                {value: 'outsideOf', label: this.props.intl.formatMessage(messages.outsideOf) }],
            margin: '0 0 0 10px',
            minWidth: '120px',
          }}
        />
      </Filter>
    );
  }
}

TimeOfDay.defaultProps = {
  availabilities: fromJS([]),
};

TimeOfDay.propTypes = {
  member: PropTypes.string.isRequired,
  selectedShop: PropTypes.string,
  availabilities: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  availabilities: state.getIn(['customerBackendDashboard', 'shops', ownProps.selectedShop, 'availability', 'data']),
});

export default injectIntl(connect(mapStateToProps, null)(TimeOfDay));

