import { createContext } from "react";
import { ShopListDataContextState } from "./shopListData.types";

export const initialState: ShopListDataContextState = {
  selectedShop: undefined,
  selectedShopId: undefined,
  onSelectedShopChange: () => {}
};

export const shopListDataContext = createContext<ShopListDataContextState>(initialState);

export const ShopListDataContextProvider = shopListDataContext.Provider;
