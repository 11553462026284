export enum PreviewDevice {
  MOBILE = "mobile",
  DESKTOP = "desktop"
}

export enum PreviewVariant {
  EMAIL = "emailService",
  PHONE = "telephoneService",
  VOUCHER = "voucher",
  LINK = "link"
}
