import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';

import { switchShop } from '../../actions/actions';

import { SelectShop, InvertedSelectBox } from './styles';
import { getParsedShops, checkIfDirty } from './selectors';
import { selectCurrShop } from '../../containers/App/selectors';

interface SwitchShopProps {
  disabled: boolean;
}

const getShopIdFromLabel = (label: string) => label.split(' - ')[0];

const SwitchShop: React.FC<SwitchShopProps> = ({ disabled }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { selectedShop, shopId: selectedShopId } = useSelector(selectCurrShop);
  const shops = useSelector(getParsedShops);
  const isDirty = useSelector(checkIfDirty);
  const shopsRecieved = useSelector((state: any) =>
    state.getIn(['customerBackendDashboard', 'userData', 'shopsRecieved']),
  );

  useEffect(() => {
    const shopId = searchParams.get('shop');

    if (!shopId) return;

    const nextShop = shops.find(
      (shop) => getShopIdFromLabel(shop.label) === shopId,
    );

    if (nextShop) {
      if (nextShop.value !== selectedShop) {
        const nextShopId = getShopIdFromLabel(nextShop.label);

        dispatch(switchShop(nextShop.value, nextShopId));
      }
    } else {
      NotificationManager.warning(
        <FormattedMessage
          id="containers.Dashboard.Notification.noShop.description"
          defaultMessage="There is no shop found with id {shopId}, you will be redirected to the first shop in your shop list."
          values={{ shopId }}
        />,
        <FormattedMessage
          id="containers.Dashboard.Notification.noShop.title"
          defaultMessage="No shop found"
        />,
        9000,
      );

      setSearchParams({ shop: `${selectedShopId}` });
    }
  }, [searchParams, shops]);

  const handleSwitchShop = (value: string) => {
    const shopId = triggerLocation(value);

    if (!isDirty && shopId > 0) {
      dispatch(switchShop(value, shopId));
    }
  };

  const triggerLocation = (value: string) => {
    const { label } = shops.find((shop) => shop.value === value) ?? {};

    if (!label) return -1;

    const shopId = getShopIdFromLabel(label);

    if (!shopId) return -1;

    setSearchParams({ shop: shopId }, { replace: false });

    return parseInt(shopId, 10);
  };

  if (!shopsRecieved) return null;

  return (
    <SelectShop>
      <span>Switch between shops:</span>
      <InvertedSelectBox
        name="shop"
        onChange={(_name: string, value: string) => {
          handleSwitchShop(value);
        }}
        value={selectedShop}
        options={shops}
        disabled={!shopsRecieved || disabled}
        optionsPosition="top"
      />
    </SelectShop>
  );
};

export default SwitchShop;
