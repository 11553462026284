import module from "module";
import { Routes } from "config";
import { PageWrapper, NavigationBar, PublicFooter } from "shared";
import { ContentWrapper, RegisterForm } from "../components";

const RegisterPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="public" />
      <ContentWrapper>
        <RegisterForm />
        <PublicFooter />
      </ContentWrapper>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.REGISTER
});

export default RegisterPage;
