import { useTranslation } from "react-i18next";
import { KeyTitlePair } from "shared";
import { EmailVendor } from "api";
import { questionnaireMailVendorTranslationKeys } from "../../config";

export const useMailVendorsValues = (): KeyTitlePair[] => {
  const { t } = useTranslation();

  return [
    {
      key: EmailVendor.Google,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Google])
    },
    {
      key: EmailVendor.Namecheap,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Namecheap])
    },
    {
      key: EmailVendor.Strato,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Strato])
    },
    {
      key: EmailVendor.Outlook,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Outlook])
    },
    { key: EmailVendor.Ionos, title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Ionos]) },
    {
      key: EmailVendor.Mailbox,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Mailbox])
    },
    { key: EmailVendor.Other, title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Other]) },
    {
      key: EmailVendor.Uptain,
      title: t(questionnaireMailVendorTranslationKeys[EmailVendor.Uptain])
    }
  ];
};
