import { Features } from "api";
import { useCallback, useState } from "react";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { VoucherSettingsFormBase } from "../VoucherSettingsFormBase";
import { BrowseAbandonmentVoucherInitialSettingsValues } from "../../types";
import {
  useDisableBrowseAbandonmentVoucher,
  useEnableBrowseAbandonmentVoucher,
  useUpdateBrowseAbandonmentVoucher,
  useDeleteBrowseAbandonmentVoucher
} from "../../hooks";

export const BrowseAbandonmentVoucherItemForm = ({
  initialState
}: {
  initialState: BrowseAbandonmentVoucherInitialSettingsValues;
}) => {
  const [opened, setOpened] = useState(false);

  const submitMethod = useUpdateBrowseAbandonmentVoucher();

  const deleteMethod = useDeleteBrowseAbandonmentVoucher();

  const enableMethod = useEnableBrowseAbandonmentVoucher();

  const disableMethod = useDisableBrowseAbandonmentVoucher();

  const handleActivationSwitch = useCallback(
    (id: number, value: boolean) => {
      if (value) {
        enableMethod(id);
      } else {
        disableMethod(id);
      }
    },
    [enableMethod, disableMethod]
  );

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <VoucherSettingsFormBase
      localizationKey="browseAbandonment"
      conditionFeatureName="browse_abandonment_conditions_enabled"
      possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.BROWSE_ABANDONMENT]}
      initialState={initialState}
      formOpened={opened}
      expandIcon={<Chevron />}
      onOpen={handleOpen}
      onClose={handleClose}
      onSubmit={submitMethod}
      onDelete={deleteMethod}
      onSwitchActive={handleActivationSwitch}
    />
  );
};
