import { useMemo } from "react";
import { SupportContactsContent } from "../../types";

export const useSupportContacts = () => {
  const contacts: SupportContactsContent = useMemo(() => {
    try {
      return JSON.parse(process.env.REACT_APP_SUPPORT_CONTACTS || "{}");
    } catch {
      console.error(
        "Invalid support contacts configuration provided, make sure that REACT_APP_SUPPORT_CONTACTS is a valid JSON string."
      );
      return {};
    }
  }, []);

  return contacts;
};
