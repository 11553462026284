import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column-reverse;
  margin: 20px 0 0 0;
  padding-right: 20px;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
  }
`;
