import { useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { TextInput } from "../TextInput";
import { SelectDropdownProps } from "./SelectDropdown.props";
import { Chevron, makePaperStyles, MenuItem, Placeholder } from "./SelectDropdown.styles";

export const SelectDropdown = ({
  label,
  onChange,
  errorMessage,
  value,
  focused,
  options,
  placeholder,
  ...props
}: SelectDropdownProps) => {
  const theme = useTheme();

  const [opened, setOpened] = useState(false);

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const handleChange = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <TextInput
      {...props}
      focused={focused || opened}
      label={label}
      value={value}
      onChange={handleChange}
      errorMessage={errorMessage}
      SelectProps={{
        open: opened,
        onOpen: handleOpen,
        onClose: handleClose,
        IconComponent: Chevron,
        MenuProps: {
          PaperProps: {
            sx: makePaperStyles(theme)
          }
        },
        displayEmpty: !!placeholder,
        renderValue: (selected) => {
          if (!selected) {
            return <Placeholder>{placeholder}</Placeholder>;
          }

          const selectedOption = options.find(({ key }) => key === selected);

          return selectedOption?.title;
        }
      }}
      select
    >
      {options.map(({ key, title, disabled }) => (
        <MenuItem key={key} value={key} disabled={disabled}>
          {title}
        </MenuItem>
      ))}
    </TextInput>
  );
};
