import { LoadingIndicator } from "shared";
import { useTranslation } from "react-i18next";
import { BrowseAbandonmentVoucherItemForm } from "../BrowseAbandonmentVoucherItemForm";
import { CreateBrowseAbandonmentVoucherForm } from "../CreateBrowseAbandonmentVoucherForm";
import { useBrowseAbandonmentVoucherData } from "../../hooks";
import {
  SettingsListHeader,
  SettingsListWrapper,
  LoadingWrapper
} from "../TriggerSettingsFormStyles";
import { DEFAULT_QUERY_LIMIT } from "config";

export const BrowseAbandonmentVoucherSettingsList = () => {
  const { t } = useTranslation();

  const { initialState, loading } = useBrowseAbandonmentVoucherData();

  const browseAbandonmentVoucherCountThresholdMet = initialState.length >= DEFAULT_QUERY_LIMIT;

  return (
    <SettingsListWrapper>
      <SettingsListHeader>
        {t("featuresModule.browseAbandonment.form.voucherSettings.heading")}
      </SettingsListHeader>
      {loading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        initialState.map((voucherSettings) => (
          <BrowseAbandonmentVoucherItemForm
            key={voucherSettings.id}
            initialState={voucherSettings}
          />
        ))
      )}
      <CreateBrowseAbandonmentVoucherForm
        disabled={loading || browseAbandonmentVoucherCountThresholdMet}
      />
    </SettingsListWrapper>
  );
};
