import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import {
  useSelectedShop,
  useErrorFormatter,
  useNotifications,
  NetworkError,
  useQueryConfig
} from "shared";
import { postComeBackMailingReprocessExistingSessions } from "../../api";

export const useReprocessComeBackMailingExistingSessions = () => {
  const { queryName } = useQueryConfig();

  const queryClient = useQueryClient();

  const { selectedShopId } = useSelectedShop();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: enableComeBackMailingConfig, isLoading } = useMutation<
    void,
    AxiosError<{ message: string }>,
    number
  >(postComeBackMailingReprocessExistingSessions);

  const executeEnableComeBackMailingConfig = useCallback(async () => {
    try {
      await enableComeBackMailingConfig(selectedShopId!);

      queryClient.invalidateQueries([`${queryName}-reprocess-status`, selectedShopId]);

      showNotification("featuresModule.comeBackMailing.administration.successMessage", "success");
    } catch (error) {
      return formatError(error as NetworkError);
    }
  }, [selectedShopId, showNotification, queryName]);

  return { executeEnableComeBackMailingConfig, loading: isLoading };
};
