/* eslint-disable no-fallthrough */
/* eslint-disable prefer-template */
import Immutable from 'immutable';
import { GET_IP_ADDR } from '../actions/actions';
import {
  ADD_DATA,
  DELETE_DATA,
  GET_DATA,
  GET_DATA_LIST,
  SAVE_DATA,
  TEST_DATA,
} from '../containers/actionTypes';
import { GET_GO_LIVE_STATUS } from '../containers/ProductSettings/goLiveStatusActions';
import {
  DELETE_SETTING_LINE,
  SAVE_SETTING_LINE,
} from '../containers/ProductSettings/actions';
import {
  CREATE_PREVIEW,
  GO_LIVE_ACTIVATION,
} from '../containers/ProductSettings/ActivationSettings/GoLive/actions';

import { b64toBlob } from '../utils/b64toBlob';

const immutableTypes = ['List', 'Map', 'OrderedMap'];

// pass an array for both UIStates & status. This function will generate the key path for ever Item
// in UIStates array and will give it the value from the correct status element
function updateUIStates(action, state, UIStates, status) {
  let newState = state;
  UIStates.forEach((UIState, index) => {
    const keyPath = getImmutableKeyPath(
      action,
      state,
      'UIStates',
      action.index !== undefined ? `${UIState}Item` : UIState,
    );
    newState = newState.setIn(keyPath, status[index]);
  });
  return newState;
}

function getBlobURL(data) {
  const blob = b64toBlob(data, 'image/png');
  return window.URL.createObjectURL(blob);
}

// update data
function updateReducer(action, state, UIState) {
  const keyPath = getImmutableKeyPath(action, state, 'data', undefined, true);
  const newState = state.setIn(
    keyPath,
    Immutable.fromJS(
      action.newData ||
        (action.blob && action.data.data
          ? getBlobURL(action.data.data)
          : action.data.data) ||
          // Put array of items returned in the api response to the redux store
          // Used by new api calls to the nest application
          action.data.items,
    ),
  );
  return updateUIStates(
    action,
    newState,
    [
      UIState,
      'errorMessage',
      ...(UIState === 'saving' || UIState === 'loading'
        ? ['successMessage']
        : []),
    ],
    [
      false,
      action.errorMessage,
      ...(UIState === 'saving' || UIState === 'loading'
        ? [action.successMessage]
        : []),
    ],
  );
}

function getIndexOfListToUpdate(action, state, id) {
  return state
    .getIn([action.shopId, action.field, 'data', action.pathInObject])
    .findIndex((listItem) => listItem.get('id') === id);
}

function getImmutableKeyPath(
  action,
  state,
  key = 'data',
  UIStateKey = undefined,
  getIndex = false,
) {
  const indexOfListToUpdate =
    action.pathInObject && action.index && getIndex
      ? getIndexOfListToUpdate(
        action,
        state,
        action.newData ? action.newData.id : action.index,
      )
      : undefined;
  return [
    action.shopId,
    action.field,
    key,
    ...(UIStateKey ? [UIStateKey] : []),
    ...(action.pathInObject ? [action.pathInObject] : []),
    ...(indexOfListToUpdate !== undefined ? [indexOfListToUpdate] : []),
    ...(action.index !== undefined && indexOfListToUpdate === undefined
      ? [action.index]
      : []),
  ];
}

function mergeDeep(source, target, schema) {
  // No schema defined, or schema is empty object
  if (
    !schema ||
    (typeof schema === 'object' && Object.keys(schema).length === 0)
  ) {
    // If Iterable Set or Stack override old source with target
    return Immutable.Iterable.isIterable(source) &&
      !Immutable.Stack.isStack(source)
      ? source.mergeDeep(target)
      : target;
  }

  const schemaType = typeof schema;

  if (schemaType === 'function') {
    return schema(source, target);
  }

  if (schemaType !== 'object') {
    throw new Error('Invalid schema');
  }

  let merged;

  const immutableType = immutableTypes.find((currType) =>
    Immutable[currType][`is${currType}`](source),
  );

  switch (immutableType) {
    case 'List':
      merged = new Immutable.List();
      break;
    case 'Map':
      merged = new Immutable.Map();
      break;
    case 'OrderedMap':
      merged = new Immutable.OrderedMap();
      break;
    default:
      throw new Error('No type found');
  }

  source.forEach((_value, key) => {
    if (source.has(key)) {
      const subSchema = schema ? schema[key] : undefined;
      const newData = mergeDeep(source.get(key), target.get(key), subSchema);
      merged = merged.set(key, newData);
    } else {
      merged = merged.set(key, source.get(key));
    }
  });

  target.forEach((_value, key) => {
    if (!source.has(key)) {
      merged = merged.set(key, target.get(key));
    }
  });

  return merged;
}

/**
 * updateDataMap function takes List (array) as the first argument
 * and updates its state with new passed data.
 * If merge is true, output is two Lists merged together.
 * @param {Immutable.List<any>} currData - current data field state
 * @param {Immutable.List<any>} newData - data to replace
 */
const updateDataMap = (currData, newData, merge) => {
  const data = Immutable.Iterable.isIterable(newData)
    ? newData
    : Immutable.fromJS(newData);

  if (merge) {
    if (!Immutable.Iterable.isIterable(currData)) {
      throw new Error(
        'Only immutable iterables can be merged using merge schema',
      );
    }

    let mergeSchema;

    if (Object.prototype.hasOwnProperty.call(merge, 'schema')) {
      mergeSchema = merge.schema;
    }

    return mergeDeep(currData, data, mergeSchema);
  }

  return currData.push(data);
};

// make sure that whenever working with a list to give the reducer the index number of the item in the array which
// might not be the same id with which the backend call is made!!!
function shopDataReducer(state = Immutable.Map(), action) {
  if(action.type === GET_DATA && action.field === 'sliderPositions') {
    action.newData = action.data;
  }

  switch (action.type) {
    case GET_DATA + '_LOADING': {
      return updateUIStates(
        action,
        state,
        ['loading', 'errorMessage', 'successMessage'],
        [true, action.errorMessage, null],
      );
    }
    case GET_DATA + '_ERROR': {
      return updateUIStates(
        action,
        state,
        ['loading', 'errorMessage', 'successMessage'],
        [false, action.errorMessage, null],
      );
    }
    case GET_DATA: {
      return updateReducer(action, state, 'loading');
    }
    case SAVE_DATA + '_LOADING': {
      return updateUIStates(
        action,
        state,
        ['saving', 'errorMessage', 'successMessage'],
        [true, action.errorMessage, null],
      );
    }
    case SAVE_DATA + '_ERROR': {
      return updateUIStates(
        action,
        state,
        ['saving', 'errorMessage', 'successMessage'],
        [false, action.errorMessage, null],
      );
    }
    case SAVE_DATA: {
      // enable all go live buttons except for the activation go live button
      let newState = state.setIn(
        [action.shopId, 'activation', 'liveStatus', 'dirtyStatus'],
        true,
      );
      newState = updateUIStates(
        action,
        state,
        ['saving', 'errorMessage', 'successMessage'],
        [false, action.errorMessage, action.successMessage],
      );
      const keyPath = getImmutableKeyPath(
        action,
        state,
        'data',
        undefined,
        true,
      );
      return newState.mergeIn(
        keyPath,
        Immutable.fromJS(action.newData || action.data.data),
      );
    }
    case DELETE_DATA + '_LOADING': {
      return updateUIStates(
        action,
        state,
        ['deleting', 'errorMessage'],
        [true, action.errorMessage],
      );
    }
    case DELETE_DATA + '_ERROR': {
      return updateUIStates(
        action,
        state,
        ['deleting', 'errorMessage'],
        [false, action.errorMessage],
      );
    }
    case DELETE_DATA: {
      if (action.index !== undefined) {
        const newState = updateUIStates(
          action,
          state,
          ['deleting', 'errorMessage'],
          [false, action.errorMessage],
        );
        const keyPath = getImmutableKeyPath(
          action,
          state,
          'data',
          undefined,
          true,
        );
        return newState.deleteIn(keyPath);
      }
      return updateUIStates(
        action,
        state,
        ['deleting', 'errorMessage'],
        [false, action.errorMessage],
      );
    }
    // use this action if the backend returns a list with several IDs that should be called seperatly
    case GET_DATA_LIST + '_LOADING': {
      return updateUIStates(
        action,
        state,
        ['loading', 'errorMessage'],
        [true, action.errorMessage],
      );
    }
    case GET_DATA_LIST + '_ERROR': {
      return updateUIStates(
        action,
        state,
        ['loading', 'errorMessage'],
        [false, action.errorMessage],
      );
    }
    case GET_DATA_LIST: {
      // sort items by ID
      const { data } = action.data;
      const itemsById = Immutable.fromJS(data).reduce(
        (lookup, item) => lookup.set(item.get('id'), item),
        Immutable.Map(),
      );

      const newState = state.setIn(
        [action.shopId, action.field, 'data'],
        Immutable.fromJS(itemsById),
      );
      return updateUIStates(
        action,
        newState,
        ['loading', 'errorMessage'],
        [false, action.errorMessage],
      );
    }
    case TEST_DATA + '_CLEAR': {
      return updateUIStates(
        action,
        state,
        ['testing', 'testingError', 'correct'],
        [undefined, undefined, undefined],
      );
    }
    case TEST_DATA + '_LOADING': {
      return updateUIStates(action, state, ['testing'], [true]);
    }
    case TEST_DATA + '_ERROR': {
      return updateUIStates(
        action,
        state,
        ['testing', 'testingError'],
        [false, action.errorMessage],
      );
    }
    case TEST_DATA: {
      const { success, message } = action.data;
      return updateUIStates(
        action,
        state,
        ['testing', 'testingError', 'correct'],
        [false, success ? '' : message, success],
      );
    }
    case ADD_DATA + '_LOADING': {
      return state.mergeIn([action.shopId, action.field, 'UIStates'], {
        adding: true,
        errorMessage: '',
      });
    }
    case ADD_DATA + '_ERROR': {
      return state.mergeIn([action.shopId, action.field, 'UIStates'], {
        adding: false,
        errorMessage: action.errorMessage,
      });
    }
    case ADD_DATA: {
      // eslint-disable-next-line prefer-const
      const newState = state.mergeIn(
        [action.shopId, action.field, 'UIStates'],
        {
          adding: false,
          errorMessage: '',
        },
      );

      if (action.pathInObject) {
        return newState.updateIn(
          [action.shopId, action.field, 'data', action.pathInObject],
          (arr) => updateDataMap(arr, action.newData, action.merge),
        );
      }

      return newState.updateIn([action.shopId, action.field, 'data'], (arr) =>
        updateDataMap(arr, action.newData, action.merge),
      );
    }
    case GET_IP_ADDR: {
      // eslint-disable-next-line prefer-const
      let ipAddressBlocking = state.getIn([
        action.shopId,
        'ignoredMailAddresses',
        'data',
        'ipAddress',
      ]);
      let addresses = [];
      if (ipAddressBlocking) {
        addresses = ipAddressBlocking.split(',');
      }
      addresses.push(`${action.data.ip}/32`);
      return state.setIn(
        [action.shopId, 'ignoredMailAddresses', 'data', 'ipAddress'],
        addresses.join(','),
      );
    }
    case GET_GO_LIVE_STATUS: {
      return state.setIn(
        [action.shopId, action.site, 'liveStatus'],
        Immutable.fromJS(action.data.data),
      );
    }
    case SAVE_SETTING_LINE:
    case DELETE_SETTING_LINE: {
      if (action.product === 'activation') {
        return state.setIn(
          [action.shopId, 'activation', 'liveStatus', 'dirtyStatus'],
          true,
        );
      }
    }
    case `${GO_LIVE_ACTIVATION}_LOADING`:
    case `${CREATE_PREVIEW}_LOADING`: {
      return state.setIn(
        [action.shopId, 'activation', 'liveStatus', 'loading'],
        true,
      );
    }
    case GO_LIVE_ACTIVATION:
    case CREATE_PREVIEW: {
      return state.setIn(
        [action.shopId, 'activation', 'liveStatus'],
        Immutable.fromJS({
          dirtyStatus: false,
          lastVersion: new Date().toISOString(),
          loading: false,
          errorMessage: '',
        }),
      );
    }
    case `${GO_LIVE_ACTIVATION}_ERROR`:
    case `${CREATE_PREVIEW}_ERROR`: {
      return state.setIn(
        [action.shopId, 'activation', 'liveStatus'],
        Immutable.fromJS({ loading: false, errorMessage: action.errorMessage }),
      );
    }
    default: {
      return state;
    }
  }
}

export default shopDataReducer;
