import { styled, Typography as MuiTypography } from "@mui/material";

export const Subheading = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { h3 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${h3.desktop.fontSize};
      line-height: ${h3.desktop.lineHeight};
      font-weight: ${h3.desktop.fontWeight};
      margin-bottom: 24px;

      ${[breakpoints.up("md")]} {
        font-size: ${h3.tablet.fontSize};
        line-height: ${h3.tablet.lineHeight};
        font-weight: ${h3.tablet.fontWeight};
      }
  
      ${[breakpoints.up("lg")]} {
        font-size: ${h3.desktop.fontSize};
        line-height: ${h3.desktop.lineHeight};
        font-weight: ${h3.desktop.fontWeight};
      }
    }
  `
);
