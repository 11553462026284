import styled from 'styled-components';
import PropTypes from 'prop-types';

const Icon = styled.a`
  color: ${(props) =>
    props.disabled ? '#EFEFEF26 !important' : '#EFEFEF !important'};
  text-decoration: none !important;
  font-weight: 700;
  float: ${(props) => props.float || 'none'};
  margin: ${(props) => props.margin};
  display: inline-block;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  line-height: normal;

  :hover,
  :visited,
  :active,
  :link {
    color: ${(props) => props.disabled ? '#EFEFEF26 !important' : '#EFEFEF !important'};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

Icon.propTypes = {
  float: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
};

export default Icon;
