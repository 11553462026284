import { useTranslation } from "react-i18next";
import { Heading, Subheading, DescriptionText, TextInput } from "shared";
import { Field, Form } from "react-final-form";
import { StepWrapper, StepsContent } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useAbandonmentMailsWaitingTimeMinMinutesStep } from "../../../hooks";
import {
  abandonmentMailsWaitingTimeValidator,
  triggerMailsWaitingTimeValidator
} from "./AbandonmentMailsWaitingTimeMinMinutes.validation";
import { InputWrapper } from "./AbandonmentMailsWaitingTimeMinMinutes.styles";

export const AbandonmentMailsWaitingTimeMinMinutes = () => {
  const { t } = useTranslation();

  const {
    data,
    hasPrev,
    metadata,
    handlePrevStep,
    executeSubmitAbandonmentMailsWaitingTimeMinMinutes
  } = useAbandonmentMailsWaitingTimeMinMinutesStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitAbandonmentMailsWaitingTimeMinMinutes}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.customer.title")}</Heading>
            <Subheading>
              {t("questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.title")}
            </Subheading>
            <DescriptionText>
              {t(
                "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.description"
              )}
            </DescriptionText>
            <InputWrapper>
              {metadata.abandonmentMailsWaitingTimeMinMinutesEnabled && (
                <Field
                  name="abandonmentMailsWaitingTimeMinMinutes"
                  validate={abandonmentMailsWaitingTimeValidator}
                  render={({
                    input: { value, onChange, onBlur, onFocus },
                    meta: { touched, error, modifiedSinceLastSubmit }
                  }) => (
                    <TextInput
                      required
                      label={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.abandonmentMailsWaitingTimeMinMinutes.label"
                      )}
                      placeholder={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.abandonmentMailsWaitingTimeMinMinutes.placeholder"
                      )}
                      infoText={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.abandonmentMailsWaitingTimeMinMinutes.hint"
                      )}
                      value={value ? value : ""}
                      onChange={(value) => {
                        const numericOnly = value.replace(/[^0-9]/g, "");

                        onChange(numericOnly);
                      }}
                      onBlur={() => {
                        onChange(Number(value));
                        onBlur();
                      }}
                      onFocus={onFocus}
                      error={
                        (error ||
                          (submitErrors?.["abandonmentMailsWaitingTimeMinMinutes"] &&
                            !modifiedSinceLastSubmit)) &&
                        touched
                      }
                      errorMessage={t(
                        submitErrors?.["abandonmentMailsWaitingTimeMinMinutes"] ?? error
                      )}
                    />
                  )}
                />
              )}
              {metadata.browseAbandonmentWaitingTimeMinHoursEnabled && (
                <Field
                  name="browseAbandonmentWaitingTimeMinHours"
                  validate={triggerMailsWaitingTimeValidator}
                  render={({
                    input: { value, onChange, onBlur, onFocus },
                    meta: { touched, error, modifiedSinceLastSubmit }
                  }) => (
                    <TextInput
                      required
                      label={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.browseAbandonmentWaitingTimeMinHours.label"
                      )}
                      placeholder={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.browseAbandonmentWaitingTimeMinHours.placeholder"
                      )}
                      infoText={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.browseAbandonmentWaitingTimeMinHours.hint"
                      )}
                      value={value ? value : ""}
                      onChange={(value) => {
                        const numericOnly = value.replace(/[^0-9]/g, "");

                        onChange(numericOnly);
                      }}
                      onBlur={() => {
                        onChange(Number(value));
                        onBlur();
                      }}
                      onFocus={onFocus}
                      error={
                        (error ||
                          (submitErrors?.["browseAbandonmentWaitingTimeMinHours"] &&
                            !modifiedSinceLastSubmit)) &&
                        touched
                      }
                      errorMessage={t(
                        submitErrors?.["browseAbandonmentWaitingTimeMinHours"] ?? error
                      )}
                    />
                  )}
                />
              )}
              {metadata.comeBackMailingWaitingTimeMinHoursEnabled && (
                <Field
                  name="comeBackMailingWaitingTimeMinHours"
                  validate={triggerMailsWaitingTimeValidator}
                  render={({
                    input: { value, onChange, onBlur, onFocus },
                    meta: { touched, error, modifiedSinceLastSubmit }
                  }) => (
                    <TextInput
                      required
                      label={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.comeBackMailingWaitingTimeMinHours.label"
                      )}
                      placeholder={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.comeBackMailingWaitingTimeMinHours.placeholder"
                      )}
                      infoText={t(
                        "questionnaireModule.steps.customer.abandonmentMailsWaitingTimeMinMinutes.form.comeBackMailingWaitingTimeMinHours.hint"
                      )}
                      value={value ? value : ""}
                      onChange={(value) => {
                        const numericOnly = value.replace(/[^0-9]/g, "");

                        onChange(numericOnly);
                      }}
                      onBlur={() => {
                        onChange(Number(value));
                        onBlur();
                      }}
                      onFocus={onFocus}
                      error={
                        (error ||
                          (submitErrors?.["comeBackMailingWaitingTimeMinHours"] &&
                            !modifiedSinceLastSubmit)) &&
                        touched
                      }
                      errorMessage={t(
                        submitErrors?.["comeBackMailingWaitingTimeMinHours"] ?? error
                      )}
                    />
                  )}
                />
              )}
            </InputWrapper>
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
