import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useInfoLinks } from "shared";

export const useRegistrationNavigation = (token: string | null) => {
  const { search } = useLocation();

  const { register } = useInfoLinks();

  const registerLink = `${register}?${search}`;

  useEffect(() => {
    if (!token) {
      window.location.href = registerLink;
    }
  }, [token]);
};
