import { apiClient } from "shared";
import { PutLinksConfigRequest } from "api";

export const putLinksConfig = async (values: PutLinksConfigRequest) => {
  const { shopMongoId, unsubscribeLink, link } = values;

  // Temporary used legacy request that will save data to both legacy and new data sources.
  // The new request will be used when abandonment mails product will be migrated to
  // the new links data source.
  // await apiClient.put(`${shopId}/links`, { link, unsubscribeLink });
  await apiClient.put(`/shop/${shopMongoId}/settings/common/products/items/1`, {
    linkbackPageLink: link,
    unsubscribePageLink: unsubscribeLink
  });
};
