import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl } from 'react-intl';

import { creatable } from '../../UpStyledCreatable';
import { selectBox } from '../../SelectBox';
import UpRegex from '../../UpRegex';
import messages from '../messages';

const Filter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: stretch;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }
`;

const Row = styled.div`
  margin-bottom: 0px;
  display: flex;
  flex: 1 1 auto;
  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const URL = (props) => {
  const operator = props.conditions.getIn([
    props.member.split('[')[1].charAt(0),
    'filter',
    'operator',
  ]);
  return (
    <Filter>
      <Row>
        <Field
          name={`${props.member}.filter.operator`}
          type="text"
          component={selectBox}
          customProps={{
            options: [
              {
                value: 'contains',
                label: props.intl.formatMessage(messages.contains),
              },
              {
                value: 'contains-not',
                label: props.intl.formatMessage(messages.containsNot),
              },
              {
                value: 'equals',
                label: props.intl.formatMessage(messages.equals),
              },
              {
                value: 'regex',
                label: props.intl.formatMessage(messages.matchesRegex),
              },
            ],
            minWidth: '250px',
            margin: '0 10px 0 0',
          }}
        />
      </Row>

      {operator === 'regex' ? (
        <UpRegex
          name={`${props.member}.filter.attribute[0].value`}
          margin="0 10px 0 0"
          width="100%"
          fieldValidation={false}
        />
      ) : (
        <Field
          name={`${props.member}.filter.attribute`}
          component={creatable}
        />
      )}
    </Filter>
  );
};

URL.propTypes = {
  member: PropTypes.string.isRequired,
  conditions: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(URL);
