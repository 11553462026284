export const GO_LIVE_ACTIVATION =
  'react-boilerplate/ACTIVATION/GO_LIVE_ACTIVATION';
export const CREATE_PREVIEW = 'react-boilerplate/ACTIVATION/CREATE_PREVIEW';

export const goLive = (shopId) => ({
  type: GO_LIVE_ACTIVATION,
  shopId,
  meta: {
    type: 'api',
    url: `shop/${shopId}/versions/products/activation`,
    method: 'post',
  },
});

export const createPreview = (shopId) => ({
  type: CREATE_PREVIEW,
  shopId,
  meta: {
    type: 'api',
    url: `shop/${shopId}/preview/products/activation`,
    method: 'post',
  },
});
