import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = useCallback(
    (
      message: string,
      variant: "success" | "error" | "default" | "warning" | undefined = "default"
    ) => {
      enqueueSnackbar(message, {
        variant
      });
    },
    [enqueueSnackbar]
  );

  return { showNotification, closeNotification: closeSnackbar };
};
