const theme = {
  primaryColor: "#31B9B5",
  fontColor: "#EFEFEF",
  secondaryColor: "#A4D7DA",
  primaryActionColor: "#AC231D",
  secondaryActionColor: "#FF614C",
  navColor: "#FFF",
  linkColor: "#31B9B5",
  inputBorder: "1px solid #EFEFEF80",
  wrapperBackgroundColor: "#1B1E22",
  inputFocus: `
    outline: none; 
    border: 1px solid #c2e5e7;
    -webkit-box-shadow: 0px 0px 5px #c2e5e7;
    box-shadow: 0px 0px 5px  #c2e5e7;
  `,
  navBreakpoint: 1472,
  xsmall: "576px",
  small: "768px",
  medium: "992px",
  large: "1200px",
  grid: {
    width: "1280px"
  },
  checkbox: {
    box: "#fff",
    icon: "#31B9B5"
  }
};

const invertTheme = {
  primaryColor: "rgba(255,255,255,0.8)",
  fontColor: "#fff",
  linkColor: "#fff",
  checkbox: {
    box: "#31B9B5",
    icon: "#fff"
  }
};

export { theme, invertTheme };
