export const redirectRoutes = [
  {
    from: '/features/activation-settings',
    to: '/features/activation-popups',
  },
  {
    from: '/activation-settings',
    to: '/features/activation-popups',
  },
  {
    from: '/features/email-settings',
    to: '/features/abandonment-mails',
  },
  {
    from: '/email-settings',
    to: '/features/abandonment-mails',
  },
  {
    from: '/features/optin-settings',
    to: '/features/newsletter-forms',
  },
  {
    from: '/optin-settings',
    to: '/features/newsletter-forms',
  },
  {
    from: '/settings/integration',
    to: '/integrations',
    hash: 'personalId',
  },
  {
    from: '/settings/communication-settings',
    to: '/my-account',
    hash: 'communicationSettings',
  },
  {
    from: '/settings/billing',
    to: '/my-account',
    hash: 'billing',
  },
  {
    from: '/settings/shopSettings',
    to: '/algorithm',
  },
  {
    from: '/settings/assets',
    to: '/algorithm',
    hash: 'imageUpload',
  },
];
