import { DescriptionText, Heading, Subheading, Link, useSelectedShop, GTagTrigger } from "shared";
import { Trans, useTranslation } from "react-i18next";
import { StepWrapper, StepsContent } from "../StepStyles";
import {
  InfoListWrapper,
  InfoListItem,
  InfoListItemHeading,
  InfoListItemTypography,
  InfoListItemHeadingTypography
} from "./SubmittedScreen.styles";
import { NumberCircle } from "../NumberCircle";

export const SubmittedScreen = () => {
  const { t } = useTranslation();

  const { selectedShopId } = useSelectedShop();

  return (
    <StepWrapper>
      <StepsContent>
        <Heading>{t("questionnaireModule.submittedScreen.title")}</Heading>
        <Subheading>{t("questionnaireModule.submittedScreen.subheading")}</Subheading>
        <DescriptionText>{t("questionnaireModule.submittedScreen.description")}</DescriptionText>
        <InfoListWrapper>
          <InfoListItem key={1}>
            <InfoListItemHeading>
              <NumberCircle index={1} selected />
              <InfoListItemHeadingTypography>
                {t("questionnaireModule.submittedScreen.conditions.title")}
              </InfoListItemHeadingTypography>
            </InfoListItemHeading>
            <InfoListItemTypography>
              <Trans
                t={t}
                i18nKey="questionnaireModule.submittedScreen.conditions.description"
                components={[
                  <Link
                    key="conditions"
                    target="_blank"
                    href={`/account/my-account?shop=${selectedShopId}#billing`}
                  />
                ]}
              />
            </InfoListItemTypography>
          </InfoListItem>
          <InfoListItem key={2}>
            <InfoListItemHeading>
              <NumberCircle index={2} selected />
              <InfoListItemHeadingTypography>
                {t("questionnaireModule.submittedScreen.uptainId.title")}
              </InfoListItemHeadingTypography>
            </InfoListItemHeading>
            <InfoListItemTypography>
              <Trans
                t={t}
                i18nKey="questionnaireModule.submittedScreen.uptainId.description"
                components={[
                  <Link
                    key="uptainId"
                    target="_blank"
                    href={`/integrations?shop=${selectedShopId}#personalId`}
                  />
                ]}
              />
            </InfoListItemTypography>
          </InfoListItem>
          <InfoListItem key={3}>
            <InfoListItemHeading>
              <NumberCircle index={3} selected />
              <InfoListItemHeadingTypography>
                {t("questionnaireModule.submittedScreen.plugin.title")}
              </InfoListItemHeadingTypography>
            </InfoListItemHeading>
            <InfoListItemTypography>
              <Trans
                t={t}
                i18nKey="questionnaireModule.submittedScreen.plugin.description"
                components={[
                  <Link
                    key="plugin"
                    target="_blank"
                    href={`https://uptain.atlassian.net/servicedesk/customer/portal/2/topic/b36d3352-8067-49a5-a5b8-00344ce6586d`}
                  />
                ]}
              />
            </InfoListItemTypography>
          </InfoListItem>
          <InfoListItem key={4}>
            <InfoListItemHeading>
              <NumberCircle index={4} selected />
              <InfoListItemHeadingTypography>
                {t("questionnaireModule.submittedScreen.dataPolicy.title")}
              </InfoListItemHeadingTypography>
            </InfoListItemHeading>
            <InfoListItemTypography>
              <Trans
                t={t}
                i18nKey="questionnaireModule.submittedScreen.dataPolicy.description"
                components={[
                  <Link
                    key="plugin"
                    target="_blank"
                    href={
                      "https://uptain.atlassian.net/servicedesk/customer/portal/2/article/352125592?src=493038910"
                    }
                  />
                ]}
              />
            </InfoListItemTypography>
          </InfoListItem>
        </InfoListWrapper>
      </StepsContent>
      <GTagTrigger id="questionnaire-submitted-screen" />
    </StepWrapper>
  );
};
