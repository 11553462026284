import { useCallback, useMemo } from "react";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  EncryptionType,
  QuestionnaireMailSettingsStepData,
  QuestionnaireStepType,
  PostTestMailSettingsResponse,
  EmailVendor
} from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter, useSelectedShop } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitMailSettings, postTestEmailSettings } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { MailSettingsFormSubmitMode, QuestionnaireMailSettingsFormValues } from "../../types";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { useQuestionnaireVendorsConfig } from "../useQuestionnaireVendorsConfig";

export const useMailSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const { selectedShop } = useSelectedShop();

  const formatError = useErrorFormatter();

  const vendorsConfig = useQuestionnaireVendorsConfig();

  const stepData = useStepsResolver(QuestionnaireStepType.MAIL_SETTINGS);

  const data: QuestionnaireMailSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      encryption: stepData?.data?.encryption || EncryptionType.SSL
    }),
    [stepData?.data]
  );

  const submitMailSettings =
    useQuestionnaireSubmitMutation<QuestionnaireMailSettingsStepData>(putSubmitMailSettings);

  const {
    mutateAsync: submitTestSettings,
    data: testResultData,
    isLoading: testSettingsLoading
  } = useMutation<
    PostTestMailSettingsResponse,
    AxiosError<{ message: string }>,
    Omit<QuestionnaireMailSettingsStepData, "alternativeEmail"> & { mongoShopId: string }
  >(postTestEmailSettings);

  const executeSubmitMailSettings: FormProps<
    QuestionnaireMailSettingsFormValues,
    QuestionnaireMailSettingsFormValues
  >["onSubmit"] = useCallback(
    async ({
      vendor,
      alternativeEmail,
      mode,
      username,
      senderName,
      senderAddress,
      port,
      password,
      host,
      encryption
    }) => {
      try {
        if (mode === MailSettingsFormSubmitMode.TEST) {
          return;
        }

        if (vendor === EmailVendor.Uptain) {
          await submitMailSettings({
            alternativeEmail,
            vendor
          });
        } else if (vendor === EmailVendor.Other) {
          const trimmedUsername = username?.trim();

          const trimmedSenderName = senderName?.trim();

          const testResult = await submitTestSettings({
            username: trimmedUsername,
            senderName: trimmedSenderName,
            senderAddress,
            port,
            password,
            host,
            encryption,
            mongoShopId: selectedShop!
          });

          if (testResult.success) {
            await submitMailSettings({
              username: trimmedUsername,
              senderName: trimmedSenderName,
              senderAddress,
              port,
              password,
              host,
              encryption,
              vendor
            });
          }
        } else if (vendor) {
          const trimmedUsername = username?.trim();

          const trimmedSenderName = senderName?.trim();

          const testResult = await submitTestSettings({
            username: trimmedUsername,
            senderName: trimmedSenderName,
            senderAddress,
            password,
            vendor,
            ...vendorsConfig[vendor],
            mongoShopId: selectedShop!
          });

          if (testResult.success) {
            await submitMailSettings({
              username: trimmedUsername,
              senderName: trimmedSenderName,
              senderAddress,
              password,
              vendor,
              ...vendorsConfig[vendor]
            });
          }
        }
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [selectedShop, submitMailSettings, submitTestSettings, vendorsConfig]
  );

  const executeTestMailSettings = useCallback(
    async ({
      encryption,
      host,
      password,
      port,
      senderAddress,
      senderName,
      username,
      vendor
    }: QuestionnaireMailSettingsFormValues) => {
      try {
        if (vendor) {
          if (vendor === EmailVendor.Uptain) {
            return;
          }

          if (vendor === EmailVendor.Other) {
            await submitTestSettings({
              encryption,
              host,
              password,
              port,
              senderAddress,
              senderName,
              username,
              mongoShopId: selectedShop!
            });

            return;
          }

          await submitTestSettings({
            username,
            senderName,
            senderAddress,
            password,
            vendor,
            ...vendorsConfig[vendor],
            mongoShopId: selectedShop!
          });
        }
      } catch (error) {
        formatError(error as NetworkError);
      }
    },
    [selectedShop, vendorsConfig]
  );

  return {
    data,
    testResultData,
    testSettingsLoading,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeTestMailSettings,
    executeSubmitMailSettings
  };
};
