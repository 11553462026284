import { NewsletterFormsAllowedPositions } from "api";
import { NewsletterFormsAvailablePositions, NewsletterFormsTemplatePositions } from "../../types";

export const NEWSLETTER_FORMS_TEMPLATE_POSITIONS: NewsletterFormsTemplatePositions = {
  desktop: {
    topRight: false,
    topCenter: false,
    topLeft: false,
    centerRight: false,
    centerCenter: false,
    centerLeft: false,
    bottomRight: true,
    bottomCenter: false,
    bottomLeft: false
  },
  mobile: {
    topRight: false,
    topCenter: false,
    topLeft: false,
    centerRight: false,
    centerCenter: false,
    centerLeft: false,
    bottomRight: false,
    bottomCenter: true,
    bottomLeft: false
  },
  availablePositions: {
    topRight: {
      desktop: true,
      mobile: false
    },
    topCenter: {
      desktop: true,
      mobile: false
    },
    topLeft: {
      desktop: true,
      mobile: false
    },
    centerRight: {
      desktop: true,
      mobile: true
    },
    centerCenter: {
      desktop: false,
      mobile: false
    },
    centerLeft: {
      desktop: true,
      mobile: true
    },
    bottomRight: {
      desktop: true,
      mobile: false
    },
    bottomCenter: {
      desktop: true,
      mobile: true
    },
    bottomLeft: {
      desktop: true,
      mobile: false
    }
  }
};

export const getAvailablePositions = (positions: NewsletterFormsAllowedPositions) => {
  const filtered = {} as NewsletterFormsAvailablePositions;

  for (const position in positions) {
    const key = position as keyof NewsletterFormsAllowedPositions;

    filtered[key] = {
      desktop: positions[key].desktopEnabled,
      mobile: positions[key].mobileEnabled
    };
  }

  return filtered;
};
