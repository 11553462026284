import { styled, Link as MuiLink } from "@mui/material";
import { LinkProps } from "./Link.props";

export const Link = styled((props: LinkProps) => <MuiLink {...props} />)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    },
    type = "primary"
  }) => {
    let mainColor;
    let pressedColor;

    switch (type) {
      case "primary":
        mainColor = custom.turquoise.main;
        pressedColor = custom.turquoise.main;
        break;

      case "secondary":
        mainColor = custom.white.light;
        pressedColor = custom.turquoise.main;
        break;
    }

    return `
      color: ${mainColor};
      text-decoration-color: ${mainColor};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
      cursor: pointer;

      &:hover {
        color: ${pressedColor};
      }

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${body1.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    `;
  }
);
