import {
  ActivationTemplate,
  ActivationType,
  IncentiveType,
  NewsletterFormsInputs,
  NewsletterFormsPositions,
  NewsletterFormsTemplate,
  NewsletterFormsTranslation
} from "api";
import { DeviceType } from "shared";
import { NewsletterFormsTemplatePositions } from "../../types";

// Function for selecting activation template
// template selection is based on language, variant and device
export const selectActivationTemplate = (
  activationTemplates: ActivationTemplate[],
  language: string,
  variant: string,
  device: string
) => {
  const { html, less, js } =
    activationTemplates.find(
      ({ language: templateLanguage, activationType, device: templateDevice, stage }) => {
        return (
          templateLanguage === language &&
          activationType === variant &&
          templateDevice === device &&
          stage === "preview"
        );
      }
    ) ?? {};
  return { html, less, js };
};

// Function for selecting optin template
// template selection is based on incentive type (variant)
export const selectOptinTemplate = (
  optinTemplates: NewsletterFormsTemplate[],
  optinPositions: NewsletterFormsTemplatePositions,
  variant: ActivationType | IncentiveType,
  device: DeviceType
) => {
  const { htmlTemplate, less, js } =
    optinTemplates.find(({ name, contentType }) => {
      const positionName = (name[0].toLowerCase() +
        name.slice(1)) as keyof NewsletterFormsPositions;

      return (
        contentType === variant &&
        optinPositions.availablePositions[positionName][device] &&
        optinPositions[device][positionName]
      );
    }) ?? {};

  return { html: htmlTemplate, less, js };
};

// Function for preparing optin settings
// settings for optin should include translation and inputs that provided from separate endpoints
export const prepareOptinSettings = (
  mockSettingsData: Record<string, string | string[]>,
  optinTranslation: NewsletterFormsTranslation | undefined,
  optinInputsData: NewsletterFormsInputs
) => {
  const settings = {
    ...mockSettingsData,
    translation: optinTranslation,
    inputs: {
      ...optinInputsData,
      hasInputs: Object.values(optinInputsData).some((value) => value)
    }
  };

  return {
    ...settings,
    config: settings
  };
};
