import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionText, Heading, Subheading, SelectDropdown } from "shared";
import { Field, Form } from "react-final-form";
import { StepWrapper, StepsContent, InputsWrappers } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useMailSettingsStep, useMailVendorsValues } from "../../../hooks";
import { MailSettingsFormSubmitMode } from "../../../types";
import { FormRenderer } from "./FormRenderer";
import { vendorValidator } from "./MailSettings.validation";

export const MailSettings = () => {
  const { t } = useTranslation();

  const {
    data,
    hasPrev,
    testResultData,
    testSettingsLoading,
    handlePrevStep,
    executeSubmitMailSettings,
    executeTestMailSettings
  } = useMailSettingsStep();

  const vendors = useMailVendorsValues();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitMailSettings}
      render={({ handleSubmit, submitting, form, submitErrors, values }) => {
        const handleFormSubmit = useCallback(async () => {
          form.change("mode", MailSettingsFormSubmitMode.SAVE);
          handleSubmit();
        }, [form, handleSubmit]);

        const handleTestSettingsPress = useCallback(async () => {
          form.change("mode", MailSettingsFormSubmitMode.TEST);
          await form.submit();
          if (form.getState().valid) {
            executeTestMailSettings(values);
          }
        }, [form, executeTestMailSettings]);

        return (
          <StepWrapper>
            <StepsContent>
              <Heading>{t("questionnaireModule.steps.integrations.title")}</Heading>
              <Subheading>
                {t("questionnaireModule.steps.integrations.mailSettings.title")}
              </Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.integrations.mailSettings.description")}
              </DescriptionText>
              <InputsWrappers singleChild>
                <Field
                  name="vendor"
                  type="select"
                  validate={vendorValidator}
                  render={({
                    input: { value, onBlur, onChange, onFocus },
                    meta: { error, modifiedSinceLastSubmit, touched }
                  }) => (
                    <SelectDropdown
                      options={vendors}
                      fullWidth
                      label={t(
                        "questionnaireModule.steps.integrations.mailSettings.form.vendor.label"
                      )}
                      placeholder={t(
                        "questionnaireModule.steps.integrations.mailSettings.form.vendor.placeholder"
                      )}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      error={
                        (error || (submitErrors?.["vendor"] && !modifiedSinceLastSubmit)) && touched
                      }
                      errorMessage={t(submitErrors?.["vendor"] ?? error)}
                    />
                  )}
                />
              </InputsWrappers>
              <FormRenderer
                vendor={values.vendor}
                submitErrors={submitErrors}
                testResult={testResultData}
                testLoading={testSettingsLoading}
                handleTestMailSettings={handleTestSettingsPress}
              />
              <NavigationButtons
                prevDisabled={!hasPrev}
                nextLoading={submitting}
                onNextClick={handleFormSubmit}
                onPrevClick={handlePrevStep}
              />
            </StepsContent>
            <MobileStepper
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleFormSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepWrapper>
        );
      }}
    />
  );
};
