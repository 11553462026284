import { styled, IconButton as MuiIconButton, Typography as MuiTypography } from "@mui/material";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { VisibilitySwitchProps } from "./VisibilitySwitch.props";

export const VisibilitySwitch = styled(
  ({ children, active, ...props }: VisibilitySwitchProps) => (
    <MuiIconButton {...props} disableRipple>
      <Chevron sx={{ transform: `rotate(${active ? "180" : "0"}deg)` }} />
      <MuiTypography variant="body2">{children}</MuiTypography>
    </MuiIconButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "error"
  }
)(
  ({
    theme: {
      palette: { custom }
    },
    error
  }) => {
    const color = error ? custom.coral.main : custom.white.main;

    return `
      padding: 0;
      color: ${color};

      & .MuiTypography-root {
        text-transform: uppercase;
      }
    `;
  }
);
