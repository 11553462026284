import { Features } from "api";
import { useCallback, useState } from "react";
import { FEATURES_POSSIBLE_CONDITIONS } from "config";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { TextSettingsFormBase } from "../TextSettingsFormBase";
import { BrowseAbandonmentTextInitialSettingsValues } from "../../types";
import {
  useDeleteBrowseAbandonmentText,
  useDisableBrowseAbandonmentText,
  useEnableBrowseAbandonmentText,
  useUpdateBrowseAbandonmentText
} from "../../hooks";

export const BrowseAbandonmentTextItemForm = ({
  initialState
}: {
  initialState: BrowseAbandonmentTextInitialSettingsValues;
}) => {
  const [opened, setOpened] = useState(false);

  const submitMethod = useUpdateBrowseAbandonmentText();

  const deleteMethod = useDeleteBrowseAbandonmentText();

  const enableMethod = useEnableBrowseAbandonmentText();

  const disableMethod = useDisableBrowseAbandonmentText();

  const handleActivationSwitch = useCallback(
    (id: number, value: boolean) => {
      if (value) {
        enableMethod(id);
      } else {
        disableMethod(id);
      }
    },
    [enableMethod, disableMethod]
  );

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <TextSettingsFormBase
      translationKey="browseAbandonment"
      conditionFeatureName="browse_abandonment_conditions_enabled"
      possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.BROWSE_ABANDONMENT]}
      initialState={initialState}
      formOpened={opened}
      expandIcon={<Chevron />}
      onOpen={handleOpen}
      onClose={handleClose}
      onSubmit={submitMethod}
      onDelete={deleteMethod}
      onSwitchActive={handleActivationSwitch}
    />
  );
};
