import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
  FETCH_ALL_DATA,
  REPORT_INVALID_DATA,
  RESET_DASHBOARD,
} from './actions';
import {
  FETCH_TABLE_DATA,
  FETCH_GRAPH_LINE_DATA,
  CHANGE_CHART_CONFIG,
  BASE_64_STRING,
  GET_TABLE_MAPPING,
  CHANGE_CHART,
  CHANGE_TABLE,
  CLEAR_TABLE_DATA,
} from './Graphs/actions';
const FETCH_ALL_DATA_ERROR =
  'react-boilerplate/LiveDashboard/FETCH_ALL_DATA_ERROR';
const FETCH_ALL_DATA_LOADING =
  'react-boilerplate/LiveDashboard/FETCH_ALL_DATA_LOADING';
const GET_TABLE_MAPPING_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/GET_TABLE_MAPPING_ERROR';
const FETCH_GRAPH_LINE_DATA_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/FETCH_GRAPH_LINE_DATA_ERROR';
const FETCH_TABLE_DATA_LOADING =
  'react-boilerplate/LiveDashboard/Graphs/FETCH_TABLE_DATA_LOADING';
const FETCH_TABLE_DATA_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/FETCH_TABLE_DATA_ERROR';
const CHANGE_CHART_CONFIG_LOADING =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART_CONFIG_LOADING';
const CHANGE_CHART_LOADING =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART_LOADING';
const CHANGE_CHART_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART_ERROR';
const CHANGE_CHART_CONFIG_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_CHART_CONFIG_ERROR';
const REPORT_INVALID_DATA_LOADING =
  'react-boilerplate/LiveDashboard/REPORT_INVALID_DATA_LOADING';
const REPORT_INVALID_DATA_ERROR =
  'react-boilerplate/LiveDashboard/REPORT_INVALID_DATA_ERROR';
const CHANGE_TABLE_LOADING =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_TABLE_LOADING';
const CHANGE_TABLE_ERROR =
  'react-boilerplate/LiveDashboard/Graphs/CHANGE_TABLE_ERROR';

function liveDashboardReducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case FETCH_ALL_DATA: {
      let dashboard = Immutable.fromJS(action.data.dashboard.elements);
      for (let i = 0; i < dashboard.get('graphs').size; i += 1) {
        dashboard = dashboard.setIn(
          ['graphs', i],
          dashboard
            .getIn(['graphs', i])
            .merge({
              data: [],
              updated: false,
              loading: false,
              errorMessage: '',
              configsSaving: false,
              configsErrorMessage: '',
            }),
        );
      }
      const noShopForTable = !(dashboard.getIn('tables', 'shops').size !== 0);
      for (let i = 0; i < dashboard.get('tables').size; i += 1) {
        dashboard = dashboard.setIn(
          ['tables', i],
          dashboard
            .getIn(['tables', i])
            .merge({
              data: [],
              updated: false,
              loading: noShopForTable,
              errorMessage: '',
            }),
        );
      }
      return state.merge(dashboard);
    }
    case FETCH_ALL_DATA_ERROR: {
      return state.set('errorMessage', action.errorMessage);
    } case GET_TABLE_MAPPING_ERROR: {
      return state.setIn(['tables', action.index, 'errorMessage'], action.errorMessage);
    } case FETCH_GRAPH_LINE_DATA: {
      return (
        state.setIn(['graphs', action.index], state.getIn(['graphs', action.index]).merge({ data: state.getIn(['graphs', action.index, 'data']).set(action.configIndex, Immutable.fromJS(action.data.data)), updated: false, errorMessage: '' }))
      );
    } case FETCH_GRAPH_LINE_DATA_ERROR: {
      return (
        state.setIn(['graphs', action.index, 'errorMessage'], action.errorMessage)
      );
    } case FETCH_TABLE_DATA: {
      return (
        state.setIn(['tables', action.index], state.getIn(['tables', action.index]).merge({ data: action.data.data, updated: false, errorMessage: '', totalCount: action.data.totalCount, loading: false }))
      );
    } case FETCH_TABLE_DATA_LOADING: {
      return (
        state.setIn(['tables', action.index, 'loading'], true)
      );
    } case FETCH_TABLE_DATA_ERROR: {
      return (
        state.setIn(['tables', action.index, 'errorMessage'], action.errorMessage)
      );
    } case CLEAR_TABLE_DATA: {
      return (
        state.setIn(['tables', action.index], state.getIn(['tables', action.index]).merge({ data: [], updated: false, errorMessage: '', totalCount: 0, loading: false }))
      );
    } case CHANGE_TABLE_LOADING: {
      return state.setIn(['tables', action.index, 'loading'], true);
    } case CHANGE_TABLE: {
      return state.setIn(['tables', action.index], Immutable.fromJS(action.newConfig).merge({ data: [], updated: true }));
    } case CHANGE_TABLE_ERROR: {
      return state.setIn(['tables', action.index, 'errorMessage'], action.errorMessage);
    } case CHANGE_CHART_LOADING: {
      return (
        state.setIn(['graphs', action.index, 'loading'], true)
      );
    } case CHANGE_CHART: {
      return (
        state.setIn(['graphs', action.index], Immutable.fromJS(action.newConfig).merge({ data: [], updated: true, loading: false }))
      );
    } case CHANGE_CHART_ERROR: {
      return (
        state.setIn(['graphs', action.index, 'errorMessage'], action.errorMessage)
      );
    } case CHANGE_CHART_CONFIG_LOADING: {
      return (
        state.setIn(['graphs', action.index, 'configsSaving'], true)
      );
    } case CHANGE_CHART_CONFIG: {
      return (
        state.setIn(['graphs', action.index], Immutable.fromJS(action.newConfig).merge({ data: [], updated: true, configsSaving: false, configsErrorMessage: '' }))
      );
    } case CHANGE_CHART_CONFIG_ERROR: {
      return (
        state.setIn(['graphs', action.index], state.getIn(['graphs', action.index]).merge({ configsErrorMessage: action.errorMessage, configsSaving: false }))
      );
    } case REPORT_INVALID_DATA: {
      let tableData = state.getIn(['tables', action.tableId, 'data']);
      action.ids.forEach((id) => {
        tableData = tableData.setIn(
          [tableData.findIndex((data) => data.get('id') === id), 'reported_at'],
          new Date(),
        );
      });
      return state.setIn(['tables', action.tableId, 'data'], tableData);
    }
    default: {
      return state;
    }
  }
}

const initialStateReport = Immutable.fromJS({
  sending: false,
  errorMessage: '',
});

function reportReducer(state = initialStateReport, action) {
  switch (action.type) {
    case REPORT_INVALID_DATA_LOADING: {
      return state.set('sending', true);
    }
    case REPORT_INVALID_DATA: {
      return state.merge({ sending: false, errorMessage: '' });
    }
    case REPORT_INVALID_DATA_ERROR: {
      return state.merge({ sending: false, errorMessage: action.errorMessage });
    }
    default: {
      return state;
    }
  }
}

function tableMappingReducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case GET_TABLE_MAPPING: {
      return state.merge(action.data);
    }
    default: {
      return state;
    }
  }
}

function base64Reducer(state = Immutable.List(), action) {
  switch (action.type) {
    case BASE_64_STRING: {
      return state.set(action.id, action.base64);
    }
    default: {
      return state;
    }
  }
}

function resetDashboardReducer(state = false, action) {
  switch (action.type) {
    case RESET_DASHBOARD: {
      return true;
    }
    default: {
      return state;
    }
  }
}

function dashboardLoadingReducer(state = true, action) {
  switch (action.type) {
    case FETCH_ALL_DATA_LOADING: {
      return true;
    }
    case FETCH_ALL_DATA: {
      return false;
    }
    default: {
      return state;
    }
  }
}

const liveDashboard = combineReducers({
  dashboard: liveDashboardReducer,
  report: reportReducer,
  tableMapping: tableMappingReducer,
  base64: base64Reducer,
  resetDashboard: resetDashboardReducer,
  dashboardLoading: dashboardLoadingReducer,
});

export default liveDashboard;
