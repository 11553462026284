import { GetContactPersonListResponse } from "api";
import { apiClient } from "../utils";

export const getContactPersonList = async (shopId: number, limit: number, offset: number) => {
  const { data } = await apiClient.get<GetContactPersonListResponse>(`${shopId}/contact-persons`, {
    params: {
      limit,
      offset
    }
  });
  return data;
};
