import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getShopList } from "../../api";
import { useNotifications } from "../useNotifications";

export const useShopListData = (enabled?: boolean) => {
  const { showNotification } = useNotifications();

  const { data, isLoading, refetch } = useQuery(["shopData"], getShopList, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled,
    onError: (error) => {
      const castedError = error as AxiosError<{ message: string }>;

      let errorTranslationKey = "global.error.serverUnavailable";

      if (castedError.response?.data?.message) {
        errorTranslationKey = castedError.response.data.message;
      }

      showNotification(errorTranslationKey, "error");
    }
  });

  return { data, loading: isLoading, refetch };
};
