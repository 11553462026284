import { useQueryWrapper, useSelectedShop } from "shared";
import { getBrowseAbandonmentCanEnable } from "../../api";

export const useBrowseAbandonmentCanEnable = () => {
  const { selectedShopId } = useSelectedShop();

  const { data, isLoading: configLoading } = useQueryWrapper(
    ["browseAbandonmentCanEnable", selectedShopId],
    async () => getBrowseAbandonmentCanEnable(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { canEnable: data?.canEnable || false, loading: configLoading };
};
