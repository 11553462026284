import Immutable from 'immutable';
import {CREATE_NEW_ROW, GET_TEMPLATES, START_TEST, UPDATE_ROW} from "./actions";
import {combineReducers} from 'redux-immutable';

const abtestDesignerReducer = combineReducers({
  templates: templatesReducer,
  state: stateReducer
});

export default abtestDesignerReducer;


function templatesReducer(state = Immutable.fromJS({
                            loading: false,
                            data: undefined,
                            errorMessage: undefined
                          }),
                          action) {

  switch (action.type) {
    case CREATE_NEW_ROW:{

      const data = state.toJS().data;
      data.push({name:action.newName, distributions:[]});
      return state.merge(Immutable.fromJS({
        data:data,
        loading: false,
        errorMessage: undefined
      }));
    }
    case GET_TEMPLATES: {

      return state.merge(Immutable.fromJS({
        data: action.data.data,
        loading: false,
        errorMessage: undefined
      }));
    }
    case GET_TEMPLATES + "_LOADING": {
      return state.merge({loading: true, errorMessage: undefined});
    }
    case GET_TEMPLATES + "_ERROR": {
      return state.merge({loading: false, errorMessage: JSON.stringify(action)});
    }
    default: {
      return state;
    }
  }
}

function stateReducer(state = Immutable.fromJS({
                        loading: false,
                      }),
                      action) {

  switch (action.type) {

    case UPDATE_ROW:
    case START_TEST: {
      return state.merge({
        loading: false,
      });
    }
    case UPDATE_ROW + "_LOADING":
    case START_TEST + "_LOADING": {

      return state.merge({loading: true});
    }

    default: {
      return state;
    }
  }
}
