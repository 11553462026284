/* eslint-disable prefer-template */
import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import { GET_SALES_PROVISION, GET_SINGLE_PAYMENT_PROVISION } from './actions';

const salesDashboardReducer = combineReducers({
  provision: salesDashboardProvisionReducer,
  singleTime: salesDashboardSinglePaymentReducer,
});

export default salesDashboardReducer;

function salesDashboardProvisionReducer(
  state = Immutable.fromJS({
    loading: false,
    data: undefined,
    errorMessage: undefined,
  }),
  action,
) {
  switch (action.type) {
    case GET_SALES_PROVISION: {
      return state.merge(
        Immutable.fromJS({
          data: action.data,
          loading: false,
          errorMessage: undefined,
        }),
      );
    }
    case GET_SALES_PROVISION + '_LOADING': {
      return state.merge({
        data: undefined,
        loading: true,
        errorMessage: undefined,
      });
    }
    case GET_SALES_PROVISION + '_ERROR': {
      return state.merge({
        data: undefined,
        loading: false,
        errorMessage: JSON.stringify(action),
      });
    }
    default: {
      return state;
    }
  }
}

function salesDashboardSinglePaymentReducer(
  state = Immutable.fromJS({
    loading: false,
    data: undefined,
    errorMessage: undefined,
  }),
  action,
) {
  switch (action.type) {
    case GET_SINGLE_PAYMENT_PROVISION: {
      return state.merge(
        Immutable.fromJS({
          data: action.data,
          loading: false,
          errorMessage: undefined,
        }),
      );
    }
    case GET_SINGLE_PAYMENT_PROVISION + '_LOADING': {
      return state.merge({
        data: undefined,
        loading: true,
        errorMessage: undefined,
      });
    }
    case GET_SINGLE_PAYMENT_PROVISION + '_ERROR': {
      return state.merge({
        data: undefined,
        loading: false,
        errorMessage: JSON.stringify(action),
      });
    }
    default: {
      return state;
    }
  }
}
