import { styled, IconButton as MuiIconButton } from "@mui/material";
import Chevron from "@mui/icons-material/ExpandMoreOutlined";
import { ReactComponent as LogoIcon } from "../../../../assets/icons/uptain_logo.svg";
import { UptainLogoProps } from "./UptainLogo.props";

export const UptainLogo = styled(({ active, ...props }: UptainLogoProps) => (
  <MuiIconButton {...props}>
    <LogoIcon />
    <Chevron
      sx={{
        transition: "transform 0.1s ease-in-out",
        transform: `rotate(${active ? "180" : "0"}deg)`
      }}
    />
  </MuiIconButton>
))(
  ({
    theme: {
      palette: { custom },
      breakpoints
    },
    active
  }) => {
    const color = active ? custom.turquoise.main : custom.white.main;

    return `
      color: ${color};
  
      & .MuiSvgIcon-root {
        display: initial;

        ${[breakpoints.up("xl")]} {
          display: none;
        }
      }
    `;
  }
);
