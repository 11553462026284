import { ConditionKind } from "api";
import { CustomerGroupConditionItem, RevenueConditionItem } from "../ConditionItemsVariants";
import { ConditionRendererProps } from "./ConditionRenderer.props";

export const ConditionRenderer = ({ kind, index }: ConditionRendererProps) => {
  switch (kind) {
    case ConditionKind.CUSTOMER_GROUP:
      return <CustomerGroupConditionItem index={index} />;
    case ConditionKind.REVENUE:
      return <RevenueConditionItem index={index} />;
    default:
      return null;
  }
};
