import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

import { LinkHrefStyled } from '../../components/LinkStyled';
import packageJson from '../../../../package.json';

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  padding: ${(props) =>
    props.dashboard ? '10px 10px 20px' : '10px 10px 50px'};

  @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    text-align: left;
    padding: 10px 20px 10px;
  }
`;

const Privacy = styled.div`
  @media screen and (min-width: ${(props) => props.theme.small}) {
    position: relative;
    right: 50%;
    transform: translateX(50%);
    float: right;
  }
`;

const Footer = ({ shops, path, intl }) => {
  const generateUrl = (german, english) => {
    const url = {
      de: german,
      en: `en/${english || german}`,
    }[intl.locale];
    return `https://uptain.de/${url}/`;
  };

  return (
    <FooterWrapper dashboard={path === '/dashboard' || shops.size <= 1}>
      Version {packageJson.version}
      <Privacy>
        <LinkHrefStyled
          href={generateUrl('datenschutz', 'privacy-policy')}
          target="_blank"
          color="#EFEFEF"
        >
          <FormattedMessage
            id="containers.Dashboard.Footer.privacyPolicy"
            defaulMessage="Privacy Policy"
          />
        </LinkHrefStyled>
      </Privacy>
    </FooterWrapper>
  );
};

Footer.defaulProps = {
  shops: [],
};

Footer.propTypes = {
  shops: PropTypes.object,
  path: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Footer);
