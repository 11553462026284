import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared";
import { getLinksConfig } from "../../api";

export const useLinksConfigData = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper(
    [queryName, selectedShopId],
    async () => await getLinksConfig(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { initialState: data, loading: isLoading };
};
