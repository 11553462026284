import { styled, Box as MuiBox, Typography as MuiTypography } from "@mui/material";
import { FieldsWrapperProps } from "./AddOptions.props";

export const FieldsWrapper = styled((props: FieldsWrapperProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "error"
})(
  ({ theme: { breakpoints }, error }) => `
    &.MuiBox-root {
      margin: 32px 0 ${error ? "0px" : "56px"};

      ${[breakpoints.up("md")]} {
        margin: 80px 0 ${error ? "0px" : "56px"};
      }
    }
  `
);

export const FieldLine = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `
);

export const FieldTitle = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    &.MuiTypography-root {
      margin: 0 32px 0 12px;
      font-weight: ${body1.mobile.fontWeight};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      color: ${custom.white.main};
    }
  `
);
