import Immutable from 'immutable';
import {CREATE_NEW_MAIL_TEMPLATE, GET_TEMPLATES, UPDATE_MAIL_TEMPLATE} from "./actions";
import {combineReducers} from 'redux-immutable';

const mailTemplatesDesignerReducer = combineReducers({
  templates: templatesReducer,
  state: stateReducer
});

export default mailTemplatesDesignerReducer;


function templatesReducer(state = Immutable.fromJS({
                            loading: false,
                            data: undefined,
                            errorMessage: undefined
                          }),
                          action) {

  switch (action.type) {
    case GET_TEMPLATES: {

      return state.merge(Immutable.fromJS({
        data: action.data.data,
        loading: false,
        errorMessage: undefined
      }));
    }
    case GET_TEMPLATES + "_LOADING": {
      return state.merge({loading: true, errorMessage: undefined});
    }
    case GET_TEMPLATES + "_ERROR": {
      return state.merge({loading: false, errorMessage: JSON.stringify(action)});
    }
    default: {
      return state;
    }
  }
}

function stateReducer(state = Immutable.fromJS({
                        loading: false,
                      }),
                      action) {

  switch (action.type) {
    case CREATE_NEW_MAIL_TEMPLATE:
    case UPDATE_MAIL_TEMPLATE: {
      return state.merge({
        loading: false,
      });
    }
    case CREATE_NEW_MAIL_TEMPLATE + "_LOADING":
    case UPDATE_MAIL_TEMPLATE + "_LOADING": {

      return state.merge({loading: true});
    }

    default: {
      return state;
    }
  }
}
