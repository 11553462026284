import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrayConditionOperators } from "api";
import { MultiValueInput, SelectDropdown } from "shared";
import { Field, useForm, useFormState } from "react-final-form";
import { ConditionItem } from "../../ConditionItem";
import { CustomerGroupConditionItemProps } from "./CustomerGroupConditionItem.props";
import { OperatorWrapper, ValueWrapper } from "./CustomerGroupConditionItem.styles";
import { valueValidator, operatorValidator } from "./CustomerGroupConditionItem.validation";
import { ConditionsObjectsFormValues, SettingsConditionsFormValues } from "../../../../types";

export const CustomerGroupConditionItem = ({ index }: CustomerGroupConditionItemProps) => {
  const { t, i18n } = useTranslation();

  const operatorOptions = useMemo(
    () =>
      Object.values(ArrayConditionOperators).map((key) => ({
        key,
        title: t(`featuresModule.conditions.operators.${key}`)
      })),
    [i18n.language]
  );

  const { values, submitErrors } = useFormState<SettingsConditionsFormValues>();

  const { change } = useForm<SettingsConditionsFormValues>();

  const handleDelete = useCallback(() => {
    const newConditions = values.conditions?.filter(
      (_: ConditionsObjectsFormValues, i) => i !== index
    );

    change("conditions", newConditions);
  }, [change, values, index]);

  const operator = values.conditions?.[index]?.operator;

  return (
    <ConditionItem onDelete={handleDelete}>
      <Field
        name={`conditions.${index}.operator`}
        validate={operatorValidator}
        render={({
          input: { value, onChange, onBlur, onFocus },
          meta: { error, touched, modifiedSinceLastSubmit }
        }) => (
          <OperatorWrapper>
            <SelectDropdown
              label={t("featuresModule.conditions.form.customerGroup.label")}
              options={operatorOptions}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              marginBottom={30}
              error={
                (error ||
                  (submitErrors?.[`conditions/${index}/operator`] && !modifiedSinceLastSubmit)) &&
                touched
              }
              errorMessage={t(submitErrors?.[`conditions/${index}/operator`] ?? error)}
            />
          </OperatorWrapper>
        )}
      />
      <Field
        name={`conditions.${index}.value`}
        validate={valueValidator}
        render={({
          input: { value, onChange, onBlur, onFocus },
          meta: { error, touched, modifiedSinceLastSubmit }
        }) => {
          const labelValue = `${t("featuresModule.conditions.form.customerGroup.label")} ${
            operator ? `${t(`featuresModule.conditions.operators.${operator}`)}` : ""
          }`;

          return (
            <ValueWrapper>
              <MultiValueInput
                label={labelValue}
                placeholder={t("featuresModule.conditions.form.customerGroup.placeholder")}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                marginBottom={30}
                minLength={2}
                maxLength={128}
                fullWidth
                error={
                  (error ||
                    (submitErrors?.[`conditions/${index}/value`] && !modifiedSinceLastSubmit)) &&
                  touched
                }
                errorMessage={t(submitErrors?.[`conditions/${index}/value`] ?? error)}
              />
            </ValueWrapper>
          );
        }}
      />
    </ConditionItem>
  );
};
