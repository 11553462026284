/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import { Field, change } from 'redux-form/immutable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SelectBox from '../SelectBox';
import UpRow from '../UpRow';

import URL from './Filters/URL';
import Referrer from './Filters/Referrer';
import ShoppingCartValue from './Filters/ShoppingCartValue';
import Element from './Filters/Element';
import TimeOfDay from './Filters/TimeOfDay';
import UptainQuery from './Filters/UptainQuery';
import Condition from './Filters/Condition';
import CustomerGroup from './Filters/CustomerGroup';
import Revenue from './Filters/Revenue';
import Language from './Filters/Language';
import QueryParameter from './Filters/QueryParameter';

const InputGroup = styled.div`
  margin-right: 10px;
`;

const selectBox = (props) => (
  <InputGroup>
    <SelectBox
      options={props.customProps.options}
      value={props.input.value}
      name={props.input.name}
      onChange={(name, value) =>
        props.change(
          props.meta.form,
          props.customProps.member,
          fromJS({
            category: value,
            filter: filter[value],
          }),
        )
      }
      minWidth="184px"
      inputDisabled
    />
  </InputGroup>
);

selectBox.propTypes = {
  customProps: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

export const productConditionMap = {
  mail: [
    'url',
    'shoppingCartValue',
    'referrer',
    'condition',
    'customerGroup',
    'revenue',
  ],
  activation: [
    'url',
    'shoppingCartValue',
    'timeOfDay',
    'element',
    'referrer',
    'uptainQuery',
    'condition',
    'customerGroup',
    'queryParameter',
    'revenue',
    'language',
  ],
  optin: ['url', 'shoppingCartValue', 'revenue'],
};

const filter = {
  url: { operator: 'contains', attribute: [] },
  referrer: { operator: 'contains', attribute: [] },
  queryParameter: { parameter: '', operator: 'contains', attribute: [] },
  shoppingCartValue: { operator: 'smaller-or-equals', attribute: '' },
  element: { element: '', operator: 'exists', attribute: [] },
  timeOfDay: { operator: 'within', element: '0' },
  uptainQuery: { parameter: '', operator: 'empty', attribute: [] },
  condition: {},
  customerGroup: { operator: 'contains', attribute: [] },
  revenue: { operator: 'greater', attribute: '' },
  language: { operator: 'oneOf', attribute: [] },
};

const options = (intl) => ({
  url: { value: 'url', label: 'URL' },
  shoppingCartValue: {
    value: 'shoppingCartValue',
    label: intl.formatMessage({
      id: 'components.ConditionLine.shoppingCartValue',
      defaultMessage: 'Shopping Cart Value',
    }),
  },
  referrer: { value: 'referrer', label: 'Referrer' },
  condition: {
    value: 'condition',
    label: intl.formatMessage({
      id: 'components.ConditionLine.condition',
      defaultMessage: 'Condition',
    }),
  },
  customerGroup: {
    value: 'customerGroup',
    label: intl.formatMessage({
      id: 'components.ConditionLine.customerGroup',
      defaultMessage: 'Customer Group',
    }),
  },
  revenue: {
    value: 'revenue',
    label: intl.formatMessage({
      id: 'components.ConditionLine.revenue',
      defaultMessage: 'Revenue',
    }),
  },
  timeOfDay: {
    value: 'timeOfDay',
    label: intl.formatMessage({
      id: 'components.ConditionLine.daysTimes',
      defaultMessage: 'Days/Times',
    }),
  },
  element: { value: 'element', label: 'HTML Element' },
  uptainQuery: { value: 'uptainQuery', label: 'uptain-query' },
  queryParameter: { value: 'queryParameter', label: 'URL Parameter' },
  language: {
    value: 'language',
    label: intl.formatMessage({
      id: 'components.ConditionLine.language',
      defaultMessage: 'Language',
    }),
  },
});

const ConditionLine = (props) => {
  const CATEGORY_STATES = (condition, member) =>
    ({
      url: <URL member={member} conditions={props.myValue} />,
      shoppingCartValue: <ShoppingCartValue member={member} />,
      referrer: (
        <Referrer
          member={member}
          product={props.product}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
      condition: <Condition member={member} product={props.product} />,
      customerGroup: (
        <CustomerGroup
          member={member}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
      revenue: <Revenue member={member} product={props.product} />,
      element: (
        <Element
          member={member}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
      timeOfDay: (
        <TimeOfDay member={member} selectedShop={props.selectedShop} />
      ),
      queryParameter: (
        <QueryParameter
          member={member}
          product={props.product}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
      language: (
        <Language
          member={member}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
      uptainQuery: (
        <UptainQuery
          member={member}
          resetOnChange={() =>
            props.change(
              props.meta.form,
              `${member}.filter.attribute`,
              fromJS([]),
            )
          }
        />
      ),
    }[condition]);

  return (
    <div>
      {props.fields.length === 0 ? (
        <div>
          {/* No conditions set: The algorithm has the permission to show this dialog box everytime. If you want to define
          rules when and where this dialog box should be visible add conditions. */}
          {props.noConditionMessage}
        </div>
      ) : (
        <div>
          {props.fields.map((member, index) => (
            <UpRow
              key={index}
              remove={() => props.fields.remove(index)}
              breakpoint="550px"
            >
              <Field
                name={`${member}.category`}
                component={selectBox}
                customProps={{
                  options: productConditionMap[props.product].map(
                    (option) => options(props.intl)[option],
                  ),
                  member,
                }}
                change={props.change}
              />
              {CATEGORY_STATES(
                props.myValue.getIn([index, 'category']),
                member,
              )}
            </UpRow>
          ))}
        </div>
      )}
    </div>
  );
};

ConditionLine.propTypes = {
  selectedShop: PropTypes.string,
  fields: PropTypes.object.isRequired,
  myValue: PropTypes.object,
  product: PropTypes.oneOf(['activation', 'mail', 'optin']).isRequired,
  noConditionMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  change: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  intl: PropTypes.object,
};

// REDUX
const mapStateToProps = (state, ownProps) => ({
  myValue: state.getIn(['form', ownProps.meta.form, 'values', 'conditions']),
  selectedShop: state.getIn([
    'customerBackendDashboard',
    'userData',
    'selectedShop',
  ]),
});

const matchDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch);

export default injectIntl(
  connect(mapStateToProps, matchDispatchToProps)(ConditionLine),
);
