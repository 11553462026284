import { Box as MuiBox, styled, Collapse as MuiCollapse } from "@mui/material";

export const FormWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin: 0 20px;
      padding: 80px 0 65px;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 500px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);

export const InputWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {

      ${[breakpoints.up("sm")]} {
        margin-left: 10px;
        width: 453px;
      }
    }
  `
);

export const ButtonWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin-top: 25px;

      ${[breakpoints.up("sm")]} {
        margin: 25px 0 0 10px;
        width: 240px;
      }
    }
  `
);

export const Collapse = styled(MuiCollapse)(
  () => `
    & .MuiCollapse-wrapperInner {
      padding-top: 26px;
    }
  `
);
