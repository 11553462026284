import { useCallback, useMemo } from "react";
import {
  Currencies,
  QuestionnaireComeBackMailingVoucherSettingsStepData,
  QuestionnaireStepType,
  VoucherTypes
} from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { putSubmitComeBackMailingVoucherSettings } from "../../api";
import {
  QuestionnaireComeBackMailingVoucherSettingsFormValues,
  VoucherListControlMode
} from "../../types";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { comeBackMailingVoucherSettingsFormMapper } from "./useComeBackMailingVoucherSettingsStep.helpers";

export const useComeBackMailingVoucherSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.COME_BACK_MAILING_VOUCHER_SETTINGS);

  const data: QuestionnaireComeBackMailingVoucherSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      voucherValueType: stepData?.data?.voucherValueType || Currencies.PERCENTAGE,
      voucherKind: stepData?.data?.voucherKind || VoucherTypes.CODE,
      voucherListControlMode:
        stepData?.data?.listName && stepData?.data?.voucherKind === VoucherTypes.LIST
          ? VoucherListControlMode.SELECT
          : VoucherListControlMode.CREATE
    }),
    [stepData?.data]
  );

  const submitComeBackMailingVoucherSettings =
    useQuestionnaireSubmitMutation<QuestionnaireComeBackMailingVoucherSettingsStepData>(
      putSubmitComeBackMailingVoucherSettings
    );

  const executeSubmitComeBackMailingVoucherSettings: FormProps<
    QuestionnaireComeBackMailingVoucherSettingsFormValues,
    QuestionnaireComeBackMailingVoucherSettingsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const comeBackMailingVoucherSettingsSubmitData =
          comeBackMailingVoucherSettingsFormMapper(formValues);

        await submitComeBackMailingVoucherSettings(comeBackMailingVoucherSettingsSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitComeBackMailingVoucherSettings]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitComeBackMailingVoucherSettings
  };
};
