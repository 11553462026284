import { useMemo, Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { importedModules } from 'modules-routes';
import { withAuth, withFeatureFlag, PageLoading, useLocalStorageValue, LocalStorageKey } from 'shared';

import routes from './routes';
import { redirectRoutes } from './redirectRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import { isLoggedIn } from '../requireAuth';


const Router = () => {
  const { search, hash } = useLocation();
  const features = useFeatures();

  function getRenderedRoutes(arr) {
    return routes.filter((obj) => arr.includes(obj.feature) || !obj.feature);
  }

  const renderedRoutes = useMemo(() => getRenderedRoutes(features), [features]);

  const mappedRoutes = useMemo(() => importedModules.map(({ component, getPageConfig }) => {
    const { path, requiredPermission, featureFlag, disabled } = getPageConfig();

    if (disabled) {
      return undefined;
    }

    let Element = component;

    if (requiredPermission) {
      Element = withAuth(Element, requiredPermission);
    }
    if (featureFlag) {
      Element = withFeatureFlag(Element, featureFlag, requiredPermission);
    }

    return (
      <Route
        key={path}
        path={path}
        exact
        element={
          <Suspense fallback={<PageLoading />}>
            <Element />
          </Suspense>
        }
      />
    );
  }), []);

  return (
    <Routes>
      {redirectRoutes.map((item) => (
        <Route
          key={item.from}
          path={item.from}
          element={
            <Navigate
              to={{
                pathname: item.to,
                search,
                hash: item.hash || hash,
              }}
              replace
            />
          }
        />
      ))}

      {renderedRoutes.map(({ exact, path, onEnter, ...props }) =>
        onEnter ? (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={<PrivateRoute checkAuth={onEnter} {...props} />}
          />
        ) : (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={<PublicRoute checkAuth={onEnter} {...props} />}
          />
        ),
      )}

      <Route
        exact
        path="/"
        element={
          !isLoggedIn() ? (
            <Navigate
              to={{
                pathname: "/login",
                search,
                hash
              }}
              replace
            />
          ) : (
            <Navigate
              to="/dashboard"
              replace
            />
          )
        }
      />

      {mappedRoutes}
    </Routes>
  );
};

export { Router };
