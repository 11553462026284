import { styled, Box as MuiBox } from "@mui/material";

export const VendorDividerWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      & > .MuiDivider-root {
        margin: 12px 0 42px;
      }
    }
  `
);
