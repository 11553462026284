import { Field } from "react-final-form";
import { Alert, ContainedButton, Divider, SelectDropdown, TextInput } from "shared";
import { Trans, useTranslation } from "react-i18next";
import { CustomFormVariantProps } from "./CustomFormVariant.props";
import {
  hostValidator,
  passwordValidator,
  portValidator,
  senderAddressValidator,
  senderNameValidator,
  usernameValidator
} from "../../MailSettings.validation";
import { TestButtonWrapper } from "../../MailSettings.styles";
import { QUESTIONNAIRE_MAIL_SETTINGS_ENCRYPTIONS } from "../../../../../config";
import { InputsWrappers, ValueAlertWrapper } from "../../../../StepStyles";
import { VendorDividerWrapper } from "./CustomFormVariant.styles";

export const CustomFormVariant = ({
  submitErrors,
  testLoading,
  testResult,
  handleTestMailSettings
}: CustomFormVariantProps) => {
  const { t } = useTranslation();

  return (
    <>
      <VendorDividerWrapper>
        <Divider />
      </VendorDividerWrapper>
      <InputsWrappers>
        <Field
          name="senderAddress"
          validate={senderAddressValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderAddress.label"
              )}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderAddress.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.senderAddress.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={
                (error || (submitErrors?.["senderAddress"] && !modifiedSinceLastSubmit)) && touched
              }
              errorMessage={t(submitErrors?.["senderAddress"] ?? error)}
            />
          )}
        />
        <Field
          name="username"
          validate={usernameValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.username.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.username.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.username.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={(error || (submitErrors?.["username"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["username"] ?? error)}
            />
          )}
        />
      </InputsWrappers>
      <InputsWrappers>
        <Field
          name="host"
          validate={hostValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.host.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.host.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.host.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={(error || (submitErrors?.["host"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["host"] ?? error)}
            />
          )}
        />
        <Field
          name="password"
          validate={passwordValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              password
              label={t("questionnaireModule.steps.integrations.mailSettings.form.password.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.password.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.password.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={(error || (submitErrors?.["password"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["password"] ?? error)}
            />
          )}
        />
      </InputsWrappers>
      <InputsWrappers>
        <Field
          name="port"
          validate={portValidator}
          render={({
            input: { value, onChange, onBlur, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.port.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.port.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.port.hint"
                  t={t}
                />
              }
              value={value ? value : ""}
              onChange={(value) => {
                const numericOnly = value.replace(/[^0-9]/g, "");

                onChange(numericOnly);
              }}
              onBlur={() => {
                onChange(Number(value));
                onBlur();
              }}
              onFocus={onFocus}
              error={(error || (submitErrors?.["port"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["port"] ?? error)}
            />
          )}
        />
        <Field
          name="senderName"
          validate={senderNameValidator}
          render={({
            input: { value, onChange, onBlur, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.senderName.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderName.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.senderName.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={
                (error || (submitErrors?.["senderName"] && !modifiedSinceLastSubmit)) && touched
              }
              errorMessage={t(submitErrors?.["senderName"] ?? error)}
            />
          )}
        />
      </InputsWrappers>
      <InputsWrappers singleChild>
        <Field
          name="encryption"
          type="select"
          render={({ input: { value, onBlur, onChange, onFocus } }) => (
            <SelectDropdown
              options={QUESTIONNAIRE_MAIL_SETTINGS_ENCRYPTIONS}
              label={t("questionnaireModule.steps.integrations.mailSettings.form.encryption.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.encryption.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.encryption.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          )}
        />
      </InputsWrappers>
      <TestButtonWrapper>
        <ContainedButton fullWidth loading={testLoading} onClick={handleTestMailSettings}>
          {t("questionnaireModule.steps.integrations.mailSettings.form.testButton")}
        </ContainedButton>
      </TestButtonWrapper>
      {testResult && (
        <ValueAlertWrapper>
          <Alert severity={testResult.success ? "info" : "error"}>
            <Trans
              i18nKey={`questionnaireModule.steps.integrations.mailSettings.testAlert.${
                testResult.success ? "success" : "error"
              }`}
              t={t}
              values={{ error: testResult.message }}
            />
          </Alert>
        </ValueAlertWrapper>
      )}
    </>
  );
};
