import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { TextButtonProps } from "./TextButton.props";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

const defaultIconViewBox = "4 4 16 16";

export const TextButton = styled(({ iconPosition, icon: Icon, ...props }: TextButtonProps) => {
  let startIcon: React.ReactNode;
  let endIcon: React.ReactNode;
  let loadingPosition: "start" | "end" | "center" = "center";

  switch (iconPosition) {
    case "start":
      startIcon = Icon ? (
        React.cloneElement(Icon, { viewBox: defaultIconViewBox, ...Icon.props })
      ) : (
        <ArrowBackIcon viewBox={defaultIconViewBox} />
      );
      loadingPosition = "start";
      break;

    case "end":
      endIcon = Icon ? (
        React.cloneElement(Icon, { viewBox: defaultIconViewBox, ...Icon.props })
      ) : (
        <ArrowForwardIcon viewBox={defaultIconViewBox} />
      );
      loadingPosition = "end";
      break;
  }

  return (
    <LoadingButton
      {...props}
      variant="text"
      startIcon={startIcon}
      endIcon={endIcon}
      loadingPosition={loadingPosition}
      disableRipple
    />
  );
})(
  ({
    theme: {
      palette: { custom },
      typography: { button, body2 },
      breakpoints
    },
    variant = "primary",
    iconPosition,
    loading
  }) => {
    let textColor;
    let pressedColor;
    let textStyle = button;

    switch (variant) {
      case "primary":
        textColor = custom.coral.main;
        pressedColor = custom.coral.light;
        break;

      case "secondary":
        textColor = custom.white.light;
        pressedColor = custom.white.main;
        break;

      case "tertiary":
        textColor = custom.turquoise.main;
        pressedColor = custom.turquoise.light;
        textStyle = body2;
        break;
    }

    const disabledColor = loading ? custom.white.light : custom.white.dark;

    return `
      color: ${textColor};
      font-size: ${textStyle.mobile.fontSize};
      line-height: ${textStyle.mobile.lineHeight};
      font-weight: ${textStyle.mobile.fontWeight};
      text-transform: uppercase;

      ${[breakpoints.up("md")]} {
        font-size: ${textStyle.tablet.fontSize};
        line-height: ${textStyle.tablet.lineHeight};
        font-weight: ${textStyle.tablet.fontWeight};
      }

      ${[breakpoints.up("lg")]} {
        font-size: ${textStyle.desktop.fontSize};
        line-height: ${textStyle.desktop.lineHeight};
        font-weight: ${textStyle.desktop.fontWeight};
      }

      :active {
        color: ${pressedColor};
      }

      :disabled {
        color: ${iconPosition ? disabledColor : "transparent"};

        & .MuiLoadingButton-loadingIndicator {
          color: ${disabledColor}
        }
      }

      & .MuiButton-endIcon, .MuiButton-startIcon {
        transition: transform .25s;

        & svg {
          width: 20px;
          height: 20px;
        }
      }

      & .MuiButton-startIcon {
        margin-left: 0;
      }

      & .MuiButton-endIcon {
        margin-right: 0;
      }

      :hover {
        background: none;

        & .MuiButton-endIcon {
          transform: translateX(2px);
        }

        & .MuiButton-startIcon {
          transform: translateX(-2px);
        }
      }
    `;
  }
);
