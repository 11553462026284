import { apiClient } from "shared";
import { QuestionnaireCustomerSupportAvailabilityStepData, ShopIdParam } from "api";

export const putSubmitCustomerSupportAvailability = async ({
  shopId,
  ...values
}: QuestionnaireCustomerSupportAvailabilityStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/support-availability`, values);

  return data;
};
