import { QuestionnaireAddOptionStepData, QuestionnaireAddOptionsStepMetadata } from "api";
import { QuestionnaireAddOptionFormMetadataState } from "../../types";

export const parseAddOptionsFormMetadata = (
  metadata: QuestionnaireAddOptionsStepMetadata | undefined
): QuestionnaireAddOptionFormMetadataState => {
  const fields = metadata?.fields;

  const emailSupportEnabled = !fields?.emailSupportEnabled?.disabled;

  const phoneSupportEnabled = !fields?.phoneSupportEnabled?.disabled;

  const activationPopupVoucherEnabled = !fields?.activationPopupVoucherEnabled?.disabled;

  const abandonmentMailVoucherEnabled = !fields?.abandonmentMailVoucherEnabled?.disabled;

  const newsletterVoucherEnabled = !fields?.newsletterVoucherEnabled?.disabled;

  const browseAbandonmentVoucherEnabled = !fields?.browseAbandonmentVoucherEnabled?.disabled;

  const comeBackMailingVoucherEnabled = !fields?.comeBackMailingVoucherEnabled?.disabled;

  return {
    emailSupportEnabled,
    phoneSupportEnabled,
    activationPopupVoucherEnabled,
    abandonmentMailVoucherEnabled,
    newsletterVoucherEnabled,
    browseAbandonmentVoucherEnabled,
    comeBackMailingVoucherEnabled
  };
};

export const addOptionsFormMapper = (
  {
    abandonmentMailVoucherEnabled,
    activationPopupVoucherEnabled,
    emailSupportEnabled,
    newsletterVoucherEnabled,
    phoneSupportEnabled,
    browseAbandonmentVoucherEnabled,
    comeBackMailingVoucherEnabled
  }: QuestionnaireAddOptionStepData,
  {
    abandonmentMailVoucherEnabled: abandonmentMailVoucherActive,
    activationPopupVoucherEnabled: activationPopupVoucherActive,
    emailSupportEnabled: emailSupportActive,
    newsletterVoucherEnabled: newsletterVoucherActive,
    phoneSupportEnabled: phoneSupportActive,
    browseAbandonmentVoucherEnabled: browseAbandonmentVoucherActive,
    comeBackMailingVoucherEnabled: comeBackMailingVoucherActive
  }: QuestionnaireAddOptionFormMetadataState
): QuestionnaireAddOptionStepData => {
  return {
    abandonmentMailVoucherEnabled: abandonmentMailVoucherActive
      ? !!abandonmentMailVoucherEnabled
      : undefined,
    activationPopupVoucherEnabled: activationPopupVoucherActive
      ? !!activationPopupVoucherEnabled
      : undefined,
    emailSupportEnabled: emailSupportActive ? !!emailSupportEnabled : undefined,
    newsletterVoucherEnabled: newsletterVoucherActive ? !!newsletterVoucherEnabled : undefined,
    phoneSupportEnabled: phoneSupportActive ? !!phoneSupportEnabled : undefined,
    browseAbandonmentVoucherEnabled: browseAbandonmentVoucherActive
      ? !!browseAbandonmentVoucherEnabled
      : undefined,
    comeBackMailingVoucherEnabled: comeBackMailingVoucherActive
      ? !!comeBackMailingVoucherEnabled
      : undefined
  };
};
