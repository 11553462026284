import { PostRenderRequest, PostRenderResponse } from "api";
import axios from "axios";
import { localStorage } from "../../../utils";
import { LocalStorageKey } from "../../../types";

export const postRender = async ({
  data,
  html,
  less,
  js
}: PostRenderRequest): Promise<PostRenderResponse> => {
  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_RENDERER_URL
  });

  apiClient.interceptors.request.use((config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: localStorage.getItem(LocalStorageKey.TOKEN) || ""
    }
  }));

  // 1. Render template
  const { data: templateRes } = await apiClient.post<PostRenderResponse>("/", {
    data,
    html,
    less
  });

  // 2. Additionally render JS for Newsletter Forms (why? because newsletter forms will be rendered properly only if template value provided)
  const { data: result } = await apiClient.post<PostRenderResponse>("/", {
    data: { ...data, template: templateRes.html, css: templateRes.less },
    js
  });

  return { html: templateRes.html, less: templateRes.less, js: result.js };
};
