import { createSelector } from 'reselect';

export const selectShops = createSelector(
  (state) => state.getIn(['customerBackendDashboard', 'userData', 'shops']),
  (state) =>
    state.getIn(['customerBackendDashboard', 'userData', 'shopsRecieved']),
  (state) =>
    state.getIn([
      'customerBackendDashboard',
      'admin',
      'storeAdministration',
      'creatingShop',
    ]),
  (shops, isShopsReceived, isCreatingNewShop) => ({
    shopsCount: shops.size,
    isShopsReceived,
    isCreatingNewShop,
  }),
);
