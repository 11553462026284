import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import {
  useQueryConfig,
  useSelectedShop,
  useErrorFormatter,
  useNotifications,
  NetworkError
} from "shared";
import { postBrowseAbandonmentConfigEnable } from "../../api";

export const useEnableBrowseAbandonmentConfig = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: enableBrowseAbandonmentConfig } = useMutation<
    void,
    AxiosError<{ message: string }>,
    number
  >(postBrowseAbandonmentConfigEnable);

  const executeEnableBrowseAbandonmentConfig = useCallback(async () => {
    try {
      await enableBrowseAbandonmentConfig(selectedShopId!);

      queryClient.invalidateQueries([queryName, selectedShopId]);

      showNotification("featuresModule.browseAbandonment.activatedMessage", "success");
    } catch (error) {
      return formatError(error as NetworkError);
    }
  }, [queryName, selectedShopId, showNotification]);

  return executeEnableBrowseAbandonmentConfig;
};
