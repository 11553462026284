import { useQueryWrapper } from "../useQueryWrapper";
import { getQuestionnaire } from "../../api";

export const useQuestionnaireData = (shopId: number | undefined) => {
  const result = useQueryWrapper(["questionnaire", shopId], () => getQuestionnaire(shopId!), {
    enabled: !!shopId,
    refetchOnMount: false
  });

  return result;
};
