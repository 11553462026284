export const GET_ALL_USERS =
  'react-boilerplate/Admin/UserAdministration/GET_ALL_USERS';
export const RESET_USER_PASSWORD =
  'react-boilerplate/Admin/UserAdministration/RESET_USER_PASSWORD';
export const RESET_USER_DASHBOARD =
  'react-boilerplate/Admin/UserAdministration/RESET_USER_DASHBOARD';
export const GET_ALL_SHOPS =
  'react-boilerplate/Admin/StoreAdministration/GET_ALL_SHOPS';
export const SAVE_NEW_USER =
  'react-boilerplate/Admin/UserAdministration/SAVE_NEW_USER';
export const UPDATE_USER =
  'react-boilerplate/Admin/UserAdministration/UPDATE_USER';
export const DELETE_USER =
  'react-boilerplate/Admin/UserAdministration/DELETE_USER';

export const getAllUsers = () => ({
  type: GET_ALL_USERS,
  meta: {
    type: 'api',
    url: 'admin/userlist',
    method: 'get',
  },
});

export const resetUserPassword = (email) => ({
  type: RESET_USER_PASSWORD,
  meta: {
    type: 'api',
    url: 'user/password/reset',
    method: 'post',
    data: { email },
  },
});

export const resetUserDashboard = (id) => ({
  type: RESET_USER_DASHBOARD,
  meta: {
    type: 'api',
    url: `user/${id}/dashboard/reset`,
    method: 'post',
  },
});

export const getAllShops = () => ({
  type: GET_ALL_SHOPS,
  meta: {
    type: 'api',
    url: 'admin/shoplist',
    method: 'get',
  },
});

export const saveNewUser = (
  firstname,
  lastname,
  email,
  shops,
  permissions,
) => ({
  type: SAVE_NEW_USER,
  meta: {
    type: 'api',
    url: 'admin/user',
    method: 'post',
    data: {
      firstname,
      lastname,
      email,
      shops,
      permissions,
    },
    error: {
      500: 'A user with this email address already exists. Please try again with another email address.',
    },
  },
});

export const updateUser = (
  firstname,
  lastname,
  shops,
  userId,
  permissions,
) => ({
  type: UPDATE_USER,
  meta: {
    type: 'api',
    url: `admin/user/${userId}`,
    method: 'put',
    data: {
      firstname,
      lastname,
      shops,
      permissions,
    },
  },
});

export const deleteUser = (email) => ({
  type: DELETE_USER,
  meta: {
    type: 'api',
    url: `admin/user/${email}`,
    method: 'delete',
  },
});
