import { styled, Box as MuiBox } from "@mui/material";
import { VendorDividerWrapperProps } from "./VendorFormVariant.props";

export const VendorDividerWrapper = styled(
  (props: VendorDividerWrapperProps) => <MuiBox {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "withHint"
  }
)(
  ({ withHint }) => `
    &.MuiBox-root {
      & > .MuiDivider-root {
        margin: ${withHint ? "32px" : "12px"} 0 42px;
      }
    }
  `
);
