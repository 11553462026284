import {
  styled,
  Box as MuiBox,
  IconButton as MuiIconButton,
  TextField as MuiTextField,
  Typography as MuiTypography
} from "@mui/material";
import { TimeFieldProps, InputRowProps } from "./TimeInput.props";

export const Wrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      box-sizing: content-box;
      flex-direction: column;
      padding: 0 0 36px;
      width: auto;
    }
  `
);

export const Label = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    }
  }) => `
    &.MuiTypography-root {
      margin: 13px 0 15px 8px;
      color: ${custom.white.light};
      font-size: ${body2.desktop.fontSize};
      line-height: ${body2.desktop.lineHeight};
      font-weight: ${body2.desktop.fontWeight};
    }
  `
);

export const ButtonRow = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 25px;

      ${breakpoints.up("lg")} {
        margin: 0 89px;
      }
    }
  `
);

export const InputRow = styled((props: InputRowProps) => <MuiBox {...props} />, {
  shouldForwardProp: (prop) => prop !== "error"
})(
  ({
    theme: {
      palette: { custom },
      breakpoints
    },
    error
  }) => {
    const borderColor = error ? custom.coral.main : "transparent";

    return `
      &.MuiBox-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 24px;
        background-color: ${custom.grey.light};
        border: 1px solid ${borderColor};
        margin: 0 25px;

        ${breakpoints.up("lg")} {
          margin: 0 49px;
        }
      }
    `;
  }
);

export const ControlButton = styled(MuiIconButton)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiIconButton-root {
      &:first-of-type {
        padding: 0 63px 0 60px;
        border-right: 1px solid ${custom.grey.dark};
      }

      &:last-of-type {
        padding: 0 60px 0 64px;
      }

      border-radius: 0;
      color: ${custom.white.light};
    }
  `
);

export const TimeField = styled((props: TimeFieldProps) => <MuiTextField {...props} />)(
  ({
    theme: {
      palette: { custom },
      typography: { h2 }
    },
    error
  }) => {
    const textColor = error ? custom.coral.main : custom.white.main;

    return `
      &.MuiFormControl-root {
        &:first-of-type {
          & .MuiInputBase-root {
            & .MuiInputBase-input {
              padding: 0 47px 0 0;
              text-align: right;
              border-right: 1px solid ${custom.grey.dark};
            }
          }
        }

        &:last-of-type {
          & .MuiInputBase-root {
            & .MuiInputBase-input {
              padding: 0 0 0 47px;
            }
          }
        }

        & .MuiInputBase-root {

          & .MuiInputBase-input {
            color: ${textColor};
            font-size: ${h2.mobile.fontSize};
            line-height: ${h2.mobile.lineHeight};
            font-weight: ${h2.mobile.fontWeight};
            height: 47px;
          }
    
          & .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }
      }
    `;
  }
);
