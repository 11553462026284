import { apiClient } from "shared";
import { QuestionnaireReferrerStepData, ShopIdParam } from "api";

export const putSubmitReferrer = async ({
  shopId,
  ...values
}: QuestionnaireReferrerStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/referrer`, values);

  return data;
};
