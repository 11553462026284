import { FC, PropsWithChildren, useMemo, memo } from "react";
import { useOpenFeatureFlag } from "../../../hooks";

const FeatureVisibilityWrapperComponent: FC<PropsWithChildren<{ requiredFeature: string }>> = ({
  children,
  requiredFeature
}) => {
  const { value } = useOpenFeatureFlag(requiredFeature);

  const componentHidden = useMemo(() => value !== true, [value]);

  if (componentHidden) {
    return null;
  }

  return <>{children}</>;
};

export const FeatureVisibilityWrapper = memo(FeatureVisibilityWrapperComponent);
