import React from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import UpInput from '../UpInput';
import UpFieldWithErrorState from '../UpFieldWithErrorState';
import globalMessages from '../../messages/globalMessages';

import { Testable } from '../../utils/testingUtils';

const SpecialInput = styled(UpInput)`
  border: 1px solid transparent;
  background: none;
  padding: 6px 10px;
  flex: 0;
`;

const LeftEndInput = styled(SpecialInput)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  max-width: 28px;
`;

const MiddleInput = styled(SpecialInput)`
  border-radius: 0px;
  flex: ${(props) => props.flex || 0};
  max-width: ${(props) => props.maxWidth || ''};
`;

const RightEndInput = styled(SpecialInput)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  max-width: 40px;
`;

const FakeField = styled.div`
  border: ${(props) => props.theme.inputBorder};
  &.error {
    border-color: ${(props) => props.theme.primaryActionColor};
    background-color: #f5d1d2;
  }
  border-radius: 3px;
  background: #fff;
  display: flex;
`;

const regexValidation = (value) => {
  if (value && value !== '') {
    const splittedValue = value.split('/');
    let isValid = true;
    try {
      // eslint-disable-next-line no-new
      new RegExp(splittedValue[1], splittedValue[2]);
    } catch (e) {
      isValid = false;
    }

    if (!isValid) {
      return 'regexError';
    }
    if (!value.split('/')[1]) {
      return 'regexError';
    }
    return undefined;
  }
};

/**
 * Bugticket UP-1012
 *
 * Because negative look behind is not available
 * we have to use negative look ahead and reverse
 * the string to ensure that escaped slashes are
 * not split.
 *
 * a(?!b) is a look ahead that matches all a's which
 * are not followed by the letter b.
 */
const splitOnSlash = (str) => {
  const rev = str.split('').reverse().join('');
  const split = rev.split(/\/(?!\\)/g);
  return split.map((e) => e.split('').reverse().join('')).reverse();
};

class regexField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regex: splitOnSlash(props.input.value)[1] || '',
      flags: splitOnSlash(props.input.value)[2] || '',
    };
  }

  handleMainRegex(value) {
    this.setState({
      regex: value,
    });

    this.props.input.onChange(`/${value}/${this.state.flags}`);
  }

  handleFlagsRegex(value) {
    this.setState({
      flags: value,
    });

    this.props.input.onChange(`/${this.state.regex}/${value}`);
  }

  render() {
    return (
      <div style={{ width: this.props.width || 'auto' }}>
        <UpFieldWithErrorState
          error={this.props.meta.error !== undefined && this.props.meta.touched}
          errorMessage={
            this.props.meta.error ? (
              <FormattedMessage {...globalMessages[this.props.meta.error]} />
            ) : (
              ''
            )
          }
          margin={this.props.margin}
          width={this.props.width}
          label={this.props.label}
        >
          <FakeField
            className={
              this.props.meta.error && this.props.meta.touched && 'error'
            }
          >
            <LeftEndInput value="/" disabled width="28px" />
            <MiddleInput
              placeholder="RegEx Expression" // If change placeholder, don't forget to change it for test/UpRegex.test.js too
              flex="1 0 auto"
              size="1"
              value={this.state.regex}
              onFocus={(e) => this.props.input.onFocus(e)}
              onBlur={(e) =>
                this.props.input.onBlur(
                  `/${e.target.value}/${this.state.flags}`,
                )
              }
              name="regex"
              type="text"
              onChange={(e) => this.handleMainRegex(e.target.value)}
            />
            <MiddleInput value="/" disabled width="28px" maxWidth="28px" />
            <RightEndInput
              placeholder="Flags" // If change placeholder, don't forget to change it for test/UpRegex.test.js too
              value={this.state.flags}
              onFocus={(e) => this.props.input.onFocus(e)}
              onBlur={(e) =>
                this.props.input.onBlur(
                  `/${this.state.regex}/${e.target.value}`,
                )
              }
              name="flags"
              width="40px"
              onChange={(e) => this.handleFlagsRegex(e.target.value)}
            />
          </FakeField>
        </UpFieldWithErrorState>
      </div>
    );
  }
}

regexField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  margin: PropTypes.string,
  width: PropTypes.string,
};

const UpRegex = ({ name, label, margin, width, fieldValidation }) => (
  <Field
    name={name}
    type="text"
    component={regexField}
    label={label}
    validate={fieldValidation ? [regexValidation] : []}
    margin={margin}
    width={width}
  />
);

UpRegex.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  fieldValidation: true,
};

UpRegex.propTypes = {
  fieldValidation: PropTypes.bool.isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default Testable(UpRegex, { regexField });
