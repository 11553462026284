import { QuestionnaireFormOfAddressStepData } from "api";
import { ValidationErrors } from "final-form";

export const formOfAddressValidation =
  (displayContactPerson: boolean) =>
  ({
    pronominalUseFormal,
    pronominalUseInformal,
    contactUseTeam,
    contactUseFirstPerson,
    communicationUseFactually,
    communicationUseCoolStyle,
    communicationUseWarmStyle
  }: QuestionnaireFormOfAddressStepData): ValidationErrors => {
    let result: ValidationErrors = {};

    if (!pronominalUseFormal && !pronominalUseInformal) {
      result = {
        ...result,
        pronominal: "questionnaireModule.errors.atLeastOneRequired"
      };
    }

    if (displayContactPerson && !contactUseTeam && !contactUseFirstPerson) {
      result = {
        ...result,
        contact: "questionnaireModule.errors.atLeastOneRequired"
      };
    }

    if (!communicationUseFactually && !communicationUseCoolStyle && !communicationUseWarmStyle) {
      result = {
        ...result,
        communication: "questionnaireModule.errors.atLeastOneRequired"
      };
    }

    return result;
  };
