import { useCallback } from "react";
import { QuestionnaireCustomerSupportStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitCustomerSupport } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useCustomerSupportStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.CUSTOMER_SUPPORT);

  const data = stepData?.data;

  const metadata = stepData?.metadata?.fields;

  const firstNameDisabled = metadata?.firstName.disabled;

  const lastNameDisabled = metadata?.lastName.disabled;

  const emailDisabled = metadata?.email.disabled;

  const phoneDisabled = metadata?.phone.disabled;

  const submitCustomerSupport =
    useQuestionnaireSubmitMutation<QuestionnaireCustomerSupportStepData>(putSubmitCustomerSupport);

  const executeSubmitCustomerSupport: FormProps<
    QuestionnaireCustomerSupportStepData,
    QuestionnaireCustomerSupportStepData
  >["onSubmit"] = useCallback(
    async ({ firstName, lastName, email, phone }) => {
      try {
        await submitCustomerSupport({
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          email,
          phone
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitCustomerSupport]
  );

  return {
    data,
    firstNameDisabled,
    lastNameDisabled,
    emailDisabled,
    phoneDisabled,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitCustomerSupport
  };
};
