import { ModuleConfig, Module } from "module";
import { accountMenuConfig, AccountMenuRouteConfig } from "config";
import { Permission } from "../../types";

export const getNavigationConfigs = (modules: Module[]): ModuleConfig[] => {
  return modules
    .map((module) => module.getPageConfig())
    .filter(({ path }) => accountMenuConfig.some(({ path: p }) => p === path));
};

export const getAccountPages = (
  modules: ModuleConfig[]
): (AccountMenuRouteConfig & {
  requiredPermission?: Permission;
})[] => {
  return accountMenuConfig.map(
    ({ path, translationKey, includeShopId, badgeKey, requiredPermission, legacy }) => {
      let permission;
      let featureFlag;

      if (legacy) {
        permission = requiredPermission;
      } else {
        const moduleConfig = modules.find(({ path: p }) => p === path);
        permission = moduleConfig?.requiredPermission;
        featureFlag = moduleConfig?.featureFlag;
      }

      return {
        translationKey,
        path,
        requiredPermission: permission,
        includeShopId,
        featureFlag,
        badgeKey
      };
    }
  );
};
