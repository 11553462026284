import React, { useEffect, useState } from 'react';
import Creatable from 'react-select/creatable';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import isEqual from 'lodash/fp/isEqual';

import {
  ClearIndicator,
  customStyles,
  getTheme,
} from '../UpStyledReactSelect';
import UpCustomerSettingsInfo from '../UpCustomerSettingsInfo';

import FieldsHOC from '../FieldsHOC/index';
import { Icon } from './styles';

const CONJUNCTION_STATES = {
  en: 'and',
  de: 'und',
};

const Info = ({ value, message }) => (
  <UpCustomerSettingsInfo
    infoText={message}
    icon="info-circle"
    margin={value.length > 0 ? '8px 0' : '8px'}
    width="200px"
  />
);

Info.propTypes = {
  value: PropTypes.array.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

const IndicatorsContainer = (props) => {
  const {
    children,
    hasValue,
    selectProps: { warningIndicator, infoInputText, value },
  } = props;
  const intl = useIntl();

  function parseWarningIntoString(arr) {
    if (arr.length === 1) return `"${arr[0]}"`;

    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];

    const conjunction = CONJUNCTION_STATES[intl.locale ?? 'en'];

    return `${firsts
      .map((el) => `"${el}"`)
      .join(', ')} ${conjunction} "${last}"`;
  }

  const tooltipText =
    warningIndicator?.length > 0
      ? {
          id: 'components.UpStyledCreatable.infoText',
          defaultMessage:
            'These selected filters {arr} contain a space at the beginning or end of the string. Please make sure they were added on purpose.',
          values: {
            arr: parseWarningIntoString(warningIndicator),
          },
        }
      : infoInputText;

  const tooltipNode =
    hasValue && tooltipText ? (
      <FormattedMessage {...tooltipText}>
        {(message) => <Info value={value} message={message} />}
      </FormattedMessage>
    ) : undefined;

  return (
    <components.IndicatorsContainer {...props}>
      {tooltipNode}
      {children}
    </components.IndicatorsContainer>
  );
};

IndicatorsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasValue: PropTypes.bool.isRequired,
  selectProps: PropTypes.object,
};

const Control = (props) => {
  const {
    children,
    selectProps: { error },
  } = props;

  const errorNode = error && <Icon icon="exclamation-circle" />;

  return (
    <components.Control {...props}>
      {children}
      {errorNode}
    </components.Control>
  );
};

Control.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectProps: PropTypes.object,
};

const UpCreatable = (props) => {
  const [warning, setWarning] = useState([]);

  useEffect(() => {
    if (props.isMulti) {
      const filteredValue = validateValue(props.value);

      if (!isEqual(filteredValue, warning)) {
        setWarning(filteredValue);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.isMulti]);

  function validateValue(values) {
    const RegEx = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);
    return values?.filter((el) => !RegEx.test(el.value)).map((el) => el.value);
  }

  return (
    <Creatable
      styles={customStyles}
      components={{ IndicatorsContainer, ClearIndicator, Control }}
      theme={(theme) => getTheme(theme)}
      warningIndicator={warning}
      {...props}
    />
  );
};

UpCreatable.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  styles: PropTypes.object,
  components: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const UnwrappedCreatable = ({ input, meta }) => {
  const intl = useIntl();

  const onChange = (value) => {
    let val = value;
    if (value === null) val = [];
    input.onChange(fromJS(val));
  };

  const onBlur = (event) =>
    event.target.value !== '' &&
    !input.value.includes(
      fromJS({ label: event.target.value, value: event.target.value }),
    ) &&
    !input.value.includes(
      fromJS({
        label: event.target.value,
        value: event.target.value,
        className: 'Select-create-option-placeholder',
      }),
    ) &&
    input.onChange(
      input.value.push(
        fromJS({ label: event.target.value, value: event.target.value }),
      ),
    );   

  return (
    <UpCreatable
      isMulti
      value={input.value ? input.value.toJS() : []}
      name={input.name}
      onChange={onChange}
      onBlur={onBlur}
      options={[]}
      noOptionsMessage={() =>
        intl.formatMessage({
          id: 'components.UpStyledCreatable.addFilterTag',
          defaultMessage: 'Add a filter tag',
        })
      }
      placeholder={intl.formatMessage({
        id: 'components.UpStyledCreatable.placeholder',
        defaultMessage: 'Filters',
      })}
      formatCreateLabel={(label) =>
        intl.formatMessage(
          {
            id: 'components.UpStyledCreatable.textCreator',
            defaultMessage: 'Add filter tag {filter}',
          },
          { filter: label },
        )
      }
      error={meta.error && meta.submitFailed}
    />
  );
};

const creatable = FieldsHOC(UnwrappedCreatable);

export { creatable, UpCreatable };

UnwrappedCreatable.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};
