import de from "./de.json";
import en from "./en.json";

import * as legacyDe from "./legacy/de.json";
import * as legacyEn from "./legacy/en.json";

export const locales = {
  de: {
    translation: de
  },
  en: {
    translation: en
  }
};

export const legacyLocales = { de: legacyDe, en: legacyEn };
