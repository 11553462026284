/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { useIntl } from 'react-intl';

import { creatable } from '../../UpStyledCreatable';
import { selectBox } from '../../SelectBox';
import { renderField } from '../../UpInput';
import FormValueSelector from '../../UpFormValueSelector';
import UpRegex from '../../UpRegex';

import { getOptions } from '../utils';

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
  @media only screen and (max-width: 680px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const QueryParameter = ({ member, product, resetOnChange }) => {
  const defaultOptions = [
    'equals',
    'empty',
    'not-empty',
    'contains',
    'contains-not',
  ];
  const intl = useIntl();

  const optionsByProduct = {
    optin: ['regex'],
  };

  return (
    <Filter>
      <Row>
        <Field
          name={`${member}.filter.parameter`}
          component={renderField}
          placeholder="Parameter"
        />

        <Field
          name={`${member}.filter.operator`}
          component={selectBox}
          customProps={{
            options: getOptions(optionsByProduct[product], defaultOptions).map(
              ({ value, label }) => ({
                value,
                label: intl.formatMessage(label),
              }),
            ),
            minWidth: '140px',
            margin: '0 10px 0 0',
            onChange: () => resetOnChange(),
          }}
        />
      </Row>
      <FormValueSelector
        fieldName={`conditions[${member
          .split('[')[1]
          .charAt(0)}]filter.operator`}
      >
        {(operator) => {
          if (operator === 'contains' || operator === 'contains-not') {
            return (
              <Row margin="10px 0 0 0">
                <Field
                  name={`${member}.filter.attribute`}
                  component={creatable}
                />
              </Row>
            );
          }

          if (operator === 'regex') {
            return (
              <Row>
                <UpRegex
                  name={`${member}.filter.attribute[0].value`}
                  margin="10px 10px 0 0"
                  width="100%"
                  fieldValidation={false}
                />
              </Row>
            );
          }
        }}
      </FormValueSelector>
    </Filter>
  );
};

QueryParameter.propTypes = {
  product: PropTypes.oneOf(['activation', 'mail', 'optin']).isRequired,
  member: PropTypes.string.isRequired,
  resetOnChange: PropTypes.func.isRequired,
};

export default QueryParameter;
