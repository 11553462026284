export const GET_ALL_SHOPS =
  'react-boilerplate/Admin/StoreAdministration/GET_ALL_SHOPS';
export const CREATE_INVOICE =
  'react-boilerplate/Admin/StoreAdministration/CREATE_INVOICE';
export const CREATE_SHOP =
  'react-boilerplate/Admin/StoreAdministration/CREATE_SHOP';
export const DELETE_SHOP =
  'react-boilerplate/Admin/StoreAdministration/DELETE_SHOP';
export const RESET_SHOP_DELETION =
  'react-boilerplate/Admin/StoreAdministration/RESET_SHOP_DELETION';
export const CREATE_REPORT =
  'react-boilerplate/Admin/StoreAdministration/CREATE_REPORT';
export const REQUEST_SHOP_REGISTRATION_TOKEN =
  'react-boilerplate/Admin/StoreAdministration/REQUEST_SHOP_REGISTRATION_TOKEN';

export const getAllShops = () => ({
  type: GET_ALL_SHOPS,
  meta: {
    type: 'api',
    url: 'admin/shoplist',
    method: 'get',
  },
});

export const createInvoice = (shopId, relativeInvoiceMonth) => ({
  type: CREATE_INVOICE,
  meta: {
    type: 'api',
    url: 'admin/invoice',
    method: 'post',
    data: { shops: shopId, relativeInvoiceMonth },
  },
});

export const createNewShop = (shopName, notify) => ({
  type: CREATE_SHOP,
  meta: {
    type: 'api',
    url: 'shop',
    method: 'post',
    data: {
      name: shopName,
      notify,
    },
  },
});

export const deleteShop = (shopId) => ({
  type: DELETE_SHOP,
  meta: {
    type: 'api',
    url: `admin/shop/${shopId}`,
    method: 'delete',
  },
});

export const resetShopDeletion = (shopId) => ({
  type: RESET_SHOP_DELETION,
  meta: {
    type: 'api',
    url: `admin/shop/${shopId}/resetDeletion`,
    method: 'post',
  },
});

export const createMonthlyReport = (shopId) => ({
  type: CREATE_REPORT,
  meta: {
    type: 'api',
    url: `shop/${shopId}/monthlyReport`,
    method: 'post',
  },
});

export const requestShopRegistrationToken = (shopId) => ({
  type: REQUEST_SHOP_REGISTRATION_TOKEN,
  meta: {
    type: 'api',
    url: `admin/shopRequestToken?shopId=${shopId}`,
    method: 'post',
  },
});
