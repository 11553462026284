import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UpCustomerSettingsInfo from '../UpCustomerSettingsInfo';
import globalMessages from '../../messages/globalMessages';

const InputGroup = styled.div`
  display: inline-block;
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || '0 10px 0 0'};
  @media only screen and (max-width: ${(props) => props.theme.xsmall}) {
    margin: ${(props) => props.margin || '10px 0 0'};
  }
  ${(props) => props.styles}
`;

const shake = keyframes`
  8%, 41% {
    transform: translateX(-10px);
  }
  25%, 58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%, 100% {
    transform: translateX(0);
  }
`;

const shakeSafari = keyframes`
  8%, 41% {
  -webkit-transform: translateX(-10px);
  }
  25%, 58% {
  -webkit-transform: translateX(10px);
  }
  75% {
  -webkit-transform: translateX(-5px);
  }
  92% {
  -webkit-transform: translateX(5px);
  }
  0%, 100% {
  -webkit-transform: translateX(0);
  }
`;

const Label = styled.div`
  margin-left: 3px;
  margin-bottom: 3px;
  font-weight: 500;
  .addNew {
    cursor: pointer;
    color: #029aa3;
    font-weight: 400;
  }
`;

const defaultPosition = {
  top: '50%',
  right: '5px',
};

const ErrorMessage = styled.div`
  color: #fff;
  position: absolute;
  top: ${(props) => `calc(${props.position.top} + 2px)`};
  right: ${(props) => `calc(${props.position.right} - 5px)`};
  background: #ac231d;
  padding: 5px 5px;
  box-sizing: border-box;
  font-size: 13px;
  border-radius: 3px;
  transform: translateY(50%);
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: -8px;
    top: 1px;
    box-sizing: border-box;
    border: 6px solid black;
    border-color: transparent transparent #ac231d #ac231d;
    transform-origin: 0 0;
    transform: rotate(135deg);
  }
`;

const getPositionStyles = ({
  position: {
    top = defaultPosition.top,
    bottom = 'auto',
    right = defaultPosition.right,
    left = 'auto',
  },
}) => `
  position: absolute;
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: #ac231d;
  background: radial-gradient(#fff 50%, #0000 0%);
  border-radius: 50%;
  transform: translate(0%, -50%);
  z-index: 9;

  ${getPositionStyles};
`;

const InfoIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 9;
`;

const Field = styled.div`
  &.errorAnimation {
    animation: ${shake} 0.5s linear;
    -webkit-animation: ${shakeSafari} 0.5s linear;
  }
`;

const renderError = (error) => {
  if (error.get && error.get('message')) {
    return (
      <FormattedMessage
        {...globalMessages[error.get('message')]}
        values={{ value: error.get('value') }}
      />
    );
  }

  if (globalMessages[error]) {
    const errMsg = globalMessages[error];
    return <FormattedMessage {...errMsg} />;
  }
  return error;
};

const errorAndFailedSubmit = (meta) => meta.error && meta.submitFailed;

const Error = ({ position = defaultPosition, error }) => (
  <ErrorMessage position={position}>{renderError(error)}</ErrorMessage>
);

Error.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
  }),
  error: PropTypes.string,
};

const FieldsHOC = (WrappedComponent, checkForError = errorAndFailedSubmit) => {
  class WrappedField extends React.PureComponent {
    state = {
      errorMessageVisible: false,
    };

    toggleErrorMessage(val = true) {
      this.setState({ errorMessageVisible: val });
    }

    render() {
      const {
        meta,
        meta: { error },
        input: { name },
        width,
        margin,
        padding,
        styles,
        label,
        infoText,
        errorIcon: position,
        infoTextInput,
        className,
      } = this.props;

      return (
        <InputGroup
          width={width}
          margin={margin}
          padding={padding}
          styles={styles}
          className={className}
        >
          {label && (
            <Label htmlFor={name}>
              {label}
              {infoText && <UpCustomerSettingsInfo infoText={infoText} />}
            </Label>
          )}
          <Field
            onFocus={() => this.toggleErrorMessage()}
            onBlur={() => this.toggleErrorMessage(false)}
            className={checkForError(meta) && 'errorAnimation'}
          >
            <div style={{ position: error || infoTextInput ? 'relative' : '' }}>
              {checkForError(meta) && (
                <ErrorIcon position={position} icon="exclamation-circle" />
              )}
              {!error && infoTextInput && (
                <InfoIcon>
                  <UpCustomerSettingsInfo
                    infoText={infoTextInput}
                    width="180px"
                  />
                </InfoIcon>
              )}
              <WrappedComponent {...this.props} />
              {checkForError(meta) && this.state.errorMessageVisible && (
                <Error position={position} error={error} />
              )}
            </div>
          </Field>
        </InputGroup>
      );
    }
  }

  WrappedField.defaultProps = {
    errorIcon: defaultPosition,
  };

  WrappedField.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    infoText: PropTypes.string,
    infoTextInput: PropTypes.string,
    width: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    styles: PropTypes.object,
    errorIcon: PropTypes.shape({
      top: PropTypes.string,
      right: PropTypes.string,
    }),
    className: PropTypes.string,
  };

  return WrappedField;
};

export default FieldsHOC;
