import { apiClient } from "shared";
import { BrowseAbandonmentVoucherSettings } from "api";
import { BrowseAbandonmentVoucherSettingsPayload } from "../../types";

export const postBrowseAbandonmentVoucher = async ({
  shopId,
  ...values
}: BrowseAbandonmentVoucherSettingsPayload & { shopId: number }) => {
  const { data } = await apiClient.post<BrowseAbandonmentVoucherSettings>(
    `/${shopId}/triggers/browse-abandonment/voucher-settings`,
    values
  );

  return data;
};
