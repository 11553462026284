import { Box as MuiBox, Typography as MuiTypography, styled } from "@mui/material";

export const Wrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      min-height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      padding: 80px 0 65px;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 550px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);

export const Img = styled("img")(
  ({ theme: { breakpoints } }) => `
    width: 250px;
    margin-bottom: 80px;

    ${[breakpoints.up("md")]} {
      width: 330px;
    }
  `
);

export const Heading = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { h3 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${h3.mobile.fontSize};
      line-height: ${h3.mobile.lineHeight};
      font-weight: ${h3.mobile.fontWeight};
      margin-bottom: 24px;

      ${[breakpoints.up("md")]} {
        font-size: ${h3.tablet.fontSize};
        line-height: ${h3.tablet.lineHeight};
        font-weight: ${h3.tablet.fontWeight};
      }
  
      ${[breakpoints.up("lg")]} {
        font-size: ${h3.desktop.fontSize};
        line-height: ${h3.desktop.lineHeight};
        font-weight: ${h3.desktop.fontWeight};
      }
    }
  `
);

export const Subheading = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      text-align: center;
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};

      ${[breakpoints.up("md")]} {
        font-size: ${body1.tablet.fontSize};
        line-height: ${body1.tablet.lineHeight};
        font-weight: ${body1.tablet.fontWeight};
      }
  
      ${[breakpoints.up("lg")]} {
        font-size: ${body1.desktop.fontSize};
        line-height: ${body1.desktop.lineHeight};
        font-weight: ${body1.desktop.fontWeight};
      }
    }
  `
);
