import { useTranslation } from "react-i18next";
import { DescriptionText, Heading, Subheading, TextInput } from "shared";
import { Form, Field } from "react-final-form";
import { StepWrapper, StepsContent } from "../../StepStyles";
import { additionalReferrerSourceValidator } from "./Referrer.validation";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useReferrerStep } from "../../../hooks";

export const Referrer = () => {
  const { t } = useTranslation();

  const { data, hasPrev, handlePrevStep, executeSubmitReferrer } = useReferrerStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitReferrer}
      render={({ handleSubmit, submitErrors, submitting }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.introduction.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.introduction.referrer.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.introduction.referrer.description")}
            </DescriptionText>
            <Field
              name="additionalReferrerSource"
              validate={additionalReferrerSourceValidator}
              render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                <TextInput
                  required
                  {...input}
                  label={t(
                    "questionnaireModule.steps.introduction.referrer.form.additionalReferrerSource.label"
                  )}
                  placeholder={t(
                    "questionnaireModule.steps.introduction.referrer.form.additionalReferrerSource.placeholder"
                  )}
                  error={
                    (error ||
                      (submitErrors?.["additionalReferrerSource"] && !modifiedSinceLastSubmit)) &&
                    touched
                  }
                  errorMessage={t(submitErrors?.["additionalReferrerSource"] ?? error)}
                  multiline
                  marginTop={64}
                  marginBottom={36}
                />
              )}
            />
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
