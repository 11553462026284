import { useMemo } from "react";
import { importedModules } from "modules-routes";
import { getAccountPages, getNavigationConfigs } from "./useAccountNavigationConfig.helpers";

export const useAccountNavigationConfig = () => {
  return useMemo(() => {
    // Temporally disabled requiredPermission fetching from modules
    const configs = getNavigationConfigs(importedModules);

    const pages = getAccountPages(configs);

    return pages;
  }, []);
};
