import styled, { css } from 'styled-components';

import Button from '../Button';

const sharedUpButtonStyles = css`
  padding: ${(props) => props.padding || '8px 25px'};

  @media screen and (min-width: ${(props) => props.theme.xsmall}) {
    padding: ${(props) => props.padding || '8px 25px'};
  }
`;

const Loading = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
  span {
    font-size: 12px;
    color: #fff;
    margin-left: 5px;
  }
`;

const Value = styled.span`
  color: #fff !important;
  &.loading {
    visibility: hidden;
  }
`;

/** custom buttonColor
 * When use custom buttonColor same color will be used for hover effect
 * to prevent mix of custom and defaul styles
 * */
const StyledUpButton = styled(Button)`
  display: block;
  ${sharedUpButtonStyles}

  .successfull-enter {
    opacity: 0;
    top: 50%;
  }
  .successfull-enter-active {
    opacity: 1;
    top: 11px;
    transition: all 200ms ease;
  }
  .successfull-exit {
    opacity: 1;
    top: 11px;
  }
  .successfull-exit-active {
    top: -50%;
    opacity: 0;
    transition: all 400ms ease;
  }
`;

export { sharedUpButtonStyles, Loading, Value, StyledUpButton };
