/*
 *  This function is inspired by the module 'rewire' which adds getter
 *  and setter functions to a module to access private members for
 *  testing.  Because rewire is not usable here you can use this function
 *  to export specific private functions and fields via the default
 *  export of a module.
 */
export const Testable = (component, internals) => {
  if (process.env.NODE_ENV === 'test') {
    component.__internal__ = internals;
  }
  return component;
};
