/**
 * INSTRUCTIONS FOR TRANSLATIONS
 * Translations must be defined in a message.js file. Check the LiveDashboard folder for an example.
 * There you define ids and keys for every translation as well as a default text. The language
 * for the default texts is english! Then you have to define a translation for every language.
 * In the translations folder is a file for every language. There you use the id that you used
 * in the message.js file to define the translation. Afterwards you can use the translation object
 * from the message.js file in the components.
 */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { localStorage, LocalStorageKey } from 'shared';
import { NotificationContainer } from 'react-notifications';

import {
  setUserRole,
  getProductList,
  getShopList,
  getTaxRates,
  getInvoiceTemplates,
  getMailTemplates,
  getEmployees,
  switchShop,
  getUserPermissions,
} from '../../actions/actions';

import { OnBoarding } from '../OnBoarding';
import { LegacyPrivateNavigationBar } from "../../../shared/components/legacyImplementation/components/LegacyPrivateNavigationBar";
import Footer from './Footer';

import { ContentWrapper } from './styles';

const Dashboard = ({
  shopsLoading,
  shopsRecieved,
  shops,
  selectedShopId,
  role,
  ...props
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    const { role: userRole } = JSON.parse(base64.decode(token.split('.')[1]));
    props.setUserRole(userRole);
    props.getProductList();
    if (!shopsRecieved) props.getShopList();
    props.getUserPermissions();

    return () => {
      const script = document.querySelector('#atlwdg-trigger');
      if (script) {
        script.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (shopsRecieved) {
      if (role !== 3) {
        props.getMailTemplates(selectedShopId);
        props.getTaxRates(selectedShopId);
        props.getInvoiceTemplates(selectedShopId);
        props.getEmployees();
      }
    }
  }, [shopsRecieved]);

  return (
    <>
      <LegacyPrivateNavigationBar />
      {shopsRecieved && (
        <ContentWrapper dashboard={pathname === '/dashboard'}>
          {props.children}
        </ContentWrapper>
      )}
      <Footer path={pathname} shops={shops} />
      <NotificationContainer />
      <OnBoarding />
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  setUserRole: PropTypes.func.isRequired,
  getProductList: PropTypes.func.isRequired,
  getShopList: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired,
  getTaxRates: PropTypes.func.isRequired,
  getInvoiceTemplates: PropTypes.func.isRequired,
  getMailTemplates: PropTypes.func.isRequired,
  getEmployees: PropTypes.func.isRequired,
  shops: PropTypes.object,
  shopsRecieved: PropTypes.bool.isRequired,
  selectedShopId: PropTypes.string,
  role: PropTypes.number,
  switchShop: PropTypes.func.isRequired,
  location: PropTypes.object,
  shopsLoading: PropTypes.bool,
};

// REDUX
const mapStateToProps = (state) => ({
  shops: state.getIn(['customerBackendDashboard', 'userData', 'shops']),
  shopsRecieved: state.getIn([
    'customerBackendDashboard',
    'userData',
    'shopsRecieved',
  ]),
  selectedShopId: state.getIn([
    'customerBackendDashboard',
    'userData',
    'selectedShopId',
  ]),
  role: state.getIn(['customerBackendDashboard', 'userData', 'role']),
  shopsLoading: state.getIn([
    'customerBackendDashboard',
    'userData',
    'shopsLoading',
  ]),
});

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserRole,
      getProductList,
      getUserPermissions,
      getShopList,
      getTaxRates,
      getInvoiceTemplates,
      getMailTemplates,
      getEmployees,
      switchShop,
    },
    dispatch,
  );

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
