import Immutable from 'immutable';

import {
  GET_EMPLOYEES,
  GET_INVOICE_TEMPLATES,
  GET_MAIL_TEMPLATES,
  GET_TAX_RATES,
} from '../actions/actions';

function globalSettingsReducer(
  state = Immutable.fromJS({
    taxRates: [],
    mailTemplates: [],
    invoiceTemplates: [],
    employees: [],
  }),
  action,
) {
  switch (action.type) {
    case GET_TAX_RATES: {
      return state.set('taxRates', Immutable.fromJS(action.data));
    }
    case GET_MAIL_TEMPLATES: {
      return state.set('mailTemplates', Immutable.fromJS(action.data));
    }
    case GET_INVOICE_TEMPLATES: {
      return state.set('invoiceTemplates', Immutable.fromJS(action.data));
    }
    case GET_EMPLOYEES: {
      return state.set('employees', Immutable.fromJS(action.data));
    }
    default: {
      return state;
    }
  }
}

export default globalSettingsReducer;
