import { styled, Box as MuiBox } from "@mui/material";

export const FormContainer = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 39px;
      display: flex;
      flex-direction: column;
    }
  `
);
