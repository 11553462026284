import {
  styled,
  Box as MuiBox,
  Collapse as MuiCollapse,
  Typography as MuiTypography
} from "@mui/material";

export const HeadingFormSubheadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
    }
  `
);

export const HeadingFormInputWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
  &.MuiBox-root {
    width: 100%;

    ${[breakpoints.up("md")]} {
      width: 375px;
    }
  }
  `
);

export const HeadingFormButtonWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      width: 100%;

      ${[breakpoints.up("md")]} {
        width: 240px;
        margin-bottom: 26px;
        margin-left: 22px;
      }
    }
  `
);

export const HeadingFormWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }
  `
);

export const HeadingFormWaitingTimeWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;

      ${[breakpoints.up("md")]} {
        flex-direction: row;
        align-items: center;
      }
    }
  `
);

export const HeadingFormLoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 50px;
      padding-bottom: 39px;
    }
  `
);

export const HeadingFormWaitingTimeAISwitchWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 24px;

      ${[breakpoints.up("md")]} {
        margin-top: 0;
      }
    }
  `
);

export const HeadingFormWaitingTimeAISwitchLabel = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
    }
  `
);

export const ContentWrapper = styled(MuiBox)(
  ({ theme: { breakpoints } }) => `
    &.MuiBox-root {
      margin: 0 20px;
      padding: 80px 0 65px;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 500px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);

export const SubmitButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
  `
);

export const CreateFormCollapse = styled(MuiCollapse)(
  () => `
    &.MuiCollapse-root {
      width: 100%;
      
      & > .MuiCollapse-wrapper {
        & > .MuiCollapse-wrapperInner {
          padding-top: 36px;
        }
      }
    }
  `
);

export const SettingsListWrapper = styled(MuiBox)(
  () => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;

    &:first-of-type {
      margin-top: 56px;
    }
  `
);

export const SettingsListHeader = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    &.MuiTypography-root {
      margin-bottom: 40px;
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
    }
  `
);

export const LoadingWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin: 0 auto 36px;
    }
  `
);

export const AdministrationBlockWrapper = styled(MuiBox)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin: 20px auto 26px;
      padding: 25px 25px 0;
      background-color: ${custom.grey.main};
      border-radius: 5px;
    }
  `
);

export const AdministrationButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: column;
      margin: 20px 0 25px;
    }
  `
);

export const AdministrationStatusWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      & .MuiTypography-root {
        margin-bottom: 0;
      }
    }
  `
);

export const VisibilitySwitchWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root { 
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0;
    }
  `
);

export const AdditionalFieldsCollapse = styled(MuiCollapse)(
  () => `
    & .MuiCollapse-wrapperInner {
      padding-top: 15px;
    }
  `
);

export const CreateButtonWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      margin-top: 36px;
    }
  `
);

export const SwitchWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 38px;
    }
  `
);

export const SwitchLabel = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body1 }
    }
  }) => `
    &.MuiTypography-root {
      color: ${custom.white.main};
      font-size: ${body1.mobile.fontSize};
      line-height: ${body1.mobile.lineHeight};
      font-weight: ${body1.mobile.fontWeight};
    }
  `
);
