import { useQuery } from "@tanstack/react-query";
import { postCheckResetHash } from "../../api";

export const useCheckResetHash = (hash?: string) => {
  const { isLoading, isError } = useQuery(
    ["checkResetHash", hash],
    async ({ queryKey }) =>
      await postCheckResetHash({
        hash: queryKey[1]!
      }),
    {
      enabled: !!hash,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { valid: !isError, loading: isLoading };
};
