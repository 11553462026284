import { ChangeEvent, useCallback, useMemo } from "react";
import { colord } from "colord";
import { convertHexToRGBA, convertAlphaToHex } from "../ColorPicker.helpers";
import { ValueInputsProps } from "./ValueInputs.props";
import { rgbInputWidth, alphaInputWidth, InputField } from "./ValueInputs.styles";
import { ColorValue } from "../../../../types";

export const RgbValueInputs = ({ value, onChange }: ValueInputsProps) => {
  const rgbaValue = useMemo(() => convertHexToRGBA(value), [value]);

  const handleChange = useCallback(
    (key: keyof ColorValue) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const numberOnly = Number(value.replace(/[^0-9]/g, ""));

      const newRgbaValue = {
        ...rgbaValue,
        [key]: numberOnly
      };

      const hex = colord({ ...newRgbaValue, a: undefined }).toHex();

      if (newRgbaValue.a >= 100) {
        onChange(hex);
        return;
      }

      const hexAlpha = convertAlphaToHex(newRgbaValue.a);

      onChange(`${hex}${hexAlpha}`);
    },
    [rgbaValue, onChange]
  );

  return (
    <>
      <InputField width={rgbInputWidth} value={rgbaValue.r} onChange={handleChange("r")} />
      <InputField width={rgbInputWidth} value={rgbaValue.g} onChange={handleChange("g")} />
      <InputField width={rgbInputWidth} value={rgbaValue.b} onChange={handleChange("b")} />
      <InputField
        width={alphaInputWidth}
        InputProps={{
          endAdornment: "%"
        }}
        value={rgbaValue.a}
        onChange={handleChange("a")}
      />
    </>
  );
};
