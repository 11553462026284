export const FETCH_ALL_DATA = 'react-boilerplate/LiveDashboard/FETCH_ALL_DATA';
export const REPORT_INVALID_DATA = 'react-boilerplate/LiveDashboard/REPORT_INVALID_DATA';
export const RESET_DASHBOARD = 'react-boilerplate/LiveDashboard/RESET_DASHBOARD';

export const fetchAllData = (userId) => ({
  type: FETCH_ALL_DATA,
  meta: {
    type: 'api',
    url: `user/${userId}/dashboard`,
    method: 'get',
  },
});

export const reportInvalidData = (orderId, comment, api = 'transactions', tableId) => ({
  type: REPORT_INVALID_DATA,
  ids: orderId,
  tableId,
  meta: {
    type: 'api',
    url: `${api}/report`,
    method: 'post',
    data: {
      id: orderId,
      comment,
    },
  },
});

export const resetDashboard = (userId) => ({
  type: RESET_DASHBOARD,
  meta: {
    type: 'api',
    url: `user/${userId}/dashboard/reset`,
    method: 'post',
  },
});
