import { useCallback } from "react";
import { QuestionnaireAboutUsStepData, QuestionnaireStepType } from "api";
import { FormProps } from "react-final-form";
import { NetworkError, useErrorFormatter } from "shared";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { putSubmitAboutUs } from "../../api";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useAboutUsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ABOUT_US);

  const data = stepData?.data;

  const submitAboutUs =
    useQuestionnaireSubmitMutation<QuestionnaireAboutUsStepData>(putSubmitAboutUs);

  const executeSubmitAboutUs: FormProps<
    QuestionnaireAboutUsStepData,
    QuestionnaireAboutUsStepData
  >["onSubmit"] = useCallback(
    async ({ isConfirmed }) => {
      try {
        await submitAboutUs({
          isConfirmed
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAboutUs]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitAboutUs
  };
};
