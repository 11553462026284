import Immutable from 'immutable';
import {GET_ZERO_SCV} from "./actions";


export default function irregularReducer(state = Immutable.fromJS({
                                                loading: false,
                                                data: undefined,
                                                errorMessage: undefined,
                                              }),
                                              action) {



  switch (action.type) {
    case GET_ZERO_SCV: {
      return state.merge(Immutable.fromJS({
        data: action.data,
        loading:false,
        errorMessage:undefined,
      }));
    }
    case GET_ZERO_SCV + "_LOADING": {

      return state.merge({data:undefined, loading: true, errorMessage:undefined});
    }
    case GET_ZERO_SCV + "_ERROR": {

      return state.merge({data:undefined, loading:false, errorMessage:JSON.stringify(action)});
    }

    default: {
      return state;
    }
  }
}
