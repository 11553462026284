import { useState, useEffect, useCallback, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QuestionnaireStepStatus } from "api";
import {
  StepperTabAccordion,
  StepperTabSummary,
  StepperTabTypography,
  StepperTabDetails
} from "./StepperTab.styles";
import { StepperTabProps } from "./StepperTab.props";
import { StepperSubTab } from "../StepperSubTab";
import { NumberCircle } from "../../NumberCircle";

const Component = ({
  title,
  index,
  steps,
  questionnaireSubmitted,
  selectedStep,
  onChangeStep
}: StepperTabProps) => {
  const { t } = useTranslation();

  const selected = useMemo(
    () => !!steps.find(({ type }) => type === selectedStep),
    [steps, selectedStep]
  );

  const completed = useMemo(
    () =>
      // If the questionnaire is submitted, the tab is considered completed
      // also if all the steps are submitted or disabled and at least one step is submitted
      // the tab is considered completed
      questionnaireSubmitted ||
      (steps.every(
        ({ status }) =>
          status === QuestionnaireStepStatus.SUBMITTED ||
          status === QuestionnaireStepStatus.DISABLED
      ) &&
        steps.some(({ status }) => status === QuestionnaireStepStatus.SUBMITTED)),
    [steps, questionnaireSubmitted]
  );

  const [opened, setOpened] = useState(selected);

  const openedState = useMemo(
    () => (questionnaireSubmitted ? false : opened),
    [questionnaireSubmitted, opened]
  );

  useEffect(() => {
    if (selected) {
      setOpened(selected);
    }
  }, [selected]);

  const handleAccordionChange = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleStepChange = useCallback(
    (type: string) => {
      onChangeStep(type);
    },
    [onChangeStep]
  );

  return (
    <StepperTabAccordion
      disabled={questionnaireSubmitted}
      expanded={openedState}
      onChange={handleAccordionChange}
    >
      <StepperTabSummary>
        <NumberCircle
          selected={!questionnaireSubmitted && selected}
          completed={completed}
          index={index}
        />
        <StepperTabTypography selected={!questionnaireSubmitted && selected} completed={completed}>
          {t(`questionnaireModule.steps.${title}.title`)}
        </StepperTabTypography>
      </StepperTabSummary>
      <StepperTabDetails>
        {steps.map(({ type, status }) => (
          <StepperSubTab
            key={type}
            type={type}
            title={`questionnaireModule.steps.${title}.${type}.title`}
            selected={type === selectedStep}
            completed={status === QuestionnaireStepStatus.SUBMITTED}
            disabled={status === QuestionnaireStepStatus.DISABLED}
            onClick={handleStepChange}
          />
        ))}
      </StepperTabDetails>
    </StepperTabAccordion>
  );
};

export const StepperTab = memo(Component);
