import { PaymentSettings } from "api";
import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared";
import { getComeBackMailingPayment } from "../../api";

export const useGetComeBackMailingPayment = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper<PaymentSettings>(
    [queryName, selectedShopId],
    async () => await getComeBackMailingPayment(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const resultData = data ?? {};

  return { initialState: resultData, loading: isLoading };
};
