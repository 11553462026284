import { TriggerMailsPaymentSettingsFormBase } from "../TriggerMailsPaymentSettingsFormBase";
import { useUpdateComeBackMailingPayment, useGetComeBackMailingPayment } from "../../hooks";

export const ComeBackMailingPaymentSettingsForm = () => {
  const { initialState, loading } = useGetComeBackMailingPayment();

  const updateMethod = useUpdateComeBackMailingPayment();

  return (
    <TriggerMailsPaymentSettingsFormBase
      initialValues={initialState}
      loading={loading}
      translationKey="comeBackMailing"
      onSubmit={updateMethod}
    />
  );
};
