import { styled, ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material";

import { ToggleButtonGroupProps } from "./ToggleButtonGroup.props";

export const ToggleButtonGroup = styled(
  ({ variant = "outlined", children, ...props }: ToggleButtonGroupProps) => {
    return (
      <MuiToggleButtonGroup
        classes={{
          grouped: `MuiToggleButtonGroup-grouped${
            variant.charAt(0).toUpperCase() + variant.slice(1)
          }`
        }}
        {...props}
      >
        {children}
      </MuiToggleButtonGroup>
    );
  }
)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 }
    },
    variant = "outlined"
  }) => {
    let color;
    let hoverColor;
    let backgroundColor;
    let hoverBackgroundColor;

    switch (variant) {
      case "contained":
        color = custom.white.light;
        hoverColor = custom.white.main;
        backgroundColor = custom.grey.dark;
        hoverBackgroundColor = custom.turquoise.main;
        break;
      case "text":
        color = custom.turquoise.main;
        hoverColor = custom.turquoise.main;
        backgroundColor = "transparent";
        hoverBackgroundColor = `${custom.turquoise.main}29`;
        break;
      case "outlined":
        color = custom.turquoise.main;
        hoverColor = custom.turquoise.main;
        backgroundColor = "transparent";
        hoverBackgroundColor = `${custom.turquoise.main}29`;
        break;
    }

    return `
      & .MuiToggleButton-root {
        max-height: calc(${body2.desktop.lineHeight} + (10px * 2)); // vertical padding + line height
        color: ${color};
        font-size: ${body2.desktop.fontSize};
        line-height: ${body2.desktop.lineHeight};
        background-color: ${backgroundColor};
        border: 0;
        padding: 10px 16px;

        &.Mui-selected {
          color: ${hoverColor};
          background-color: ${hoverBackgroundColor};
          pointer-events: none;
        }

        &.Mui-disabled {
          color: ${color};
          background-color: ${backgroundColor};
        }

        &.MuiToggleButtonGroup-groupedText {
          &.MuiToggleButtonGroup-groupedHorizontal {
            &:not(:last-of-type) {
              border-right: 1px solid;
            }
          }

          &.MuiToggleButtonGroup-groupedVertical {
            &:not(:last-of-type) {
              border-bottom: 1px solid;
            }
          }
        }

        &.MuiToggleButtonGroup-groupedOutlined {
          border: 1px solid;

          &.MuiToggleButtonGroup-groupedHorizontal {
            &:not(:last-of-type) {
              border-right: 0;
            }
          }

          &.MuiToggleButtonGroup-groupedVertical {
            &:not(:last-of-type) {
              border-bottom: 0;
            }
          }
        }

        &:hover {
          color: ${hoverColor};
          background-color: ${hoverBackgroundColor};
        }
      }
    `;
  }
);
