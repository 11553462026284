import { SetPasswordResponse } from "api";
import { Routes } from "config";
import { setUserDataToCookies, setUserDataToLocalStorage } from "modules/Auth/utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useAuthenticateUser = () => {
  const navigate = useNavigate();

  const authenticateUser = useCallback((res: SetPasswordResponse) => {
    setUserDataToLocalStorage(res);

    setUserDataToCookies(false, res.data.shopId);

    navigate(Routes.DASHBOARD);
  }, []);

  return authenticateUser;
};
