import Immutable from 'immutable';
import { GET_DWH_DATA } from './actions';

export default function lineDataReducer(
  state = Immutable.fromJS({
    loading: false,
  }),
  action,
) {
  const shopId = action.shopSqlId;
  const { dataType } = action;

  switch (action.type) {
    case `${GET_DWH_DATA}_LOADING`: {
      return state.merge({ loading: true });
    }
    case `${GET_DWH_DATA}_ERROR`: {
      return state.merge({
        errorMessage: 'Error fetching data for graph chart',
        loading: false,
      });
    }
    case GET_DWH_DATA: {
      const newState = Object.assign({}, state.toJS(), {
        loading: false,
      });

      if (!newState[shopId]) {
        newState[shopId] = {};
      }

      newState[shopId][dataType] = action.data.data;
      return state.merge(Immutable.fromJS(newState));
    }
    default: {
      return state;
    }
  }
}
