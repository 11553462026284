import { useCallback } from "react";
import { useSelectedShop } from "../useSelectedShop";
import { UNSAFE_useLegacyShopSwitch } from "../useLegacyShopSwitch";
import { ShopData } from "../../types/shopData.type";

const SHOP_LIST_LOADING_INITIAL_STATE: ShopData = { id: "", shopId: 0, name: "..." };

export const useShopSwitchState = () => {
  // unfortunately, we need to use this hook here, because we need to update selected shop in legacy store
  const legacyShopSwitchMethod = UNSAFE_useLegacyShopSwitch();

  const {
    selectedShopId,
    selectedShopValue,
    loading,
    shopList,
    refetchShopList,
    onSelectedShopChange
  } = useSelectedShop();

  const resultLoading = loading || !selectedShopId || !shopList;

  const currentlySelectedShop = resultLoading ? SHOP_LIST_LOADING_INITIAL_STATE : selectedShopValue;

  const shopsList = resultLoading ? [SHOP_LIST_LOADING_INITIAL_STATE] : shopList;

  const onShopChange = useCallback(
    (id: string, shopId: number) => {
      onSelectedShopChange(shopId);
      legacyShopSwitchMethod(id, shopId);
    },
    [onSelectedShopChange, legacyShopSwitchMethod]
  );

  return {
    shopsList,
    currentlySelectedShop,
    onShopChange,
    refetchShopList,
    loading: resultLoading,
    shopsListLoading: loading
  };
};
