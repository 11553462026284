import { apiClient } from "shared";
import { ComeBackMailingTextSettingsPayload } from "../../types";

export const putComeBackMailingText = async ({
  shopId,
  id,
  ...values
}: ComeBackMailingTextSettingsPayload & { shopId: number; id: number }) => {
  const { data } = await apiClient.put<void>(
    `/${shopId}/triggers/come-back-mailing/text-settings/${id}`,
    values
  );

  return data;
};
