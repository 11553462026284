import { createContext } from "react";
import { OpenFeatureState } from "./openFeatureState.types";

// Feature flags are moved into global context to be accessible from any component
// And not trigger state invalidation and flickering on legacy components
const initialState: OpenFeatureState = {
  loading: true,
  error: false,
  flagsState: {},
  addFlagToContext: () => {},
  onFlagsChangeHandler: () => {}
};

export const openFeatureStateContext = createContext<OpenFeatureState>(initialState);

export const OpenFeatureStateContextProvider = openFeatureStateContext.Provider;
