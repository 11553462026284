import { QuestionnaireFormOfAddressStepData, QuestionnaireFormOfAddressStepMetadata } from "api";

export const formOfAddressFormMapper = (
  {
    communicationUseCoolStyle,
    communicationUseFactually,
    communicationUseWarmStyle,
    contactUseFirstPerson,
    contactUseTeam,
    pronominalUseFormal,
    pronominalUseInformal
  }: QuestionnaireFormOfAddressStepData,
  metadata: QuestionnaireFormOfAddressStepMetadata | undefined
): QuestionnaireFormOfAddressStepData => {
  const fromPersonInactive = metadata?.fields?.contactUseFirstPerson.disabled;

  const fromTeamInactive = metadata?.fields?.contactUseTeam.disabled;

  const contactEnabled = !fromPersonInactive || !fromTeamInactive;

  return {
    communicationUseCoolStyle,
    communicationUseFactually,
    communicationUseWarmStyle,
    pronominalUseFormal,
    pronominalUseInformal,
    contactUseFirstPerson: contactEnabled ? !!contactUseFirstPerson : undefined,
    contactUseTeam: contactEnabled ? !!contactUseTeam : undefined
  };
};
