import { OpenFeature } from "@openfeature/web-sdk";
import { useEffect } from "react";
import {
  useAuthState,
  UNSAFE_useLegacyShopListDataSelector,
  useSelectedShop,
  useOpenFeatureState
} from "../../../hooks";

export const OpenFeatureContextObserver = () => {
  const { token, getRole } = useAuthState();
  const { selectedShopId } = useSelectedShop();
  const { onFlagsChangeHandler } = useOpenFeatureState();
  // TODO: Rudimentary code, should be removed as soon as redux will be removed
  const {
    shopsReceived,
    loading,
    selectedShopId: legacySelectedShopId
  } = UNSAFE_useLegacyShopListDataSelector();

  // Legacy shop id, should be removed as soon as redux will be removed
  const legacyShopId = shopsReceived && !loading ? legacySelectedShopId : undefined;
  const shopId = selectedShopId ?? legacyShopId;

  useEffect(() => {
    const execute = async () => {
      if (token) {
        const context = OpenFeature.getContext();

        await OpenFeature.setContext({
          ...context,
          userRole: getRole() as number
        });

        onFlagsChangeHandler();
      } else {
        await OpenFeature.setContext({});
      }
    };

    execute();
  }, [token, onFlagsChangeHandler]);

  useEffect(() => {
    const execute = async () => {
      const context = OpenFeature.getContext();

      if (shopId) {
        await OpenFeature.setContext({
          ...context,
          shopId: Number(shopId)
        });

        onFlagsChangeHandler();
      }
    };

    execute();
  }, [shopId, onFlagsChangeHandler]);

  return null;
};
