/**
 *
 * DocumentLanguageUpdater.react.js
 *
 * An additional decorator class which sets the lang attribute to the html tag
 *
 * NOTE: Needed for cookie banner language switch
 */

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class DocumentLanguageUpdater extends Component {
  componentDidMount() {
    this.updateDocumentLanguage(this.getDocumentLanguage(this.props.intl.locale));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.intl.locale !== this.props.intl.locale) this.updateDocumentLanguage(this.getDocumentLanguage(this.props.intl.locale));
  }

  getDocumentLanguage(lang) {
    return {
      en: 'en-GB',
      de: 'de-DE',
    }[lang];
  }

  updateDocumentLanguage(lang) {
    window.document.documentElement.lang = lang;
  }

  render() {
    return this.props.children;
  }
}

DocumentLanguageUpdater.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  intl: PropTypes.object,
};

export default injectIntl(DocumentLanguageUpdater);
