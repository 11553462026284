import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';

import { renderEditor } from '../../UpCodeEditor';

const Filter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: stretch;
    /* margin-right: 10px; */
  }
  @media only screen and (max-width: 550px) {
    flex-direction: row;
    /* margin-top: 10px; */
    margin-left: 0px;
    align-items: center;
  }
  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: stretch;
    width: auto;
    /* margin-right: 10px; */
  }
`;

const Condition = ({ member, product }) => (
  <Filter>
    <Field
      name={`${member}.value`}
      component={renderEditor}
      placeholder="JavaScript Code"
      height="100px"
      givenSignature="()=>{"
      mode="javascript"
      validateCustom={{
        editor: true,
        dom: product === 'mail',
      }}
      errorIcon={{
        top: '85%',
        right: '20px',
      }}
    />
  </Filter>
);

Condition.propTypes = {
  member: PropTypes.string.isRequired,
  product: PropTypes.oneOf(['activation', 'mail', 'optin']).isRequired,
};

export default Condition;
