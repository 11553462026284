export const GET_FEATURE_TOGGLES =
  'react-boilerplate/App/FeatureToggles/GET_FEATURE_TOGGLES';
export const UPDATE_FEATURE_TOGGLES =
  'react-boilerplate/App/FeatureToggles/UPDATE_FEATURE_TOGGLES';

export const getFeatureToggles = () => ({
  type: GET_FEATURE_TOGGLES,
  meta: {
    type: 'api',
    url: 'feature-toggles',
    method: 'get',
  },
});

export const saveFeatureToggles = (data) => ({
  type: UPDATE_FEATURE_TOGGLES,
  meta: {
    type: 'api',
    url: 'feature-toggles',
    method: 'put',
    data,
  },
});
