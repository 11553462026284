import { styled, Box as MuiBox } from "@mui/material";

export const GTagTriggerDiv = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      height: 1px;
      width: 1px;
    }
  `
);
