import { Drawer as MuiDrawer, styled } from "@mui/material";

export const NavigationBarDrawer = styled(MuiDrawer)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
  &.MuiDrawer-root {
    z-index: 1000;

    & .MuiDrawer-paper { 
      background-color: ${custom.background};
      width: 100vw;
      padding-top: 64px;
      overflow-y: auto;
      overflow-x: hidden;
  
      ${breakpoints.up("sm")} {
        width: 400px;
      }
    }
  }
`
);
