import { AppBar, Toolbar, Logo, LanguageSwitch } from "./PublicNavigationBar.styles";

export const PublicNavigationBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Logo />
        <LanguageSwitch />
      </Toolbar>
    </AppBar>
  );
};
