import { apiClient } from "shared";
import { QuestionnaireMailSettingsStepData, ShopIdParam } from "api";

export const putSubmitMailSettings = async ({
  shopId,
  ...values
}: QuestionnaireMailSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/mail-settings`, values);

  return data;
};
