import { FieldValidator } from "final-form";
import { SetPasswordValues } from "../../types";

const ONE_DIGIT_REGEX = /\d+/;

const ONE_LOWERCASE_REGEX = /[a-z]+/;

const ONE_UPPERCASE_REGEX = /[A-Z]+/;

const ONE_SPECIAL_CHARACTER_REGEX = /[^A-Za-z0-9]+/;

export const passwordValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "authModule.errors.required";
  }

  if (value.length < 8) {
    return "authModule.errors.tooShort";
  }

  if (!ONE_DIGIT_REGEX.test(value)) {
    return "authModule.errors.missingDigits";
  }

  if (!ONE_LOWERCASE_REGEX.test(value)) {
    return "authModule.errors.missingLowercase";
  }

  if (!ONE_UPPERCASE_REGEX.test(value)) {
    return "authModule.errors.missingUppercase";
  }

  if (!ONE_SPECIAL_CHARACTER_REGEX.test(value)) {
    return "authModule.errors.missingSpecialCharacter";
  }
};

export const confirmPasswordValidator: FieldValidator<string> = (value, allValues) => {
  if (!value) {
    return "authModule.errors.required";
  }

  if (value !== (allValues as SetPasswordValues).password) {
    return "authModule.errors.passwordsNotMatch";
  }
};
